@charset "UTF-8";
/*------------------------------------------------------------------
Author       : Code-Theme
Template Name: Find Houses - HTML5 Template
Version      : 1.2
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]

1. Typography
2. Section Headings
3. Section Recently Properties
4. Section Welcome
5. Section Property Services
6. Section Featured Properties
7. Section Popular Places
8. Section Popular Places 2
9. Section Agents
10.Section Top Location
11.Section Blog
12.Section Testimonials
13.Section Partnes
14.Section Counter Up
15.Section Footer
16.Section Home 2 Image
17.Section Home 3 Image
18.Section Home 4 Image Video
19.Section Home 5 Image
20.Section Home 2, 3 and 4 Search Area
21.Section Home 6 Parallax Image
22.Section Home 7 Video
23.Section Info Sidebar Home 13
24.Section Banner Slider Home 13
25.Section Featured Properties Home 13
26.Section Banner Slider Home 14
27.Section Typed Image Home 15
28.Section Info Home 15
29.Section Featured Properties Home 15
30.Section Info Help Home 15
31.Section Visited Cities Home 15
32.Section Recently Properties Home 16
33.Seccion Parallax Home 17
34.Seccion Info Help Home 17
35.Seccion Agents Home 17
36.Seccion Image Home 8
37.Seccion Image Home 18
38.Seccion Why Choose Us Home 18
39.Seccion Testimonials Home 18
40.Seccion Header Slider Home 19
41.Seccion Header Search Home 19
42.Seccion Header Video Home 20
43.Seccion Home 23 Image
44.Seccion Header Image Home 24
45.Inner Page Properties List
46.Inner Page Properties List Right Sidebar
47.Inner Page Properties Grid
48.Inner Page Property Map
49.Inner Page Property Top Map
50.Inner Page Property Details
51.Inner Page Agents Listing Grid
52.Inner Page Agents Listing Row
53.Inner Page Agents Details
54.Inner Page Dashboard
55.Inner Page Payment-Method
56.Inner Page About Us
57.Inner Page Faq
58.Inner Page Pricing
59.Inner Page 404
60.Inner Page Login
61.Inner Page Coming Soon
62.Inner Page Under Construction
63.Inner Page Blog Grid
64.Inner Page Blog Grid Sidebar
65.Inner Page Blog List Sidebar
66.Inner Page Blog Details
67.Inner Page Contact Us
68.Inner Page Submit Property
69.PreLoader
70.Header Google Maps
71.Header Filter
72.UI Element
73.Page Shop
74.Medias Queries
-------------------------------------------------------------------*/
/*-----------------------------------*/
/*------ 1. TYPOGRAPHY ------*/
/*-----------------------------------*/
html {
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #434747;
  overflow-x: hidden !important;
}

p{

}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 3em;
  font-weight: 600;
  color: #272B2B;
}

h2 {
  font-family: 'Poppins', sans-serif;
  color: #272B2B;
  font-size: 36px;
  font-weight: 600;
}

h2 span {
  font-weight: 500;
  color: #FF385C;
}

h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #272B2B;
}

h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: #333;
  font-weight: 500;
}

h5 {
  color: #444;
}

.top-headings-section {
  padding-bottom: 2rem;
}

.top-headings-section p {
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  color: #555;
  max-width: 550px;
}

.top-headings-section h2::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 100px;
  margin: 0 auto;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

*::after,
*::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.section-title {
  padding-left: 35px;
  position: relative;
  margin-bottom: 2.5rem;
}

.section-title:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 15px;
  content: '';
  border-radius: 3px;
  position: absolute;
  background-color: #FF385C;
}

.section-title h3 {
  font-size: 18px;
  color: #111;
  text-transform: uppercase;
}

.section-title h2 {
  font-size: 28px;
  color: #FF385C;
  text-transform: uppercase;
}

.section-title.col-md-5 {
  margin-left: 1rem;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.bg-white {
  background: #fff !important;
}

.bg-gray {
  background: #f5f7fb !important;
}



/*-----------------------------------*/
/*------ 2. SECTION HEADINGS ------*/
/*-----------------------------------*/
.header-top {
  background: #FF385C;
  color: #7f8997;
  display: block;
  padding: 1rem 0;
}

.header.head-tr {
  position: absolute;
  width: 100%;
  z-index: 99999;
}

.header-top.head-tr {
  background: rgba(255, 255, 255, 0.06);
  color: #7f8997;
  display: block;
  padding: 1rem 0;
}

@media screen and (min-width: 1025px) {
  .container-header {
    max-width: 1360px;
  }

  #logo {
    margin-right: 0px;
  }
}
@media screen and (max-width: 767px) {
  .header-top {
    padding: 0px;
  }
}
.header-top .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-left: auto;
}

.header-top .the-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 60px;
  padding-left: 60px;
}

.top-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-top p {
  margin-right: 2rem;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0px;
}

.header-top p i {
  color: #fff;
  margin-right: .5rem;
  font-size: 1.3rem;
}

.top-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.top-social .login-wrap ul {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  list-style: none;
  margin-bottom: 0px;
}

.top-social .login-wrap ul li {
  padding: 0px 12px;
  position: relative;
}

.top-social .login-wrap ul li a {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.top-social .login-wrap ul li a:hover {
  color: #fff;
}

.top-social .login-wrap ul li a i {
  margin-right: 5px;
}

.header-top .social-icons {
  text-align: right;
  margin-left: .6rem;
}

.header-top .social-icons i {
  line-height: 26px;
  font-size: 1.2rem;
  margin-right: 1rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.social-icons-header .fa-facebook {
  color: #fff;
}

.social-icons-header .fa-twitter {
  color: #fff;
}

.social-icons-header .fa-google-plus {
  color: #fff;
}

.social-icons-header .fa-linkedin {
  color: #fff;
}

.social-icons-header .fa-skype {
  color: #fff;
}

.header-top .social-icons:hover {
  color: #FF385C;
}

.header-top .dropdown {
  display: inline-block;
  background: transparent;
}

.header-top .dropdown button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.header-top .dropdown button:hover {
  color: #fff;
}

.header-top .dropdown .dropdown-menu {
  border-radius: 0;
  left: auto;
  margin: 0;
  min-width: 115px;
  padding: 0px;
  position: absolute;
  right: 0;
  z-index: 9999999;
}

.header-top .dropdown-menu img {
  margin-right: 5px;
}

.header-top .dropdown-menu li {
  color: #333;
  cursor: pointer;
  padding: 5px 10px;
}

.header-top .dropdown-menu li:hover {
  background: #eee none repeat scroll 0 0;
}

.heading {
  background: #121B22;
  color: #fff;
  position: relative;
  padding: 1rem 0;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.sticky,
.sticky.head-tr {
  -webkit-animation: 600ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 600ms ease-in-out 0s normal none 1 running fadeInDown;
  background: #121B22 none repeat scroll 0 0;
  left: 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}

@media screen and (max-width: 991px) {
  .heading.head-tr {
    background: rgba(255, 255, 255, 0.06);
    padding-bottom: 0px;
  }

  .header.head-tr #main-menu {
    background: #fff;
    width: 100%;
  }

  .header.head-tr #main-menu ul a,
  .header.head-tr #main-menu .po a::after {
    color: #000 !important;
  }

  .sticky.head-tr {
    background: #121B22 none repeat scroll 0 0;
  }

  .header.head-tr .heading .logo img {
    padding-left: 15px;
    padding-bottom: 1rem;
  }

  .header.head-tr .button-menu {
    padding-right: 18px !important;
  }

  .heading.head-tr .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .header.head-tr .search-button,
  .header.head-tr .button-menu {
    margin-bottom: 1rem !important;
  }
}
.heading .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.heading .logo img {
  width: 60px;
}

@media screen and (max-width: 414px) {
  .heading .logo img {
    width: 60px;
  }
}
@media screen and (max-width: 575px) {
  .header.head-tr #bloq-search {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tp-caption.slid-2 {
    padding-top: 7.7rem;
  }
}
.search-button,
.button-menu {
  border: 0;
  font-size: 1.5rem;
  margin: 0;
  padding: 0 .5rem;
  background: transparent;
  color: #fff;
}

.search-button:focus,
.button-menu:focus {
  outline: 0;
}

.search-button {
  color: #fff;
  margin-left: auto;
  margin-right: .8rem;
}

.get-quote {
  background: #fff;
  margin-left: 2rem;
  color: #333;
  padding: 1rem;
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  border-radius: 5px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.get-quote:hover {
  background: #fff;
  color: #fff !important;
}

.get-quote p {
  text-transform: capitalize;
  margin: 0px;
  font-weight: 600;
}

.get-quote a {
  text-decoration: none;
  color: #333;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.get-quote a:hover {
  color: #FF385C;
}

#bloq-search {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
}

.bloq-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3rem;
  margin: 1.3rem 0;
  z-index: 1;
}

.bloq-search input[type="text"] {
  border: 1px solid #FF385C;
  border-radius: 5px 0 0 5px;
  margin-bottom: 0px;
  padding: 1rem;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.bloq-search input[type="submit"] {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  border: 0;
  width: auto;
  border-radius: 0 5px 5px 0;
  background: #FF385C;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0px;
  padding: 0 1rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.bloq-search input[type="submit"]:hover {
  background: #121B22;
  color: #fff;
}

#main-menu {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
}

#main-menu ul {
  padding: 0;
  list-style: none;
  margin: 1rem 0 0 0;
}

#main-menu ul a {
  display: block;
  padding: .8rem 0;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  border-top: 1px solid #e5e5e5;
  text-decoration: none;
}

#main-menu ul a:hover,
#main-menu ul li.active a {
  color: #FF385C;
}

#main-menu .card {
  background: transparent;
}

#main-menu .card a:hover {
  background: transparent;
}

#main-menu .po a::after {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #fff;
  margin-left: .3rem;
}

.text-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*------ ARROW DOWN ------*/
.arrow-down a {
  display: inline-block;
  color: #fff;
  font-size: 3rem;
  -webkit-animation: animate-arrow 2s ease-in-out infinite;
          animation: animate-arrow 2s ease-in-out infinite;
}

@-webkit-keyframes animate-arrow {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes animate-arrow {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/*------ HEADER SLIDER ------*/
.rev_slider_wrapper {
  width: 100%;
}

.slotholder {
  position: relative;
}

.slotholder:after {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.tp-leftarrow {
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.tp-leftarrow:hover {
  background: #FF385C;
}

.tp-rightarrow {
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.tp-rightarrow:hover {
  background: #FF385C;
}

.tp-parallax-wrap {
  top: 10px;
}

.tp-bannertimer {
  z-index: 21;
}

.mfp-close-btn-in .mfp-close {
  color: #fff;
  font-size: 48px;
}

.mfp-bg {
  z-index: 1112;
}

.mfp-wrap {
  z-index: 1113;
}

.rev_slider a {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.text-theme-colored2 {
  color: #0098ef !important;
}

.tp-caption.font-p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 496px) {
  .tp-caption.font-p {
    margin-top: .8rem;
  }
}
.btn-default.btn-theme-colored2 {
  color: #fff;
  font-weight: 600;
  background-color: #121B22;
  border-color: #fff;
  font-size: 16px;
  padding: 13px 32px;
  margin-top: .8rem;
  margin-right: 1rem;
  line-height: 1.3;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.btn-default.btn-theme-colored2:hover {
  background: #FF385C;
  color: #fff;
  border-color: #fff;
}

.btn-dark.btn-theme-colored {
  color: #fff;
  background-color: #FF385C;
  border-color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 13px 32px;
  margin-top: .8rem;
  line-height: 1.3;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.btn-dark.btn-theme-colored:hover {
  background: #121B22;
  color: #fff;
  border-color: #fff;
}

/*------ BUTTOM HOVER EFFECT ------*/
/* Box colors */
.bg-1 {
  background: #FF385C;
  color: #fff;
  display: inline-block;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin: 0px;
}

.bg-2 {
  background: #fff;
  color: #000;
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.bg-3 {
  background: transparent;
  color: #e54242;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0px;
}

.bg-4 {
  background: transparent;
  color: #fff;
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* Common button styles */
.box .button {
  float: left;
  min-width: 150px;
  max-width: 250px;
  display: block;
  margin-bottom: 0px;
  padding: 1em 2em;
  border: 2px solid;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.button:focus {
  outline: none;
}

.button > span {
  vertical-align: middle;
}

/* Text color adjustments */
.bg-1 .button {
  color: #fff;
  border-color: #fff;
}

.bg-2 .button {
  color: #000 !important;
  border-color: #FF385C;
}

.bg-2 .button:hover {
  color: #fff !important;
}

.bg-4 .button {
  color: #fff;
  border-color: transparent;
}

.bg-3 .button {
  color: #FF385C;
  border-color: #FF385C;
}

.bg-3 .button:hover {
  border-color: #FF385C;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .bg-3 {
    padding-top: 1rem;
  }
}
/* Sizes */
.button--size-s {
  font-size: 15px;
  font-weight: bold;
}

.button--size-m {
  font-size: 16px;
}

.button--size-l {
  font-size: 18px;
}

/* Typography and Roundedness */
.button--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button--text-thin {
  font-weight: 300;
}

.button--text-medium {
  font-weight: 500;
}

.button--text-thick {
  font-weight: 600;
}

.button--round-s {
  border-radius: 5px;
}

.button--round-m {
  border-radius: 15px;
}

.button--round-l {
  border-radius: 40px;
}

/* Borders */
.button--border-thin {
  border: 1px solid;
}

.button--border-medium {
  border: 2px solid;
}

.button--border-thick {
  border: 3px solid;
}

/* Wayra */
.button--wayra {
  overflow: hidden;
  cursor: pointer;
  width: 210px;
  -webkit-transition: border-color 0.3s, color 0.3s;
  transition: border-color 0.3s, color 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
          transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--wayra::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  background: #FF385C;
  z-index: -1;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
          transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  -webkit-transition: opacity 0.3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s, -webkit-transform 0.3s;
}

.button--wayra:hover {
  color: #fff;
  border-color: #fff;
}

.button--wayra.button--inverted:hover {
  color: #fff;
  border-color: #FF385C;
}

.button--wayra:hover::before {
  opacity: 1;
  background-color: #FF385C;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
          transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
          transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--wayra.button--inverted:hover::before {
  background-color: #FF385C;
}

.portfolio .box a {
  background: #121B22;
  text-decoration: none;
  text-align: center;
}

/* Moema */
.button--moema {
  padding: 1.5em 3em;
  border-radius: 50px;
  background: #7986cb;
  color: #fff;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}

.button--moema.button--inverted {
  background: #ECEFF1;
  color: #37474f;
}

.button--moema::before {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  bottom: -20px;
  right: -20px;
  background: inherit;
  border-radius: 50px;
  z-index: -1;
  opacity: 0.4;
  -webkit-transform: scale3d(0.8, 0.5, 1);
          transform: scale3d(0.8, 0.5, 1);
}

.button--moema:hover {
  -webkit-transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
  transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
  color: #ECEFF1;
  background-color: #3f51b5;
  -webkit-animation: anim-moema-1 0.3s forwards;
          animation: anim-moema-1 0.3s forwards;
}

.button--moema.button--inverted:hover {
  color: #ECEFF1;
  background-color: #7986cb;
}

.button--moema:hover::before {
  -webkit-animation: anim-moema-2 0.3s 0.3s forwards;
          animation: anim-moema-2 0.3s 0.3s forwards;
}

@-webkit-keyframes anim-moema-1 {
  60% {
    -webkit-transform: scale3d(0.8, 0.8, 1);
            transform: scale3d(0.8, 0.8, 1);
  }
  85% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes anim-moema-1 {
  60% {
    -webkit-transform: scale3d(0.8, 0.8, 1);
            transform: scale3d(0.8, 0.8, 1);
  }
  85% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes anim-moema-2 {
  to {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes anim-moema-2 {
  to {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
/*------------------------------------*/
/*-- 3. SECTION RECENTLY PROPERTIES --*/
/*------------------------------------*/
section.recently.portfolio {
  padding: 4.6rem 0;
  background: #f7f7f7;
}

.portfolio .sec-title {
  margin-left: 0;
}

.portfolio .sec-title h2 span {
  font-weight: 600;
  color: #111;
}

.portfolio .sec-title h2 {
  color: #FF385C;
  margin-bottom: .5rem;
}

.homes.no-mb {
  margin-bottom: 0px;
}

.homes-img {
  display: block;
  -webkit-transition: -webkit-transform 0.6s ease-in;
  transition: -webkit-transform 0.6s ease-in;
  transition: transform 0.6s ease-in;
  transition: transform 0.6s ease-in, -webkit-transform 0.6s ease-in;
}

.homes,
.homes-img {
  position: relative;
  overflow: hidden;
}

.homes-tag.featured {
  width: 84px !important;
  background: #18ba60 !important;
}

.recently .homes .button {
  font-size: 13px;
  font-weight: 600;
  padding: 6px 14px;
  border-radius: 2px;
  color: #fff;
  border: none;
  background-color: #FF385C;
  display: inline-block;
  z-index: 99;
}

.recently .homes-tag.featured {
  left: 15px;
  margin-top: 15px;
}

.recently .homes-price,
.recently .homes-tag {
  position: absolute;
  z-index: 99;
}

.recently .homes-tag.sale {
  background: #e54242 !important;
  width: 84px !important;
  height: 31px;
}

.recently .homes-tag.sale {
  right: 15px;
  margin-top: 15px;
  top: 0;
}

.recently .homes-tag.sale.rent {
  background: #ffb200 !important;
  color: #fff;
  z-index: 99;
}

.recently .price-properties {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 1rem;
}

.recently .compare {
  margin-top: 1.1rem;
  line-height: 0px;
}

.recently .compare a {
  margin-right: 1.5rem;
}

.recently .compare a:last-child {
  margin-right: 0px;
}

.recently .compare i {
  font-size: 1rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.recently .fa-exchange-alt {
  color: #666;
}

.recently .fa-exchange-alt:hover {
  color: #18ba60;
}

.recently .fa-share-alt {
  color: #666;
}

.recently .fa-share-alt:hover {
  color: #ffb200;
}

.recently .fa-heart-o {
  color: #666;
}

.recently .fa-heart-o:hover {
  color: #e54242;
}

.recently .homes-price {
  color: #fff;
  font-size: 13px !important;
  font-weight: 600;
  padding: 6px 14px;
  border-radius: 2px;
  background: #3a2c5f;
  bottom: 15px;
  left: 15px;
  z-index: 99;
}

.recently .homes-content {
  background: #fff !important;
  padding: 20px 20px !important;
}

.recently .homes-content h3.title a {
  font-size: 1.1rem;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
  text-decoration: none;
}

.recently .homes-content h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 3px;
}

.price-properties h3 {
  margin-bottom: 0px;
}

.recently .homes-content .homes-address {
  margin-top: 0px;
}

.recently .homes-content .homes-address .fa-map-marker {
  font-size: 1rem;
  text-decoration: none;
  color: #18ba60;
}

.recently .homes-content .homes-address a span {
  font-size: 13px;
  font-weight: 400;
  margin: 0 0 1px;
  text-decoration: none;
}

.recently .homes-address span {
  color: #666;
}

.recently .homes-content .homes-address a i {
  margin-right: 10px;
}

.recently .homes-content .homes-list {
  padding: 0;
  margin: 0;
}

.recently .homes-content .homes-list li {
  width: 45%;
  float: left;
  font-size: 14px;
  line-height: 40px;
  list-style: none;
  color: #FF385C;
}

.recently .homes-content .homes-list li i {
  font-size: 1rem;
  margin-right: 5px;
  color: #FF385C;
}

.recently .homes-content .homes-list span {
  color: #666;
}

.recently.portfolio .item {
  padding-bottom: 0px;
}

.recently.portfolio .homes {
  margin-bottom: 0px;
}

.recently .footer {
  background: none;
  border-top: 1px solid #ebebeb;
  padding-top: 1rem;
  font-size: 13px;
  color: #666;
}

.recently .footer a {
  color: #666;
  text-decoration: none;
}

.recently .footer span {
  float: right;
}

.recently .footer i {
  margin-right: 5px;
  font-size: 1rem;
  color: #666;
}

.hidden.photo-gallery {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.house-details.thehp-1 i {
  color: #FF385C;
  font-size: 18px;
  font-weight: 500;
}

.listing-item.compact.thehp-1 .listing-badges span,
.listing-item.compact.thehp-2 .listing-badges span {
  left: 15px;
  width: 77px !important;
  font-size: .8334rem;
  text-transform: uppercase;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 2px;
  color: #fff;
  border: none;
  background-color: #FF385C;
  display: inline-block;
  z-index: 1;
}

.listing-item.compact.thehp-2 .listing-badges span {
  width: 80px !important;
  background-color: #6449E7 !important;
}

.hp1 .agents-grid {
  padding-left: 0px;
  padding-right: 1.250rem;
}

.hp1 .slick-next {
  right: -60px;
}

.hp1 .slick-prev {
  left: -80px;
}

.hp1 div.partners-1,
.hp1 div.partners {
  padding: 4.6rem 0;
}

.hp1 .top-location .tp-caption p {
  color: #fff;
  margin-bottom: 0px;
}

.hp1 .top-location .tp-caption {
  bottom: 0px;
}

.hp1 .top-location .tp-caption p {
  opacity: 10;
}

.hp1 .blog-section .news-item-sm .news-img-link {
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
}

.hp1 .parallax-searchs.home15.thome-6 .hero-inner {
  padding: 293px 0;
}

.homepage-1 .full-l .project-single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-shadow: 0px 0px 2px #e7e7e7 !important;
          box-shadow: 0px 0px 2px #e7e7e7 !important;
  border: 1px solid #e7e7e7;
}

.homepage-1 .how-it-works .serv-flex {
  -webkit-box-shadow: 0px 0px 1px #e7e7e7 !important;
          box-shadow: 0px 0px 1px #e7e7e7 !important;
  border: 1px solid #e7e7e7;
}

.homepage-1 .portfolio.full-l .homes-content {
  width: 60%;
}

.homepage-1 .portfolio.full-l .homes-content h3 a {
  color: #333;
  font-size: 1.1rem;
}

.homepage-1 .first-footer .bloq-email .email input[type="submit"] {
  margin-top: 0px;
}

.homepage-1 .portfolio.full-l .project-head {
  border-radius: 3px 0 0 3px;
  max-height: 270px;
  max-width: -webkit-fill-available;
  margin: 0;
  position: relative;
  width: 410px;
}

.homepage-1 .portfolio .homes-content .homes-list li i {
  color: #696969;
  font-size: 18px;
}

.homepage-1 .portfolio .footer i {
  color: #696969;
  font-size: 16px;
}

.homepage-1 .portfolio.full-l .project-head:before {
  background: transparent !important;
}

.homepage-1 .portfolio.full-l .project-head img {
  opacity: 1 !important;
}

.homepage-1 .small-category-2,
.homepage-1 .blog-section .news-item,
.homepage-1 .team-block .inner-box {
  -webkit-box-shadow: 0px 0px 2px #e7e7e7;
          box-shadow: 0px 0px 2px #e7e7e7;
  border: 1px solid #e7e7e7;
}

.homepage-1 .team .team-social li a.facebook,
.homepage-1 .team .team-social li a.twitter,
.homepage-1 .team .team-social li a.instagram,
.homepage-1 .team .team-social li a.linkedin {
  background-color: rgba(74, 74, 76, 0.5);
}

@media screen and (max-width: 1024px) {
  .homepage-1 .portfolio.full-l .homes-content {
    width: 100%;
  }

  .homepage-1 .portfolio.full-l .project-head {
    width: 60%;
  }
}
@media screen and (max-width: 575px) {
  .hp1 .agents-grid {
    padding-left: 0px;
    padding-right: 1px;
    width: 100%;
  }

  .homepage-1 .full-l .project-single {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .homepage-1 .portfolio.full-l .project-head {
    width: 100% !important;
  }

  .hp1 .blog-section .news-item-sm .news-img-link .news-item-img {
    padding-bottom: 75%;
  }
}
@media screen and (max-width: 767px) {
  .how-it-works .service-1 .serv.mb-0.pt.its-2 {
    margin-top: 2rem !important;
  }
}
@media screen and (max-width: 991px) {
  .hp1 .bgc {
    margin-top: 3rem;
  }

  .homepage-1 .portfolio.full-l .project-head {
    width: 100%;
  }
}
/*------ SECTION REQUEST ------*/
.hp1 section.request {
  padding: 6rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.3)), to(rgba(255, 255, 255, 0.3))), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
  background-size: cover;
  position: relative;
  z-index: 3;
  color: #fff;
}

.hp1 .request h3 {
  margin-bottom: 2rem;
}

.hp1 .request ul li {
  list-style: none;
}

.hp1 .request ul {
  margin: 0;
  padding: 0;
}

.hp1 .request .btn {
  cursor: pointer;
  margin-top: 1rem;
  background: #FF385C;
}

.hp1 .request .btn:hover {
  background: #FF385C;
}

.hp1 .request .address-info p:last-child {
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .hp1 .request .offices h3:first-child {
    margin-top: 4rem;
  }
}
.hp1 label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #000;
}

.hp1 form label.error {
  display: block;
  color: #ff0000;
  font-size: 14px !important;
  opacity: 1 !important;
}

.hp1 .request .form-control {
  background: #fff;
  border: 1px solid #ebebeb;
}

.hp1 .request .contact-form input[type="text"] {
  height: 50px;
}

.hp1 .request .contact-form input[type="text"]:focus,
.hp1 .request .contact-form input[type="email"]:focus,
.hp1 .request .contact-form textarea:focus {
  border-color: #FF385C;
}

.hp1 .request .contact-form textarea {
  min-height: 100px;
  max-height: 100px;
}

.hp1 .successform,
.hp1 .errorform {
  display: none;
  height: 70px;
}

.hp1 .successform span,
.hp1 .errorform span {
  display: block;
}

.hp1 .successform span p,
.hp1 .errorform span p {
  margin-top: 15px;
}

.hp1 .successform span p {
  color: #01c15a;
  font-size: 2rem;
}

.hp1 .errorform span p {
  color: #c0392b;
}

.hp1 .bgc {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 43, 43, 0.8)), to(rgba(43, 43, 43, 0.8))), url(../images/bg/bg-testimonials.jpg) no-repeat center center;
  background: linear-gradient(rgba(43, 43, 43, 0.8), rgba(43, 43, 43, 0.8)), url(../images/bg/bg-testimonials.jpg) no-repeat center center;
  padding: 2rem;
  background-size: cover;
}

.hp1 .request h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff;
}

.hp1 .request .btn {
  border: 0;
}

.hp1 .request h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.hp1 .call-info h3 {
  margin-bottom: 2rem;
}

.hp1 .call-info h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 767px) {
  .hp1 .call-info {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
.hp1 .call-info .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
}

.hp1 .call-info .ti {
  margin-bottom: 0px;
}

.hp1 .cll:last-child {
  margin-bottom: 0px;
}

.hp1 .call-info .info p {
  margin: 0px;
  margin-left: 1.5rem;
  font-weight: 300;
}

.hp1 .call-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hp1 .call-info ul li .fa {
  font-size: 1.6rem;
  color: #FF385C;
}

.info-help .project-single {
  border: 0px !important;
}

/*----- HOVER EFFECT -----*/
.recently .project-single {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.recently .project-inner {
  position: relative;
  overflow: hidden;
}

.recently .project-head {
  position: relative;
  text-align: center;
}

.recently .project-head:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0;
  z-index: 33;
  background: #121B22;
  color: #fff;
  text-align: center;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.recently .project-single:hover .project-head:before {
  opacity: 0.8;
}

.recently .project-head img {
  width: 100%;
  z-index: 2;
}

.recently .project-bottom {
  text-align: center;
  padding: 10px 0 0;
  position: absolute;
  width: 100%;
  text-align: left;
  z-index: 333;
  text-align: center;
  top: 50%;
  margin-top: -23.5px;
}

.recently .project-bottom h4 {
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 800;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.recently .project-bottom h4:before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
}

.recently .project-single:hover .project-bottom h4 {
  opacity: 1;
  visibility: visible;
}

.recently .project-bottom h4 a {
  color: #fff;
  text-decoration: none;
}

.recently .project-bottom h4 span {
  display: block;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
}

.recently .button-effect {
  position: absolute;
  text-align: left;
  bottom: 0;
  right: 0;
  z-index: 33;
  background: transparent;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  padding: 8px 15px;
  border-radius: 20px 0 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: .7rem;
}

.recently .project-single:hover .button-effect {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.recently .button-effect .btn {
  display: inline-block;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-right: 15px;
  border-radius: 100%;
  width: 31px;
  height: 31px;
  line-height: 31px;
  background: #fff;
  z-index: 99;
}

.recently .button-effect .btn:hover {
  color: #fff;
}

.recently .button-effect .btn:last-child {
  margin: 0;
}

.recently .load-button {
  margin-top: 50px;
}

.recently .load-button .btn {
  padding: 14px 30px;
  color: #fff;
  font-weight: 500;
  border: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin: 0;
}

.recently .load-button .btn:hover {
  background: #353535;
}

.recently .button-effect .fa-link:before {
  content: "\f0c1";
  font-family: FontAwesome;
  color: #000;
}

.recently .button-effect .fa-video:before {
  content: "\f03d";
  font-family: FontAwesome;
  color: #000;
}

.recently .button-effect .fa-photo:before {
  content: "\f03e";
  font-family: FontAwesome;
  color: #000;
}

.recently.portfolio .item {
  padding-bottom: 0;
}

.recently.portfolio .project-single {
  margin-bottom: 0px;
}

.recently.portfolio.bshd .project-single {
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.listing-hidden-content.blue {
  background: #FF385C;
}

/*----- SLICK CAROUSEL -----*/
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #fff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: block;
  top: calc(50% - 15px);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.slick-prev:hover,
.slick-next:hover {
  color: transparent;
  outline: none;
  background: #FF385C;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  opacity: 1;
  outline: none;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  background-color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: no-drop;
}

.slick-prev.slick-disabled:hover,
.slick-next.slick-disabled:hover {
  background: #FF385C;
  color: #fff;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  color: #696969;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  color: #696969;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #fff;
  -webkit-transform: .5s ease;
          transform: .5s ease;
}

.slick-prev {
  left: -70px;
}

.slick-prev:before {
  content: '\f053';
  left: -1px;
}

.slick-next {
  right: -70px;
}

.slick-next:before {
  content: '\f054';
  right: -1px;
}

/* Dots */
.slick-dots {
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

ul.slick-dots {
  margin-top: 20px;
  list-style-type: disc;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 3px;
  cursor: pointer;
}

.slick-dots li {
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: #FF385C;
  border: 0;
  position: relative;
  outline: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 3px;
  opacity: 1;
  border-radius: 50%;
  line-height: 0;
  -webkit-box-shadow: inset 0 0 0 2px #FF385C;
          box-shadow: inset 0 0 0 2px #FF385C;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.slick-dots li.slick-active {
  -webkit-box-shadow: inset 0 0 0 6px #FF385C;
          box-shadow: inset 0 0 0 6px #FF385C;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.slick-dots li:after {
  display: block;
  height: 13px;
  width: 13px;
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: transparent;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  background-color: #FF385C;
}

.slick-dots li.slick-active:after {
  opacity: 1;
}

.slick-dots button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  cursor: pointer;
}

.agents-grid {
  position: relative;
  background: transparent;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px;
  border: none;
}

@media screen and (max-width: 767px) {
  .recently.portfolio .item.no-pb.pbp-3 {
    padding-bottom: 1.6rem !important;
  }

  .agents-grid {
    margin-right: 0px;
  }

  .recently.portfolio .item.landscapes.no-pb {
    padding-bottom: 0px;
    margin-bottom: 10px !important;
  }

  .recently.portfolio .item.landscapes.no-pb:last-child {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 991px) {
  .recently.portfolio .item {
    padding-bottom: 1.6rem;
  }

  .recently.portfolio .project-single {
    margin-bottom: 10px;
  }

  .recently.portfolio .item.no-pb {
    padding-bottom: 0px;
  }

  .recently.portfolio .project-single.no-mb {
    margin-bottom: 0px;
  }

  .recently.portfolio .item.landscapes.no-pb {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .recently .compare {
    margin-top: .8rem;
    margin-right: .2rem;
  }
}
@media screen and (max-width: 420px) {
  .recently .compare {
    margin-right: 0;
  }
}
/*-----------------------------------*/
/*------ 4. SECTION WELCOME ------*/
/*-----------------------------------*/
section.welcome {
  padding: 6rem 0;
  background: #fff;
}

.welcome-title h2 {
  color: #121B22;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: .8rem;
}

.welcome-img {
  text-align: right;
}

.welcome-title h2 span,
.welcome-title h2 span {
  color: #FF385C;
  font-weight: 800;
}

.welcome-title h4 {
  color: #000;
  font-size: 22px;
  text-transform: uppercase;
}

.welcome-title {
  padding-bottom: 17px;
}

.welcome-content > p {
  color: #666;
  font-weight: 400;
}

.welcome-content > p span {
  color: #666;
  font-size: 16px;
  font-weight: 600;
}

.services-img,
.services-desc {
  display: inline-block;
}

.services-img {
  float: left;
  margin-right: 16px;
}

.services-desc > h6 {
  color: #555;
  font-weight: 600;
}

.services-desc > p {
  color: #666;
  font-weight: 400;
  margin: 0;
}

.w-single-services {
  margin-bottom: 31px;
}

.w-single-services.no-mb {
  margin-bottom: 0px;
}

.welcome-services {
  padding-top: 34px;
}

.single-services .welcome-title {
  padding-top: 43px;
}

.single-services .welcome-content {
  padding-top: 17px;
}

.welcome .popup-youtube {
  background: #fff none repeat scroll 0 0;
  border-radius: 100%;
  color: #FF385C;
  font-size: 30px;
  height: 70px;
  left: 50%;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 70px;
  z-index: 9;
}

.welcome .popup-youtube i {
  margin-left: 5px;
}

.welcome .wprt-image-video .iq-waves {
  height: 352px;
  height: 22rem;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 352px;
  width: 22rem;
  z-index: 2;
}

.welcome .wprt-image-video .iq-waves .waves {
  -webkit-animation: 3s ease-in-out 0s normal none infinite running waves;
          animation: 3s ease-in-out 0s normal none infinite running waves;
  background: #274abb none repeat scroll 0 0 padding-box;
  border-radius: 320px;
  height: 352px;
  height: 22rem;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  width: 352px;
  width: 22rem;
}

.welcome .wprt-image-video .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.welcome .wprt-image-video .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.welcome .wprt-image-video .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
            transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.6, 0.6);
            transform: scale(0.6, 0.6);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
            transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.6, 0.6);
            transform: scale(0.6, 0.6);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@media screen and (max-width: 991px) {
  .welcome .wprt-image-video {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .w-single-services.no-mb.mbx {
    margin-bottom: 31px;
  }
}
@media screen and (max-width: 575px) {
  .w-single-services.no-mb.mbx {
    margin-bottom: 31px;
  }

  .welcome-title h2 {
    font-size: 20px;
  }

  .welcome-title h4 {
    font-size: 16px;
  }

  .welcome-content > p span {
    font-size: 15px;
  }
}
/*------------------------------------*/
/*--- 5. SECTION PROPERTY SERVICES ---*/
/*------------------------------------*/
section.services-home {
  padding: 4.6rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0.8))), url(../images/bg/bg-counter.jpg) no-repeat center center;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(../images/bg/bg-counter.jpg) no-repeat center center;
  background-attachment: fixed;
}

.services-home .service .media {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 50px;
  height: 75px;
  margin-top: -40px;
  width: 75px;
}

.services-home .service .media img {
  width: 100%;
}

.services-home .service .media i {
  font-size: 34px;
  width: 100%;
  text-align: center;
  height: 100%;
  line-height: 65px;
}

.services-home .bg-base {
  background: #FF385C;
}

.services-home .text-white {
  color: #ffffff !important;
}

.services-home .box-shadow-1,
.box-shadow-1-hover:hover,
.box-shadow-1:focus,
.box-shadow-1.focus {
  -webkit-box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.06) !important;
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.06) !important;
}

.services-home .rounded-100 {
  border-radius: 100px;
}

.services-home .p-left-5 {
  padding-left: 5px !important;
}

.services-home .p-bottom-5 {
  padding-bottom: 5px !important;
}

.p-right-5 {
  padding-right: 5px !important;
}

.services-home .p-top-5 {
  padding-top: 5px !important;
}

.services-home .p-left-25 {
  padding-left: 25px !important;
}

.services-home .p-right-25 {
  padding-right: 25px !important;
}

.services-home .p-top-35 {
  padding-top: 35px !important;
}

.services-home .p-bottom-30 {
  padding-bottom: 30px !important;
}

.services-home .blog-section,
.agent-section,
.agency-section,
.profile-section {
  display: inline-block;
  width: 100%;
}

.services-home .text-bold-700 {
  color: #fff;
  font-weight: 600;
}

.services-home .m-bottom-15 {
  margin-bottom: 15px;
}

.services-home .agent-section p {
  color: #fff;
}

.services-home a.text-base {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.services-home .border-light {
  border-color: #eaeaea !important;
}

.services-home .border-1 {
  border-style: solid;
  border-width: 1px !important;
}

.services-home .badge-light-2,
.bg-light-2 {
  background: #121B22;
}

.services-home a.text-base:hover {
  color: #FF385C;
}

@media screen and (max-width: 991px) {
  .services-home .m-bottom-40 {
    margin-top: 4.5rem;
  }

  .services-home .m-top-0 {
    margin-top: 0px;
  }
}
/*------------------------------------*/
/*-- 6. SECTION FEATURED PROPERTIES --*/
/*------------------------------------*/
section.portfolio {
  padding: 4.6rem 0;
  background: #FFF;
}

.portfolio .sec-title {
  margin-left: 0;
}

.portfolio .sec-title h2 span {
  font-weight: 600;
  color: #111;
}

.portfolio .sec-title h2 {
  color: #FF385C;
  margin-bottom: .5rem;
}

.portfolio .item {
  padding-bottom: 1.6rem;
}

.portfolio .filters-group {
  margin-top: 1.4rem;
  margin-bottom: 0px;
  padding-left: 0px;
  text-align: right;
  border-radius: 5px;
}

.portfolio .filters-group ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.portfolio .filters-group ul li {
  list-style: none;
  color: #000;
  display: inline-block;
  padding: 10px 15px;
  text-transform: capitalize;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.portfolio .filters-group ul li:hover {
  background: #FF385C;
  color: #fff;
}

@media screen and (max-width: 543px) {
  .portfolio .filters-group ul {
    padding-left: 0px;
  }

  .portfolio .filters-group ul li:hover {
    background: none;
    color: #000;
  }
}
.portfolio .filters-group .active {
  background: #121B22;
  color: #fff;
}

.project-single {
  margin-bottom: 10px;
}

.project-single.no-mb {
  margin-bottom: 0px;
}

.portfolio .item.no-pb {
  padding-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .project-single.no-mb.last:last-child {
    margin-bottom: 0px;
  }

  .portfolio .filters-group {
    text-align: left;
    margin-bottom: 2rem;
  }

  .filters-group.col-md-6 {
    margin-left: 1rem;
  }

  .portfolio .item.sale.no-pb {
    padding-bottom: 1.6rem;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 991px) {
  .portfolio .item.landscapes.no-pb {
    padding-bottom: 1.6rem;
    margin-bottom: 10px;
  }

  .portfolio .item.landscapes.h-17.no-pb {
    padding-bottom: 30px;
    margin-bottom: 0px;
  }

  .project-single.mbx-10 {
    margin-bottom: 10px;
  }

  .featured .compare {
    margin-top: .9rem !important;
  }
}
.homes-img {
  display: block;
}

.homes,
.homes-img {
  position: relative;
  overflow: hidden;
}

.homes-tag.featured {
  width: 84px !important;
  background: #18ba60 !important;
}

.portfolio .homes .button {
  font-size: 13px;
  font-weight: 600;
  padding: 6px 14px;
  border-radius: 2px;
  color: #fff;
  border: none;
  background-color: #FF385C;
  display: inline-block;
  z-index: 1;
}

.portfolio .homes-tag.featured {
  left: 15px;
  margin-top: 15px;
}

.portfolio .homes-price,
.portfolio .homes-tag {
  position: absolute;
  z-index: 99;
}

.portfolio .homes-tag.sale {
  background: #e54242 !important;
  width: 84px !important;
  height: 31px;
}

.portfolio .homes-tag.sale {
  right: 15px;
  margin-top: 15px;
  top: 0;
}

.portfolio .homes-tag.sale.rent {
  background: #ffb200 !important;
  color: #fff;
  z-index: 1;
}

.portfolio .price-properties {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 1rem;
}

.portfolio .compare {
  margin-top: 1.1rem;
  line-height: 0px;
}

.portfolio .compare a {
  margin-right: 1.5rem;
}

.portfolio .compare a:last-child {
  margin-right: 0px;
}

.portfolio .compare i {
  font-size: 1rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.portfolio .fa-exchange-alt {
  color: #666;
}

.portfolio .fa-exchange-alt:hover {
  color: #18ba60;
}

.portfolio .fa-share-alt {
  color: #666;
}

.portfolio .fa-share-alt:hover {
  color: #ffb200;
}

.portfolio .fa-heart-o {
  color: #666;
}

.portfolio .fa-heart-o:hover {
  color: #e54242;
}

.portfolio .homes-price {
  color: #fff;
  font-size: 13px !important;
  font-weight: 600;
  padding: 6px 14px;
  border-radius: 2px;
  background: #3a2c5f;
  bottom: 15px;
  left: 15px;
  z-index: 1;
}

.portfolio .homes-content {
  background: #fff !important;
  padding: 20px 20px !important;
}

.portfolio .homes-content h3.title a {
  font-size: 1.1rem;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
  text-decoration: none;
}

.portfolio .homes-content h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 3px;
}

.portfolio .homes-content h3 a {
  font-size: 1.2rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 3px;
  text-decoration: none;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.portfolio .homes-content h3 a:hover {
  color: #FF385C;
}

.price-properties h3 {
  margin-top: 1.3 !important;
  margin-bottom: 0px;
}

.portfolio .homes-content .homes-address {
  margin-top: 0px;
}

.portfolio .homes-content .homes-address .fa-map-marker {
  font-size: 1rem;
  text-decoration: none;
  color: #18ba60;
}

.portfolio .homes-content .homes-address a {
  text-decoration: none;
}

.portfolio .homes-content .homes-address a span {
  font-size: 13px;
  font-weight: 400;
  margin: 0 0 1px;
  text-decoration: none;
}

.portfolio .homes-address span {
  color: #666;
}

.portfolio .homes-content .homes-address a i {
  margin-right: 10px;
}

.portfolio .homes-content .homes-list {
  padding-left: 0px;
  padding-bottom: .9rem;
  margin: 0;
}

.portfolio .homes-content .homes-list li {
  width: 45%;
  float: left;
  font-size: 14px;
  line-height: 40px;
  list-style: none;
  color: #FF385C;
}

.portfolio .homes-content .homes-list li i {
  font-size: 1rem;
  margin-right: 5px;
  color: #FF385C;
}

.portfolio .homes-content .homes-list span {
  color: #666;
}

.portfolio .footer {
  background: none;
  border-top: 1px solid #e8e8e8;
  padding-top: 1rem;
  font-size: 13px;
  color: #666;
}

.portfolio .footer a {
  color: #666;
  text-decoration: none;
}

.portfolio .footer span {
  float: right;
}

.portfolio .footer i {
  margin-right: 5px;
  color: #696969;
  font-size: 18px;
}

/*----- HOVER EFFECT -----*/
.portfolio .project-single {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border: 1px solid #e7e7e7;
}

.portfolio .project-inner {
  position: relative;
  overflow: hidden;
}

.portfolio .project-head {
  position: relative;
  text-align: center;
}

.portfolio .project-head:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0;
  z-index: 1;
  background: #121B22;
  color: #fff;
  text-align: center;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.portfolio .project-single:hover .project-head:before {
  opacity: 0.8;
}

.portfolio .project-head img {
  width: 100%;
  z-index: 2;
}

.portfolio .project-bottom {
  text-align: center;
  padding: 10px 0 0;
  position: absolute;
  width: 100%;
  text-align: left;
  z-index: 333;
  text-align: center;
  top: 50%;
  margin-top: -23.5px;
}

.portfolio .project-bottom h4 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.portfolio .project-bottom h4:before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
}

.portfolio .project-single:hover .project-bottom h4 {
  opacity: 1;
  visibility: visible;
}

.portfolio .project-bottom h4 a {
  color: #fff;
  text-decoration: none;
}

.portfolio .project-bottom h4 span {
  display: block;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
}

.portfolio .button-effect {
  position: absolute;
  text-align: left;
  bottom: 0;
  right: 0;
  z-index: 33;
  background: transparent;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  padding: 8px 15px;
  border-radius: 20px 0 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: .7rem;
}

.portfolio .project-single:hover .button-effect {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.portfolio .button-effect .btn {
  display: inline-block;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-right: 15px;
  border-radius: 100%;
  width: 31px;
  height: 31px;
  line-height: 31px;
  background: #fff;
  z-index: 99;
}

.portfolio .button-effect .btn:hover {
  color: #fff;
}

.portfolio .button-effect .btn:last-child {
  margin: 0;
}

.portfolio .load-button {
  margin-top: 50px;
}

.portfolio .load-button .btn {
  padding: 14px 30px;
  color: #fff;
  font-weight: 500;
  border: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin: 0;
}

.portfolio .load-button .btn:hover {
  background: #353535;
}

.portfolio .button-effect .fa-link:before {
  content: "\f0c1";
  font-family: FontAwesome;
  color: #000;
}

.portfolio .button-effect .fa-video:before {
  content: "\f03d";
  font-family: FontAwesome;
  color: #000;
}

.portfolio .button-effect .fa-photo:before {
  content: "\f03e";
  font-family: FontAwesome;
  color: #000;
}

/*------ BUTTOM HOVER EFFECT ------*/
.bg-all {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 30px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.btn.btn-outline-light {
  background-color: #FF385C;
  color: #fff;
  border-color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3rem;
  padding: .9rem 2.5rem .9rem 2rem;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.btn.btn-outline-light:hover {
  background: #121B22;
  color: #fff;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.btn.btn-outline-light:after {
  content: '\f0a9';
  font-family: 'FontAwesome';
  position: relative;
  left: .5rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

.btn.btn-outline-light:hover:after {
  left: 1rem;
  color: #fff;
  -webkit-transform: rotateZ(-360deg);
          transform: rotateZ(-360deg);
}

/*------------------------------------*/
/*--- 7. SECTION POPULAR PLACES ---*/
/*------------------------------------*/
section.popular-places {
  padding: 4.6rem 0;
  background: #fff;
}

.popular-places .img-box {
  height: 350px;
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 1.5rem;
  z-index: 90;
  border-radius: 3px;
}

.popular-places .img-box.no-mb {
  margin-bottom: 0px;
}

.popular-places .img-box:hover {
  background: #FF385C;
}

.popular-places .img-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  background-color: #121B22;
  opacity: 0.2;
  z-index: 99;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.popular-places .listing-badges {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
  overflow: hidden;
  height: 100px;
}

.popular-places .listing-badges .featured {
  background-color: #18ba60;
  float: left;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: -67px;
  top: 17px;
  position: relative;
  text-align: center;
  width: 200px;
  font-size: 12.5px;
  margin: 0;
}

.popular-places .listing-badges span {
  color: #fff;
  display: inline-block;
  padding: 1px 10px;
  float: right;
  background-color: rgba(74, 74, 76, 0.7);
  border-radius: 3px;
  top: 15px;
  right: 15px;
  position: absolute;
}

.popular-places .img-box-content {
  position: absolute;
  z-index: 101;
  top: 50%;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  -webkit-transform: translate(0, -50.5%);
          transform: translate(0, -50.5%);
}

.popular-places .img-box-content h4 {
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  margin: 0;
}

.popular-places .img-box-content span {
  font-size: 16px;
  color: #fff;
}

.popular-places .img-box img {
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.55s;
  transition: all 0.55s;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  -webkit-transition: -webkit-transform 0.35s ease-out;
  transition: -webkit-transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
}

@media screen and (max-width: 767px) {
  .popular-places .img-box.no-mb {
    margin-bottom: 1.5rem;
  }

  .popular-places .img-box.no-mb.x3 {
    margin-bottom: 0px;
  }
}
/*----- HOVER EFFECT -----*/
.popular-places .hover-effect img {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all .8s ease;
  transition: all .8s ease;
}

.popular-places .hover-effect:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

/*--------------------------------------*/
/*--- 8. SECTION POPULAR PLACES 2 ---*/
/*--------------------------------------*/
section.feature-cities-1 {
  padding: 6rem 0;
  background: #f5f6ff;
}

.feature-cities-1 .img-box {
  height: 290px;
  display: inline-block;
  position: relative;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
  z-index: 90;
  border-radius: 3px;
}

.feature-cities-1 .col-lg-4.col-md-6 a {
  padding: 20px;
}

.feature-cities-1 .img-box.no-mb {
  margin-bottom: 0px;
}

.feature-cities-1 .img-box:hover {
  background: #FF385C;
}

.feature-cities-1 .img-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  display: block;
  content: "";
  background-color: #121B22;
  opacity: 0.3;
  z-index: 99;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.feature-cities-1 .listing-badges {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 15px;
  font-weight: bold;
  padding: 0px;
  overflow: hidden;
  height: 100px;
}

.feature-cities-1 .listing-badges .featured {
  background-color: #FF385C;
  float: right;
  border-radius: 20px;
  right: 23px;
  top: 23px;
  font-weight: 400;
  position: relative;
  text-align: center;
  width: 120px;
  font-size: 15px;
  margin: 0;
}

.feature-cities-1 .listing-badges span {
  color: #fff;
  display: inline-block;
  padding: 5px;
  float: right;
  background-color: rgba(74, 74, 76, 0.9);
  border-radius: 3px;
  top: 15px;
  right: 15px;
  position: absolute;
}

.feature-cities-1 .img-box-content {
  position: absolute;
  z-index: 101;
  top: 73%;
  margin: 0 auto;
  text-align: left;
  -webkit-transform: translate(0, -50.5%);
          transform: translate(0, -50.5%);
}

.feature-cities-1 .img-box-content h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #fff;
}

.feature-cities-1 .img-box-content span,
.feature-cities-1 .img-box-content pre {
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #fff;
}

.feature-cities-1 .img-box img {
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.55s;
  transition: all 0.55s;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  -webkit-transition: -webkit-transform 0.35s ease-out;
  transition: -webkit-transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
}

/*----- HOVER EFFECT -----*/
.feature-cities-1 .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  z-index: 2;
}

.hover-effect img {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all .8s ease;
  transition: all .8s ease;
}

.hover-effect:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

@media screen and (max-width: 767px) {
  .feature-cities-1 .img-box.no-mb,
  .feature-cities-1 .img-box {
    margin-bottom: 30px;
  }

  .feature-cities-1 .mt {
    margin-top: 30px;
  }

  .feature-cities-1 .img-box.no-mb.x3 {
    margin-bottom: 0px;
  }

  .feature-cities-1 .img-box {
    height: 350px;
  }

  .feature-cities-1 .img-box img {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .feature-cities-1 .img-box img,
  .feature-cities-1 .img-box {
    height: 286px;
  }
}
/*-----------------------------------*/
/*------ 9. SECTION OUR AGENTS ------*/
/*-----------------------------------*/
section.team {
  padding: 4.6rem 0;
  background: #f6f6f6;
}

.team .sec-title {
  margin-left: 1rem;
}

.team .sec-title h2 span {
  font-weight: 600;
  color: #111;
}

.team .sec-title h2 {
  color: #FF385C;
  margin-bottom: .5rem;
}

.team .team-all {
  padding: 0;
  margin: 0;
}

.team-wrap {
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.team-img {
  position: relative;
  z-index: 9;
}

.team-img img {
  width: 100%;
}

.team-content {
  height: 80px;
  width: 100%;
  bottom: 0;
  border: 1px solid #ccc;
  position: absolute;
  text-align: center;
  overflow: hidden;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 9;
  background: #fff;
}

.team-wrap:hover .team-content {
  height: 150px;
}

.team-info {
  background: #fff;
  padding: 20px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.team-wrap:hover .team-content .team-info {
  background: #fff;
}

.team-content h3 {
  text-transform: capitalize;
  margin-bottom: 0px;
  color: #444;
  font-size: 16px;
}

.team-content p {
  color: #666;
  font-style: italic;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
}

.team-content ul {
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.6s;
  transition: opacity 0.4s, -webkit-transform 0.6s;
  transition: transform 0.6s, opacity 0.4s;
  transition: transform 0.6s, opacity 0.4s, -webkit-transform 0.6s;
}

.team-wrap:hover .team-content ul {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: opacity 0.4s, -webkit-transform 0.6s;
  transition: opacity 0.4s, -webkit-transform 0.6s;
  transition: transform 0.6s, opacity 0.4s;
  transition: transform 0.6s, opacity 0.4s, -webkit-transform 0.6s;
  margin-bottom: 10px;
}

.team-content ul li {
  opacity: 0;
  padding: .3rem 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: opacity 0.1s, -webkit-transform 0.6s;
  transition: opacity 0.1s, -webkit-transform 0.6s;
  transition: transform 0.6s, opacity 0.1s;
  transition: transform 0.6s, opacity 0.1s, -webkit-transform 0.6s;
  display: inline-block;
}

.team-wrap:hover .team-content ul li {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.team-wrap:hover .team-content ul li:nth-child(1) {
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms;
}

.team-wrap:hover .team-content ul li:nth-child(2) {
  -webkit-transition-delay: 200ms;
          transition-delay: 200ms;
}

.team-wrap:hover .team-content ul li:nth-child(3) {
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms;
}

.team-wrap:hover .team-content ul li:nth-child(4) {
  -webkit-transition-delay: 400ms;
          transition-delay: 400ms;
}

.team-wrap:hover .team-content ul li:nth-child(5) {
  -webkit-transition-delay: 500ms;
          transition-delay: 500ms;
}

.team-socials ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.team-content span a {
  color: #000;
  -webkit-transition: all 1s;
  transition: all 1s;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
}

.team-wrap:hover .team-content span a {
  bottom: 6px;
}

.team-content span a:hover {
  color: #121B22;
}

.team .team-pro ul {
  padding: 0;
  margin: 0;
}

.team .team-pro ul li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.team .team-pro .fa {
  font-size: 1.2rem;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  margin: 0 .5rem;
  width: 2.1rem;
  height: 2.1rem;
  line-height: 2.1rem;
}

.team .team-all.padding {
  padding-bottom: 2.5rem;
}

@media screen and (max-width: 991px) {
  .team .team-pro {
    padding-bottom: 2.5rem;
  }

  .team .team-pro:last-child {
    padding-bottom: 0px;
  }

  .team .pb-none {
    padding-bottom: 0px;
  }

  .team .team-all.padding {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 767px) {
  .team .pb-none {
    padding-bottom: 2.5rem;
  }
}
.team .team-pro .fa.fa-facebook {
  background: #3b5998;
}

.team .team-pro .fa.fa-twitter {
  background: #1da1f2;
}

.team .team-pro .fa.fa-instagram {
  background: #e1306c;
}

/*------------------------------------*/
/*----- 10. SECTION TOP LOCATION -----*/
/*------------------------------------*/
section.top-location {
  padding: 6rem 0;
  background: #fff;
  text-align: center;
}

.top-location .item {
  position: relative;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
}

.top-location .item:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(16, 37, 46, 0)), color-stop(150%, #10252e));
  background: linear-gradient(to bottom, rgba(16, 37, 46, 0) 0%, #10252e 150%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000', GradientType=0);
}

.top-location .tp-caption {
  position: absolute;
  left: 0;
  bottom: -50px;
  padding: 20px;
  width: 100%;
  z-index: 99;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.top-location .tp-caption h6 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 0 5px;
}

.top-location .tp-caption strong {
  color: #fff;
  font-weight: 400;
  margin: 0 0 10px;
  display: block;
}

.top-location .tp-caption p {
  color: #18ba60;
  font-weight: bold;
  opacity: 0;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.top-location .item:hover .tp-caption {
  bottom: 0px;
}

.top-location .item:hover .tp-caption p {
  opacity: 1;
}

.top-location h4 {
  font-weight: 600;
  font-size: 1.2rem;
  top: 27px;
  z-index: 9;
  color: #fff;
  margin: 0px !important;
  padding: 0px !important;
  line-height: 0px !important;
  position: relative;
}

.top-location .owl-theme .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  z-index: 9999;
  width: 18%;
  margin: auto;
  background: #121B22;
  height: 54px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .top-location .owl-theme .owl-nav {
    width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .top-location .owl-theme .owl-nav {
    width: 70%;
  }
}
@media screen and (max-width: 400px) {
  .top-location h4 {
    font-size: .9rem;
  }
}
.top-location .owl-theme .owl-nav [class*=owl-] {
  margin: 0;
  padding: 0;
  background: #FF385C;
  display: inline-block;
  cursor: pointer;
  border-radius: 0;
  font-size: 0;
}

.top-location .owl-prev {
  width: 54px;
  height: 54px;
  background: #fff;
  position: relative;
  float: left;
  border-right: 1px solid #121B22;
}

.top-location .owl-prev:after {
  content: "\f104";
  font-family: FontAwesome;
  font-size: 20px;
  color: #fff !important;
}

.top-location .owl-next {
  width: 54px;
  height: 54px;
  background: #fff;
  position: relative;
  float: right;
  border-left: 1px solid #121B22;
}

.top-location .owl-prev:after {
  content: "\f104";
  font-family: FontAwesome;
  font-size: 36px;
  color: #fff !important;
  line-height: 52px;
}

.top-location .owl-next:after {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 36px;
  color: #fff !important;
  line-height: 52px;
}

/*------------------------------------*/
/*--------- 11. SECTION BLOG ---------*/
/*------------------------------------*/
section.blog-section {
  padding: 4.6rem 0;
  background: #f7f7f7;
}

.blog-section .news-item {
  background: #fff;
  -webkit-box-shadow: 0px 0px 1px #e7e7e7 !important;
          box-shadow: 0px 0px 1px #e7e7e7 !important;
  border: 1px solid #e7e7e7;
  overflow: hidden;
}

.blog-section .news-item.-news-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.blog-section .news-item-descr.big-news {
  height: 106px;
}

.blog-section .news-item h3 {
  color: #222;
  -webkit-transition: all .25s;
  transition: all .25s;
}

.blog-section .news-item.-news-slider-item {
  height: 100%;
}

.blog-section .news-item.-news-slider {
  height: auto;
}

.blog-section .news-item a:hover {
  text-decoration: none;
}

.blog-section .news-item a:hover h3 {
  color: #FF385C;
  -webkit-transition: all .25s;
  transition: all .25s;
}

.blog-section .news-item .news-img-link.-news-page {
  display: block;
  position: relative;
  padding-bottom: 69%;
  width: 100%;
}

.blog-section .news-item .news-img-link.-news-page .resp-img {
  background-size: cover;
}

.blog-section .resp-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blog-section .news-item-img .resp-img {
  background-size: cover;
}

.blog-section .news-item-img {
  position: relative;
  width: 100%;
}

.blog-section .news-item-img .resp-img {
  background-size: cover;
}

.blog-section .news-item-text {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.blog-section .news-item-text-header {
  display: block;
}

.blog-section .news-item .date {
  display: block;
  margin-bottom: 9px;
  font-size: 13px;
  color: #333;
}

.blog-section .dates {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: .3rem;
}

.blog-section .news-item-bottom img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.blog-section .admin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-section .admin p {
  font-size: 13px;
  font-weight: 600;
  margin-top: .5rem;
  margin-right: 1rem;
  margin-bottom: 0px;
}

.blog-section .news-item-descr {
  margin: 6px 0px 20px 0px;
  height: 80px;
}

.blog-section .news-item-descr p {
  color: #666;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.blog-section .news-item-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.blog-section .action-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: .8rem;
  color: #333;
}

.blog-section .action-list .fa-comment {
  color: #FF385C;
}

.blog-section .action-list .fa-share-alt {
  color: #FF385C;
}

.blog-section .action-list .fa-heart {
  color: #FF385C;
}

.blog-section ul.action-list {
  margin-bottom: 0px;
}

.blog-section .action-item {
  display: inline-block;
  padding-left: 15px;
}

.blog-section .news-slider-wrap .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-section .news-item-sm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 29px;
}

.blog-section .news-item-sm.last {
  margin-bottom: 0px;
}

.blog-section .news-item-sm .news-img-link {
  -ms-flex-preferred-size: 49%;
      flex-basis: 49%;
  position: relative;
}

.blog-section .news-item-sm .news-img-link .news-item-img {
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.blog-section .news-item-sm .news-item-text {
  -ms-flex-preferred-size: 66.6%;
      flex-basis: 66.6%;
  padding: 15px 20px;
}

.blog-section .news-item-sm .news-item-descr {
  height: 52px;
}

.blog-section .news-link {
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  color: #FF385C;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.blog-section .news-link:hover {
  color: #121B22;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

@media screen and (max-width: 1199px) {
  .blog-section .news-item.news-item-sm.mb,
  .blog-section .news-item {
    margin-bottom: 0px;
  }

  .blog-section .news-item.no-mb {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 1024px) {
  .blog-section .news-item {
    margin-bottom: 2.5rem;
  }

  .blog-section .news-item.no-mb:last-child {
    margin-bottom: 0px;
  }

  .blog-section .news-item-sm .news-img-link {
    -ms-flex-preferred-size: 33.5%;
        flex-basis: 33.5%;
  }
}
@media screen and (min-width: 1025px) {
  .blog-section .news-item.min-last {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 577px) {
  .blog-section .news-item-sm {
    display: block;
  }

  .blog-section .news-item-sm .news-img-link .news-item-img {
    position: relative;
    padding-bottom: 69%;
  }

  .blog-section .news-item-sm {
    border-radius: 0px;
  }
}
@media screen and (max-width: 414px) {
  .blog-section .news-item-descr p,
  .blog-section .news-item-descr {
    overflow: hidden;
  }

  .blog-section ul.action-list {
    padding-left: 0px;
  }

  .blog-section .news-link {
    font-size: .9rem;
  }
}
@media screen and (max-width: 1024px) {
  .blog-section .news-item-sm .news-img-link {
    -ms-flex-preferred-size: 32%;
        flex-basis: 32%;
  }
}
/*-----------------------------------*/
/*----- 12. SECTION TESTIMONIALS ----*/
/*-----------------------------------*/
section.testimonials {
  background: #fff;
  padding: 4.6rem 0;
}

.testimonials .title-style h2,
.testimonials .title-style p {
  color: #FF385C;
}

.testimonials .title-style span {
  color: #000;
}

.testimonials .title-icon:before {
  content: "";
  width: 100px;
  height: 2px;
  background: #121B22;
  position: absolute;
  top: 14px;
  left: -80px;
}

.testimonials .title-icon:after {
  content: "";
  width: 100px;
  height: 2px;
  background: #121B22;
  position: absolute;
  top: 14px;
  right: -80px;
}

.testimonials .title-icon i {
  color: #FF385C;
  font-size: 1.8rem;
  margin-top: 1px;
}

.testimonials .owl-carousel .test-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #fff;
  color: #000;
  padding: 2rem;
  border-radius: 10px;
}

.testimonials .test-1 img {
  width: 90px;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonials .test-1 p {
  text-align: center;
  font-weight: 400;
  font-style: italic;
  margin: .3rem auto 0;
  max-width: 500px;
  color: #666;
}

.testimonials .test-1 p::before {
  content: "\f10d ";
  font-size: 1.2rem;
  font-family: fontawesome;
  left: 136px;
  top: -24px;
  position: relative;
  color: #FF385C;
}

.testimonials .test-1 p::after {
  bottom: 4px;
  content: "\f10e";
  font-size: .7rem;
  font-family: fontawesome;
  color: #fff;
  right: -6px;
  top: 0px;
  position: relative;
  color: #fff;
}

.testimonials .test-1 h3 {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: .8rem;
  color: #FF385C;
  font-weight: 600;
}

.testimonials .test-1 h6 {
  text-align: center;
  font-weight: 400;
  margin-bottom: 0px;
  color: #000;
}

.owl-dots {
  text-align: center;
  margin-top: 1.3rem;
  line-height: 0;
}

.owl-dot {
  border-radius: 50px;
  height: 10px;
  width: 10px;
  display: inline-block;
  background: #121B22 !important;
  margin-left: 5px;
  margin-right: 5px;
}

.owl-dot.active {
  background: #FF385C !important;
}

.starts {
  list-style: none;
  margin-top: .4rem;
  padding: 0px;
}

.starts li {
  text-align: center;
  display: inline-block;
  margin-bottom: 1.8rem;
}

.starts .fa {
  color: #ffc107 !important;
}

@media screen and (max-width: 767px) {
  .testimonials .test-1 p::before {
    left: 190px !important;
  }
}
@media screen and (max-width: 414px) {
  .testimonials .test-1 p::before {
    left: 135px !important;
  }
}
@media screen and (max-width: 360px) {
  .testimonials .test-1 p::before {
    left: 105px !important;
  }
}
/*-----------------------------------*/
/*------ 13. SECTION PARTNERS ------*/
/*-----------------------------------*/
div.partners-1,
div.partners {
  padding: 3rem 0;
  background: #f7f7f7;
  color: #000;
}

.partners-1 .style2 .owl-item img,
.partners .style2 .owl-item img {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.partners-1 .style2 img {
  background: #fff;
  width: 100px;
  opacity: 0.5;
  border-radius: 10px;
}

.partners .style2 img {
  width: 100%;
  opacity: 0.5;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

/*-----------------------------------*/
/*------ 14. SECTION COUNTER UP -----*/
/*-----------------------------------*/
section.counterup {
  padding: 6rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0.8))), url(../images/bg/bg-counter2.jpg) no-repeat center center;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(../images/bg/bg-counter2.jpg) no-repeat center center;
  background-attachment: fixed;
  color: #fff;
  text-align: center;
}

.counterup .section-title {
  padding-bottom: 3.5rem;
}

.counterup .title-icon:before {
  content: "";
  width: 100px;
  height: 2px;
  background: #FF385C;
  position: absolute;
  top: 14px;
  left: -80px;
}

.counterup .title-icon:after {
  content: "";
  width: 100px;
  height: 2px;
  background: #FF385C;
  position: absolute;
  top: 14px;
  right: -80px;
}

.counterup .title-icon i {
  color: #121B22;
  font-size: 1.8rem;
  margin-top: 1px;
}

.counterup .countr .fa {
  font-size: 3rem;
  color: #FF385C;
  border-radius: 5px;
  margin-right: 1.5rem;
}

.counterup .countr p {
  font-size: 30px;
  font-weight: 700;
  color: #121B22;
}

.counterup .title-style h2,
.counterup .title-style p {
  color: #FF385C;
}

.counterup .title-style span {
  color: #121B22;
}

.counterup .countr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.counterup .count-me h3 {
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  color: #121B22;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .counterup .countr h3 {
    margin-bottom: 5rem;
  }

  .counterup .countr.mb-0 h3 {
    margin-bottom: 0px;
  }

  .counterup .countr {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .counterup .countr p {
    padding: 0px;
  }

  h3.specific:last-child {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 767px) {
  .counterup .countr.mb-0 h3 {
    margin-bottom: 5rem;
  }

  .counterup .countr.mb-0.last {
    margin-right: 1rem;
  }

  .counterup .countr.mb-0.last h3 {
    margin-bottom: 0px;
  }

  .counterup .countr .fa {
    margin-top: 0px;
  }
}
/*-----------------------------------*/
/*------ 15. SECTION FOOTER ------*/
/*-----------------------------------*/
.first-footer .top-footer {
  background: #303441;
  color: #333;
  padding: 80px 0;
  border-top: 1px solid #e5e5e5;
}

.first-footer .logo img {
  width: 180px;
  height: auto;
  margin-bottom: 1.6rem;
}

@media screen and (max-width: 991px) {
  .first-footer .netabout {
    margin-bottom: 0px;
  }

  .first-footer .netabout p {
    margin-bottom: 2rem !important;
  }
}
.first-footer .netabout h3 {
  margin-bottom: 2rem;
}

.first-footer .netabout h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #fff;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.first-footer .netabout p {
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: rgba(245, 255, 255, 0.8);
}

.first-footer .netsocials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.first-footer .netsocials a .fa,
.first-footer .netsocials a .fab {
  font-size: 1rem;
  text-align: center;
  background: transparent;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 1.5rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.first-footer .netsocials a .fa-facebook {
  background: transparent;
  color: #fff;
}

.first-footer .netsocials a .fa-facebook:hover {
  background: #3b5998;
  color: #fff;
}

.first-footer .netsocials a .fa-twitter {
  background: transparent;
  color: #fff;
}

.first-footer .netsocials a .fa-twitter:hover {
  background: #1da1f2;
  color: #fff;
}

.first-footer .netsocials a .fa-instagram {
  background: transparent;
  color: #fff;
}

.first-footer .netsocials a .fa-instagram:hover {
  background: #E1306C;
  color: #fff;
}

.first-footer .netsocials a .fa-youtube {
  background: transparent;
  color: #fff;
  margin-right: 0px;
}

.first-footer .netsocials a .fa-youtube:hover {
  background: #cd201f;
  color: #fff;
}

.homepage-2 .first-footer .netsocials a .fa-facebook,
.homepage-5 .first-footer .netsocials a .fa-facebook,
.int_white_bg .first-footer .netsocials a .fa-facebook,
.th-17 .first-footer .netsocials a .fa-facebook {
  background: #3b5998;
}

.homepage-2 .first-footer .netsocials a .fa-twitter,
.homepage-5 .first-footer .netsocials a .fa-twitter,
.int_white_bg .first-footer .netsocials a .fa-twitter,
.th-17 .first-footer .netsocials a .fa-twitter {
  background: #1da1f2;
}

.homepage-2 .first-footer .netsocials a .fa-instagram,
.homepage-5 .first-footer .netsocials a .fa-instagram,
.int_white_bg .first-footer .netsocials a .fa-instagram,
.th-17 .first-footer .netsocials a .fa-instagram {
  background: #E1306C;
}

.homepage-2 .first-footer .netsocials a .fa-youtube,
.homepage-5 .first-footer .netsocials a .fa-youtube,
.int_white_bg .first-footer .netsocials a .fa-youtube,
.th-17 .first-footer .netsocials a .fa-youtube {
  background: #cd201f;
}

@media screen and (max-width: 768px) {
  .homepage-2 .second-footer .container-fluid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media screen and (min-width: 1025px) {
}
.first-footer h3 {
  margin-bottom: 2rem;
  color: #fff;
}

.first-footer .contactus h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.first-footer .contactus ul li:last-child,
.first-footer .contactus .info:last-child {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 767px) {
  .first-footer .top-footer {
    padding-bottom: 6rem;
  }
}
.first-footer .contactus .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: .6rem;
}

.first-footer .contactus .ti {
  margin-bottom: 0px;
}

.first-footer .contactus .info p {
  margin: 0px;
  font-size: 15px;
  color: rgba(245, 255, 255, 0.8);
  margin-left: 1rem;
  line-height: 1.1;
  font-weight: 400;
}

.first-footer .contactus ul li {
  margin-bottom: 1.1rem;
}

.first-footer .contactus ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.first-footer .contactus ul li .fa {
  font-size: 1rem;
  color: #fff;
}

.navigation h3 {
  margin-bottom: 2rem;
}

.first-footer .nav-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.first-footer .navigation h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 991px) {
  .first-footer .navigation h3 {
    margin-top: 0px;
  }

  .navigation ul {
    margin-bottom: 0px;
  }

  .navigation ul li.no-mgb {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 767px) {
  .first-footer .navigation h3 {
    margin-top: 3rem;
  }
}
.navigation ul {
  list-style: none;
  padding: 0px;
  margin-top: 16px;
  margin-bottom: 0px;
}

footer .title{
  font-weight: 600;
}

.navigation ul li a {
  text-decoration: none;
  font-weight: 400;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  line-height: 21px;
  font-size: 14px;
  color: #434747;
  margin-bottom: 16px;
}

.navigation ul li a span{
    color: #949E9D;
}

.navigation a {
  display: block;
  margin-bottom: .6rem;
}

.navigation .nav-right {
  margin-left: 2rem;
}

.first-footer .widget h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.twitter-widget.contuct .single-item {
  position: relative;
  margin-bottom: 25px;
}

.twitter-widget.contuct .single-item:last-child {
  margin-bottom: 0px;
}

.twitter-widget.contuct .twitter-area .icon-holder {
  position: absolute;
  left: 0;
  top: 0;
}

.twitter-widget.contuct .twitter-area .icon-holder i {
  color: #fff;
}

.twitter-widget.contuct .twitter-area .text {
  padding: 0px 0px 0px 30px;
}

.twitter-widget.contuct .twitter-area .text h5,
.inner-pages .twitter-widget.contuct .twitter-area .text h5 {
  font-size: 14px;
  color: rgba(245, 255, 255, 0.8);
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1.3rem;
}

.twitter-widget.contuct .twitter-area .text h5 a,
.inner-pages .twitter-widget.contuct .twitter-area .text h5 a {
  color: #fff;
  font-weight: 400;
}

.twitter-widget.contuct .twitter-area .text h4,
.inner-pages .twitter-widget.contuct .twitter-area .text h4 {
  font-size: 14px;
  color: rgba(245, 255, 255, 0.8);
  font-weight: 500;
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .first-footer .widget,
  .inner-pages .first-footer .widget {
    padding-top: 0px !important;
  }

  .first-footer .widget h3 {
    margin-top: 3rem;
  }
}
.inner-pages .first-footer .twitter-widget.contuct h5::after {
  display: none;
}

.bloq-email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.bloq-email .email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.newsletters h3 {
  margin-bottom: 2rem;
}

.first-footer .newsletters h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 768px) {
  .second-footer p {
    margin-bottom: 1rem !important;
  }
}
@media screen and (max-width: 991px) {
  .first-footer .newsletters h3 {
    margin-top: 3rem;
  }

  .bloq-email .email {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}
.newsletters p {
  margin-bottom: 2rem;
  color: rgba(245, 255, 255, 0.8);
  font-weight: 400;
}

.first-footer .bloq-email .email input[type="email"] {
  border: 0px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: .8rem;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.first-footer .bloq-email .email input[type="submit"] {
  border: 0px;
  padding: .8rem;
  background: #FF385C;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 1.5rem;
  cursor: pointer;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.first-footer .email input[type="submit"]:hover {
  background: rgba(245, 255, 255, 0.1);
}

@media screen and (max-width: 991px) {
  .first-footer .bloq-email .email input[type="submit"] {
    margin-top: 0px;
  }
}
@media screen and (max-width: 767px) {
  .first-footer .bloq-email .email input[type="submit"] {
    margin-top: 0px;
  }
}
@media screen and (max-width: 320px) {
  .bloq-email .email {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .first-footer .bloq-email .email input[type="submit"] {
    margin-top: 1.5rem;
  }
}
.first-footer .bloq-email label.error {
  color: #ff0000;
}

.first-footer .bloq-email label {
  color: #fff;
}

.first-footer .bloq-email .email input::-webkit-input-placeholder {
  color: rgba(245, 255, 255, 0.8);
  opacity: 1;
}

.first-footer .bloq-email .email input:-ms-input-placeholder {
  color: rgba(245, 255, 255, 0.8);
  opacity: 1;
}

.first-footer .bloq-email .email input::-ms-input-placeholder {
  color: rgba(245, 255, 255, 0.8);
  opacity: 1;
}

.first-footer .bloq-email .email input::placeholder {
  color: rgba(245, 255, 255, 0.8);
  opacity: 1;
}

/*------ HOVER EFFECT ------*/
.hover-effect figure {
  position: relative;
  overflow: hidden;
  margin: 0;
}

.hover-effect figure::before {
  position: absolute;
  top: 0;
  left: -90%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
          transform: skewX(-25deg);
}

.hover-effect:hover figure::before {
  -webkit-animation: shine .75s;
          animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.hover-effect figure img {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.hover-effect:hover figure img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.second-footer {
  background: #F2F7F7;
  border-top: 1px solid #949E9D;
  padding-top: 10px;
  padding-bottom: 10px;
}

.second-footer h3 {
  margin: 0;
  font-weight: 300;
}

.second-footer p {
  margin-top: .5rem;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #272B2B;
}

.second-footer a{
    color: #272B2B;
}

.second-footer p .fa-heart:before {
  color: #fff;
}

/*------ ARROW GO UP ------*/
element.style {
  display: inline;
}

.go-up {
  position: fixed;
  bottom: 1.5rem;
  right: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  background: #9AD3B5;
  padding: .9rem;
  color: #272B2B;
  border-radius: 5px;
  text-decoration: none;
  z-index: 99999;
  line-height: 0;
  display: none;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.go-up:hover,
.go-up:focus {
  outline: 0;
  text-decoration: none;
  color: #fff;
  background: #272B2B;
}

/*---------------------------------------*/
/*--------- 16. HOME 2 Image ----------*/
/*---------------------------------------*/
.bg-img3 {
  background-attachment: scroll;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url(../images/bg/video-poster2.png) no-repeat center top;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/bg/video-poster2.png) no-repeat center top;
  border-radius: 8px;
  height: 700px;
  position: relative;
  z-index: 99;
}

.m0a {
  margin: 0 auto;
}

.maxw1600 {
  max-width: 1600px;
}

.homepage-2 #header.cloned {
  display: none;
}

.maxw1600 .hero-main {
  padding: 152px 0;
}

.homepage-2 .btn.btn-yellow:hover {
  background: #fff !important;
  color: #687389;
}

.homepage-2 .info-help:before {
  border-radius: 10px;
}

.homepage-2 .right-side {
  width: 280px;
}

.homepage-2 .info-help.h18 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0.1))), url(../images/bg/info-service3.png) no-repeat scroll center center;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url(../images/bg/info-service3.png) no-repeat scroll center center;
}

.homepage-2 .team .team-social li a.facebook,
.homepage-2 .team .team-social li a.twitter,
.homepage-2 .team .team-social li a.instagram,
.homepage-2 .team .team-social li a.linkedin {
  background: rgba(74, 74, 76, 0.5);
}

.homepage-2 .first-footer .top-footer {
  background: #F2F7F7;
}

.homepage-2 .w100 {
  width: 100% !important;
}

.homepage-2 .rld-banner-tab {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.homepage-2 .nav-item a.active,
.homepage-2 .nav-item a:hover,
.homepage-2 .nav-item a.active:before,
.homepage-2 .btn.btn-yellow {
  background: #687389;
}

.homepage-2 .form-group .nice-select {
  margin: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.homepage-2 .form-group .nice-select i {
  color: #687389;
  font-size: 1.2rem;
  margin-top: 5px;
  margin-right: 10px;
}

.homepage-2 .form-group.categories {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}

.homepage-2 .explore__form-checkbox-list.full-filter.filter-block {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 20px;
}

.homepage-2 .range-slider .ui-slider .ui-slider-handle {
  border: 2px solid #687389;
}

.homepage-2 .range-slider .ui-widget-header,
.homepage-2 .checkboxes input[type=checkbox]:checked + label:before {
  background: #687389;
}

.homepage-2 .banner-inner h1 {
  margin-bottom: 30px;
}

.homepage-2 .rld-main-search {
  border-radius: 5px;
}

.homepage-2 .rld-main-search {
  height: 112px;
  padding: 30px 20px 30px 20px;
  -webkit-box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.2);
          box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 1024px) {
  .homepage-2 .team-block {
    padding-bottom: 2.5rem;
  }

  .homepage-2 .team-block.pb-none {
    padding-bottom: 0px;
  }

  .homepage-2 .newsletters p {
    width: 300px;
  }

  .homepage-2 .rld-main-search {
    padding: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .homepage-2 .featured-boxes-area {
    padding-bottom: 3.5rem;
  }

  .homepage-2 .explore__form-checkbox-list.full-filter.filter-block {
    margin-top: 32px;
  }
}
@media only screen and (min-width: 992px) {
  .homepage-2 .first-footer .bloq-email .email input[type="submit"] {
    margin-top: 0px;
  }

  .homepage-2 .twitter-widget.contuct .single-item,
  .homepage-2 .first-footer .netabout p {
    width: 280px;
  }
}
@media only screen and (max-width: 991px) {
  .homepage-2 #header.cloned {
    position: absolute;
  }

  .maxw1600 {
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .maxw1600 .hero-main {
    padding: 70px 0;
  }

  .homepage-2 .banner-inner h1 {
    margin-bottom: 30px;
  }

  .homepage-2 .explore__form-checkbox-list.full-filter.filter-block {
    margin-top: 40px;
  }

  .homepage-2 .team-block.pb-none {
    padding-bottom: 2.5rem;
  }
}
.homepage-2 .second-footer .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.homepage-2 .portfolio .homes-price {
  font-size: 20px !important;
  font-weight: 500;
  background: transparent !important;
}

.homepage-2 .featured .project-single .button-effect {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.homepage-2 .featured .button-effect {
  position: absolute;
  text-align: left;
  bottom: 0;
  right: 0;
  z-index: 33;
  background: transparent;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 0;
  visibility: visible;
  padding: 8px 0px;
  border-radius: 20px 0 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: .7rem;
}

.homepage-2 .featured .button-effect .btn {
  display: inline-block;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-right: 15px;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  line-height: 33px;
  background: rgba(74, 74, 76, 0.7);
  z-index: 99;
}

.homepage-2 .featured .button-effect .fa-link:before {
  content: "\f0c1";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-2 .featured .button-effect .fa-video:before {
  content: "\f03d";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-2 .featured .button-effect .fa-photo:before {
  content: "\f03e";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-2 .portfolio .project-head {
  background-color: #1d293e;
  border-radius: 8px 8px 0 0;
}

.homepage-2 .portfolio .homes-price {
  padding-left: 0px;
}

.homepage-2 .portfolio .project-head:before {
  background: transparent !important;
}

.homepage-2 .portfolio .project-head img {
  background-color: #1d293e;
  opacity: .7;
}

.homepage-2 .portfolio .project-single {
  border-radius: 0 0 8px 8px;
}

.homepage-2 .portfolio .homes-content .homes-address .fa-map-marker {
  color: #666;
}

.homepage-2 .portfolio .homes-content h3 a:hover,
.homepage-2 .blog-section .news-item h3:hover,
.homepage-2 .dropdown-filter span::after {
  color: #687389;
}

.homepage-2 .btn:hover,
.homepage-2 .popular-places .listing-badges .featured,
.homepage-2 .homes-tag.featured {
  background: #687389 !important;
}

.homepage-2 .portfolio .homes-tag.sale {
  background: rgba(74, 74, 76, 0.7) !important;
}

.homepage-2 .popular-places .img-box {
  border-radius: 10px;
}

.homepage-2 .portfolio .footer img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.homepage-2 .portfolio .footer {
  border-top: 1px solid #eee;
}

.homepage-2 .portfolio .footer span {
  margin-top: 12px;
}

/*------ register form------------------------------------------------*/
.login-and-register-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  overflow: auto;
  display: none;
  -webkit-transform: translate3d(0, 0, 0);
  background: rgba(0, 0, 0, 0.81);
}

.login-and-register-form .hid-body {
  overflow: hidden;
}

.login-and-register-form .main-register-holder {
  max-width: 480px;
  margin: 100px auto 50px;
  position: relative;
  z-index: 5;
}

.login-and-register-form .main-register h3 {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #444;
  border-bottom: 1px solid #eee;
}

.login-and-register-form .main-register h3 span {
  font-weight: 800;
  font-size: 18px;
  color: #121B22;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}

.login-and-register-form .main-register {
  float: left;
  width: 100%;
  position: relative;
  padding: 0 0 30px;
  margin-bottom: 50px;
  background: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.12);
}

.login-and-register-form .close-reg {
  position: absolute;
  background: #FF385C;
  top: 14px;
  right: 30px;
  border-radius: 100%;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.login-and-register-form .fa.fa-times {
  padding-left: 14px;
  text-align: center;
}

.login-and-register-form .fa-remove:before,
.login-and-register-form .fa-close:before,
.login-and-register-form .fa-times:before {
  content: "\f00d";
}

.login-and-register-form .soc-log {
  padding: 15px 30px;
}

.login-and-register-form .soc-log p {
  margin-bottom: 12px;
  font-size: 18px;
  color: #454545;
  font-weight: 600;
}

.login-and-register-form .soc-log a {
  float: left;
  width: 100%;
  padding: 13px 0;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  outline: 0;
  text-decoration: none;
}

.login-and-register-form .soc-log a.facebook-log {
  text-align: center;
  background: #3b5998;
}

.login-and-register-form .soc-log a.twitter-log {
  text-align: center;
  background: #55acee;
}

.login-and-register-form .soc-log a i {
  font-size: 20px;
  padding-right: 15px;
  position: relative;
  top: 3px;
}

.login-and-register-form .soc-log a.facebook-log {
  background: #3b5998;
}

.login-and-register-form .soc-log a.twitter-log {
  background: #55acee;
}

.login-and-register-form .close-reg:hover, .custom-form .log-submit-btn:hover, .soc-log a:hover {
  background: #2F3B59;
}

.login-and-register-form .log-separator span {
  position: relative;
  width: 100%;
  font-size: 16px;
  display: inline-block;
  background: #fff;
  z-index: 2;
  text-align: center;
}

.login-and-register-form .log-separator:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 30px;
  right: 30px;
  height: 1px;
  background: #eee;
  z-index: 1;
}

.login-and-register-form .custom-form .log-submit-btn {
  float: left;
  padding: 13px 35px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 12px;
}

.login-and-register-form li {
  list-style: none;
}

.login-and-register-form ul {
  list-style: none;
}

.login-and-register-form .tabs-menu li.current a {
  background: #FF385C;
}

.login-and-register-form .main-register .filter-tags {
  float: left;
}

.login-and-register-form .lost_password {
  margin-top: 36px;
  float: right;
}

.login-and-register-form .lost_password a {
  text-decoration: none;
  float: left;
  color: #50596E;
  font-size: 14px;
  font-weight: 400;
}

.login-and-register-form #tabs-container {
  float: left;
  width: 100%;
  margin-top: 20px;
  padding: 0 30px;
}

.login-and-register-form .tab {
  float: left;
  width: 100%;
}

.login-and-register-form .tabs-menu {
  float: left;
  padding-left: 0px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.login-and-register-form .tabs-menu li {
  float: left;
  margin-right: 20px;
}

.login-and-register-form .tabs-menu li a {
  text-decoration: none;
  float: left;
  padding: 14px 35px;
  font-weight: 500;
  color: #494949;
  background: #eee;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.login-and-register-form .main-register .custom-form label {
  padding-bottom: 12px;
}

.login-and-register-form .custom-form label {
  float: left;
  font-size: 14px;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: #666;
}

.login-and-register-form .main-register .custom-form input[type="text"],
.login-and-register-form .main-register .custom-form input[type=email],
.login-and-register-form .main-register .custom-form input[type=password] {
  padding-left: 20px;
}

.login-and-register-form .custom-form textarea,
.login-and-register-form .custom-form input[type="text"],
.login-and-register-form .custom-form input[type=email],
.login-and-register-form .custom-form input[type=password],
.login-and-register-form .custom-form input[type=button] {
  float: left;
  border: 1px solid #eee;
  background: #f9f9f9;
  width: 100%;
  padding: 15px 20px 15px 55px;
  border-radius: 6px;
  color: #666;
  font-size: 13px;
  -webkit-appearance: none;
}

.login-and-register-form .custom-form input {
  margin-bottom: 20px;
}

.login-and-register-form .custom-form .log-submit-btn {
  float: left;
  background: #FF385C;
  padding: 13px 35px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 12px;
}

.login-and-register-form .main-register .filter-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
}

.login-and-register-form .filter-tags {
  margin-top: 30px;
}

.login-and-register-form .tabs-menu li.current a,
.login-and-register-form .tabs-menu li a:hover {
  color: #fff;
  background: #FF385C;
}

.login-and-register-form .tab-contents {
  width: 100%;
  padding: 20px 0;
  display: none;
}

.login-and-register-form #tab-1 {
  display: block;
}

.login-and-register-form .main-register h3 span strong {
  color: #FF385C;
  border: none;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.login-and-register-form .fl-wrap {
  float: left;
  width: 100%;
  position: relative;
}

.login-and-register-form .soc-log a i {
  font-size: 20px;
  padding-right: 15px;
  position: relative;
  top: 3px;
}

/*---------------------------------------*/
/*--------- 17. HOME 3 Image ----------*/
/*---------------------------------------*/
.homepage-3 .head-tr.bottom {
  background: transparent;
  border: 0px;
}

.homepage-3 a.button.border,
a.button,
.homepage-4 .header-image .left .btn-white {
  background: #fff;
  color: #8731E8 !important;
}

.homepage-3 .header-user-name,
.homepage-3 .header-user-name:before {
  color: #fff;
}

.homepage-3 #navigation.style-2.cloned .header-user-name,
.homepage-3 #header.cloned.sticky .header-user-name,
.homepage-3 #navigation.style-2.cloned .header-user-name:before,
.homepage-3 #header.cloned.sticky .header-user-name:before {
  color: #666;
}

.homepage-3 #navigation.style-1 ul li:hover {
  background: #8731E8;
}

.homepage-3 .welcome-area {
  background: linear-gradient(-36deg, rgba(69, 40, 220, 0.8) 0%, rgba(135, 49, 232, 0.8) 100%), url(../images/bg/video-poster2.png) no-repeat scroll center center/cover;
}

.homepage-3 .welcome-area {
  height: 840px;
  position: relative;
  z-index: 99;
}

.homepage-3 .banner-inner h1 {
  margin-bottom: 15px;
}

.homepage-3 .banner-inner .sub-title {
  margin-bottom: 60px;
}

.homepage-3 .bg-overlay, .overlay-dark {
  position: relative;
  z-index: 0;
}

.homepage-3 .hero-main {
  padding: 243px 0;
}

@media (max-width: 550px) {
  .homepage-3 .hero-main {
    padding: 160px 0;
  }

  .homepage-3 .banner-inner h1 {
    font-size: 22px;
  }

  .homepage-3 .banner-inner .sub-title {
    font-size: 15px;
  }
}
.homepage-3 .btn, .homepage-3 .btn:active,
.homepage-3 .btn.sApp-btn:before,
.homepage-3 .bg-overlay::after,
.homepage-3 .benifits-item:after {
  background: linear-gradient(-47deg, #4528DC 0%, #8731E8 100%);
}

.homepage-3 a.btn.btn-yellow:hover {
  background: #fff !important;
  color: #8731E8;
}

.homepage-3 .bg-overlay::after {
  opacity: 0.87;
  z-index: -1;
}

.homepage-3 .rld-banner-tab .nav-item a.active,
.homepage-3 .rld-banner-tab .nav-item a:hover,
.homepage-3 .rld-banner-tab .nav-item a.active:before,
.homepage-3 .range-slider .ui-widget-header,
.homepage-3 .checkboxes input[type=checkbox]:checked + label:before {
  background: #963aff;
}

.homepage-3 .parallax-searchs .form-group .nice-select i,
.homepage-3 .dropdown-filter span::after {
  color: #963aff;
}

.homepage-3 .parallax-searchs .form-group .nice-select:hover {
  color: #963aff;
}

.homepage-3 .rld-banner-tab .nav-item a {
  border-radius: 8px;
}

.homepage-3 .range-slider .ui-slider .ui-slider-handle {
  border: 2px solid #963aff;
}

.homepage-3 .rld-single-select .single-select:before {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 8px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: 48%;
  -webkit-transform-origin: 66% 66%;
          transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
}

.homepage-3 .rld-single-input input {
  border-radius: 8px;
  margin-left: 0px;
  outline: none;
  text-decoration: none;
}

.homepage-3 .rld-single-select .single-select {
  width: 180px;
}

.homepage-3 .btn.btn-yellow {
  border: 0px;
  border-radius: 8px;
  font-size: 15px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
          box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  color: #ffffff;
  height: 50px;
  width: 170px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

@media (min-width: 992px) {
  .homepage-3 .rld-main-search .rld-single-input, .homepage-3 .rld-main-search .rld-single-select .single-select, .homepage-3 .popular-searchs {
    margin-bottom: 0px;
  }
}
@media (max-width: 575px) {
  .homepage-3 .popular-searchs {
    display: none !important;
  }
}
.homepage-3 .popular-searchs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 40px;
}

.homepage-3 .popular-searchs li {
  display: inline-block;
}

.homepage-3 .popular-searchs li a {
  display: inline-block;
  text-decoration: none;
  width: 115px;
  height: 100px;
  margin-right: 15px;
  background: rgba(255, 255, 255, 0.12);
  padding-top: 11px;
  color: #ffffff;
  font-size: 28px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-transition: all ease .5s;
  transition: all ease .5s;
}

.homepage-3 .popular-searchs .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.homepage-3 .popular-searchs li a .box span {
  font-size: 16px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
  font-weight: 400;
  display: block;
}

.homepage-3 .rec-pro .container-fluid {
  max-width: 1560px;
}

.homepage-1 .rec-pro2 .container-fluid {
  max-width: 1320px;
}

.homepage-1 .recent-16 {
  display: block;
  position: relative;
  overflow: hidden;
  height: 310px;
}

.homepage-1 .recent-title {
  font-size: 20px;
  font-weight: 500;
}

.homepage-3 .portfolio .homes-price {
  font-size: 20px !important;
  font-weight: 500;
  background: transparent !important;
}

.homepage-3 .featured .project-single .button-effect {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.homepage-3 .featured .button-effect {
  position: absolute;
  text-align: left;
  bottom: 0;
  right: 0;
  z-index: 33;
  background: transparent;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 0;
  visibility: visible;
  padding: 8px 0px;
  border-radius: 20px 0 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: .7rem;
}

.homepage-3 .featured .button-effect .btn {
  display: inline-block;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-right: 15px;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  line-height: 33px;
  background: rgba(74, 74, 76, 0.7);
  z-index: 99;
}

.homepage-3 .featured .button-effect .fa-link:before {
  content: "\f0c1";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-3 .featured .button-effect .fa-video:before {
  content: "\f03d";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-3 .featured .button-effect .fa-photo:before {
  content: "\f03e";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-3 .portfolio .project-head {
  background-color: #1d293e;
  border-radius: 8px 8px 0 0;
}

.homepage-3 .portfolio .homes-price {
  padding-left: 0px;
}

.homepage-3 .portfolio .project-head:before {
  background: transparent !important;
}

.homepage-3 .portfolio .project-head img {
  background-color: #1d293e;
  opacity: 1;
}

.homepage-3 .portfolio .project-single {
  border-radius: 0 0 8px 8px;
  margin-bottom: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #e7e7e7;
}

.homepage-3 .portfolio .homes-content .homes-address .fa-map-marker {
  color: #666;
}

.homepage-3 .portfolio .homes-content h3 a:hover,
.homepage-3 .blog-section .news-item h3:hover {
  color: #687389;
}

.homepage-3 .btn:hover,
.homepage-3 .popular-places .listing-badges .featured,
.homepage-3 .homes-tag.featured {
  background: #8731E8 !important;
}

.homepage-3 .portfolio .homes-tag.sale {
  background: #FF385C !important;
}

.homepage-3 .portfolio .homes-tag.rent {
  background: #18ba60 !important;
}

.homepage-3 .popular-places .img-box {
  border-radius: 10px;
}

.homepage-3 .portfolio .footer img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.homepage-3 .portfolio .homes-content .homes-list li {
  width: 33.3%;
}

.homepage-3 .portfolio .homes-content .homes-list li i {
  color: #696969;
  font-size: 18px;
}

.homepage-3 img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.homepage-3 .portfolio .footer {
  border-top: 1px solid #eee;
}

.homepage-3 .portfolio .footer span {
  margin-top: 12px;
}

.homepage-3 .sec-title {
  text-align: center;
  padding-bottom: 2rem;
}

.homepage-3 .sec-title h2 {
  color: #111;
  font-weight: 600;
  text-transform: capitalize;
}

.homepage-3 .sec-title h2 span {
  position: relative;
  color: #444;
  font-weight: 500;
}

.homepage-3 .sec-title p {
  margin-top: 13px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  max-width: 550px;
}

.homepage-3 .slick-dots li.slick-active {
  -webkit-box-shadow: inset 0 0 0 6px #8731E8;
          box-shadow: inset 0 0 0 6px #8731E8;
}

.homepage-3 .slick-prev:hover,
.homepage-3 .slick-next:hover {
  background: #8731E8;
}

.homepage-3 .slick-dots li {
  color: #8731E8;
}

.homepage-3 .slick-dots li:after {
  background: #8731E8;
}

.homepage-3 .slick-dots li {
  -webkit-box-shadow: inset 0 0 0 2px #8731E8;
          box-shadow: inset 0 0 0 2px #8731E8;
}

.homepage-3 .popular-places .img-box {
  height: 280px;
}

.homepage-3 .popular-places .img-box-content h4 {
  font-size: 20px;
}

.homepage-3 .info-help .info-head .info-text .btn-pro {
  background: #8731E8;
}

.homepage-3 .info-help.h17 {
  background: url(../images/bg/bg-home-3.png) no-repeat scroll center center;
}

.homepage-3 .blog-section .action-list .fa-heart,
.homepage-3 .blog-section .action-list .fa-comment,
.homepage-3 .blog-section .action-list .fa-share-alt,
.homepage-3 .blog-section .news-link {
  color: #8731E8;
}

.homepage-3 .first-footer .top-footer {
  background: #232733;
}

.homepage-3 .first-footer .navigation h3::after,
.homepage-3 .first-footer .widget h3::after,
.homepage-3 .first-footer .newsletters h3::after,
.homepage-3 .first-footer .bloq-email .email input[type="submit"],
.homepage-3 .go-up {
  background: #8731E8;
}

.bg-white-3 {
  background: url(../images/bg/bg-white-3.png) 0 0 repeat !important;
}

.homepage-3 .singleJobClinet,
.homepage-1 .singleJobClinet {
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0px 0px 2px #e7e7e7;
          box-shadow: 0px 0px 2px #e7e7e7;
  border: 1px solid #e7e7e7;
  padding: 44px 53px 55px;
  margin-bottom: 30px;
}

.homepage-3 .singleJobClinet p,
.homepage-1 .singleJobClinet p {
  letter-spacing: 0.32px;
  margin-bottom: 25px;
}

.homepage-3 .detailJC,
.homepage-1 .detailJC {
  position: relative;
  padding-left: 88px;
  padding-top: 7px;
}

.homepage-3 .detailJC span,
.homepage-1 .detailJC span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 68px;
  height: 68px;
  padding: 4px;
  border-radius: 50%;
  background: #f04786;
  background: -webkit-gradient(linear, left top, right top, from(rgba(240, 71, 134, 0.75)), to(rgba(125, 107, 255, 0.75)));
  background: linear-gradient(90deg, rgba(240, 71, 134, 0.75) 0%, rgba(125, 107, 255, 0.75) 100%);
}

.homepage-1 .detailJC span {
  background: -webkit-gradient(linear, left top, right top, from(rgba(240, 71, 134, 0.75)), to(rgba(255, 56, 92, 0.75)));
  background: linear-gradient(90deg, rgba(240, 71, 134, 0.75) 0%, rgba(255, 56, 92, 0.75) 100%);
}

.homepage-3 .detailJC span img,
.homepage-1 .detailJC span img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.homepage-3 .detailJC h5,
.homepage-1 .detailJC h5 {
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.38px;
  margin-bottom: 0;
}

.homepage-3 .detailJC p,
.homepage-1 .detailJC p {
  color: #666;
  font-size: 15px;
  letter-spacing: .30px;
  margin: 0;
}

.homepage-3 .detailJC:after,
.homepage-1 .detailJC:after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: '';
  width: 68px;
  height: 58px;
  background: url(../images/icons/quoe.png) no-repeat center center/cover;
}

.homepage-3 .job_clientSlide .owl-nav .owl-prev,
.homepage-3 .job_clientSlide .owl-nav .owl-next,
.homepage-1 .job_clientSlide .owl-nav .owl-prev,
.homepage-1 .job_clientSlide .owl-nav .owl-next {
  margin: 0 5px;
  padding: 0 0;
  text-align: center;
  width: 43px;
  height: 43px;
  border: 2px solid #ddd;
  border-radius: 50%;
  font-size: 18px;
  color: #70778b;
  line-height: 43px;
  background: transparent;
  -webkit-transition: all ease .5s;
  transition: all ease .5s;
}

.homepage-3 .job_clientSlide .owl-nav,
.homepage-1 .job_clientSlide .owl-nav {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.homepage-3 .owl-nav,
.homepage-1 .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.homepage-3 .job_clientSlide .owl-nav .owl-prev:hover,
.homepage-3 .job_clientSlide .owl-nav .owl-next:hover {
  background: #8731E8;
  color: #fff;
}

.homepage-1 .job_clientSlide .owl-nav .owl-prev:hover,
.homepage-1 .job_clientSlide .owl-nav .owl-next:hover {
  background: #FF385C;
  color: #fff;
}

/*---------------------------------------*/
/*--- 18. IMAGE VIDEO HOME 4  ---*/
/*---------------------------------------*/
.welcome-area {
  height: 800px;
  z-index: 1;
}

.welcome-thumb {
  max-width: 300px;
  padding-top: 0px;
}

@media (min-width: 992px) {
  .download-page .welcome-intro > h1 {
    font-size: 3.8em;
  }

  .download-page .welcome-intro p {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .homepage-4 .header-image {
    padding: 100px 30px 70px 30px;
  }

  .homepage-4 .info-help .info-head .info-text {
    margin-top: 0px !important;
  }

  .homepage-4 .info-help .info-head .info-text .btn-pro,
  .homepage-4 .portfolio .project-single.specials {
    margin-bottom: 30px;
  }

  .download-page .welcome-intro p {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .homepage-4 a.button.border,
  a.button,
  .homepage-4 .header-image .left .btn-white {
    margin-left: 20px;
  }
}
@media (max-width: 991px) {
  .homepage-4 a.button.border,
  a.button,
  .homepage-4 .header-image .left .btn-white {
    margin-left: 0px;
  }
}
.homepage-4 .header-user-name,
.homepage-4 .header-user-name:before {
  color: #fff;
}

.homepage-4 #navigation.style-2.cloned .header-user-name,
.homepage-4 #header.cloned.sticky .header-user-name,
.homepage-4 #navigation.style-2.cloned .header-user-name:before,
.homepage-4 #header.cloned.sticky .header-user-name:before {
  color: #666;
}

.welcome-area .video-icon {
  position: absolute;
  top: 50%;
  left: 70%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.play-btn {
  position: relative;
  display: inline-block;
  height: 100px;
  width: 100px;
  background-color: #fff;
  border-radius: 50%;
}

.btn-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #fff;
}

.play-icon {
  font-size: 22px;
  color: #FF385C;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@-webkit-keyframes grow {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes grow {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}
.play-animation {
  -webkit-animation: grow 3s infinite;
          animation: grow 3s infinite;
}

.animation-short {
  -webkit-animation-delay: .5s;
          animation-delay: .5s;
}

.welcome-area.bg-overlay::after {
  z-index: -2;
}

.welcome-area .shape-bottom {
  z-index: -1;
}

.shape-bottom {
  position: absolute;
  top: auto;
  bottom: -1px;
  left: 0;
  right: 0;
}

.homepage-4 .portfolio .homes-content .homes-list li i {
  color: #696969;
  font-size: 18px;
}

.homepage-4 .portfolio .footer i {
  color: #696969;
  font-size: 18px;
}

.the-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 17px !important;
  padding-top: 12px;
  padding-bottom: 12px;
}

/*BACKGROUND OVERLAY*/
.bg-overlay,
.overlay-dark {
  position: relative;
  z-index: 0;
}

.bg-overlay::after,
.overlay-dark:after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.bg-overlay::after {
  opacity: 0.90;
  z-index: -1;
}

.overlay-dark::after {
  background-color: #ff385c;
  opacity: 0.80;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
  z-index: -1;
}

/*BACKGROUND COLORS*/
.homepage-4 .bg-gray {
  background-color: #f6f9fe;
}

.bg-inherit {
  background: inherit !important;
}

.homepage-4 #navigation.style-1 ul li:hover {
  background: #FF385C;
}

.homepage-4 .btn,
.homepage-4 .btn:active,
.homepage-4 .btn.sApp-btn:before,
.homepage-4 .bg-overlay::after,
.homepage-4 .benifits-item:after {
  background: linear-gradient(148deg, #fd5559 0%, #ff337d 100%);
}

.homepage-4 .head-tr.bottom {
  background: transparent;
  border: 0px;
}

.homepage-4 .slick-prev:hover,
.homepage-4 .slick-next:hover {
  background: #FF385C;
}

.homepage-4 .project-single .button-effect {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.homepage-4 .button-effect .btn {
  display: inline-block;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-right: 15px;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  line-height: 33px;
  background: rgba(74, 74, 76, 0.7);
  z-index: 99;
}

.homepage-4 .button-effect .fa-link:before {
  content: "\f0c1";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-4 .button-effect .fa-video:before {
  content: "\f03d";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-4 .button-effect .fa-photo:before {
  content: "\f03e";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-4 img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.hp4 .agents-grid {
  padding-left: 15px;
  padding-right: 15px;
}

.hp4 .slick-next {
  right: -73px;
}

.hp4 .slick-prev {
  left: -73px;
}

.hp4 .slick-dots li.slick-active,
.hp4 .slick-dots li {
  -webkit-box-shadow: inset 0 0 0 3px rgba(74, 74, 76, 0.4) !important;
          box-shadow: inset 0 0 0 3px rgba(74, 74, 76, 0.4) !important;
}

.hp4 .slick-dots li:after {
  background: #696565;
}

.header-user-name span:after {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  bottom: -5px;
  right: -5px;
  background-color: #38b653;
  display: block;
  border: 2px solid #fff;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.homepage-4 a.button.border, a.button,
.homepage-4 .header-image .left .btn-white {
  background: #fff;
  color: #FF385C !important;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
          box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
}

.homepage-4 .home18 .sec-title h2 {
  color: #FF385C;
}

.homepage-4 .home18 .sec-title h2 span::before {
  background: #FF385C;
}

.homepage-4 .portfolio .homes-tag.sale {
  background: rgba(74, 74, 76, 0.7) !important;
}

.homepage-4 .homes-tag.featured {
  background: #FF385C !important;
}

.homepage-4 .portfolio .homes-price {
  font-size: 20px !important;
  font-weight: 500;
  background: transparent !important;
}

.homepage-4 .featured .project-single .button-effect {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.homepage-4 .featured .button-effect {
  position: absolute;
  text-align: left;
  bottom: 0;
  right: 0;
  z-index: 33;
  background: transparent;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 0;
  visibility: visible;
  padding: 8px 0px;
  border-radius: 20px 0 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: .7rem;
}

.homepage-4 .featured .button-effect .btn {
  display: inline-block;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-right: 15px;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  line-height: 33px;
  background: rgba(74, 74, 76, 0.7);
  z-index: 99;
}

.homepage-4 .featured .button-effect .fa-link:before {
  content: "\f0c1";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-4 .featured .button-effect .fa-video:before {
  content: "\f03d";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-4 .featured .button-effect .fa-photo:before {
  content: "\f03e";
  font-family: FontAwesome;
  color: #fff;
}

.homepage-4 .portfolio .project-head {
  background-color: #1d293e;
  border-radius: 8px 8px 0 0;
}

.homepage-4 .portfolio .homes-price {
  padding-left: 0px;
}

.homepage-4 .portfolio .project-head:before {
  background: transparent !important;
}

.homepage-4 .portfolio .project-head img {
  background-color: #1d293e;
  opacity: .7;
}

.homepage-4 .portfolio .project-single {
  border-radius: 0 0 8px 8px;
}

.homepage-4 .portfolio .homes-content .homes-address .fa-map-marker {
  color: #666;
}

.homepage-4 .portfolio .homes-content h3 a:hover,
.homepage-4 .blog-section .news-item h3:hover {
  color: #FF385C;
}

.homepage-4 .btn:hover,
.homepage-4 .popular-places .listing-badges .featured {
  background: #FF385C;
}

.homepage-4 .popular-places .img-box {
  border-radius: 10px;
}

.homepage-4 .portfolio .footer img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.homepage-4 .portfolio .footer {
  border-top: 1px solid #eee;
}

.homepage-4 .portfolio .footer span {
  margin-top: 12px;
}

.homepage-4 .info-help {
  padding: 4.6rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.3)), to(rgba(255, 255, 255, 0.3))), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
  background-size: cover;
  position: relative;
  z-index: 3;
}

.homepage-4 .info-help .info-head .info-text {
  margin-top: 120px;
}

.homepage-4 .info-help .info-head .info-text .btn-pro {
  background: #FF385C;
}

.homepage-4 .testimonials .test-1 h3 {
  color: #666;
}

.homepage-4 .starts .fa {
  color: #FF385C !important;
}

.homepage-4 .testimonials .test-1 p::before {
  content: "\f10d ";
  font-size: 1.2rem;
  font-family: fontawesome;
  left: 236px;
  top: -24px;
  position: relative;
  color: #FF385C;
}

.homepage-4 .owl-dots {
  margin-top: 0px;
}

.homepage-4 .owl-dot.active {
  background: #666 !important;
}

.homepage-4 .owl-dot {
  background: #dedede !important;
}

.homepage-4 .starts {
  list-style: none;
  margin-top: .7rem;
  padding: 0px;
}

.homepage-4 .starts li {
  margin-bottom: 2.4rem;
}

.homepage-4 .testimonials .owl-carousel .test-1 {
  background: transparent;
}

.homepage-4 .blog-section .action-list .fa-heart,
.homepage-4 .blog-section .action-list .fa-comment,
.homepage-4 .blog-section .action-list .fa-share-alt,
.homepage-4 .blog-section .news-link {
  color: #FF385C;
}

.homepage-4 .first-footer .top-footer {
  background: #232733;
}

.homepage-4 .first-footer .navigation h3::after,
.homepage-4 .first-footer .widget h3::after,
.homepage-4 .first-footer .newsletters h3::after,
.homepage-4 .first-footer .bloq-email .email input[type="submit"],
.homepage-4 .go-up {
  background: #FF385C;
}

.homepage-4 .welcome-area {
  background: rgba(0, 0, 0, 0) url("../images/bg/bg-demo.jpg") no-repeat scroll center center/cover;
}

.homepage-4 .welcome-area .shape-bottom svg {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/*---------------------------------------*/
/*--- 19. HOME 5 IMAGE  ---*/
/*---------------------------------------*/
.homepage-5 .parallax-searchs.home17 {
  background: url(../images/bg/bg-home-5.jpg) no-repeat center top;
  background-size: cover;
  background-attachment: scroll !important;
  width: 100%;
  height: 85vh;
  position: relative;
  z-index: 99;
}

.homepage-5 .banner-inner-wrap {
  height: 90vh;
}

.homepage-5 .banner-inner .sub-title {
  margin-bottom: 80px;
}

.homepage-5 .rld-banner-tab .nav-item a:hover,
.homepage-5 .rld-banner-tab .nav-item a:focus,
.homepage-5 .rld-banner-tab .nav-item a.active {
  background: #3e4452;
  color: #fff;
}

.homepage-5 .btn-yellow {
  background: #3e4452;
}

.homepage-5 .rld-banner-tab {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.homepage-5 .nav-item a.active,
.homepage-5 .nav-item a:hover,
.homepage-5 .nav-item a.active:before,
.homepage-5 .btn.btn-yellow {
  background: #3e4452;
}

.homepage-5 .form-group .nice-select i {
  color: #3e4452 !important;
}

.homepage-5 .form-group .nice-select:hover {
  color: #3e4452 !important;
}

.homepage-5 .range-slider .ui-slider .ui-slider-handle {
  border: 2px solid #3e4452;
}

.homepage-5 .explore__form-checkbox-list.full-filter.filter-block {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 20px;
}

.homepage-5 .range-slider .ui-widget-header,
.homepage-5 .checkboxes input[type=checkbox]:checked + label:before {
  background: #3e4452;
}

.homepage-5 .range-slider .ui-widget-header,
.homepage-5 .checkboxes input[type=checkbox]:checked + label:before {
  background: #3e4452;
}

.homepage-5 .rld-main-search {
  height: 112px;
  padding: 30px 20px 30px 20px;
  border-radius: 5px;
}

.homepage-5 .banner-inner h1,
.homepage-5 .banner-inner .sub-title,
.homepage-5 .info-help .info-head .info-text h3,
.homepage-5 .info-help .info-head .info-text p,
.homepage-5 .dropdown-filter span::after {
  color: #3e4452;
}

.homepage-5 .portfolio.right-slider {
  position: relative;
}

.homepage-5 .recently-slider {
  padding-left: calc((100% - 1140px) / 2 + 15px) !important;
  padding-right: 0 !important;
}

.container-fluid.recently-slider {
  width: 100%;
}

.homepage-5 .home5-right-slider.owl-carousel .owl-prev,
.homepage-5 .home5-right-slider.owl-carousel .owl-next {
  background-color: #ffffff !important;
  border-radius: 50%;
  color: #555;
  height: 54px;
  line-height: 35px;
  position: absolute;
  top: 45%;
  width: 54px;
  -webkit-transform: translate(-50%, -50%) scale(1, 1);
          transform: translate(-50%, -50%) scale(1, 1);
  z-index: 10;
  overflow: hidden;
  -webkit-transition: all ease .5s;
  transition: all ease .5s;
}

.home5-right-slider.owl-carousel .owl-prev {
  left: 0px;
}

.home5-right-slider.owl-carousel .owl-next {
  right: 20px;
}

@media only screen and (max-width: 1440px) {
  .home5-right-slider.owl-carousel .owl-next {
    right: -5%;
  }
}
@media only screen and (max-width: 991px) {
  .homepage-5 .recently-slider {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .home5-right-slider.owl-carousel .owl-next {
    right: 0.5%;
  }

  .home5-right-slider.owl-carousel .owl-prev {
    left: 5%;
  }

  .homepage-5 .team .team-block {
    padding-bottom: 1px;
  }

  .homepage-19 .team .team-block {
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 575px) {
  .homepage-5 .banner-inner .sub-title {
    margin-bottom: 30px;
  }

  .home5-right-slider.owl-carousel .owl-prev {
    left: 50px;
  }

  .homepage-5 .explore__form-checkbox-list.full-filter.filter-block {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 1024px) {
  .homepage-5 .recently-slider {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
.home5-right-slider.owl-carousel .owl-prev:hover,
.home5-right-slider.owl-carousel .owl-next:hover {
  background: #3e4452 !important;
  color: #fff;
}

.home5-right-slider.owl-carousel .owl-prev i,
.home5-right-slider.owl-carousel .owl-next i {
  margin-left: 16px;
  margin-top: 16px;
  font-size: 26px;
}

.homepage-5 .owl-carousel .owl-stage {
  padding-left: 0px !important;
}

.homepage-5 .info-help.h18 {
  padding: 9rem 0;
  background: url(../images/bg/bg-service-home-5.png) no-repeat scroll center center;
  background-size: cover;
  position: relative;
  z-index: 0 !important;
}

.homepage-5 .info-help:before {
  background: transparent;
}

.homepage-5 .popular-places .listing-badges .featured,
.homepage-5 .team .team-social li a.facebook,
.homepage-5 .team .team-social li a.twitter,
.homepage-5 .team .team-social li a.instagram,
.homepage-5 .team .team-social li a.linkedin {
  background: #3e4452;
}

.homepage-5 .popular-places .img-box {
  height: 300px;
}

.homepage-5 .popular-places .img-box-content h4 {
  font-size: 20px;
}

.homepage-5 .sec-title {
  text-align: center;
  padding-bottom: 2rem;
}

.homepage-5 .sec-title h2 {
  color: #111;
  font-weight: 600;
  text-transform: capitalize;
}

.homepage-5 .sec-title h2 span {
  position: relative;
  color: #3e4452;
  font-weight: 600;
}

.homepage-5 .sec-title p {
  margin-top: 13px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  max-width: 550px;
}

.homepage-5 .info-help.h17 {
  padding: 9rem 0;
  background: url(../images/bg/bg-2-home-5.png) no-repeat scroll center center;
  background-size: cover;
  position: relative;
  z-index: 0 !important;
}

.homepage-5 .team-block .inner-box {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/*---------------------------------------*/
/*--- 20. HOME 2, 3 AND 4 SEARCH AREA ---*/
/*---------------------------------------*/
.main-search-field {
  padding: 3rem 0;
  background: #FF385C;
  background-repeat: repeat;
}

.main-search-field.mt10 {
  margin-top: -10px;
}

.main-search-field h3 {
  font-size: 2rem;
  margin-bottom: 2.5rem !important;
  color: #fff;
  font-weight: 800;
  margin: 0 auto 15px;
}

.main-search-field h3 span {
  color: #222;
  font-weight: 300;
}

.main-search-field select {
  width: 100%;
  height: 45px;
  padding: 5px 10px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16pxrem;
  border-radius: 0;
  background: transparent;
  text-transform: capitalize;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.main-search-field .no-mb select,
.main-search-field .at-col-default-mar.no-mb,
.main-search-field .at-input {
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .main-search-field .no-mb select,
  .main-search-field .at-input {
    margin-bottom: 20px;
  }
}
.main-search-field select:focus {
  outline: none;
}

.main-search-field option {
  background: #fff;
  color: #333;
}

.main-search-field select:focus {
  background: transparent;
}

.main-search-field .at-input {
  width: 100%;
  height: 45px;
  padding: 5px 10px;
  color: #000 !important;
  border: 1px solid #fff;
  border-radius: 0;
  background: #fff;
}

.main-search-field .at-input:focus {
  outline: none;
}

.main-search-field .at-col-default-mar {
  margin-bottom: 10px;
}

.main-search-field .at-input-price {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #fff;
  height: 25px;
  width: 180px;
}

.at-pricing-range h4 {
  color: #fff;
}

.main-search-field .hvr-bounce-to-right {
  width: 100%;
}

.main-search-field .hide {
  display: none !important;
}

.main-search-field .at-pricing-range label {
  color: #ffffff;
}

/* BTN CSS START*/
.main-search-field .at-btn-default {
  font-size: 18px;
  background: #fff;
  color: #ffffff;
  border: none;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.main-search-field .hvr-bounce-to-right {
  font-size: 14px;
  background: #121B22;
  color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  padding: 12px 22px;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.main-search-field .hvr-bounce-to-right:hover {
  background: #fff;
  color: #000;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.main-search-field .hvr-bounce-to-right:before {
  background-color: #bf864f;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

.slider_amount {
  border: none;
  background: none;
  padding: 0px;
  margin-bottom: 10px;
  width: 100%;
  color: #fff;
}

.ui-widget.ui-widget-content {
  border-radius: 2px;
  background: #fff;
  border: none;
}

.ui-slider-horizontal {
  height: 10px;
}

.ui-widget-header {
  background: #121B22;
}

@media screen and (max-width: 767px) {
  .hvr-bounce-to-right {
    margin-top: 30px;
  }

  .main-search-field.pt-3 {
    padding-top: 0px !important;
  }
}
/*---------------------------------------*/
/*--- 21. HOME 6 PARALLAX IMAGE ---*/
/*---------------------------------------*/
.parallax-search {
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url("../images/bg/bg-demo.jpg") no-repeat center top;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/bg/bg-demo.jpg") no-repeat center top;
  background-size: cover;
  background-attachment: fixed !important;
  width: 100%;
  height: auto;
}

.parallax-search .hero-inner {
  text-align: center;
  padding: 300px 0;
}

.parallax-search .welcome-text p {
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
}

.parallax-search .welcome-text h1 {
  font-size: 40px;
  color: #fff;
  text-transform: capitalize;
}

/* Search Form */
.parallax-search .trip-search {
  background: #fff;
  padding: 10px;
  border: 1px solid #A7CCE8;
  border-radius: 50px;
  text-align: left;
  margin-top: 30px;
}

.parallax-search .trip-search .form {
  line-height: 0px;
}

.parallax-search .form-group {
  width: 190px;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 0px;
}

.parallax-search .form-group:last-child {
  margin-right: 0px;
}

.parallax-search .form-group .nice-select {
  margin: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.parallax-search .form-group .nice-select:hover {
  color: #FF385C;
}

.parallax-search .form-group .nice-select::after {
  right: 20px;
  color: #757575;
}

.parallax-search .form-group .nice-select i {
  color: #FF385C;
  font-size: 1.2rem;
  margin-top: 5px;
  margin-right: 10px;
}

.parallax-search .form-group .list li {
  color: #757575;
}

.parallax-search .form-group .list li.selected {
  color: #757575;
  font-weight: 400;
}

.parallax-search .form-group .list li:hover {
  color: #fff;
  background: #FF385C;
}

.parallax-search .form-group.location {
  width: 150px;
}

.parallax-search .form-group.button {
  width: 145px;
  position: relative;
  height: 50px;
}

.hp-6 .rld-main-search {
  height: 112px;
  padding: 30px 20px 30px 20px;
  border-radius: 8px;
}

.hp-6 .rld-single-select .single-select:before {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 8px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: 48%;
  -webkit-transform-origin: 66% 66%;
          transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
}

.hp-6 .rld-single-select .single-select .list {
  margin-top: 10px;
  border-right: 8px;
  z-index: 99999;
}

.hp-6 .rld-single-input input {
  border-radius: 8px;
  margin-left: 0px;
  outline: none;
  text-decoration: none;
}

.hp-6 .rld-single-input input:active {
  text-decoration: none;
}

.hp-6 .rld-banner-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.hp-6 .rld-banner-tab .nav-item a {
  border-radius: 8px;
}

.hp-6 .rld-banner-tab .nav-item a.active:before {
  content: "";
  height: 20px;
  margin-left: 20px;
  position: absolute;
  top: 40px;
  width: 20px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.hp-6 .tab-content > .active {
  margin-top: 30px;
}

.hp-6 .btn, .homepage-3 .btn:active,
.hp-6 .btn.sApp-btn:before,
.hp-6 .bg-overlay::after,
.hp-6 .benifits-item:after {
  background: linear-gradient(-47deg, #FF385C 0%, #FF385C 100%);
}

.hp-6 a.btn.btn-yellow:hover {
  background: #fff !important;
  color: #FF385C;
}

.hp-6 .bg-overlay::after {
  opacity: 0.87;
  z-index: -1;
}

.hp-6 .rld-banner-tab .nav-item a.active,
.hp-6 .rld-banner-tab .nav-item a:hover,
.hp-6 .rld-banner-tab .nav-item a.active:before {
  background: #FF385C;
}

.hp-6 .btn.btn-yellow {
  border: 0px;
  border-radius: 8px;
  font-size: 15px;
  background-color: #FF385C;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
          box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  color: #ffffff;
  height: 50px;
  width: 170px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.hp-6 .welcome-text p {
  margin-bottom: 40px !important;
}

.hp-6 .rld-main-search,
.hp-6 .rld-main-search .rld-single-select .single-select {
  margin-bottom: 0px;
  border-radius: 8px;
  margin-right: 15px;
  border: 1px solid #ebebeb;
  -webkit-box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.2);
          box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.2);
}

.hp-6 a.button.border,
.hp-6 a.button {
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
          box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
}

.lang-wrap {
  float: right;
  margin-right: 0px;
  position: relative;
  top: 0px;
}

.header-user-menu .lang-wrap ul {
  width: 100px;
  min-width: 0px;
  left: -1px !important;
}

.header-user-menu .lang-wrap ul li a {
  font-size: 15px;
  display: block;
  text-transform: capitalize;
}

.header-user-menu .lang-wrap ul li {
  width: 55%;
}

.show-lang span .fa-globe-americas {
  font-size: 17px;
}

.show-lang {
  color: #fff;
  cursor: pointer;
  width: 100px;
  float: right;
  padding: 10px 10px 10px 0;
  position: relative;
  z-index: 106;
  font-size: 12px;
  border: 1px solid transparent;
}

.show-lang span {
  position: relative;
  z-index: 2;
  float: left;
  margin-left: 10px;
}

.lang-tooltip {
  position: absolute;
  top: 40px;
  right: 0;
  width: 100px;
  z-index: 105;
  background: #fff;
  padding: 10px 0 12px;
  border-radius: 4px;
  border: 1px solid #eee;
  border-top: none;
  visibility: hidden;
  opacity: 0;
}

.lang-tooltip li {
  float: left;
  width: 100%;
  position: relative;
  text-align: left;
  color: #fff;
  border-radius: 4px;
  padding: 6px 14px;
}

.lang-tooltip li a {
  color: #566985;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  position: relative;
  float: left;
  width: 100%;
}

.show-lang .fa-caret-down.arrlan {
  position: absolute;
  left: 80px;
  top: 45%;
  line-height: 10px;
  height: 10px;
  margin-top: -5px;
  color: #fff;
}

.header-widget.sign-in {
  height: auto;
}

.header-widget.sign-in a {
  color: #fff;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  margin-top: 30px;
}

.hd-white .header-widget.sign-in a,
.hd-white .show-lang,
.hd-white .show-lang .fa-caret-down.arrlan {
  color: #666;
}

.hd-white a.button.border, a.button {
  width: 150px;
}

.hd-white a.button.border,
.hd-white a.button {
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
          box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 991px) {
  .hd-white a.button.border,
  .hd-whitea.button {
    width: 240px;
  }
}
.right-side.sign {
  float: right;
  margin-top: 20px;
  margin-left: 0px !important;
  display: inline-block;
}

.show-lang span strong {
  padding-left: 10px;
  top: -2px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.divider {
  content: '';
  width: 1px;
  height: 15px;
  opacity: .7;
  background-color: #fff;
}

.hp-6 #navigation.style-2.cloned .show-lang,
.hp-6 #header.cloned.sticky .show-lang,
.hp-6 #navigation.style-2.cloned .show-lang .fa-caret-down.arrlan,
.hp-6 #header.cloned.sticky .show-lang .fa-caret-down.arrlan,
.hp-6 #navigation.style-2.cloned .header-widget.sign-in a,
.hp-6 #header.cloned.sticky .header-widget.sign-in a {
  color: #666;
}

.hp-6 .rld-single-select .single-select {
  width: 180px;
}

.hp-6 .rld-single-input input {
  width: 255px;
  border: 1px solid #ebebeb;
  margin-right: 15px;
}

.hp-6 .dropdown-filter {
  cursor: pointer;
}

.hp-6 .dropdown-filter span {
  width: 180px !important;
  padding: 15px 34px 15px 34px;
  border-radius: 8px;
  line-height: 47px;
  margin: 0 15px 0 15px;
  border: 1px solid #ebebeb;
  width: 150px;
  position: relative;
}

.hp-6 .dropdown-filter span::after {
  content: "\f142";
  font-family: FontAwesome;
  font-size: 20px;
  font-weight: 400;
  margin-left: 15px;
  color: #FF385C;
}

.hp-6 .explore__form-checkbox-list.full-filter.filter-block {
  background: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 30px 30px 20px 30px;
  margin-top: 20px;
}

.hp-6 .explore__form-checkbox-list.full-filter {
  top: 75px;
}

.hp-6 .explore__form-checkbox-list.full-filter.filter-block {
  opacity: 1;
  z-index: 999;
  visibility: visible;
}

.hp-6 .explore__form-checkbox-list.full-filter {
  opacity: 0;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  z-index: -1;
  visibility: hidden;
}

.hp-6 .parallax-searchs .form-group {
  width: 100%;
  margin-bottom: 30px;
}

.hp-6 .parallax-searchs .form-group .nice-select {
  border: 1px solid #ebebeb;
}

.homepage-9.hp-6 .google-maps input,
.homepage-9.hp-6 .google-maps textarea,
.homepage-9.hp-6 .google-maps select {
  margin-bottom: 0px;
}

.homepage-12 .google-maps input,
.homepage-12 .google-maps textarea,
.homepage-12 .google-maps select {
  margin-bottom: 0px;
}

.homepage-12 #map-container.fullwidth-home-map {
  height: 790px;
}

.homepage-12 .popular-places .img-box {
  z-index: 1;
}

.homepage-12 .rld-single-input input {
  width: 255px;
}

.homepage-12 .explore__form-checkbox-list.full-filter.filter-block {
  margin-top: 22px;
}

.homepage-12 .header-user-name,
.homepage-12 .header-user-name:before {
  color: #fff;
}

.homepage-12 .header-image {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), url(../images/bg/bg-21.jpg) no-repeat center top;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../images/bg/bg-21.jpg) no-repeat center top;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 90vh;
  position: relative;
  z-index: 99;
}

.homepage-12 .jc-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.homepage-12 .teh-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10px;
}

.homepage-12 .header-image .left .btn-white {
  background: #FF385C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 35px;
  border-radius: 40px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  margin-top: 10px;
  -webkit-box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.15);
}

.homepage-12 .play-btn {
  position: relative;
  display: inline-block;
  height: 100px;
  width: 100px;
  background-color: #FF385C;
  border-radius: 50%;
  margin-top: 50px;
}

.homepage-12 .btn-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #FF385C;
}

.homepage-12 .play-icon {
  color: #fff;
}

.homepage-12 .header-image .left h1 {
  font-size: 30px;
  color: #fff;
  text-align: center;
}

.homepage-12 .header-image .left p {
  color: #fff;
  text-align: center;
  font-size: 18px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .homepage-12 .rld-single-input input {
    width: 200px;
  }

  .homepage-12 .header-image {
    padding: 0px;
  }

  .homepage-12 .header-image .left h1 {
    font-size: 26px;
  }
}
@media screen and (max-width: 1024px) {
  .hp-6 .dropdown-filter span {
    padding: 15px 15px 15px 15px;
  }

  .hp-6 .rld-single-select.ml-22 {
    margin-left: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .header-user-menu ul {
    left: -80px !important;
  }
}
@media screen and (max-width: 768px) {
  .hp-6 .explore__form-checkbox-list.full-filter.filter-block {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .hp-6 .checkboxes.ch-1,
  .hp-6 .checkboxes.ch-2 {
    margin-top: 30px;
  }

  .hp-6 .dropdown-filter span {
    margin: 0px;
  }

  .homepage-9.hp-6 .dropdown-filter span {
    margin: 0px 15px 0 0;
  }

  .homepage-9.hp-6 .explore__form-checkbox-list.full-filter.filter-block {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 575px) {
  .hp-6 .dropdown-filter {
    width: 100% !important;
    margin-right: 0px;
  }

  .hp-6 .rld-single-select.ml-22 {
    margin-left: 0px;
  }

  .hp-6 .explore__form-checkbox-list.full-filter.filter-block {
    margin-top: 30px;
  }

  .hp-6 .btn.btn-yellow {
    margin-top: 20px;
  }

  .hp-6 .checkboxes.ch-2 {
    margin-top: 0px;
  }

  .hp-6 .sld {
    margin-right: 0px !important;
  }

  .homepage-9.hp-6 .explore__form-checkbox-list.full-filter.filter-block {
    margin-top: 28px !important;
  }

  .homepage-12 .explore__form-checkbox-list.full-filter.filter-block {
    margin-top: 30px;
  }

  .homepage-12 .header-image .left h1 {
    font-size: 20px;
  }
}
/* Range Slider
------------------------------------- */
.hp-6 .range-slider label {
  color: #333;
  font-size: 16px;
  margin-bottom: 25px;
}

.hp-6 .range-slider .ui-slider {
  position: relative;
  text-align: left;
}

.hp-6 .range-slider .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #FF385C;
  cursor: w-resize;
  background: #fff;
  outline: none;
  top: -8px;
  margin-left: -8px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.hp-6 .range-slider .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
  background-position: 0 0;
}

.hp-6 .range-slider .ui-widget-content {
  background-color: #e8e8e8 !important;
}

.hp-6 .range-slider .ui-widget-header {
  background-color: #FF385C;
}

.hp-6 .range-slider .ui-slider.ui-state-disabled .ui-slider-handle,
.hp-6 .range-slider .ui-slider.ui-state-disabled .ui-slider-range {
  -webkit-filter: inherit;
          filter: inherit;
}

.hp-6 .range-slider .ui-slider-horizontal {
  height: 3px;
  border-radius: 10px;
  width: calc(100% - 16px);
  margin-left: 8px;
}

.hp-6 .range-slider .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.hp-6 .range-slider .ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.hp-6 .range-slider .ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.hp-6 input.first-slider-value:focus,
.hp-6 input.second-slider-value:focus,
.hp-6 input.first-slider-value,
.hp-6 input.second-slider-value {
  width: 40%;
  display: inline-block;
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 12px 0 0 0;
  cursor: default;
}

.hp-6 input.first-slider-value {
  float: left;
  margin-left: -8px;
  width: 40%;
}

.hp-6 input.second-slider-value {
  float: right;
  width: 40%;
  text-align: right;
  margin-right: -8px;
}

.hp-6 .range-slider {
  line-height: 37px;
}

/*----------------------------------- */
/* Custom Checkboxes
------------------------------------- */
.hp-6 .checkboxes {
  list-style: none;
  padding: 0;
}

.hp-6 .checkboxes.in-row label {
  margin-top: 9px;
}

.hp-6 .checkboxes.one-in-row label {
  margin-top: 9px;
  width: 100%;
}

.hp-6 .checkboxes li {
  padding: 4px 0;
}

.hp-6 .checkboxes li:last-child {
  margin-bottom: -8px;
}

.hp-6 .checkboxes label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  margin-right: 20px;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
  color: #666;
  font-size: 14.5px;
}

.hp-6 .checkboxes label span {
  color: #909090;
  font-size: 15px;
}

.hp-6 .checkboxes input[type=checkbox] {
  display: none;
}

.hp-6 .checkboxes label:before {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 3px;
  background-color: #fff;
  border: 2px solid #d0d0d0;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.hp-6 .checkboxes input[type=checkbox]:checked + label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 15px;
  background-color: #FF385C;
  border: 2px solid transparent;
  -webkit-transition: border-color 0s;
  transition: border-color 0s;
}

.parallax-search .form-group .btn {
  display: block;
  width: 100%;
  background: #FF385C;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  top: 0;
  height: 50px;
  line-height: 50px;
  padding: 0;
  font-size: 14px;
  border: none;
  border-radius: 50px;
}

.parallax-search .form-group .btn:hover {
  background: #121B22;
  color: #fff;
}

.homepage-9.hp-6 .explore__form-checkbox-list.full-filter.filter-block {
  margin-top: 20px;
}

@media only screen and (max-width: 1024px) {
  .parallax-search .trip-search .form-group {
    margin-top: 15px;
    margin-right: 20px;
    width: 100%;
  }

  .hp-6 .rld-single-input input {
    width: 160px;
  }

  .hp-6 .rld-main-search .rld-single-input,
  .hp-6 .rld-main-search .rld-single-select .single-select {
    width: 160px;
  }

  .hp-6 .parallax-searchs.home15.thome-6 {
    height: 100vh;
  }

  .hp-6 .parallax-searchs.home15.thome-6 .hero-inner {
    padding: 228px 0;
  }

  .homepage-10 .parallax-searchs.home15.thome-10 .hero-inner {
    padding: 228px 0 !important;
  }
}
@media only screen and (max-width: 991px) {
  .parallax-search .trip-search .form-group {
    margin-top: 15px;
    margin-right: 20px;
  }

  .hp-6 .rld-main-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 160px;
    border-radius: 10px;
  }

  .hp-6 .rld-single-input input {
    width: 200px;
  }

  .hp-6 .rld-main-search .rld-single-input,
  .hp-6 .rld-main-search .rld-single-select .single-select {
    width: 200px;
  }

  .parallax-search .trip-search {
    border-radius: 1px;
  }

  .parallax-search .hero-inner {
    margin: 97px 0;
  }

  .parallax-search.home15 .hero-inner {
    margin: 0 !important;
  }

  .int_dark_bg .portfolio .item.landscapes.no-pb {
    margin-bottom: 0px;
  }

  .int_dark_bg .portfolio .item.landscapes.no-pb {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .parallax-search .welcome-text h1 {
    font-size: 30px;
  }

  .parallax-search .hero-inner {
    margin: 69px 0;
  }

  .parallax-search .trip-search .form-group {
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 450px) {
  .parallax-search .welcome-text h1 {
    font-size: 26px !important;
  }

  .parallax-search .hero-inner {
    padding: 40px 0;
  }

  .parallax-search .welcome-text p {
    font-size: 14px;
  }

  .parallax-search .hero-inner {
    margin: 70px 0;
  }

  .parallax-search .trip-search {
    margin-top: 30px;
  }

  .hp-6 .rld-main-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 363px;
    border-radius: 10px;
  }

  .hp-6 .rld-single-input input {
    width: 100%;
  }

  .hp-6 .rld-single-select {
    width: 100%;
  }

  .hp-6 .rld-main-search .rld-single-input,
  .hp-6 .rld-main-search .rld-single-select .single-select {
    width: 100%;
  }

  .hp-6 .parallax-searchs.home15.thome-6 .hero-inner {
    padding: 90px 0;
    margin-bottom: 0px !important;
  }

  .hp-6 .checkboxes.ch-1 {
    margin-top: 30px;
  }

  .homepage-10 .parallax-searchs.home15.thome-10 .hero-inner {
    padding: 90px 0 !important;
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-width: 768px) {
  .small-category-2.pc-mb {
    margin-bottom: 0px !important;
  }
}
/* Owl Nav */
.hero-area.style2 .owl-controls .owl-nav div {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 60px;
  line-height: 60px;
  margin-top: -30px;
  padding: 0;
  font-size: 25px;
  border-radius: 25px;
  background: transparent;
  border: 1px solid #fff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin: 0;
}

.hero-area.style2 .owl-controls .owl-nav div:hover {
  background: #FF7550;
  border-color: transparent;
  color: #fff;
}

.hero-area.style2 .owl-controls .owl-nav div.owl-prev {
  left: 0;
}

.hero-area.style2 .owl-controls .owl-nav div.owl-next {
  right: 0;
}

/* Slider Animation */
.hero-area .owl-item.active .welcome-text p {
  -webkit-animation: fadeInDown 0.8s both 1s;
          animation: fadeInDown 0.8s both 1s;
}

.hero-area .owl-item.active .welcome-text h1 {
  -webkit-animation: fadeInLeft 0.8s both 1.4s;
          animation: fadeInLeft 0.8s both 1.4s;
}

.hero-area .owl-item.active .welcome-text .btn {
  -webkit-animation: fadeInRight 0.8s both 1.6s;
          animation: fadeInRight 0.8s both 1.6s;
}

/*---------------------------------------*/
/*--------- 22. HOME 7 VIDEO ----------*/
/*---------------------------------------*/
/* Search Form */
.search-2 .trip-search.vid {
  background: #fff;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.88);
  border-radius: 50px;
  text-align: left;
  margin-top: 30px;
}

.search-2 .trip-search.vid .form {
  line-height: 0px;
}

.search-2 .form-group {
  width: 190px;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 0px;
}

.search-2 .form-group:last-child {
  margin-right: 0px;
}

.search-2 .form-group .nice-select {
  margin: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.search-2 .form-group .nice-select:hover {
  color: #FF385C;
}

.search-2 .form-group .nice-select::after {
  right: 20px;
  color: #757575;
}

.search-2 .form-group .nice-select i {
  color: #FF385C;
  font-size: 1.2rem;
  margin-top: 5px;
  margin-right: 10px;
}

.search-2 .form-group .list li {
  color: #757575;
}

.search-2 .form-group .list li.selected {
  color: #757575;
  font-weight: 400;
}

.search-2 .form-group .list li:hover {
  color: #fff;
  background: #FF385C;
}

.search-2 .form-group.looking {
  width: 350px;
  margin: 0px;
}

.search-2 .first-select {
  height: 30px;
  border-radius: 5px;
  padding-right: 3px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.main-search-input-item {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 50px;
  position: relative;
}

.nice-select.wide {
  width: 100%;
}

.search-2 .form-group.looking input {
  float: left;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 20px;
}

.search-2 .form-group.location {
  width: 150px;
}

.search-2 .form-group.categories {
  width: 150px;
}

.search-2 .form-group.button {
  width: 135px;
  position: relative;
  height: 50px;
}

.search-2 .form-group .btn {
  display: block;
  width: 100%;
  background: #FF385C;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  top: 0;
  height: 50px;
  line-height: 50px;
  padding: 0;
  font-size: 14px;
  border: none;
  border-radius: 50px;
}

.search-2 .form-group .btn:hover {
  background: #121B22;
  color: #fff;
}

#map-container {
  position: relative;
  top: 0;
  left: 0;
  height: 520px;
  width: 100%;
  z-index: 8;
  margin-bottom: 60px;
}

#map-container.fullwidth-home-map {
  height: 690px;
  margin-bottom: 0;
}

#map-container.fullwidth-home-map .main-search-inner {
  position: relative;
  display: block;
  bottom: 60px;
  top: auto;
  -webkit-transform: none;
          transform: none;
  padding-bottom: 0;
  z-index: 9999;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.dark-overlay .main-search-inner {
  bottom: 580px !important;
}

.video-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  overflow: hidden;
}

.fullwidth-home-map.dark-overlay {
  height: 90vh !important;
  margin-bottom: 0;
}

.fullwidth-home-map.dark-overlay video {
  top: 50%;
  -webkit-transform: translateY(-52%);
          transform: translateY(-52%);
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -10;
  background-size: cover;
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
  position: absolute;
}

.dark-overlay:before {
  background: rgba(0, 0, 0, 0.2) !important;
}

.dark-overlay:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  background: -webkit-gradient(linear, left top, right top, color-stop(20%, rgba(255, 255, 255, 0.99)), color-stop(70%, rgba(255, 255, 255, 0.7)), color-stop(95%, rgba(255, 255, 255, 0)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0.99) 20%, rgba(255, 255, 255, 0.7) 70%, rgba(255, 255, 255, 0) 95%);
}

.dark-overlay h1 {
  font-size: 40px;
  color: #fff;
  line-height: 57px;
  text-transform: capitalize;
  text-align: center;
}

.dark-overlay p {
  color: rgba(255, 255, 255, 0.99);
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 400;
  text-align: center;
}

.homepage-5.th-10 .recently-slider {
  padding-left: calc((100% - 1140px) / 2 + 15px) !important;
  padding-right: 0 !important;
}

.th-10 .info-help.h17 {
  background: url(../images/bg/bg-home-3.png) no-repeat scroll center center;
}

.th-10 .info-help .info-head .info-text .btn-pro {
  background: #6449E7;
}

.th-10 .info-help .info-head .info-text .btn-pro:hover {
  background: #6449E7;
}

@media only screen and (max-width: 1024px) {
  .search-2 .trip-search .form-group {
    margin-top: 15px;
    margin-right: 20px;
    width: 100%;
  }

  .homepage-5.th-10 .recently-slider {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .th-10 .services-img {
    margin-bottom: 15px;
  }

  .th-10 .welcome .popup-youtube,
  .th-10 .welcome .wprt-image-video .iq-waves {
    top: 50%;
  }

  .wprt-image-video.w50 img {
    height: 490px;
  }

  .search-2 .trip-search.vid {
    border-radius: 1px;
  }
}
@media only screen and (max-width: 768px) {
  .th-10 .welcome .popup-youtube,
  .th-10 .welcome .wprt-image-video .iq-waves {
    top: 50%;
  }

  .wprt-image-video.w50 img {
    height: auto;
  }
}
@media only screen and (max-width: 991px) {
  .search-2 .hero-inner {
    margin: 97px 0;
  }

  .hero-inner2 {
    margin-top: 50px;
  }

  .dark-overlay h1 {
    font-size: 30px;
  }

  .dark-overlay .main-search-inner {
    bottom: 770px !important;
  }

  a.button.border, a.button {
    width: 240px;
  }
}
@media only screen and (max-width: 767px) {
  .search-2 .hero-inner {
    margin: 69px 0;
  }

  .search-2 .trip-search .form-group {
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .dark-overlay h1 {
    font-size: 25px !important;
    line-height: 37px;
  }

  .search-2 .welcome-text p {
    font-size: 15px;
  }
}
@media only screen and (max-width: 450px) {
  .search-2 .welcome-text p {
    font-size: 14px;
  }

  .hero-inner2 {
    margin-top: 0px;
  }

  .search-2 .hero-inner {
    margin: 70px 0;
  }

  .search-2 .trip-search {
    margin-top: 30px;
  }

  .dark-overlay h1 {
    font-size: 21px !important;
  }

  .dark-overlay .main-search-inner {
    bottom: 600px !important;
  }
}
@media only screen and (max-width: 360px) {
  .dark-overlay .main-search-inner {
    bottom: 488px !important;
  }

  .search-2 .trip-search.vid {
    margin-top: 10px;
  }
}
/*-----------------------------------*/
/*- 23. INFO SIDEBAR HOME 13 --*/
/*-----------------------------------*/
.int_infosidebar {
  width: 60px;
  background: #282d32;
  position: absolute;
  left: 0;
  height: 950px;
  z-index: 1;
}

.int_infosidebar .siderbar_social > ul > li {
  margin-bottom: 10px;
  list-style: none;
}

.int_infosidebar .siderbar_social > ul > li > a {
  color: #ffffff;
}

.int_infosidebar .siderbar_social {
  margin: 167px 0px 24px 0px;
  text-align: center;
}

.int_infosidebar .siderbar_contact,
.int_infosidebar .siderbar_email {
  text-align: center;
  padding: 30px 0 22px;
  border-top: 1px solid #353c43;
}

.int_infosidebar .siderbar_contact > a,
.int_infosidebar .siderbar_email > a {
  color: #ffffff;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  text-transform: lowercase;
}

.int_infosidebar .siderbar_contact > a > svg,
.int_infosidebar .siderbar_email > a > svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-bottom: 5px;
  width: 16px;
  height: 15px;
  vertical-align: baseline;
}

.int_infosidebar .siderbar_contact > a > svg path,
.int_infosidebar .siderbar_email > a > svg path {
  fill: #c18847;
}

.int_infosidebar .siderbar_social > ul > li > a {
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.int_infosidebar .siderbar_social > ul > li > a:hover {
  color: #c18847;
}

.int_content_wraapper.hd {
  margin-left: 0px;
}

.int_content_wraapper {
  margin-left: 80px;
}

.int_dark_bg .header-user-name,
.int_dark_bg .header-user-name:before {
  color: #fff;
}

.int_dark_bg .blog-section .news-item {
  border: 0px;
}

@media (max-width: 480px) {
  .main_contentblock {
    width: 100%;
  }
}
@media (max-width: 1600px) {
  .int_infosidebar {
    top: -100px;
  }
}
/*-----------------------------------*/
/*- 24. BANNER SLIDER HOME 13 --*/
/*-----------------------------------*/
.int_bannerr_slider .swiper-container {
  width: 100%;
  height: 100%;
}

.int_bannerr_slider .swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body.int_dark_bg {
  background-color: #212529;
}

body.int_index3_bg {
  background-color: #0e0f21;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.int_dark_bg .rec-pro .container-fluid {
  max-width: 1560px;
}

.int_dark_bg .agents-grid {
  padding-left: 0px;
  padding-right: 1.250rem;
}

.int_dark_bg .slick-prev:hover,
.int_dark_bg .slick-next:hover {
  background: #c18847;
}

.int_dark_bg .slick-prev.slick-disabled,
.int_dark_bg .slick-next.slick-disabled {
  background-color: transparent;
}

.int_dark_bg .slick-prev.slick-disabled:before,
.int_dark_bg .slick-next.slick-disabled:before {
  color: #fff;
}

.int_dark_bg .slick-next,
.int_dark_bg .slick-prev {
  background: transparent;
}

.int_dark_bg .slick-next:before,
.int_dark_bg .slick-prev:before {
  color: #fff;
}

.int_dark_bg .slick-next {
  right: -60px;
}

.int_dark_bg .slick-prev {
  left: -80px;
}

.bg-black-1 {
  background: #282d32 !important;
}

.bg-black-2 {
  background: #212529 !important;
}

.int_dark_bg .section-title h3 {
  color: #fff;
}

.int_dark_bg .section-title h2 {
  color: #c18847;
}

.int_dark_bg .section-title:before {
  background: #c18847;
}

.int_dark_bg .slick-dots li.slick-active {
  -webkit-box-shadow: inset 0 0 0 6px #c18847;
          box-shadow: inset 0 0 0 6px #c18847;
}

.int_dark_bg .slick-dots li:after {
  background: #c18847;
}

.int_dark_bg .slick-dots li {
  -webkit-box-shadow: inset 0 0 0 2px #c18847;
          box-shadow: inset 0 0 0 2px #c18847;
}

.int_dark_bg a.button.border,
a.button {
  background: #c18847;
}

.int_dark_bg section.services-home {
  padding: 4.6rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8))), url(../images/bg/bg-counter.jpg) no-repeat center center;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../images/bg/bg-counter.jpg) no-repeat center center;
  background-attachment: fixed;
}

.int_dark_bg .services-home .bg-base {
  background: #c18847;
}

.int_dark_bg .services-home .border-1 {
  border: 1px dashed #c18847 !important;
}

.int_dark_bg .services-home a.text-base:hover {
  color: #c18847;
}

.int_dark_bg .portfolio .project-single {
  border: 0px;
  margin-bottom: 0px;
}

.int_dark_bg .btn.btn-outline-light {
  background: #c18847;
}

.int_dark_bg .bg-all {
  margin-top: 0px;
}

.int_dark_bg .portfolio .project-single {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.int_dark_bg .feature-cities-1 .listing-badges .featured {
  background: #c18847;
}

.int_dark_bg .team-content {
  background: #212529;
  border: 0px;
}

.int_dark_bg .team-info {
  background: #282d32;
  border: 0px;
}

.int_dark_bg .team-wrap:hover .team-content .team-info {
  background: #c18847;
}

.int_dark_bg .team-content h3,
.int_dark_bg .team-content p,
.int_dark_bg .team-content span a {
  color: #fff;
}

.int_dark_bg .team .team-pro .fa {
  background: #c18847;
  border: 0px;
}

.int_dark_bg .plan {
  background: #212529;
  border: 1px dashed #c18847;
}

.int_dark_bg .plan ul {
  background: #212529;
  color: #f9f9f9;
}

.int_dark_bg .plan ul li {
  border-top: 1px dashed #c18847;
}

.int_dark_bg .plan a.btn {
  background: #c18847;
}

.int_dark_bg .plan a.btn.pro2 {
  background: #282d32;
}

.int_dark_bg .plan.featured .plan-price {
  background: #282d32;
}

.int_dark_bg .plan.featured .plan-price:after {
  border-top-color: #282d32;
}

.int_dark_bg .plan-name,
.int_dark_bg .plan.featured .plan-name {
  color: #fff;
}

.int_dark_bg .plan a.btn.pro {
  background: #121B22;
}

.int_dark_bg .plan-price {
  background: #c18847;
}

.int_dark_bg .plan-price:after {
  border-top-color: #c18847;
}

.int_dark_bg .testimonials .owl-carousel .test-1 {
  background: #282d32;
}

.int_dark_bg .testimonials .test-1 h3 {
  color: #fff;
  margin-bottom: 1.5rem;
}

.int_dark_bg .testimonials .test-1 h6,
.int_dark_bg .testimonials .test-1 p::before,
.int_dark_bg .testimonials .test-1 p {
  color: #fff;
}

.int_dark_bg .testimonials .test-1 img {
  margin-bottom: .5rem;
}

.int_dark_bg .starts .fa {
  color: #c18847 !important;
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.int_dark_bg .owl-dot.active {
  background: #c18847 !important;
}

.int_dark_bg .testimonials .test-1 p::after {
  display: none;
}

.int_dark_bg .blog-section .news-item {
  background: #212529;
}

.int_dark_bg .blog-section .news-item h3,
.int_dark_bg .blog-section .news-item-descr p {
  color: #fff;
}

.int_dark_bg .blog-section .news-item .date,
.int_dark_bg .blog-section .news-link {
  color: #c18847;
}

.int_dark_bg .blog-section .action-list .fa {
  color: #fff;
}

.int_dark_bg .blog-section .news-item h3:hover {
  color: #c18847;
}

.int_dark_bg .first-footer .top-footer {
  border-top: 0px;
}

.int_dark_bg .first-footer .netabout p,
.int_dark_bg .first-footer .contactus ul li .fa,
.int_dark_bg .first-footer h3,
.int_dark_bg .navigation ul li a,
.int_dark_bg .twitter-widget.contuct .twitter-area .icon-holder i,
.int_dark_bg .twitter-widget.contuct .twitter-area .text h5,
.int_dark_bg .newsletters p {
  color: #fff;
}

.int_dark_bg .navigation ul li a:hover,
.int_dark_bg .twitter-widget.contuct .twitter-area .text h5 a,
.int_dark_bg .twitter-widget.contuct .twitter-area .text h4 {
  color: #c18847;
}

.int_dark_bg .first-footer .navigation h3::after,
.int_dark_bg .first-footer .widget h3::after,
.int_dark_bg .first-footer .newsletters h3::after {
  background: #c18847;
}

.int_dark_bg .first-footer .contactus .info p {
  color: #efe9e9;
}

.int_dark_bg .first-footer .bloq-email .email input[type="email"],
.int_dark_bg .first-footer .bloq-email .email input[type="submit"] {
  border: 1px solid #c18847;
  background: #282d32;
  color: #fff;
}

.int_dark_bg .first-footer .bloq-email .email input::-webkit-input-placeholder {
  color: #fff;
}

.int_dark_bg .first-footer .bloq-email .email input:-ms-input-placeholder {
  color: #fff;
}

.int_dark_bg .first-footer .bloq-email .email input::-ms-input-placeholder {
  color: #fff;
}

.int_dark_bg .first-footer .bloq-email .email input::placeholder {
  color: #fff;
}

.int_dark_bg .second-footer {
  background: #282d32;
}

.int_dark_bg .go-up {
  background: #c18847;
}

.int_dark_bg .portfolio .item {
  padding-bottom: 30px;
}

.int_dark_bg .first-footer .bloq-email .email input[type="submit"] {
  margin-top: 0px;
}

.int_dark_bg .container-fluid.sd-f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.int_dark_bg .blog-section .news-item-sm .news-img-link {
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
}

.int_banner_slider .banner_box_wrapper {
  position: relative;
}

.swiper_imgbox .swipper_img > h4 {
  font-size: 12px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #c18847;
  margin: 0;
  position: relative;
}

.swiper_imgbox .swipper_img > h4 > span {
  font-size: 90px;
  text-transform: uppercase;
  font-weight: 400;
  color: #252a2d;
  letter-spacing: 12px;
  position: absolute;
  left: 10px;
  right: 0;
  top: -50px;
  z-index: -1;
}

.swiper_imgbox .swipper_img > h2 {
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.swiper_imgbox .swipper_img > h3 {
  font-size: 26px;
  color: #c18847;
  margin-bottom: 20px;
  font-weight: 300;
}

.swiper_imgbox .swipper_img > h3 > .banner_span1 {
  color: #c18847;
}

.swiper_imgbox .swipper_img > p {
  color: #fff;
  margin-bottom: 15px;
}

.swiper_imgbox .homes-list {
  padding-left: 0px;
  padding-bottom: 2.5rem;
  margin: 0;
}

.swiper_imgbox .homes-list li {
  width: 45%;
  float: left;
  font-size: 16px;
  line-height: 40px;
  list-style: none;
  color: #fff;
}

.swiper_imgbox .homes-list li i {
  font-size: 16px;
  margin-right: 8px;
  color: #fff;
}

a.int_btn,
.int_infosidebar .siderbar_contact > a,
.int_infosidebar .siderbar_email > a {
  text-decoration: none;
}

.banner_box_wrapper .swiper_imgbox {
  margin: 140px 0 0;
  padding: 0 15px 0 25px;
}

.banner_box_wrapper .banner_navi {
  position: absolute;
  left: 95px;
  bottom: -8px;
}

.banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-container-rtl .swiper-button-prev,
.banner_box_wrapper .swiper-button-prev,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next {
  background-image: none;
  text-transform: uppercase;
  width: auto;
  height: auto;
}

.banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-button-prev {
  color: #fff;
}

.banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-button-prev {
  bottom: 50px;
  top: auto;
}

.banner_box_wrapper .swiper-button-prev,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next {
  left: 0px;
}

.banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-container-rtl .swiper-button-prev {
  right: auto;
  left: 97px;
}

.banner_box_wrapper .swiper-button-prev:after,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next:after {
  content: "";
  position: absolute;
  width: 45px;
  height: 1px;
  background: #c18847;
  top: 0px;
  bottom: 0;
  left: -55px;
  margin: auto;
}

.banner_box_wrapper .swiper-button-prev:before,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -55px;
  margin: auto;
  background: #c18847;
  width: 45px;
  height: 1px;
}

.banner_box_wrapper .swiper-button-next:before,
.banner_box_wrapper .swiper-container-rtl .swiper-button-prev:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -55px;
  margin: auto;
  background: #c18847;
  width: 45px;
  height: 1px;
}

.int_main_wraapper {
  overflow: hidden;
  position: relative;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img h4 {
  -webkit-animation: fadeInLeft 1s linear;
          animation: fadeInLeft 1s linear;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img h2 {
  -webkit-animation: fadeInRight 1s linear;
          animation: fadeInRight 1s linear;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img h3 {
  -webkit-animation: fadeInLeft 1s linear;
          animation: fadeInLeft 1s linear;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img p {
  -webkit-animation: fadeInRight 1s linear;
          animation: fadeInRight 1s linear;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img .int_btn {
  -webkit-animation: fadeInLeft 1s ease;
          animation: fadeInLeft 1s ease;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img h4 {
  -webkit-animation: fadeInDown 1s linear;
          animation: fadeInDown 1s linear;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img h2 {
  -webkit-animation: fadeInUp 1s linear;
          animation: fadeInUp 1s linear;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img h3 {
  -webkit-animation: fadeInUp 1s linear;
          animation: fadeInUp 1s linear;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img p {
  -webkit-animation: fadeInDown 1s linear;
          animation: fadeInDown 1s linear;
}

.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img .int_btn {
  -webkit-animation: fadeInUp 1s ease;
          animation: fadeInUp 1s ease;
}

.swiper_imgbox .swipper_img > h1 {
  font-size: 90px;
  text-transform: uppercase;
  color: #252a2d;
  font-weight: 600;
  margin-top: 85px;
  margin-left: -9px;
}

.swiper_imgbox .btn_caret {
  right: -29px;
}

@media (max-width: 1600px) {
  .swiper_imgbox .swipper_img > h4 > span {
    font-size: 100px;
  }
}
@media (max-width: 1600px) {
  .swiper_imgbox .swipper_img > h2 {
    font-size: 60px;
  }
}
@media (max-width: 1600px) {
  .swiper_imgbox .swipper_img > h3 {
    font-size: 58px;
  }
}
@media (max-width: 1600px) {
  .swiper_imgbox .swipper_img > h1 {
    font-size: 100px;
    margin-top: 30px;
    margin-left: -5px;
  }
}
@media (max-width: 1600px) {
  .banner_box_wrapper .swiper_imgbox {
    margin: 40px 0 0;
    padding: 0 15px 0 0;
  }
}
@media (max-width: 1024px) {
  .swiper_imgbox .swipper_img > h4 {
    font-size: 12px !important;
    line-height: 27px;
  }

  .swiper_imgbox .swipper_img > h2,
  .swiper_imgbox .swipper_img > h3 {
    font-size: 30px !important;
    line-height: 27px;
  }

  .swiper_imgbox .swipper_img > p {
    font-size: 14px !important;
  }

  .swiper_imgbox .swipper_img > h1,
  .swiper_imgbox .swipper_img > h4 > span,
  .int_infosidebar {
    display: none;
  }

  .main_contentblock {
    padding: 20px 0px 100px;
  }

  .int_content_wraapper.int_content_left {
    margin-left: 20px;
  }

  .banner_box_wrapper .banner_navi {
    left: 70px;
    bottom: -8px;
  }
}
@media (max-width: 767px) {
  .main_contentblock {
    text-align: center;
    padding: 12px 0px 92px;
    width: 80%;
    margin: auto;
  }
}
@media (max-width: 480px) {
  .main_contentblock {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .swiper_imgbox .btn_caret {
    display: none;
  }

  .banner_box_wrapper .banner_navi {
    left: 48%;
    -webkit-transform: translate(-50px, 20px);
            transform: translate(-50px, 20px);
    bottom: 12px;
  }

  .main_imgblock {
    display: none;
  }

  .swiper_imgbox .homes-list .fa-bed,
  .swiper_imgbox .homes-list .fa-bath,
  .swiper_imgbox .homes-list,
  .int_white_bg .swiper_imgbox .homes-list .fa-bed,
  .int_white_bg .swiper_imgbox .homes-list .fa-bath,
  .int_white_bg .swiper_imgbox .homes-list {
    margin-left: 1rem;
  }

  .swiper_imgbox .homes-list li,
  .int_white_bg .swiper_imgbox .homes-list li {
    font-size: 15px !important;
  }
}
@media (max-width: 575px) {
  .int_content_wraapper.int_content_left {
    margin-left: 0;
  }

  .int_content_wraapper {
    margin-left: 0px;
  }

  .int_dark_bg .container-fluid.sd-f {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    justify-items: center;
  }

  .int_dark_bg .agents-grid {
    padding-left: 0px;
    padding-right: 1px;
    width: 100%;
  }
}
/* button css */
.int_btn,
.int_btn:focus {
  min-width: 140px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #ffffff;
  background: #c18847;
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.int_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333333;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: 0% 10% 0;
          transform-origin: 0% 10% 0;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

.int_btn:hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
          transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.int_btn.left_zero:before {
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}

.int_btn_two:focus {
  background: #ff1d4d;
}

.int_btn_two {
  background: #ff1d4d;
  width: 170px;
  letter-spacing: 1px;
}

.btn_caret {
  height: 50px;
  line-height: 53px;
  background: #c18847;
  display: inline-block;
  padding: 0px 12px;
  position: absolute;
  top: 0;
  right: -29px;
}

.btn_caret_two {
  background: #f41a48;
}

.int_btn:hover {
  color: #fff;
  background: #555555;
}

/* button css */
/*------------------------------------*/
/*- 25. FEATURED PROPERTIES HOME 13 --*/
/*------------------------------------*/
.fs-listings,
.listings-container {
  position: relative;
  z-index: 9;
}

.listing-item {
  background-color: #fff;
  display: block;
  width: 100%;
  border-radius: 3px;
}

.owl-carousel .listing-item {
  margin-bottom: 0;
}

.listing-img-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
}

.listing-img-container img {
  width: 100%;
  border-radius: 3px 3px 0 0;
}

.listing-img-container:after,
.listing-img-container:before {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  content: "";
  z-index: 9;
}

.listing-img-container:before {
  opacity: 0.9;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(65%, transparent), to(#2f2f2f));
  background: linear-gradient(to bottom, transparent 65%, #2f2f2f);
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}

.compact .listing-img-container:before {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, transparent), to(#2f2f2f));
  background: linear-gradient(to bottom, transparent 50%, #2f2f2f);
}

.listing-img-container:after {
  opacity: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), to(#66676b));
  background: linear-gradient(to bottom, transparent 60%, #66676b);
  -webkit-transition: opacity .4s;
  transition: opacity .4s;
}

.listing-img-container:hover:before {
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.listing-img-container:hover:after {
  opacity: 0.9;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

/* Content on image */
.listing-img-content {
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 25px 30px;
}

.compact .listing-img-content {
  padding: 0;
}

.compact .listing-img-content .listing-compact-title {
  padding: 23px 26px;
  display: block;
}

.compact .listing-img-content .listing-compact-title i {
  display: block;
  font-style: normal;
  font-size: 17px;
  padding: 0;
  margin: 2px 0 0 0;
}

.listing-hidden-content {
  position: relative;
  width: 100%;
  background-color: #c18847;
  color: #fff;
  height: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  padding: 0 0 0 25px;
  display: block;
  overflow: hidden;
}

.listing-item.compact .listing-hidden-content li {
  opacity: 1;
}

.compact .listing-img-container:before {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.listing-item.compact:hover .listing-hidden-content {
  height: 70px;
}

.compact .listing-img-container:after {
  display: none;
}

.compact .listing-img-container:before {
  opacity: 0.7 !important;
}

.listing-item.compact li {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  line-height: 20px;
  padding: 15px 20px 0 0;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}

.listing-item.compact li span {
  display: block;
  font-size: 14px;
  color: #fff;
  margin: 0;
  padding: 0;
}

.compact .listing-img-container:after {
  opacity: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, transparent), color-stop(95%, #2f2f2f));
  background: linear-gradient(to bottom, transparent 35%, #2f2f2f 95%);
  -webkit-transition: opacity .6s;
  transition: opacity .6s;
}

.listing-img-container:after {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), to(#274abb));
  background: linear-gradient(to bottom, transparent 60%, #274abb);
}

.listing-img-container:after,
.listing-img-container:before {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  content: "";
  z-index: 9;
}

.compact .listing-img-container:after {
  opacity: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, transparent), color-stop(95%, #2f2f2f));
  background: linear-gradient(to bottom, transparent 35%, #2f2f2f 95%);
  -webkit-transition: opacity .6s;
  transition: opacity .6s;
}

.listing-img-content span {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.listing-img-content .listing-price i {
  display: block;
  font-style: normal;
  font-size: 16px;
  padding: 0;
  margin: -4px 0 0 0;
}

/* Badges */
.listing-item.compact .listing-badges {
  position: absolute;
  top: 0;
  z-index: 8;
  right: 0;
  width: 100%;
  display: block;
  font-size: 13px;
  padding: 0px;
  overflow: hidden;
  height: 100px;
}

.listing-item.compact .listing-badges span {
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  float: right;
  background-color: #FF385C;
  border-radius: 3px;
  top: 15px;
  right: 15px;
  position: absolute;
}

.listing-item.compact .listing-badges span.rent {
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  float: right;
  background-color: #6449E7;
  border-radius: 3px;
  top: 15px;
  right: 15px;
  position: absolute;
}

.listing-item.compact .listing-badges .featured {
  background-color: #18ba60;
  float: left;
  left: 15px;
  top: 17px;
  position: relative;
  text-align: center;
  width: 90px;
  font-size: 14.5px;
  margin: 0;
}

/*-----------------------------------*/
/*- 26. BANNER SLIDER HOME 14 --*/
/*-----------------------------------*/
body.int_white_bg {
  background-color: #fff;
}

.bg-white-1 {
  /*background: url(../images/bg/bg-pattern-dot.png) 0 0 repeat !important;*/
}

.bg-white-2 {
  background: #f5f7fb !important;
}

.int_white_bg .section-title h3 {
  color: #333;
}

.int_white_bg .section-title h2 {
  color: #c18847;
}

.int_white_bg .section-title:before {
  background: #c18847;
}

.int_white_bg .slick-dots li.slick-active {
  -webkit-box-shadow: inset 0 0 0 6px #c18847;
          box-shadow: inset 0 0 0 6px #c18847;
}

.int_white_bg .slick-dots li:after {
  background: #c18847;
}

.int_white_bg .slick-dots li {
  -webkit-box-shadow: inset 0 0 0 2px #c18847;
          box-shadow: inset 0 0 0 2px #c18847;
}

.int_white_bg .services-home .bg-base {
  background: #c18847;
}

.int_white_bg .services-home .border-1 {
  border: 1px dashed #c18847 !important;
  background: #fff;
}

.int_white_bg .rec-pro .container-fluid {
  max-width: 1560px;
}

.int_white_bg .agents-grid {
  padding-left: 0px;
  padding-right: 1.250rem;
}

.int_white_bg .slick-prev:hover,
.int_white_bg .slick-next:hover {
  background: #c18847;
}

.int_white_bg .slick-next {
  right: -60px;
}

.int_white_bg .slick-prev {
  left: -80px;
}

.int_white_bg a.button.border,
a.button {
  background: #c18847;
}

.int_white_bg .services-home .text-bold-700 {
  color: #333;
}

.int_white_bg .services-home .agent-section p {
  color: #666;
}

.int_white_bg .services-home a.text-base {
  color: #333;
}

.int_white_bg .services-home a.text-base:hover {
  color: #c18847;
}

.int_white_bg .portfolio .project-single {
  border: 0px;
  margin-bottom: 0px;
}

.int_white_bg .btn.btn-outline-light {
  background: #c18847;
}

.int_white_bg .bg-all {
  margin-top: 0px;
}

.int_white_bg .portfolio .project-single {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.int_white_bg .feature-cities-1 .listing-badges .featured {
  background: #c18847;
}

.int_white_bg .team-content,
.int_white_bg .team-info {
  background: #fff;
  border: 0px;
}

.int_white_bg .team-wrap:hover .team-content .team-info {
  background: #fff;
}

.int_white_bg .team-content h3,
.int_white_bg .team-content p,
.int_white_bg .team-content span a {
  color: #333;
}

.int_white_bg .team .team-pro .fa {
  background: #c18847;
  border: 0px;
}

.int_white_bg .plan-price,
.int_white_bg .plan a.btn {
  background: #c18847;
}

.int_white_bg .plan a.btn.pro {
  background: #121B22;
}

.int_white_bg .plan-price:after {
  border-top-color: #c18847;
}

.int_white_bg .testimonials .owl-carousel .test-1 {
  background: #fff;
  border: 1px solid #e7e7e7;
}

.int_white_bg .testimonials .test-1 h3 {
  color: #333;
  margin-bottom: 1.5rem;
}

.int_white_bg .testimonials .test-1 h6,
.int_white_bg .testimonials .test-1 p::before {
  color: #333;
}

.int_white_bg .testimonials .test-1 p {
  color: #666;
}

.int_white_bg .testimonials .test-1 img {
  margin-bottom: .5rem;
}

.int_white_bg .starts .fa {
  color: #febe42 !important;
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.int_white_bg .owl-dot.active {
  background: #c18847 !important;
}

.int_white_bg .testimonials .test-1 p::after {
  display: none;
}

.int_white_bg .blog-section .news-item {
  background: #fff;
}

.int_white_bg .blog-section .news-item h3 {
  color: #333;
}

.int_white_bg .blog-section .news-item-descr p {
  color: #666;
}

.int_white_bg .blog-section .news-item .date,
.int_white_bg .blog-section .news-link {
  color: #c18847;
}

.int_white_bg .blog-section .action-list .fa {
  color: #c18847;
}

.int_white_bg .blog-section .news-item h3:hover {
  color: #c18847;
}

.int_white_bg .first-footer .top-footer {
  border-top: 0px;
}

.int_white_bg .first-footer .contactus ul li .fa,
.int_white_bg .first-footer h3,
.int_white_bg .navigation ul li a,
.int_white_bg .twitter-widget.contuct .twitter-area .icon-holder i,
.int_white_bg .twitter-widget.contuct .twitter-area .text h5 {
  color: #333;
}

.int_white_bg .newsletters p,
.int_white_bg .first-footer .netabout p {
  color: #666;
}

.int_white_bg .navigation ul li a:hover,
.int_white_bg .twitter-widget.contuct .twitter-area .text h5 a,
.int_white_bg .twitter-widget.contuct .twitter-area .text h4 {
  color: #c18847;
}

.int_white_bg .first-footer .navigation h3::after,
.int_white_bg .first-footer .widget h3::after,
.int_white_bg .first-footer .newsletters h3::after {
  background: #c18847;
}

.int_white_bg .first-footer .contactus .info p {
  color: #666;
}

.int_white_bg .first-footer .bloq-email .email input[type="email"] {
  border: 1px solid #c18847;
  background: #282d32;
  color: #fff;
}

.int_white_bg .first-footer .bloq-email .email input[type="submit"] {
  border: 1px solid #c18847;
  background: #c18847;
  color: #fff;
}

.int_white_bg .first-footer .bloq-email .email input::-webkit-input-placeholder {
  color: #fff;
}

.int_white_bg .first-footer .bloq-email .email input:-ms-input-placeholder {
  color: #fff;
}

.int_white_bg .first-footer .bloq-email .email input::-ms-input-placeholder {
  color: #fff;
}

.int_white_bg .first-footer .bloq-email .email input::placeholder {
  color: #fff;
}

.int_white_bg .second-footer p {
  color: #666;
}

.int_white_bg .second-footer p .fa-heart:before {
  color: #c18847;
}

.int_white_bg .go-up {
  background: #c18847;
}

.int_white_bg .portfolio .item {
  padding-bottom: 30px;
}

.int_white_bg .first-footer .bloq-email .email input[type="submit"] {
  margin-top: 0px;
}

.int_white_bg .container-fluid.sd-f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.int_white_bg .swiper_imgbox .swipper_img > h4 {
  font-size: 12px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #c18847;
  margin: 0;
  position: relative;
}

.int_white_bg .swiper_imgbox .swipper_img > h4 > span {
  font-size: 90px;
  text-transform: uppercase;
  font-weight: 400;
  color: #f1f1f1;
  letter-spacing: 12px;
  position: absolute;
  left: 10px;
  right: 0;
  top: -50px;
  z-index: -1;
}

.int_white_bg .swiper_imgbox .swipper_img > h2 {
  color: #333;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.int_white_bg .swiper_imgbox .swipper_img > h3 {
  font-size: 26px;
  color: #c18847;
  margin-bottom: 20px;
  font-weight: 300;
}

.int_white_bg .swiper_imgbox .homes-list li,
.int_white_bg .swiper_imgbox .homes-list li i {
  color: #333;
  font-size: 16px;
}

.int_white_bg .swiper_imgbox .swipper_img > h3 > .banner_span1 {
  color: #c18847;
}

.int_white_bg .swiper_imgbox .swipper_img > p {
  color: #333;
  margin-bottom: 15px;
}

.int_white_bg .banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-button-prev {
  color: #fff;
}

.int_white_bg .banner_box_wrapper .swiper-button-prev:after,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next:after {
  content: "";
  position: absolute;
  width: 45px;
  height: 1px;
  background: #c18847;
  top: 0px;
  bottom: 0;
  left: -55px;
  margin: auto;
}

.int_white_bg .banner_box_wrapper .swiper-button-prev:before,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -55px;
  margin: auto;
  background: #c18847;
  width: 45px;
  height: 1px;
}

.int_white_bg .banner_box_wrapper .swiper-button-next:before,
.banner_box_wrapper .swiper-container-rtl .swiper-button-prev:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -55px;
  margin: auto;
  background: #c18847;
  width: 45px;
  height: 1px;
}

.int_white_bg .swiper_imgbox .swipper_img > h1 {
  font-size: 90px;
  text-transform: uppercase;
  color: #f1f1f1;
  font-weight: 600;
  margin-top: 85px;
  margin-left: -9px;
}

.int_white_bg .banner_box_wrapper .swiper-button-prev,
.int_white_bg .banner_box_wrapper .swiper-button-next {
  color: #111;
}

.int_white_bg .int_infosidebar {
  background: #fff;
}

.int_white_bg .int_infosidebar .siderbar_social > ul > li > a,
.int_white_bg .int_infosidebar .siderbar_contact > a,
.int_white_bg .int_infosidebar .siderbar_email > a {
  color: #333;
}

.int_white_bg .blog-section .news-item-sm .news-img-link {
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
}

@media (max-width: 575px) {
  .int_white_bg .container-fluid.sd-f {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    justify-items: center;
  }

  .int_white_bg .agents-grid {
    padding-left: 0px;
    padding-right: 1px;
    width: 100%;
  }
}
/*---------------------------------------*/
/*--- 27. TYPED IMAGE HOME 15 ---*/
/*---------------------------------------*/
.parallax-searchs.home15 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url("../images/bg/bg-15.jpg") no-repeat center top;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/bg/bg-15.jpg") no-repeat center top;
  background-size: cover;
  background-attachment: fixed !important;
  width: 100%;
  height: 90vh;
}

.parallax-searchs.home15.thome-6 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url(../images/bg/thome-6.png) no-repeat center top;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../images/bg/thome-6.png) no-repeat center top;
  background-size: cover;
  background-attachment: fixed !important;
  width: 100%;
  height: 95vh;
  position: relative;
  z-index: 99;
}

.parallax-searchs.thome-1 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), url(../images/bg/bg-h-1.png) no-repeat center top !important;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../images/bg/bg-h-1.png) no-repeat center top !important;
  background-size: cover;
  background-attachment: fixed !important;
  width: 100%;
  height: 95vh;
}

.parallax-searchs.thome-10 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), url(../images/bg/bg-h-10.png) no-repeat center top !important;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../images/bg/bg-h-10.png) no-repeat center top !important;
  background-size: cover;
  background-attachment: fixed !important;
  width: 100%;
  height: 100vh !important;
}

.parallax-searchs.home15.thome-10 .hero-inner {
  text-align: center;
  padding: 315px 0 !important;
}

.parallax-searchs.home15.thome-6 .hero-inner {
  text-align: center;
  padding: 290px 0;
}

.th-15 .rec-pro .container-fluid {
  max-width: 1560px;
}

.home-11 .rec-pro .container-fluid {
  max-width: 1560px;
}

.home-11 .house-details.thehp-1 i {
  color: #fff;
  font-weight: 300;
}

.home-11 .header-user-name,
.home-11 .header-user-name:before {
  color: #666 !important;
}

.home-11 .request h3::after {
  background: #fff;
}

.home-11 .request .btn {
  background: #274abb;
}

.home-11 .request .btn:hover {
  background: #274abb;
}

.home-11 .call-info ul li .fa {
  color: #fff;
}

.home-11 .sec-title {
  text-align: center;
  padding-bottom: 2rem;
}

.home-11 .sec-title h2 {
  color: #111;
  font-weight: 600;
  text-transform: capitalize;
}

.home-11 .sec-title h2 span {
  position: relative;
  color: #444;
  font-weight: 500;
}

.home-11 .sec-title p {
  margin-top: 13px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  max-width: 550px;
}

.home-11 .first-footer .bloq-email .email input[type="submit"],
.homepage-6 .first-footer .bloq-email .email input[type="submit"],
.th-15 .first-footer .bloq-email .email input[type="submit"] {
  margin-top: 0px;
}

.homepage-1 .header-user-name,
.homepage-1 .header-user-name:before {
  color: #fff;
}

.homepage-1 .agents-grid .project-single {
  border: none !important;
}

.homepage-6 .rec-pro2.hmp .container-fluid {
  max-width: 1320px;
}

.homepage-6 .recent-16.hmp {
  display: block;
  position: relative;
  overflow: hidden;
  height: 310px;
}

.homepage-6 .portfolio .homes-content .homes-list li,
.hmp7 .portfolio .homes-content .homes-list li {
  width: 33.3%;
}

.hmp7 .portfolio .homes-content .homes-list li i {
  color: #696969;
  font-size: 18px;
}

.hmp7 .portfolio .footer i {
  color: #696969;
  font-size: 16px;
}

.hmp7 .rp2 .slick-dots li.slick-active,
.hmp7 .rp2 .slick-dots li {
  -webkit-box-shadow: inset 0 0 0 3px rgba(74, 74, 76, 0.4) !important;
          box-shadow: inset 0 0 0 3px rgba(74, 74, 76, 0.4) !important;
}

.hmp7 .rp2 .slick-dots li:after {
  background: #696565;
}

.homepage-6 .small-category-2 {
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.homepage-6 .how-it-works .serv-flex {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.homepage-6 .portfolio .homes-content {
  border-bottom: 3px solid #eee;
}

.homepage-6 .portfolio .project-head img {
  opacity: 1 !important;
}

.homepage-6 .recent-title {
  font-size: 20px;
  font-weight: 500;
}

.home-11 .container-fluid.sd-f,
.homepage-6 .container-fluid.sd-f,
.th-15 .container-fluid.sd-f,
.homepage-1 .container-fluid.sd-f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.th-15 .recent-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.th-15 .price-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.th-15 .recent-details {
  padding-bottom: 0px;
}

.th-15 .recent-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.th-15 .house-details {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.th-15 .house-details.thehp-1 i {
  color: #18ba60;
}

.th-15 .portfolio .homes-content .homes-list li {
  width: 33.3%;
}

.th-15 .sec-title {
  text-align: center;
  padding-bottom: 2rem;
}

.th-15 .sec-title h2 {
  color: #111;
  font-weight: 600;
  text-transform: capitalize;
}

.th-15 .sec-title h2 span {
  position: relative;
  color: #444;
  font-weight: 500;
}

.th-15 .sec-title p {
  margin-top: 13px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  max-width: 550px;
}

.th-15 .blog-section .news-item-sm .news-img-link {
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
}

.th-15 div.partners-1,
.th-15 div.partners {
  padding: 4.6rem 0;
}

.th-15 .top-location .tp-caption p {
  color: #fff;
  margin-bottom: 0px;
}

.th-15 .top-location .tp-caption {
  bottom: 0px;
}

.th-15 .top-location .tp-caption p {
  opacity: 10;
}

@media only screen and (max-width: 1024px) {
  .th-15 .price-details {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 20px;
  }

  .th-15 .recent-title {
    margin-bottom: 20px;
  }

  .th-15 .featured-boxes-area {
    margin-bottom: 4.6rem;
  }

  .th-15 .team-block {
    padding-bottom: 2.5rem;
  }

  .th-15 .team-block.pb-none {
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 575px) {
  .th-15 .team-block.pb-none {
    padding-bottom: 2.5rem;
  }

  .home-11 .container-fluid.sd-f,
  .homepage-6 .container-fluid.sd-f,
  .th-15 .container-fluid.sd-f,
  .homepage-1 .container-fluid.sd-f {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media only screen and (max-width: 450px) {
  .parallax-searchs.home15.thome-6 {
    height: 95vh;
  }
}
@media only screen and (max-width: 991px) {
  .parallax-searchs.home15.thome-6 .hero-inner {
    padding: 100px 0;
  }
}
.parallax-searchs.home15 .welcome-text p {
  color: rgba(255, 255, 255, 0.89);
  font-size: 18px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  text-transform: capitalize;
  line-height: 37px;
  font-weight: 400;
}

.parallax-searchs.home15 .hero-inner {
  text-align: center;
  padding: 320px 0;
}

.parallax-searchs .welcome-text p {
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
}

.parallax-searchs .welcome-text h1 {
  font-size: 36px;
  color: #fff;
  text-transform: capitalize;
}

/* Search Form */
.parallax-searchs .trip-search {
  background: #fff;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 50px;
  text-align: left;
  margin-top: 30px;
}

.parallax-searchs .trip-search .form {
  line-height: 0px;
}

.parallax-searchs .form-group {
  width: 190px;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 0px;
}

.parallax-searchs .form-group:last-child {
  margin-right: 0px;
}

.parallax-searchs .form-group .nice-select {
  margin: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.parallax-searchs .form-group .nice-select:hover {
  color: #18ba60;
}

.parallax-searchs .form-group .nice-select::after {
  right: 20px;
  color: #757575;
}

.parallax-searchs .form-group .nice-select i {
  color: #18ba60;
  font-size: 1.2rem;
  margin-top: 5px;
  margin-right: 10px;
}

.parallax-searchs .form-group .list li {
  color: #757575;
}

.parallax-searchs .form-group .list li.selected {
  color: #757575;
  font-weight: 400;
}

.parallax-searchs .form-group .list li:hover {
  color: #fff;
  background: #18ba60;
}

.parallax-searchs .form-group.location {
  width: 150px;
}

.parallax-searchs .form-group.button {
  width: 145px;
  position: relative;
  height: 50px;
}

.parallax-searchs .form-group .btn {
  display: block;
  width: 100%;
  background: #18ba60;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  top: 0;
  height: 50px;
  line-height: 50px;
  padding: 0;
  font-size: 14px;
  border: none;
  border-radius: 50px;
}

.parallax-searchs .form-group .btn:hover {
  background: #121B22;
  color: #fff;
}

.th-15 .header-user-name,
.th-15 .header-user-name:before {
  color: #fff;
}

.th-15 #navigation.style-2.cloned .header-user-name,
.th-15 #header.cloned.sticky .header-user-name,
.th-15 #navigation.style-2.cloned .header-user-name:before,
.th-15 #header.cloned.sticky .header-user-name:before {
  color: #666;
}

@media only screen and (max-width: 1024px) {
  .parallax-searchs .trip-search .form-group {
    margin-top: 15px;
    margin-right: 20px;
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .parallax-searchs .trip-search .form-group {
    margin-top: 15px;
    margin-right: 20px;
  }

  .parallax-searchs.home15 {
    height: 100vh;
  }

  .parallax-searchs .trip-search {
    border-radius: 1px;
  }

  .parallax-searchs .hero-inner {
    margin: 97px 0 !important;
  }

  .parallax-searchs.home15 .hero-inner {
    padding: 30px 0;
  }

  .parallax-searchs .welcome-text h1 {
    font-size: 26px;
  }

  .parallax-searchs.home15 .welcome-text p {
    font-size: 15px;
  }

  .featured-boxes-area {
    top: 50px;
  }

  .int_dark_bg .portfolio .item.landscapes.no-pb {
    margin-bottom: 0px;
  }

  .int_dark_bg .portfolio .item.landscapes.no-pb {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .parallax-searchs.home15 .hero-inner {
    padding: 130px 0;
  }
}
@media only screen and (max-width: 767px) {
  .parallax-searchs .welcome-text h1 {
    font-size: 30px;
  }

  .parallax-searchs .hero-inner {
    margin: 69px 0;
  }

  .parallax-searchs .trip-search .form-group {
    margin-top: 10px;
    width: 100%;
  }

  .header-top.head-tr {
    display: none;
  }
}
@media only screen and (max-width: 450px) {
  .parallax-searchs .welcome-text h1 {
    font-size: 20px !important;
    line-height: 34px;
    margin-bottom: 40px;
  }

  .parallax-searchs .hero-inner {
    padding-top: 55px !important;
  }

  .parallax-searchs.home15 .welcome-text p {
    display: none;
  }

  .parallax-searchs.home15 {
    height: 100vh;
  }

  .featured-boxes-area {
    top: 100px;
    padding-bottom: 6rem;
  }

  .parallax-searchs .welcome-text p {
    font-size: 14px;
  }

  .parallax-searchs .hero-inner {
    margin: 70px 0;
  }

  .parallax-searchs .trip-search {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 360px) {
  .parallax-searchs .welcome-text h1,
  .parallax-searchs.home15 .welcome-text p {
    display: none;
  }

  .parallax-searchs .hero-inner {
    padding-top: 45px !important;
  }

  .parallax-searchs .trip-search {
    margin-top: 0px;
  }
}
/*--------------------------------------------*/
/*-- 28. SECTION INFO HOME 15 --*/
/*--------------------------------------------*/
.featured-boxes-area {
  position: relative;
  z-index: 1;
  margin-top: -100px;
}

.featured-boxes-inner {
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.featured-boxes-inner .col-lg-3 {
  border-right: 1px solid #eee;
}

.featured-boxes-inner .col-lg-3:last-child {
  border-right: none;
}

.single-featured-box {
  text-align: center;
  position: relative;
  padding: 115px 25px 40px;
  overflow: hidden;
}

.single-featured-box .icon {
  -webkit-transition: .4s;
  transition: .4s;
  color: #ee0979;
  position: absolute;
  top: 40px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  margin-top: -12px;
}

.single-featured-box .icon i::before {
  font-size: 55px;
}

.single-featured-box h3 {
  -webkit-transition: .4s;
  transition: .4s;
  margin: 15px 0;
  font-size: 18px;
}

.single-featured-box p {
  -webkit-transition: .4s;
  transition: .4s;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 0;
}

.single-featured-box .read-more-btn {
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #000), to(#000));
  background: linear-gradient(90deg, #000 0, #000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .6;
  transition: .6;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

.single-featured-box .read-more-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 0;
  -webkit-transition: .5s;
  transition: .5s;
  height: 1px;
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #18ba60), to(#18ba60));
  background: linear-gradient(90deg, #18ba60 0, #18ba60 100%);
}

.single-featured-box .read-more-btn:hover::before {
  width: 100%;
}

.single-featured-box:hover .icon {
  top: 23px;
  -webkit-animation: 0.4s ease-in-out iconTop;
          animation: 0.4s ease-in-out iconTop;
}

.single-featured-box:hover h3,
.single-featured-box:hover p {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.single-featured-box:hover .read-more-btn {
  opacity: 1;
  visibility: visible;
  bottom: 23px;
}

@-webkit-keyframes iconTop {
  0% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  25% {
    opacity: 0;
    -webkit-transform: translate(-50%, -70%);
            transform: translate(-50%, -70%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-50%, -40%);
            transform: translate(-50%, -40%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}
@keyframes iconTop {
  0% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  25% {
    opacity: 0;
    -webkit-transform: translate(-50%, -70%);
            transform: translate(-50%, -70%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-50%, -40%);
            transform: translate(-50%, -40%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}
@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
            transform: translateX(88px);
  }
}
@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
            transform: translateX(88px);
  }
}
@-webkit-keyframes rotateMe {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotateMe {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/*---------------------------------------------*/
/*-- 29. SECTION FEATURED PROPERTIES HOME 15 --*/
/*---------------------------------------------*/
section.featured.home15 {
  padding: 6rem 0;
  background: #fff;
}

.featured.home15 .sec-title {
  margin-left: 0;
}

.featured.home15 .sec-title h2 span {
  font-weight: 600;
  color: #111;
}

.featured.home15 .sec-title h2 {
  color: #FF385C;
  margin-bottom: .5rem;
}

.featured.home15 .item {
  padding-bottom: 30px;
}

.featured.home15 .filters-group {
  margin-bottom: 2.5rem;
  padding-left: 0px;
}

.featured.home15 .filters-group ul {
  padding-left: 0px;
}

.featured.home15 .filters-group ul li {
  list-style: none;
  color: #fff;
  display: inline-block;
  padding: 10px 15px;
  text-transform: capitalize;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.featured.home15 .filters-group ul li:hover {
  background: #FF385C;
  color: #fff;
}

.featured.home15 .filters-group {
  text-align: center;
  border-radius: 5px;
}

@media screen and (max-width: 543px) {
  .featured.home15 .filters-group ul {
    padding-left: 0px;
  }

  .featured.home15 .filters-group ul li:hover {
    background: none;
    color: #000;
  }
}
.featured.home15 .filters-group .active {
  background: #FF385C;
  color: #fff;
}

.project-single {
  margin-bottom: 10px;
}

.project-single.no-mb {
  margin-bottom: 0px;
}

.featured.home15 .item.no-pb {
  padding-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .project-single.no-mb.last:last-child {
    margin-bottom: 0px;
  }

  .featured.home15 .filters-group {
    text-align: left;
    margin-bottom: 2rem;
  }

  .filters-group.col-md-6 {
    margin-left: 1rem;
  }

  .featured.home15 .item.sale.no-pb {
    padding-bottom: 1.6rem;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 991px) {
  .featured.home15 .item.landscapes.no-pb {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .featured.home15 .item.h33 {
    padding-bottom: 30px !important;
  }

  .project-single.mbx-10 {
    margin-bottom: 10px;
  }

  .featured .compare {
    margin-top: .9rem !important;
  }
}
.homes-img {
  display: block;
}

.homes,
.homes-img {
  position: relative;
  overflow: hidden;
}

.featured.home15 .homes-tag.featured {
  background-color: transparent !important;
  float: left;
  left: 0px !important;
  top: 0px !important;
  position: relative;
  text-align: left;
  width: 200px !important;
  font-size: 14px !important;
  margin: 0;
}

.featured.home15 .homes-tag.button.alt.featured.pt-44 {
  padding-top: 25px;
  padding-left: 0px;
}

.featured.home15 .homes .button {
  font-size: 15px;
  font-weight: 600;
  padding: 6px 14px;
  border-radius: 2px;
  color: #fff;
  border: none;
  background-color: #FF385C;
  display: inline-block;
  z-index: 99;
}

.featured.home15 .homes-tag.featured {
  left: 15px !important;
  margin-top: 15px;
}

.featured.home15 .homes-price,
.featured.home15 .homes-tag {
  position: absolute !important;
  z-index: 99 !important;
}

.featured.home15 .homes-tag.sale {
  background: #e54242 !important;
  width: 84px !important;
  height: 36px;
}

.featured.home15 .homes-tag.sale {
  right: 15px;
  margin-top: 15px;
  top: 0;
}

.featured.home15 .homes-tag.sale.rent {
  background: #34495e !important;
  text-align: center;
  color: #fff;
  width: 96px !important;
  z-index: 99;
}

.featured.home15 .price-properties {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 1rem;
}

.featured.home15 .compare {
  margin-top: 1.1rem;
  line-height: 0px;
}

.featured.home15 .compare a {
  margin-right: 1.5rem;
}

.featured.home15 .compare a:last-child {
  margin-right: 0px;
}

.featured.home15 .compare i {
  font-size: 1rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.featured.home15 .fa-exchange-alt {
  color: #666;
}

.featured.home15 .fa-exchange-alt:hover {
  color: #18ba60;
}

.featured.home15 .fa-share-alt {
  color: #666;
}

.featured.home15 .fa-share-alt:hover {
  color: #ffb200;
}

.featured.home15 .fa-heart-o {
  color: #666;
}

.featured.home15 .fa-heart-o:hover {
  color: #e54242;
}

.featured.home15 .homes-price {
  color: #fff;
  font-size: 15px !important;
  font-weight: 600;
  padding: 6px 14px;
  border-radius: 2px;
  background: #18ba60;
  bottom: 20px;
  left: 15px;
  z-index: 99;
}

.featured.home15 .homes-content {
  background: #212529 !important;
  padding: 20px 20px !important;
}

.featured.home15 .homes-content h3.title a {
  font-size: 1.1rem;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
  text-decoration: none;
}

.featured.home15 .homes-content h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 20px;
}

.featured.home15 .homes-content h3 a {
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 30px;
  text-decoration: none;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.featured.home15 .homes-content h3 a:hover {
  color: #FF385C;
}

.price-properties h3 {
  margin-top: 1.3 !important;
  margin-bottom: 0px;
}

.featured.home15 .homes-content .homes-address {
  margin-top: 0px;
}

.featured.home15 .homes-content .homes-address .fa-map-marker {
  font-size: 1rem;
  text-decoration: none;
  color: #18ba60;
}

.featured.home15 .homes-content .homes-address a {
  text-decoration: none;
}

.featured.home15 .homes-content .homes-address a span {
  font-size: 13px;
  font-weight: 400;
  margin: 0 0 1px;
  text-decoration: none;
}

.featured.home15 .homes-address span {
  color: #666;
}

.featured.home15 .homes-content .homes-address a i {
  margin-right: 10px;
}

.featured.home15 .homes-content .homes-list {
  padding-left: 0px;
  padding-bottom: 0;
  margin: 0;
}

.featured.home15 .homes-content .homes-list li {
  width: 33%;
  float: left;
  font-size: 13px;
  line-height: 40px;
  list-style: none;
  color: #FF385C;
}

.featured.home15 .homes-content .homes-list li i {
  font-size: 14px;
  margin-right: 5px;
  color: #cecdcd;
}

.featured.home15 .homes-content .homes-list span {
  color: #fff;
}

.featured.home15 .footer {
  background: none;
  border-top: 1px solid #d8d8d8;
  padding-top: 1rem;
  font-size: 13px;
  color: #666;
}

.featured.home15 .footer a {
  color: #666;
  text-decoration: none;
}

.featured.home15 .footer span {
  float: right;
}

.featured.home15 .footer i {
  margin-right: 5px;
  font-size: 1rem;
  color: #666;
}

/*----- HOVER EFFECT -----*/
.featured.home15 .project-single {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 0px !important;
}

.featured.home15 .project-inner {
  position: relative;
  overflow: hidden;
}

.featured.home15 .project-head {
  position: relative;
  text-align: center;
}

.featured.home15 .project-head:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0;
  z-index: 33;
  background: #121B22;
  color: #fff;
  text-align: center;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.featured.home15 .project-single:hover .project-head:before {
  opacity: 0.8;
}

.featured.home15 .project-head img {
  width: 100%;
  z-index: 2;
}

.featured.home15 .project-bottom {
  text-align: center;
  padding: 10px 0 0;
  position: absolute;
  width: 100%;
  text-align: left;
  z-index: 333;
  text-align: center;
  top: 50%;
  margin-top: -23.5px;
}

.featured.home15 .project-bottom h4 {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.featured.home15 .project-bottom h4:before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
}

.featured.home15 .project-single:hover .project-bottom h4 {
  opacity: 1;
  visibility: visible;
}

.featured.home15 .project-bottom h4 a {
  color: #fff;
  text-decoration: none;
}

.featured.home15 .project-bottom h4 span {
  display: block;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
}

.featured.home15 .button-effect {
  position: absolute;
  text-align: left;
  bottom: 0;
  right: 0;
  z-index: 33;
  background: transparent;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 0;
  visibility: visible;
  padding: 8px 15px;
  border-radius: 20px 0 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: .7rem;
}

.featured.home15 .project-single .button-effect {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.featured.home15 .button-effect .btn {
  display: inline-block;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-right: 15px;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  line-height: 33px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.featured.home15 .button-effect .btn:hover {
  color: #fff;
}

.featured.home15 .button-effect .btn:last-child {
  margin: 0;
}

.featured.home15 .load-button {
  margin-top: 50px;
}

.featured.home15 .load-button .btn {
  padding: 14px 30px;
  color: #fff;
  font-weight: 500;
  border: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin: 0;
}

.featured.home15 .load-button .btn:hover {
  background: #353535;
}

.featured.home15 .button-effect .fa-link:before {
  content: "\f0c1";
  font-family: FontAwesome;
  color: #fff;
}

.featured.home15 .button-effect .fa-video:before {
  content: "\f03d";
  font-family: FontAwesome;
  color: #fff;
}

.featured.home15 .button-effect .fa-photo:before {
  content: "\f03e";
  font-family: FontAwesome;
  color: #fff;
}

/*----------------------------------------------*/
/*-------- 30. SECTION INFO HELP HOME 15 -------*/
/*----------------------------------------------*/
.info-help {
  padding: 6rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.3)), to(rgba(255, 255, 255, 0.3))), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
  background-size: cover;
  position: relative;
  z-index: 3;
}

.info-help:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.info-help .info-head .info-text {
  padding-left: 0px;
}

.info-help .info-head .info-text h3 {
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  margin-bottom: 0px;
}

.info-help .info-head .info-text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  color: #fff;
  padding: 1.5rem 0;
  margin-bottom: 0px;
}

.info-help .info-head .info-text .btn-pro {
  background: #18ba60;
  color: #fff;
  margin-top: 10px;
  border: 0;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.info-help .info-head .info-text .btn-pro:hover {
  background: #121B22;
}

@media screen and (max-width: 767px) {
  .info-help .info-head .info-text h3 {
    font-size: 25px;
  }

  .info-help .info-head .info-text p {
    font-size: 16px;
  }
}
.rev_row_zone_bottom,
.tp-caption.have {
  bottom: 80px;
}

.hermes .tp-bullet,
.uranus.tparrows {
  display: none;
}

/*------------------------------------------*/
/*--- 31. SECTION VISITED CITIES HOME 15 ---*/
/*------------------------------------------*/
section.visited-cities {
  padding: 6rem 0;
}

.visited-cities .img-box {
  height: 280px;
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 1rem;
  z-index: 90;
  border-radius: 3px;
}

.visited-cities .img-box.no-mb {
  margin-bottom: 0px;
}

.visited-cities .img-box:hover {
  background: #FF385C;
}

.visited-cities .img-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  background-color: #121B22;
  opacity: 0.2;
  z-index: 99;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.visited-cities .listing-badges {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
  overflow: hidden;
  height: 100px;
}

.visited-cities .listing-badges .featured {
  background-color: #FF385C;
  float: left;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: -67px;
  top: 17px;
  position: relative;
  text-align: center;
  width: 200px;
  font-size: 13px;
  margin: 0;
}

.visited-cities .listing-badges span {
  color: #fff;
  display: inline-block;
  padding: 3px;
  float: right;
  background-color: rgba(74, 74, 76, 0.7);
  border-radius: 3px;
  top: 15px;
  right: 15px;
  position: absolute;
}

.visited-cities .starts {
  list-style: none;
  margin-top: 0;
  padding: 0px;
  margin-bottom: 0px;
}

.visited-cities .starts li {
  text-align: center;
  display: inline-block;
  margin-bottom: 0px;
}

.visited-cities .starts .fa {
  color: #fe4f4f !important;
}

.visited-cities .starts ul {
  margin-bottom: 0px;
}

.visited-cities .img-box-content {
  position: absolute;
  z-index: 101;
  top: 50%;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  -webkit-transform: translate(0, -50.5%);
          transform: translate(0, -50.5%);
}

.visited-cities .img-box-content h4 {
  font-size: 20px;
  color: #fff;
  margin: 0;
}

.visited-cities .img-box-content span {
  font-size: 16px;
  color: #fff;
}

.visited-cities .img-box img {
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.55s;
  transition: all 0.55s;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  -webkit-transition: -webkit-transform 0.35s ease-out;
  transition: -webkit-transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
}

@media screen and (max-width: 991px) {
  .visited-cities .pr {
    padding-right: 5px;
  }

  .tp-caption.have {
    bottom: -80px;
  }
}
@media screen and (max-width: 767px) {
  .visited-cities .img-box.no-mb {
    margin-bottom: 1.5rem;
  }

  .visited-cities .img-box.no-mb.x3 {
    margin-bottom: 0px;
  }

  .visited-cities .img-box.san.no-mb.x3 {
    margin-bottom: 0px !important;
  }

  .visited-cities .img-box.mi,
  .visited-cities .img-box {
    margin-bottom: 1.6rem !important;
  }

  .visited-cities .bg-all {
    margin-top: 0px;
  }

  .visited-cities .pr-1,
  .feature-categories .pr-1,
  .feature-categories .mt,
  .visited-cities .pr {
    padding-right: 15px !important;
  }
}
/*-----------------------------------------------*/
/*--- 32. SECTION RECENTLY PROPERTIES HOME 16 ---*/
/*-----------------------------------------------*/
.recent-16 {
  display: block;
  position: relative;
  overflow: hidden;
  height: 400px;
}

.recent-img16 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.recent-16:hover .recent-img16 {
  -webkit-transform: scale(1.06, 1.06);
          transform: scale(1.06, 1.06);
}

.recent-content {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.4)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.recent-details {
  position: absolute;
  padding: 20px;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.recent-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
}

.recent-price {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
}

.recent-price > small {
  font-size: 60%;
}

.house-details {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.house-details > span {
  opacity: 0.5;
  display: inline-block;
  padding: 0 5px;
}

.recent-16:hover .recent-details {
  -webkit-transform: translateY(-30%);
          transform: translateY(-30%);
}

.view-proper {
  position: absolute;
  color: #fff;
  padding: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  font-size: 14px;
  letter-spacing: 1px;
  opacity: 0;
  font-weight: 400;
  -webkit-transform: translateY(400%);
          transform: translateY(400%);
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.recent-16:hover .view-proper {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.img-center {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/*----------------------------*/
/*--- 33. PARALLAX HOME 17 ---*/
/*----------------------------*/
.parallax-searchs.home17 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2))), url("../images/bg/home-17-2.jpg") no-repeat center top;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../images/bg/home-17-2.jpg") no-repeat center top;
  background-size: cover;
  background-attachment: fixed !important;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 99;
}

.banner-inner-wrap {
  height: 110vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.banner-inner-area {
  position: relative;
}

.th-17 .rld-banner-tab {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.th-17 .nav-item a.active,
.th-17 .nav-item a:hover,
.th-17 .nav-item a.active:before,
.th-17 .btn.btn-yellow,
.th-17 .range-slider .ui-widget-header,
.th-17 .checkboxes input[type=checkbox]:checked + label:before {
  background: #687389;
}

.th-17 .dropdown-filter span::after,
.th-17 .form-group .nice-select i,
.th-17 a.btn.btn-yellow:hover,
.th-17 .form-group .nice-select:hover {
  color: #687389;
}

.th-17 .range-slider .ui-slider .ui-slider-handle {
  border: 2px solid #687389;
}

.th-17 .explore__form-checkbox-list.full-filter.filter-block {
  margin-top: 22px;
}

.tab-content > .active {
  display: block;
}

.banner-inner .sub-title {
  color: #fff;
  margin-bottom: 22px;
}

.banner-inner h1 {
  color: #fff;
  font-size: 45px;
  line-height: 66px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.banner-inner .content {
  margin-top: 20px;
  margin-bottom: 0;
}

.banner-inner .banner-btn-wrap {
  margin-top: 19px;
}

.banner-inner .btn {
  height: 55px;
  line-height: 55px;
}

.banner-inner .video-play-btn {
  height: 55px;
  width: 55px;
  line-height: 52px;
  text-align: center;
  border-radius: 6px;
  display: inline-block;
  border: 2px solid #D4D4D4;
  font-size: 20px;
  color: #fff;
}

.banner-inner .rld-banner-search {
  margin-top: 33px;
}

.thumb-wrap {
  position: absolute;
  bottom: 0;
}

.main-search-area {
  margin-top: -50px;
}

.rld-main-search {
  padding: 50px 30px 35px 30px;
  background: #fff;
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 6px 6px 6px;
}

.rld-main-search .rld-single-input,
.rld-main-search .rld-single-select .single-select {
  margin-bottom: 15px;
}

.rld-main-search .btn {
  width: 100%;
}

.btn-yellow {
  background: #687389;
  color: #fff;
  border: 1px solid #687389;
}

.btn.btn-yellow {
  height: 48px;
  line-height: 48px;
  padding: 0 30px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: 0px solid #687389;
  font-size: 14px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-weight: 600;
}

.btn-yellow:hover,
.btn-yellow:focus {
  background: #fff;
  border: 1px solid #687389;
  color: #687389;
}

.rld-single-input {
  position: relative;
}

.rld-single-input input {
  height: 48px;
  line-height: 48px;
  width: 100%;
  padding: 0 20px;
  border: 1px solid #D4D4D4;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
  font-size: 15px;
}

.rld-single-input input:hover,
.rld-single-input input:active,
.rld-single-input input:focus {
  border: 1px solid #687389;
}

.rld-single-input.left-icon:before {
  content: '\f041';
  font-size: 18px;
  font-family: fontawesome;
  color: #687389;
  position: absolute;
  top: 12px;
  background-size: auto;
  height: 17px;
  width: 12px;
  left: 19px;
  background-repeat: no-repeat;
}

.rld-single-input.left-icon input {
  padding-left: 40px;
}

.rld-single-input textarea {
  height: 120px;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #D4D4D4;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
  font-size: 15px;
}

.rld-single-input textarea:hover,
.rld-single-input textarea:active,
.rld-single-input textarea:focus {
  border: 1px solid #687389;
}

.rld-single-select .single-select {
  height: 48px;
  line-height: 47px;
  width: 100%;
  padding: 0 34px 0 20px;
  border: 1px solid #D4D4D4;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
  border-radius: 4px;
}

.rld-single-select .single-select:hover,
.rld-single-select .single-select:active,
.rld-single-select .single-select:focus {
  border: 1px solid #687389;
}

.rld-single-select .single-select:after {
  border: 0;
}

.rld-single-select .single-select:before {
  content: '\f0dd';
  font-size: 20px;
  font-family: fontawesome;
  color: #687389;
  position: absolute;
  top: 0px;
  margin: 0px;
  background-size: auto;
  height: 0px;
  width: 11px;
  right: 14px;
  left: auto;
  background-repeat: no-repeat;
}

.rld-single-select .single-select .list {
  margin-top: 0px;
  width: 100%;
}

.rld-price-slider-wrap .rld-price-slider,
.rld-price-slider-wrap .rld-size-slider {
  height: 5px;
  background: #FFF0E5;
  border: 0;
  margin: 20px 0 0 0;
}

.rld-price-slider-wrap .ui-slider-handle {
  height: 24px;
  width: 42px;
  background: #687389;
  border: 0;
  text-align: center;
  color: #fff;
  border-radius: 30px;
  top: -10px;
  font-size: 13px;
  line-height: 25px;
}

/****** banner-search *******/
.rld-banner-search .rld-single-input input {
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border: 0;
  padding-right: 140px;
}

.rld-banner-search .rld-single-input button {
  position: absolute;
  right: 0;
  top: 0;
  height: 48px;
  line-height: 48px;
  border: 0;
  padding: 0 20px;
  font-weight: 600;
  background: var(--main-color-one);
  color: #fff;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}

.th-17 .header-user-name,
.th-17 .header-user-name:before {
  color: #fff;
}

.th-17 #navigation.style-2.cloned .header-user-name,
.th-17 #header.cloned.sticky .header-user-name,
.th-17 #navigation.style-2.cloned .header-user-name:before,
.th-17 #header.cloned.sticky .header-user-name:before {
  color: #666;
}

/******* banner tab style *****/
.banner-area {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner-area .banner-search-wrap {
  margin-top: 24px;
}

.rld-banner-tab {
  border-bottom: 0;
}

.rld-banner-tab .nav-item {
  margin-bottom: 0;
}

.rld-banner-tab .nav-item a {
  border: 0;
  border-radius: 5px 5px 0 0;
  height: 50px;
  line-height: 50px;
  padding: 0 35px;
  margin-right: 15px;
  position: relative;
  display: block;
  letter-spacing: 0.4px;
  background: #fff;
  color: #000;
  font-family: var(--heading-font);
  font-weight: 600;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.rld-banner-tab .nav-item a:hover,
.rld-banner-tab .nav-item a:focus,
.rld-banner-tab .nav-item a.active {
  background: #687389;
  color: #fff;
}

.rld-banner-tab .nav-item:last-child {
  margin-bottom: 0;
}

.navbar-area-3 {
  background: #fff;
}

.banner-search {
  margin-top: 80px;
}

@media screen and (max-width: 1024px) {
  .th-17 .banner-inner .sub-title {
    margin-top: 120px;
  }
}
@media screen and (max-width: 768px) {
  .th-17 .banner-inner .sub-title {
    margin-top: 0px;
  }
}
@media screen and (max-width: 575px) {
  .th-17 .explore__form-checkbox-list.full-filter.filter-block {
    margin-top: 30px;
  }
}
/*----------------------------------------------*/
/*-------- 34. SECTION INFO HELP HOME 17 -------*/
/*----------------------------------------------*/
.info-help.h18 {
  padding: 9rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0.1))), url(../images/bg/info-service4.png) no-repeat scroll center center;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url(../images/bg/info-service4.png) no-repeat scroll center center;
  background-size: cover;
  position: relative;
  z-index: 0 !important;
}

.info-help.h17 {
  padding: 9rem 0;
  background: url(../images/bg/info-service6.png) no-repeat scroll center center;
  background-size: cover;
  position: relative;
  z-index: 0 !important;
}

.info-help.h17:before {
  background: none;
}

.info-help.h17 .info-head .info-text {
  background: #fff;
  padding: 30px;
}

.info-help.h17 .info-head .info-text h3 {
  color: #333;
  line-height: 32px;
}

.info-help.h17 .info-head .info-text h5 {
  color: #333;
  font-weight: 500;
}

.info-help.h17 .info-head .info-text p {
  color: #666;
}

.portfolio.ho-17 .item {
  padding-bottom: 30px;
}

.inner-pages .portfolio.ho-17 .item {
  padding-bottom: 30px;
}

@media screen and (max-width: 360px) {
  .banner-inner h1,
  .banner-inner .sub-title {
    display: none;
  }

  .banner-inner-wrap {
    height: 120vh;
  }
}
@media screen and (max-width: 767px) {
  .portfolio .item.sale.h-177.no-pb {
    padding-bottom: 30px;
    margin-bottom: 0px;
  }

  .portfolio .ho17-thelast {
    padding-top: 30px;
    padding-bottom: 0px !important;
  }
}
@media screen and (max-width: 991px) {
  .portfolio .ho17-thelast,
  .portfolio .item.sale.h-177.no-pb {
    padding-bottom: 0px !important;
  }
}
/*-----------------------------------*/
/*------ 35. AGENTS HOME 17 ----*/
/*-----------------------------------*/
section.team {
  padding: 4.6rem 0;
  background: #f7f7f7;
}

.team-block {
  position: relative;
}

.team-block .inner-box {
  position: relative;
  padding-bottom: 0px;
  -webkit-box-shadow: 2px 2px 30px #ccc;
          box-shadow: 2px 2px 30px #ccc;
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}

.team-block .inner-box .image {
  position: relative;
}

.team-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
  height: auto;
}

.team-block .inner-box .lower-box {
  position: relative;
  padding: 22px 0;
  text-align: center;
}

.team-block .inner-box .lower-box h3 {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2em;
  margin-bottom: 0px;
}

.team-block .inner-box .lower-box h3 a {
  position: relative;
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.team-block .inner-box .lower-box h3 a:hover {
  color: #687389;
}

.team-block .inner-box .lower-box .designation {
  position: relative;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  padding-top: 2px;
  text-transform: capitalize;
}

@media screen and (max-width: 991px) {
  .team-block {
    padding-bottom: 2.5rem;
  }

  .team-block.pb-none {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 767px) {
  .team-block.pb-none {
    padding-bottom: 2.5rem;
  }

  .team-block.pb-none:last-child {
    padding-bottom: 0px;
  }
}
/*------ HOVER EFFECT ------*/
.team .team-head {
  position: relative;
  overflow: hidden;
}

.team .team-details {
  background: #fff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.team .team-head::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  content: "";
  z-index: 2;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.team .team-head img {
  width: 100%;
  position: relative;
}

.team .team-social {
  background: transparent;
  width: 75px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  z-index: 33;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: perspective(600px) rotateY(90deg);
          transform: perspective(600px) rotateY(90deg);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.team ul.team-social {
  margin: 0px;
}

.team .team-details .team-social {
  opacity: 1;
  visibility: visible;
  -webkit-transform: perspective(600px) rotateY(0deg);
          transform: perspective(600px) rotateY(0deg);
}

.team .team-social li a {
  display: block;
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid #d4cfcf;
}

.team .team-social li a.linkedin {
  border-bottom: 0px;
}

.team .team-social li a.facebook {
  background-color: #687389;
}

.team .team-social li a.twitter {
  background-color: #687389;
}

.team .team-social li a.instagram {
  background-color: #687389;
}

.team .team-social li a.linkedin {
  background-color: #687389;
}

.team .team-social li {
  display: block;
}

.team .team-social li:last-child {
  margin: 0;
}

.team .team-details:hover .team-bottom {
  background-color: #F9A056;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/*---------------------------------------------*/
/*--------- 36. HEADER IMAGE HOME 8 ----------*/
/*---------------------------------------------*/
.th-8 .header-image {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0))), url("../images/bg/bg-home-8.png") no-repeat center top;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("../images/bg/bg-home-8.png") no-repeat center top;
  background-attachment: fixed !important;
  width: 100%;
  height: 90vh;
  position: relative;
}

.th-8 .header-image {
  border-bottom: 1px solid #e5e5e5;
}

.th-8 .filter-item label {
  color: #333;
}

.th-8 .filter {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 30px;
  width: 80%;
}

.th-8 input,
.th-8 textarea,
.th-8 select {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  background: #f8f8f8;
}

.th-8 .header-user-name,
.th-8 .header-user-name:before {
  color: #666 !important;
}

.th-8 .ui-widget.ui-widget-content,
.th-8 .header-image .left .btn-white,
.th-8 .sec-title h2 span::before,
.th-8 .btn:hover,
.th-8 .popular-places .listing-badges .featured,
.th-8 .homes-tag.featured {
  background: #FF385C !important;
}

.th-8 .filter .button {
  background: #fff;
}

.th-8 .filter-item.sli {
  margin-top: 30px;
}

.th-8 .filter-item .button {
  width: 100% !important;
  cursor: pointer;
  color: #fff;
}

.th-8 img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.th-8 a.button.border,
a.button,
.th-8 .header-image .left .btn-white {
  color: #fff !important;
}

.th-8 .first-footer .top-footer {
  background: #24324a;
}

.th-8 .second-footer {
  background: #1d293e;
}

.th-8 .slick-dots li.slick-active,
.th-8 .slick-dots li,
.homepage-6 .slick-dots li.slick-active,
.homepage-6 .slick-dots li {
  -webkit-box-shadow: inset 0 0 0 3px rgba(74, 74, 76, 0.4) !important;
          box-shadow: inset 0 0 0 3px rgba(74, 74, 76, 0.4) !important;
}

.th-8 .slick-dots li:after,
.home-11 .slick-dots li:after,
.homepage-6 .slick-dots li:after {
  background: #696565 !important;
}

.homepage-6 .header-user-name,
.homepage-6 .header-user-name:before {
  color: #fff;
}

.homepage-6 .portfolio .homes-content .homes-list li i {
  color: #696969;
  font-size: 18px;
}

.home-11 .portfolio .project-single {
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 0px;
  border: 1px solid #e7e7e7;
}

.home-11 .portfolio .homes-content h3 a:hover {
  color: #444 !important;
}

.th-8 .sec-title {
  text-align: center;
  padding-bottom: 2rem;
}

.th-8 .form-group.location {
  width: 100%;
  margin: 30px 0;
}

.th-8 .sec-title h2 span {
  position: relative;
  color: #444;
  font-weight: 500;
}

.th-8 a.button.border, a.button {
  background: #FF385C;
  color: #fff !important;
}

.th-8 .header-image .left h1 {
  color: #333;
}

.th-8 .header-image .left p {
  color: #333;
}

.th-8 .project-single .button-effect {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.th-8 .button-effect .btn {
  display: inline-block;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-right: 15px;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  line-height: 33px;
  background: rgba(74, 74, 76, 0.7);
  z-index: 99;
}

.th-8 .button-effect .fa-link:before {
  content: "\f0c1";
  font-family: FontAwesome;
  color: #fff;
}

.th-8 .button-effect .fa-video:before {
  content: "\f03d";
  font-family: FontAwesome;
  color: #fff;
}

.th-8 .button-effect .fa-photo:before {
  content: "\f03e";
  font-family: FontAwesome;
  color: #fff;
}

.home-18.th-8 .google-maps .filter .button {
  font-size: 18px;
}

.home-18.th-8 .google-maps .filter .button:hover {
  color: #fff;
}

/* Contact Form */
.th-8 .contact-us {
  padding: 6rem 0;
}

.th-8 .contact-us ul li {
  list-style: none;
  font-weight: 300;
}

.th-8 .contact-us ul {
  margin: 0;
  padding: 0;
}

.th-8 .contact-us .btn {
  background: #FF385C;
  border: 1px solid #FF385C;
  cursor: pointer;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.th-8 .contact-us .btn:hover {
  background: #fff;
  color: #121B22;
  border: 1px solid #FF385C;
}

.th-8 .address-info p:last-child {
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .th-8 .offices h3:first-child {
    margin-top: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .th-8 .contact-us h3 {
    font-size: 1.1rem !important;
  }
}
label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #000;
}

form label.error {
  display: block;
  color: #ff0000;
  font-size: 14px !important;
  opacity: 1 !important;
}

.successform,
.errorform {
  display: none;
  height: 70px;
}

.successform span,
.errorform span {
  display: block;
}

.successform span p,
.errorform span p {
  margin-top: 15px;
}

.successform span p {
  color: #01c15a;
  font-size: 2rem;
}

.errorform span p {
  color: #c0392b;
}

.th-8 .contact-us h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
}

.th-8 .offices h3 {
  text-transform: capitalize;
}

.th-8 .offices p {
  font-weight: 300;
}

/* Search Form */
.th-8 .parallax-search8 .trip-search {
  background: #fff;
  padding: 30px;
  -webkit-box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  text-align: left;
  margin-top: 10px;
}

.th-8 .trip-search.contact-us h4 {
  line-height: 27px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .th-8 .header-image .left {
    margin-top: 60px;
  }

  .th-8 .header-image .left p {
    display: none;
  }

  .th-8 .header-image .left h1 {
    font-size: 22px;
  }

  .th-8 a.button.border, a.button, .th-8 .header-image .left .btn-white {
    margin-top: 0px;
  }
}
@media screen and (max-width: 450px) {
  .th-8 .header-image {
    padding: 0px 0px !important;
  }

  .th-8 .header-image {
    height: 100vh;
  }

  .th-8 .header-image .left h1 {
    font-size: 18px;
    margin-bottom: 0px;
    padding-top: 20px;
  }

  .th-8 .header-image .left .btn-white {
    display: none;
  }

  .th-8 .parallax-search8 .trip-search {
    margin-top: 10px !important;
    z-index: 9;
  }

  .th-8 .trip-search.contact-us h4 {
    font-size: 15px;
  }
}
@media (max-width: 991.98px) {
  .th-8 .header-image .left {
    margin-top: 80px;
    margin-bottom: 0px;
  }

  .th-8 .header-image {
    padding: 500px 30px;
  }
}
.th-8 .parallax-search8 p {
  font-size: 17px;
  margin-top: 10px;
}

.th-8 .align-items-center.bg8 {
  margin-bottom: 110px;
}

.th-8 .parallax-search8 .trip-search .form {
  line-height: 0px;
}

.th-8 .parallax-search8 .form-group:last-child {
  margin-right: 0px;
}

.th-8 .parallax-search8 .form-group .nice-select {
  margin: 0px;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.th-8 .parallax-search8 .form-group .nice-select:hover {
  color: #FF385C;
}

.th-8 .parallax-search8 .form-group .nice-select::after {
  right: 20px;
  color: #757575;
}

.th-8 .parallax-search8 .form-group .nice-select i {
  color: #FF385C;
  font-size: 1.2rem;
  margin-top: 5px;
  margin-right: 10px;
}

.th-8 .parallax-search8 .form-group .list li {
  color: #757575;
}

.th-8 .parallax-search8 .form-group .list li.selected {
  color: #757575;
  font-weight: 400;
}

.th-8 .parallax-search8 .form-group .list li:hover {
  color: #fff;
  background: #FF385C;
}

.th-8 .parallax-search8 .form-group.looking {
  width: 100%;
  margin: 0px;
  border: 1px solid #dddddd !important;
}

.th-8 .parallax-search8 .first-select {
  height: 30px;
  border-radius: 5px;
  margin-right: 4px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.th-8 .main-search-input-item {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 50px;
  position: relative;
}

.th-8 .nice-select.wide {
  width: 100%;
}

.th-8 .parallax-search8 .form-group.looking input {
  float: left;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 20px;
}

.th-8 .parallax-search8 .form-group.location {
  width: 100%;
  margin: 30px 0;
}

.th-8 .parallax-search8 .form-group.categories {
  width: 100%;
}

.th-8 .parallax-search8 .form-group.button {
  width: 100%;
  position: relative;
  height: 50px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.th-8 .parallax-search8 .form-group .btn {
  display: block;
  width: 100%;
  background: #FF385C;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  top: 0;
  margin-top: 5px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  font-size: 14px;
  border: none;
  border-radius: 5px;
}

.th-8 .parallax-search8 .form-group .nice-select {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.th-8 .parallax-search8 .form-group .nice-select i {
  color: #FF385C;
}

.th-8 .parallax-search8 .form-group .nice-select:hover {
  color: #FF385C;
}

.th-8 .parallax-search8 img {
  height: auto;
  max-width: 100%;
}

.th-8 .parallax-search8 h1 {
  color: #444;
  font-size: 50px;
}

.th-8 .filter-item .button {
  width: 100% !important;
  cursor: pointer;
  color: #fff;
}

.th-8 input, textarea, select {
  width: 100%;
  height: 50px;
  padding: 9px;
  margin-bottom: 0px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-weight: 400;
  color: #000;
  font-family: 'Poppins', sans-serif;
  background: #f8f8f8;
}

.th-8 .form-group {
  margin-bottom: .625rem;
}

.th-8 .first-footer input, textarea, select {
  margin-bottom: 0px;
  width: auto;
}

.th-8 .filter-item {
  width: 100%;
  margin-top: 30px;
  float: none;
}

.th-8 .parallax-search8 .form-group .btn:hover {
  background: #121B22;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .th-8 .header-image {
    padding: 350px 0px;
  }
}
@media screen and (max-width: 768px) {
  .th-8 .parallax-search8 .trip-search {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .th-8 .parallax-search8 .trip-search {
    margin-top: 90px;
  }
}
/* Range Slider
------------------------------------- */
.th-8 .range-slider label {
  color: #333;
  font-size: 16px;
  margin-bottom: 25px;
}

.th-8 .range-slider .ui-slider {
  position: relative;
  text-align: left;
}

.th-8 .range-slider .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #FF385C;
  cursor: w-resize;
  background: #fff;
  outline: none;
  top: -8px;
  margin-left: -8px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.th-8 .range-slider .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
  background-position: 0 0;
}

.th-8 .range-slider .ui-widget-content {
  background-color: #e8e8e8 !important;
}

.th-8 .range-slider .ui-widget-header {
  background-color: #FF385C;
}

.th-8 .range-slider .ui-slider.ui-state-disabled .ui-slider-handle,
.th-8 .range-slider .ui-slider.ui-state-disabled .ui-slider-range {
  -webkit-filter: inherit;
          filter: inherit;
}

.th-8 .range-slider .ui-slider-horizontal {
  height: 3px;
  border-radius: 10px;
  width: calc(100% - 16px);
  margin-left: 8px;
}

.th-8 .range-slider .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.th-8 .range-slider .ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.th-8 .range-slider .ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.th-8 input.first-slider-value:focus,
.th-8 input.second-slider-value:focus,
.th-8 input.first-slider-value,
.th-8 input.second-slider-value {
  width: 40%;
  display: inline-block;
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 12px 0 0 0;
  cursor: default;
}

.th-8 input.first-slider-value {
  float: left;
  margin-left: -8px;
  width: 40%;
}

.th-8 input.second-slider-value {
  float: right;
  width: 40%;
  text-align: right;
  margin-right: -8px;
}

.th-8 .range-slider {
  line-height: 30px;
}

.th-8 .main-search-field-2 {
  margin-top: 30px;
  line-height: 30px;
}

/*---------------------------------------------*/
/*--------- 37. HEADER IMAGE HOME 18 ----------*/
/*---------------------------------------------*/
.header-image.home-18 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2))), url("../images/bg/home-18.jpg") no-repeat center top;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../images/bg/home-18.jpg") no-repeat center top;
  background-size: cover;
  background-attachment: fixed !important;
  width: 100%;
  height: 100vh;
}

.header-image .left h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  margin: 0;
  margin-bottom: 20px;
}

.header-image .left p {
  font-size: 17px;
  width: 90%;
  font-weight: 400;
  line-height: 27px;
  color: #fff;
  margin: 0;
}

.header-image .left .btn-white {
  background: #f55d2c;
  display: inline-block;
  padding: 12px 35px;
  border-radius: 40px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  margin-top: 30px;
  -webkit-box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.15);
}

a.btn-white {
  text-decoration: none;
}

.google-maps.home-18 .filter {
  padding: 20px;
  margin-top: 180px;
  width: 70%;
  position: relative;
  z-index: 100;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  border-top: 0px solid rgba(255, 255, 255, 0.2);
  float: right;
  color: #fff;
}

.th-18 .header-user-name,
.th-18 .header-user-name:before {
  color: #fff;
}

.th-18 #navigation.style-2.cloned .header-user-name,
.th-18 #header.cloned.sticky .header-user-name,
.th-18 #navigation.style-2.cloned .header-user-name:before,
.th-18 #header.cloned.sticky .header-user-name:before {
  color: #666;
}

@media (max-width: 768px) {
  .th-18 .google-maps .filter form {
    background: #f55d2c;
  }

  .th-18 .ui-widget-header,
  .th-18 .google-maps .filter .button {
    background: #1e1629;
  }

  .th-18 .header-image {
    padding: 10px 0;
  }

  .th-18 .header-image .left,
  .th-18 .google-maps.home-18 .filter {
    margin-top: 0px !important;
  }
}
@media (max-width: 1024px) {
  .th-18 .google-maps.home-18 .filter {
    margin-top: 100px;
  }
}
@media (max-width: 991.98px) {
  .header-image {
    text-align: center;
    padding: 600px 30px;
  }

  .google-maps.home-18 .filter {
    margin-top: 0px;
  }

  .google-maps.home-18 .filter {
    width: 100%;
  }

  .google-maps.home-18 {
    top: 60px;
  }

  .header-image .left {
    margin-top: 150px;
    margin-bottom: 0px;
  }

  .header-image .left h1 {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .header-image.home-18 {
    height: 10vh;
  }

  .header-image {
    padding: 350px 30px;
  }

  .header-image .left {
    margin-top: 10px;
  }

  .header-image .left h1 {
    font-size: 26px;
  }

  .header-image .left p {
    font-size: 15px;
  }

  .google-maps.home-18 .filter {
    padding: 0px;
  }

  .th-18 .header-image {
    padding: 300px 0;
  }

  .google-maps.home-18 .filter form {
    background: #f55d2c;
    border-top: 1px solid #f55d2c;
  }

  .home18 .sec-title h2 {
    font-size: 24px !important;
  }

  .home18 .sec-title h2 span::before {
    display: none;
  }
}
/*--------------------------------------------*/
/*---- 38. SECTION WHY CHOOSE US HOME 18 -----*/
/*--------------------------------------------*/
section.how-it-works {
  background: #fff;
  padding: 4.6rem 0;
}

.how-it-works .serv-flex {
  background: #fff;
  color: #f55d2c;
  text-align: center;
  padding: 1.5rem;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 1px #e7e7e7 !important;
          box-shadow: 0px 0px 1px #e7e7e7 !important;
  border: 1px solid #e7e7e7;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.how-it-works .serv-flex.arrow:after {
  display: none;
}

.how-it-works .art-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.how-it-works article .fa {
  padding-right: 1rem;
  padding-bottom: .5rem;
  font-size: 4rem;
  text-align: left;
}

.how-it-works article img {
  padding-right: 1rem;
  padding-bottom: .5rem;
  width: 100px;
  height: 100px;
  text-align: center;
}

.how-it-works .sec-title {
  margin-left: .5rem;
}

.how-it-works .sec-title h2 span {
  font-weight: 600;
  color: #111;
}

.how-it-works .sec-title img {
  position: relative;
  width: 151px;
  height: 26px;
}

.how-it-works article h3 {
  color: #222;
  margin: 1.7rem 0 .2rem;
}

.how-it-works article p {
  color: #666;
  margin-top: 1rem;
  margin-bottom: 0px;
}

.how-it-works .service-1 .serv:hover,
.how-it-works .service-2 .serv:hover {
  cursor: all-scroll;
}

@media screen and (max-width: 767px) {
  .how-it-works .service-1 .serv {
    margin-bottom: 2rem;
  }

  .how-it-works .service-1 .serv:last-child {
    margin-bottom: 0px;
  }

  .how-it-works .mt-50 {
    margin-top: 0px !important;
  }

  .how-it-works .service-1 .serv.mb-0.pt {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 991px) {
  .how-it-works .mt-50 {
    margin-top: 2rem;
  }

  .how-it-works .service-1 .serv.mb-0 {
    margin-bottom: 0px;
  }

  .how-it-works .service-1 .serv.mb-0.pt {
    margin-top: 2rem;
  }

  .how-it-works .serv-flex:after {
    display: none;
  }
}
/*-------------------------------------------*/
/*----- 39. SECTION TESTIMONIALS HOME 18 ----*/
/*-------------------------------------------*/
section.h18.testimonials {
  padding: 6rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(14, 18, 68, 0.88)), to(rgba(14, 18, 68, 0.88))), url(../images/bg/bg-testimonials.jpg) no-repeat scroll center center;
  background: linear-gradient(rgba(14, 18, 68, 0.88), rgba(14, 18, 68, 0.88)), url(../images/bg/bg-testimonials.jpg) no-repeat scroll center center;
  background-size: cover;
}

.h18.testimonials .sec-title h2 {
  color: #fff;
}

.h18.testimonials .sec-title p {
  color: #fff;
}

.h18.testimonials .client-info {
  display: inline-block;
}

.h18.testimonials .client-thumb {
  border-radius: 100%;
  height: 60px;
  margin-right: 20px;
  width: 60px;
  float: left;
}

.h18.testimonials .sngl-client {
  border: 1px solid #fafafa;
  margin-bottom: 30px;
  text-align: center;
  margin: 5px;
}

.h18.testimonials .single-testimonial {
  display: inline-block;
  padding: 0 10px;
  width: 100%;
}

.h18.testimonials .clinet-inner {
  margin-top: 20px;
}

.h18.testimonials .client-info h2 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
}

.h18.testimonials .client-info span {
  color: #fff;
  font-size: 13px;
  display: inline-block;
  text-transform: uppercase;
}

.h18.testimonials .client-comment span::before {
  height: 1px;
  background: #4cc700;
  width: 40px;
  content: "";
  position: absolute;
  bottom: 0;
}

.h18.testimonials .client-comment h3 {
  color: #4cc700;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: normal;
  font-style: italic;
}

.h18.testimonials .client-comment {
  background-color: #f55d2c;
  padding: 20px 20px 20px 20px;
  position: relative;
  font-style: italic;
  border-radius: 10px;
  color: #fff;
}

.h18.testimonials .client-comment p {
  margin-bottom: 0;
}

.h18.testimonials .client-comment::after {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 16px solid #f55d2c;
  content: "";
  height: 0;
  left: 12px;
  position: absolute;
  width: 0;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  bottom: -16px;
}

.h18.testimonials .client-comment::before {
  color: #fff;
  content: "\f10d";
  font-family: fontawesome;
  font-size: 71px;
  left: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
  opacity: 0.20;
  font-style: normal;
}

.h18.testimonials .client-thumb img {
  width: 90px;
  border-radius: 50%;
  margin: 0 auto;
}

.h18.testimonials .client-reviews i {
  color: #e54242;
  font-size: 13px;
}

.h18.testimonials .client-comment > ul {
  padding-bottom: 15px;
}

.h18.testimonials .owl-dots {
  text-align: center;
  margin-top: 3rem;
  line-height: 0;
}

.h18.testimonials .owl-dot {
  border-radius: 50px;
  height: 10px;
  width: 10px;
  display: inline-block;
  background: #fff;
  margin-left: 5px;
  margin-right: 5px;
}

.h18.testimonials .owl-dot.active {
  background: #f55d2c;
}

.h18.testimonials .sec-title {
  text-align: center;
  padding-bottom: 2.5rem;
}

.h18.testimonials .sec-title h2 {
  font-size: 28px;
  color: #f55d2c;
}

.h18.testimonials .sec-title h2 span {
  position: relative;
  color: #fff;
}

.h18.testimonials .sec-title h2 span::before {
  position: absolute;
  width: 50px;
  height: 1px;
  background: #f55d2c;
  top: 18px;
  content: "";
  left: -60px;
}

.h18.testimonials .sec-title p {
  margin-top: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  max-width: 550px;
}

.home18 .sec-title {
  text-align: center;
  padding-bottom: 2.5rem;
}

.home18 .sec-title h2 {
  font-size: 28px;
  color: #f55d2c;
}

.home18 .sec-title h2 span {
  position: relative;
  color: #111;
}

.home18 .sec-title h2 span::before {
  position: absolute;
  width: 50px;
  height: 1px;
  background: #f55d2c;
  top: 18px;
  content: "";
  left: -60px;
}

.home18 .sec-title p {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  max-width: 550px;
}

/*----------------------------------------------*/
/*--------- 40. HEADER SLIDER HOME 19 ----------*/
/*----------------------------------------------*/
.slide-left-social {
  position: absolute;
  bottom: 30px;
  left: 40px;
  z-index: 20;
}

.slide-left-social ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.slide-left-social ul li {
  float: left;
  margin-right: 60px;
  position: relative;
}

.slide-left-social ul li:last-child {
  margin-right: 0px;
}

.slide-left-social ul li:after {
  content: '';
  position: absolute;
  width: 36px;
  height: 1px;
  right: -46px;
  top: 50%;
  margin-top: 1px;
  background-color: #000;
}

.slide-left-social ul li:last-child:after {
  display: none;
}

.slide-left-social ul li a {
  color: #000;
  font-size: 10px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

@media (max-width: 778px) {
  .slide-left-social {
    display: none;
  }
}
/*----------------------------------------------*/
/*--------- 41. HEADER SEARCH HOME 19 ----------*/
/*----------------------------------------------*/
.int_white_bg.h19 .search-2 .form-group .list li.selected:hover,
.int_white_bg.h19 .search-2 .form-group .list li:hover,
.int_white_bg.h19 .search-2 .form-group .btn,
.int_white_bg.h19 .section-title:before,
.int_white_bg.h19 .btn.btn-outline-light,
.int_white_bg.h19 .services-home .bg-base,
.int_white_bg.h19 .listing-hidden-content,
.int_white_bg.h19 .plan-price,
.int_white_bg.h19 .plan a.btn,
.int_white_bg.h19 .go-up {
  background: #293046;
}

.int_white_bg.h19 a.button.border,
a.button {
  background: #293046;
}

.int_white_bg.h19 .dark-overlay h1,
.int_white_bg.h19 .search-2 .form-group .nice-select i,
.int_white_bg.h19 .section-title h2 {
  color: #293046;
}

.int_white_bg.h19 .dark-overlay h1 span {
  color: #fff;
}

.int_white_bg.h19 .search-2 .form-group .nice-select:hover {
  color: #293046;
}

.int_white_bg.h19 .services-home .border-1,
.int_white_bg.h19 .plan,
.int_white_bg.h19 .plan ul li {
  border: 1px dashed #293046 !important;
}

.int_white_bg.h19 .services-home a.text-base:hover {
  color: #293046;
}

.int_white_bg.h19 .listing-item.compact li {
  color: #fff;
}

.int_white_bg.h19 .team-wrap:hover .team-content .team-info {
  background: #293046;
}

.int_white_bg.h19 .team .team-pro .fa {
  background: #212529;
}

.int_white_bg.h19 .plan a.btn.pro2 {
  background: #293046;
}

.int_white_bg.h19 .first-footer .navigation h3::after,
.int_white_bg.h19 .first-footer .widget h3::after,
.int_white_bg.h20 .first-footer .newsletters h3::after {
  background: #293046;
}

.int_white_bg.h19 .navigation ul li a:hover,
.int_white_bg.h19 .twitter-widget.contuct .twitter-area .text h5 a,
.int_white_bg.h19 .twitter-widget.contuct .twitter-area .text h4 {
  color: #293046;
}

.int_white_bg.h19 .first-footer .bloq-email .email input[type="email"],
.int_white_bg.h19 .first-footer .bloq-email .email input[type="submit"] {
  border: 1px solid #293046;
}

.int_white_bg.h19 #navigation.style-1.black ul li:hover {
  background-color: #293046;
  color: #293046;
}

.int_white_bg.h19 .plan-price:after {
  border-top-color: #293046;
}

.int_white_bg.h19 .plan ul li:first-child {
  border-top: 0 !important;
}

.int_white_bg.h19 .plan ul li:last-child {
  border-bottom: 0 !important;
}

.int_white_bg.h19 .header-user-name,
.int_white_bg.h19 .header-user-name:before {
  color: #fff;
}

.int_white_bg.h19 #navigation.style-2.cloned .header-user-name,
.int_white_bg.h19 #header.cloned.sticky .header-user-name,
.int_white_bg.h19 #navigation.style-2.cloned .header-user-name:before,
.int_white_bg.h19 #header.cloned.sticky .header-user-name:before {
  color: #666;
}

.h19 .sec-title {
  text-align: center;
  padding-bottom: 2rem;
}

.h19 .sec-title h2 {
  color: #111;
  font-weight: 600;
  text-transform: capitalize;
}

.h19 .sec-title h2 span {
  position: relative;
  color: #444;
  font-weight: 500;
}

.h19 .sec-title p {
  margin-top: 13px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  max-width: 550px;
}

/*---------------------------------------------*/
/*--------- 42. HEADER VIDEO HOME 20 ----------*/
/*---------------------------------------------*/
.int_dark_bg.h20 a.button.border,
a.button,
.int_dark_bg.h20 .search-2 .form-group .list li.selected:hover,
.int_dark_bg.h20 .search-2 .form-group .list li:hover,
.int_dark_bg.h20 .search-2 .form-group .btn,
.int_dark_bg.h20 .section-title:before,
.int_dark_bg.h20 .btn.btn-outline-light,
.int_dark_bg.h20 .services-home .bg-base,
.int_dark_bg.h20 .listing-hidden-content,
.int_dark_bg.h20 .plan-price,
.int_dark_bg.h20 .plan a.btn,
.int_dark_bg.h20 .go-up {
  background: #f5b200;
}

.int_dark_bg.h20 .dark-overlay h1,
.int_dark_bg.h20 .search-2 .form-group .nice-select i,
.int_dark_bg.h20 .section-title h2 {
  color: #f5b200;
}

.int_dark_bg.h20 .dark-overlay h1 span,
.int_dark_bg.h20 .blog-section .action-list,
.int_dark_bg.h20 .blog-section .admin p {
  color: #fff;
}

.int_dark_bg.h20 .blog-section .news-link {
  color: #f5b200;
}

.int_dark_bg.h20 .blog-section .news-item .date {
  color: #fff;
}

.int_dark_bg.h20 .search-2 .form-group .nice-select:hover,
.int_dark_bg.h20 .blog-section .news-item h3:hover {
  color: #f5b200;
}

.int_dark_bg.h20 .services-home .border-1,
.int_dark_bg.h20 .plan,
.int_dark_bg.h20 .plan ul li {
  border: 1px dashed #f5b200 !important;
}

.int_dark_bg.h20 .services-home a.text-base:hover {
  color: #f5b200;
}

.int_dark_bg.h20 .listing-item.compact li {
  color: #fff;
}

.int_dark_bg.h20 .team-wrap:hover .team-content .team-info {
  background: #f5b200;
}

.int_dark_bg.h20 .team .team-pro .fa {
  background: #212529;
}

.int_dark_bg.h20 .plan a.btn.pro2 {
  background: #282d32;
}

.int_dark_bg.h20 .first-footer .navigation h3::after,
.int_dark_bg.h20 .first-footer .widget h3::after,
.int_dark_bg.h20 .first-footer .newsletters h3::after {
  background: #f5b200;
}

.int_dark_bg.h20 .navigation ul li a:hover,
.int_dark_bg.h20 .twitter-widget.contuct .twitter-area .text h5 a,
.int_dark_bg.h20 .twitter-widget.contuct .twitter-area .text h4 {
  color: #f5b200;
}

.int_dark_bg.h20 .first-footer .bloq-email .email input[type="email"],
.int_dark_bg.h20 .first-footer .bloq-email .email input[type="submit"] {
  border: 1px solid #f5b200;
}

.int_dark_bg.h20 #navigation.style-1.black ul li:hover {
  background-color: #f5b200;
  color: #f5b200;
}

.int_dark_bg.h20 .plan-price:after {
  border-top-color: #f5b200;
}

.int_dark_bg.h20 .plan ul li:first-child {
  border-top: 0 !important;
}

.int_dark_bg.h20 .plan ul li:last-child {
  border-bottom: 0 !important;
}

.h20 .first-footer .bloq-email .email input[type="submit"] {
  margin-top: 1.5rem;
}

@media screen and (max-width: 991px) {
  .int_dark_bg.h20 .fullwidth-home-map.dark-overlay video {
    height: 115vh !important;
  }

  .h20 .first-footer .bloq-email .email input[type="submit"] {
    margin-top: 0;
  }
}
/*---------------------------------------*/
/*--------- 43. HOME 23 Image ----------*/
/*---------------------------------------*/
.homepage-23 .head-tr.bottom {
  background: transparent;
  border: 0px;
}

.homepage-23 a.button.border,
a.button {
  background: #fff;
  color: #274abb !important;
}

.homepage-23 .header-user-name,
.homepage-23 .header-user-name:before {
  color: #fff;
}

.homepage-23 #navigation.style-2.cloned .header-user-name,
.homepage-23 #header.cloned.sticky .header-user-name,
.homepage-23 #navigation.style-2.cloned .header-user-name:before,
.homepage-23 #header.cloned.sticky .header-user-name:before {
  color: #666;
}

.homepage-23 #navigation.style-1 ul li:hover {
  background: #274abb;
}

.homepage-23 .welcome-area {
  background: linear-gradient(-36deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url(../images/bg/bg-23.jpg) no-repeat scroll center center/cover;
}

.homepage-23 .welcome-area {
  height: 100vh;
  position: relative;
  z-index: 99;
}

.homepage-23 .banner-inner h1 {
  margin-bottom: 15px;
}

.homepage-23 .banner-inner .sub-title {
  margin-bottom: 60px;
}

.homepage-23 .bg-overlay, .overlay-dark {
  position: relative;
  z-index: 0;
}

.homepage-23 .hero-main {
  padding: 315px 0;
}

@media (max-width: 550px) {
  .homepage-23 .hero-main {
    padding: 160px 0;
  }

  .homepage-23 .banner-inner h1 {
    font-size: 22px;
  }

  .homepage-23 .banner-inner .sub-title {
    font-size: 15px;
  }
}
.homepage-23 .btn, .homepage-3 .btn:active,
.homepage-23 .btn.sApp-btn:before,
.homepage-23 .bg-overlay::after,
.homepage-23 .benifits-item:after {
  background: linear-gradient(-47deg, #274abb 0%, #274abb 100%);
}

.homepage-23 a.btn.btn-yellow:hover {
  background: #fff !important;
  color: #274abb;
}

.homepage-23 .bg-overlay::after {
  opacity: 0.87;
  z-index: -1;
}

.homepage-23 .rld-banner-tab .nav-item a.active,
.homepage-23 .rld-banner-tab .nav-item a:hover,
.homepage-23 .rld-banner-tab .nav-item a.active:before,
.homepage-23 .range-slider .ui-widget-header,
.homepage-23 .checkboxes input[type=checkbox]:checked + label:before {
  background: #274abb;
}

.homepage-23 .parallax-searchs .form-group .nice-select i,
.homepage-23 .dropdown-filter span::after {
  color: #274abb;
}

.homepage-23 .parallax-searchs .form-group .nice-select:hover {
  color: #274abb;
}

.homepage-23 .rld-banner-tab .nav-item a {
  border-radius: 8px;
}

.homepage-23 .range-slider .ui-slider .ui-slider-handle {
  border: 2px solid #274abb;
}

.homepage-23 .rld-single-select .single-select:before {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 8px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: 48%;
  -webkit-transform-origin: 66% 66%;
          transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
}

.homepage-23 .rld-single-input input {
  border-radius: 8px;
  margin-left: 0px;
  outline: none;
  text-decoration: none;
}

.homepage-23 .rld-single-select .single-select {
  width: 180px;
}

@media (min-width: 992px) {
  .homepage-23 .rld-main-search .rld-single-input, .homepage-3 .rld-main-search .rld-single-select .single-select, .homepage-23 .popular-searchs {
    margin-bottom: 0px;
  }
}
@media (max-width: 575px) {
  .homepage-23 .popular-searchs {
    display: none !important;
  }
}
.homepage-23 .popular-searchs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 40px;
}

.homepage-23 .popular-searchs li {
  display: inline-block;
}

.homepage-23 .popular-searchs li a {
  display: inline-block;
  text-decoration: none;
  width: 115px;
  height: 100px;
  margin-right: 15px;
  background: rgba(255, 255, 255, 0.12);
  padding-top: 11px;
  color: #ffffff;
  font-size: 28px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-transition: all ease .5s;
  transition: all ease .5s;
}

.homepage-23 .popular-searchs .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.homepage-23 .popular-searchs li a .box span {
  font-size: 16px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
  font-weight: 400;
  display: block;
}

.homepage-23 img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.homepage-23 .portfolio .price-properties {
  padding-bottom: 0px;
}

/*--------------------------------------------*/
/*--------- 44. HEADER IMAGE HOME 24 ----------*/
/*--------------------------------------------*/
.homepage-24 .header-image {
  background: url(../images/bg/bg-home-24.jpg) no-repeat center bottom;
  height: 90vh;
  border-bottom: 1px solid #e5e5e5;
}

.homepage-24 #header {
  -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}

.homepage-24 .header-image .left h1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 60px;
  color: #333;
  margin: 0;
  margin-bottom: 20px;
}

.homepage-24 .image-24 {
  padding-bottom: 190px;
}

.homepage-24 .header-image .left p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: #666;
  margin: 0;
}

.homepage-24 .header-image .left .btn-white {
  background: #FF385C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 35px;
  border-radius: 40px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  -webkit-box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.15);
  margin-right: 60px;
}

.homepage-24 .header-user-name,
.homepage-24 .header-user-name:before {
  color: #666;
}

.homepage-24 .portfolio .homes-content .homes-list li {
  width: 33.3%;
}

.homepage-24 .portfolio.full-l .project-head {
  border-radius: 3px 0 0 3px;
  max-height: 220px;
  max-width: -webkit-fill-available;
  margin: 0;
  position: relative;
  width: 300px;
}

.homepage-24 section.request {
  padding: 6rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.3)), to(rgba(255, 255, 255, 0.3))), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
  background-size: cover;
  position: relative;
  z-index: 3;
  color: #fff;
}

.homepage-24 .request h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff;
}

.homepage-24 .request h3 {
  margin-bottom: 2rem;
}

.homepage-24 .request h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.homepage-24 .successform, .hp1 .errorform {
  display: none;
  height: 70px;
}

.homepage-24 .request .contact-form input[type="text"] {
  height: 50px;
}

.homepage-24 .request .form-control {
  background: #fff;
  border: 1px solid #ebebeb;
}

.homepage-24 .request .contact-form textarea {
  min-height: 140px;
  max-height: 100px;
}

.homepage-24 .request .btn {
  cursor: pointer;
  margin-top: 1rem;
  border: 0px;
  background: #FF385C;
}

.homepage-24 .bgc {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 43, 43, 0.8)), to(rgba(43, 43, 43, 0.8))), url(../images/bg/bg-testimonials.jpg) no-repeat center center;
  background: linear-gradient(rgba(43, 43, 43, 0.8), rgba(43, 43, 43, 0.8)), url(../images/bg/bg-testimonials.jpg) no-repeat center center;
  padding: 2rem;
  background-size: cover;
}

.homepage-24 .request h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4rem;
  color: #fff;
  margin-bottom: 2rem;
}

.homepage-24 .call-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.homepage-24 .request ul li {
  list-style: none;
}

.homepage-24 .call-info ul li .fa {
  font-size: 1.6rem;
  color: #FF385C;
}

.homepage-24 .call-info .info p {
  margin: 0px;
  margin-left: 1.5rem;
  font-weight: 300;
}

.homepage-24 .call-info .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
}

.homepage-24 .play-btn {
  position: relative;
  display: inline-block;
  height: 100px;
  width: 100px;
  background-color: #FF385C;
  border-radius: 50%;
  margin-top: 35px;
}

.homepage-24 .btn-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #FF385C;
}

.homepage-24 .teh-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 0px;
}

.homepage-24 .play-icon {
  color: #fff;
}

.homepage-24 .visited-cities .img-box {
  margin-bottom: 0px;
}

@media (max-width: 991.98px) {
  .homepage-24 .header-image .left {
    margin-top: 0px;
    margin-bottom: 80px;
  }

  .homepage-24 .portfolio.full-l .project-head {
    width: 100%;
  }

  .homepage-24 .header-image .left h1 {
    font-size: 26px;
  }

  .homepage-24 .header-image {
    padding: 500px 30px;
  }

  .homepage-24 .bgc {
    margin-top: 3rem;
  }

  .homepage-24 .request h3 {
    font-size: 1.2rem;
  }

  .homepage-24 .teh-left {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .homepage-24 .visited-cities .img-box {
    margin-bottom: 20px;
  }

  .homepage-24 .visited-cities .img-box.no-mb {
    margin-bottom: 0px;
  }

  .homepage-24 .team .team-pro.no-mb {
    padding-bottom: 0px;
  }

  .homepage-24 .image-24 {
    padding-bottom: 0px;
  }
}
@media (max-width: 576px) {
  .homepage-24 .header-image .left {
    margin-top: 0px;
    margin-bottom: 80px;
  }

  .homepage-24 .header-image .left h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .homepage-24 .header-image {
    padding: 100px 30px;
  }

  .homepage-24 .visited-cities .img-box.no-mb.sin {
    margin-bottom: 0px !important;
  }

  .homepage-24 .team .team-pro.no-mb.sin {
    padding-bottom: 2.5rem !important;
  }
}
/*-----------------------------------*/
/*- 45. INNER PAGE PROPERTIES LIST --*/
/*-----------------------------------*/
.inner-pages .blog .block-heading {
  background: #f9f9f9;
  border-radius: 3px;
  height: 52px;
  margin-bottom: 30px;
}

.inner-pages .properties-list.full .project-single {
  border-bottom: 0px;
}

.inner-pages .properties-list.full .my-44 {
  margin: 2.5rem 0;
}

.agents.list .blog .homes-content .homes-list {
  padding-bottom: 2rem;
}

.inner-pages.hp-6.full .tab-content > .active {
  width: 100%;
  margin-bottom: 30px;
}

.inner-pages.hp-6.full .rld-main-search {
  margin-right: 0px;
}

.inner-pages.hp-6.full .rld-single-input input {
  width: 309px;
  background: #fff;
}

.inner-pages.hp-6.full .rld-main-search {
  padding: 30px 0px 30px 20px;
  background: #f5f7fb;
  border: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.inner-pages.hp-6.full .rld-main-search .single-select,
.inner-pages.hp-6.full .dropdown-filter span,
.inner-pages.hp-6.full .form-group .nice-select {
  background: #fff;
}

.inner-pages.hp-6.full .form-group .nice-select {
  border: 1px solid #ebebeb;
}

.inner-pages.hp-6.full .tab-content > .active {
  margin-top: 0px;
}

.inner-pages.hp-6.full .explore__form-checkbox-list.full-filter.filter-block {
  margin-top: 2px;
  background: #fff;
}

.inner-pages.hp-6.full .main-search-field-2 {
  margin-top: 0px;
}

.inner-pages.hp-6.full .parallax-searchs .form-group .nice-select:hover,
.inner-pages.hp-6.full .dropdown-filter span::after,
.inner-pages.hp-6.full .btn.btn-yellow:hover {
  color: #FF385C;
}

.inner-pages.hp-6.full .btn.btn-yellow {
  background: #FF385C;
}

.inner-pages.hp-6.full .nice-select.wide {
  margin-top: 0px;
}

.inner-pages.hp-6.full .single.detail-wrapper {
  z-index: 8;
}

@media only screen and (max-width: 1024px) {
  .inner-pages.hp-6.full .rld-single-input input {
    width: 160px;
  }
}
@media screen and (max-width: 768px) {
  .inner-pages.hp-6.full .dropdown-filter span {
    margin: 0px 15px 0 0;
  }
}
@media only screen and (max-width: 450px) {
  .inner-pages.hp-6.full .rld-single-input input {
    width: 100% !important;
  }

  .inner-pages.hp-6.full .rld-main-search {
    padding-right: 20px;
  }
}
@media screen and (max-width: 991px) {
  .inner-pages .properties-list.full .my-44.ft {
    margin: 0px;
  }

  .inner-pages.hp-6.full .rld-single-input input {
    width: 200px;
  }

  .inner-pages .properties-list.full .pr-0 {
    padding-left: 0px;
  }

  .inner-pages .properties-list.full .col-lg-8 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .inner-pages .properties-list.full .mb-44 {
    margin-bottom: 2.5rem !important;
  }

  .inner-pages .properties-list.full .row.featured {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 575px) {
  .agents.list .pagination.lis-view {
    margin-top: 0px;
  }
}
/*-------------------------------------------------*/
/*- 46. INNER PAGE PROPERTIES LIST RIGHT SIDEBAR --*/
/*-------------------------------------------------*/
.inner-pages .blog .block-heading {
  background: #f9f9f9;
  border-radius: 3px;
  height: 52px;
  margin-bottom: 30px;
}

.inner-pages .properties-right.list .homes-img img {
  height: 272px;
}

.inner-pages .properties-right.list .project-single {
  border-bottom: 0px;
}

.inner-pages .properties-right.list .my-44 {
  margin: 2.5rem 0;
}

@media screen and (max-width: 991px) {
  .inner-pages .properties-right.list .my-44.ft {
    margin: 0px;
  }

  .inner-pages .properties-right.list .homes-img img {
    height: auto;
  }

  .inner-pages .properties-right.list .pr-0 {
    padding-left: 8px;
  }

  .inner-pages .properties-right.list .col-lg-8 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .inner-pages .properties-right.list .mb-44 {
    margin-bottom: 2.5rem !important;
  }

  .inner-pages .properties-right.list .row.featured {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/*-----------------------------------*/
/*- 47. INNER PAGE PROPERTIES GRID --*/
/*-----------------------------------*/
.inner-pages .blog .block-heading {
  background: #fff;
  border-radius: 3px;
  height: 52px;
  margin-bottom: 30px;
}

.inner-pages section.portfolio {
  background: #f5f7fb;
}

.inner-pages .blog .block-heading h4 {
  line-height: 52px;
  color: #121B22;
  display: inline-block;
  padding-right: 20px;
  border-radius: 3px 0 0 3px;
  margin: 0 0 0;
  font-weight: 600;
}

.inner-pages .blog .space-3 {
  margin-bottom: 1.6rem;
}

.inner-pages .blog .block-heading .heading-icon {
  background: #121B22;
  width: 50px;
  text-align: center;
  margin-right: 8px;
  display: inline-block;
  border-radius: 3px 0 0 3px;
  position: relative;
  color: #fff;
}

.inner-pages .project-single .homes-content .homes-list li i {
  font-size: 18px;
}

.inner-pages .project-single .fa-user,
.inner-pages .project-single .fa-calendar {
  color: #666 !important;
}

.inner-pages .portfolio .homes-content .homes-list li {
  width: 45% !important;
  line-height: 40px;
}

.inner-pages .portfolio .homes-content {
  -webkit-box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
          box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
}

.properties-list .item {
  padding-bottom: 1.6rem;
}

.inner-pages .w-auto {
  width: auto !important;
}

.inner-pages .letter-spacing-093 {
  font-weight: 600;
  letter-spacing: .93px !important;
}

.inner-pages .input-group-lg > .form-control {
  padding: .5rem 1rem .5rem .5rem !important;
  font-size: 15px;
  line-height: 1;
  margin-top: 7px;
  border-radius: 3px;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.inner-pages .cod-pad.single.detail-wrapper .nice-select:after {
  height: 8;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 37%;
}

.inner-pages .pro-wrapper .detail-wrapper-body p {
  line-height: 1;
}

.inner-pages .nice-select.open .list {
  overflow: visible;
  z-index: 99999;
}

@media screen and (max-width: 1024px) {
  .single.detail-wrapper.grid {
    margin-left: 10rem;
  }

  .inner-pages.half-map-view .single.detail-wrapper.grid {
    margin-left: 4rem;
  }
}
@media screen and (max-width: 450px) {
  .single.detail-wrapper {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .inner-pages .letter-spacing-093 {
    padding-left: 0px !important;
  }

  .inner-pages .pro-wrapper .detail-wrapper-body p {
    margin-bottom: 30px !important;
  }

  .single.detail-wrapper.grid {
    margin-left: 0px;
  }

  .inner-pages.half-map-view .single.detail-wrapper.grid {
    margin-left: 0px;
  }

  .inner-pages .pro-wrapper .detail-wrapper-body p.pb-2 {
    margin-bottom: 0.75rem !important;
  }
}
@media screen and (max-width: 767px) {
  .inner-pages .blog .item {
    padding-bottom: 1.6rem;
  }

  .inner-pages .portfolio.blog .item.no-pb.x2 {
    padding-bottom: 0px;
  }
}
.inner-pages .blog .block-heading .sorting {
  padding: 8px 12px;
  width: auto;
  background: #FFF;
  border: 1px solid #EEE;
  color: #999;
  margin-right: 5px;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 300;
  outline: none;
}

@media screen and (max-width: 380px) {
  .inner-pages .blog .block-heading .sorting {
    float: left;
    width: 120px;
  }
}
.inner-pages .blog .block-heading .sorting-options {
  margin: 10px 15px 0 0;
  text-align: right;
}

.inner-pages .blog .block-heading .sorting {
  font-size: 13px;
  font-weight: 300;
  padding: 5px 12px;
  border: solid 1px #e7e7e7;
}

.inner-pages .blog .block-heading a {
  text-decoration: none;
}

.inner-pages .blog .block-heading .change-view-btn.lde .fa {
  color: #121B22 !important;
}

.inner-pages .blog .block-heading .change-view-btn.lde:hover {
  background: #FF385C;
  color: #fff !important;
}

.inner-pages .blog .sorting-options a .active-view-btn .fa {
  color: #fff !important;
}

.inner-pages .blog .change-view-btn {
  color: #121B22 !important;
  border: 1px solid #121B22;
  margin-left: 5px;
  border-radius: 2px;
  background: transparent;
  padding: 5px 9px;
}

.inner-pages .change-view-btn:hover {
  color: #FF385C !important;
}

.inner-pages .active-view-btn {
  background: #121B22 !important;
  color: #fff !important;
  margin-left: 5px;
  padding: 5px 9px;
}

.inner-pages .blog .active-view-btn:hover {
  background: #FF385C !important;
  color: #fff;
}

.inner-pages .blog .block-heading .fa {
  color: #fff;
  margin-right: 0px;
}

.inner-pages .blog .homes .fa {
  color: #FF385C;
}

.inner-pages .properties-list .compare,
.inner-pages .properties-right .compare {
  margin-top: 1.1rem !important;
}

.inner-pages .properties-right .fa-heart-o {
  color: #666 !important;
}

.inner-pages .blog .single-block {
  padding: 3rem 0;
}

.inner-pages .blog .section-heading .media .media-left i {
  background: #FF385C;
  color: #fff;
  width: 47px;
  height: 47px;
  line-height: 47px;
  text-align: center;
  font-size: 25px;
  float: left;
}

.inner-pages .blog .section-heading .media .media-left {
  padding-right: 15px;
}

.inner-pages .blog .section-heading .media .media-body h5::after {
  margin-bottom: 16px;
}

.inner-pages .blog .section-heading .media .media-body p {
  margin: 0 0 0;
  color: #666;
  line-height: 0;
}

.inner-pages .blog .main-search-field h5,
.inner-pages .blog .main-search-field-2 h5,
.inner-pages .blog .section-heading .media .media-body h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-transform: capitalize;
}

.inner-pages .blog .main-search-field {
  background: #fff;
  padding-bottom: 30px;
}

.inner-pages .blog .main-search-field select {
  background: #fff;
  border: 2px solid #e2dfdf;
  color: #333;
}

.inner-pages .blog .main-search-field .at-col-default-mar {
  margin-bottom: 0px;
}

.inner-pages .blog .at-input {
  width: 100%;
  height: 45px;
  padding: 5px 10px;
  color: #000;
  border: 2px solid #e2dfdf;
  border-radius: 0;
  background: #fff;
}

@media screen and (max-width: 991px) {
  .inner-pages .blog .at-input {
    margin-bottom: 0px;
  }
}
.inner-pages .blog .col-lg-12.no-pds {
  padding-left: 0px;
  padding-right: 0px;
}

.inner-pages .blog .slider_amount {
  color: #000;
  font-weight: 400;
}

.inner-pages .blog .ui-widget.ui-widget-content {
  background: #FF385C;
}

.inner-pages .blog .hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-top: 2rem;
  font-size: 14px;
  cursor: pointer;
  background: #FF385C;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  padding: 12px 22px;
  border-radius: 0;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .blog .hvr-bounce-to-right:hover {
  background: #121B22;
}

.inner-pages.homepage-4 .first-footer .navigation h3::after,
.inner-pages.homepage-4 .first-footer .widget h3::after,
.inner-pages.homepage-4 .first-footer .newsletters h3::after,
.inner-pages.homepage-4 .first-footer .bloq-email .email input[type="submit"],
.inner-pages.homepage-4 .go-up {
  background: #FF385C;
}

.inner-pages.homepage-4 #navigation.style-1 ul li:hover {
  background: #FF385C;
}

@media screen and (min-width: 992px) {
  .portfolio .item.sale.h-177.no-pb, .ho17-thelast.no-pb, .rent.h-17.no-pb {
    padding-bottom: 0px !important;
  }
}
/* More Search Options
------------------------------------- */
.inner-pages .more-search-options {
  opacity: 0;
  visibility: hidden;
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 30px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  z-index: 19;
  -webkit-transform: translate3d(0, -20px, 0);
          transform: translate3d(0, -20px, 0);
}

.inner-pages .more-search-options.relative {
  position: relative;
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: none;
  transition: none;
  display: none;
}

.inner-pages .more-search-options.relative.active {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.inner-pages .more-search-options.relative .more-search-options-container {
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin: 20px 0 5px 0;
}

/* Custom box*/
.inner-pages .custom-box {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
}

.inner-pages .custom-box .checkboxes {
  padding: 20px 30px 25px 30px;
}

.inner-pages .more-search-options:before {
  position: absolute;
  top: -10px;
  width: 100%;
  content: "";
  background-color: #fff;
  height: 10px;
  display: inline-block;
  z-index: 101;
}

.inner-pages .more-search-options.relative:before {
  display: none;
}

.inner-pages .more-search-options.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
}

.inner-pages .more-search-options-container {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  padding: 30px 30px 25px 30px;
  padding-top: 0;
  border-radius: 0 0 3px 3px;
}

/* Trigger */
.inner-pages .more-search-options-trigger {
  font-size: 15px;
  margin: 5px 0 2px 0;
  display: inline-block;
}

.inner-pages .more-search-options-trigger:after {
  content: attr(data-open-title);
}

.inner-pages .more-search-options-trigger.active:after {
  content: attr(data-close-title);
}

.inner-pages .more-search-options-trigger:before {
  content: "\f055";
  font-family: "FontAwesome";
  padding-right: 7px;
}

.inner-pages .more-search-options-trigger.active:before {
  content: "";
}

.inner-pages .widget .more-search-options-trigger {
  font-size: 16px;
}

.inner-pages .widget .more-search-options-trigger {
  font-size: 16px;
}

.inner-pages .margin-bottom-10 {
  margin-bottom: 10px !important;
}

.inner-pages .margin-top-30 {
  margin-top: 30px !important;
}

.inner-pages a.more-search-options-trigger {
  text-decoration: none;
  color: #FF385C;
}

.inner-pages .form-group.looking {
  width: 100%;
  margin-top: 30px;
}

.inner-pages.listing .form-group {
  width: 100%;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 0px;
}

.inner-pages .first-select {
  height: 30px;
  border-radius: 5px;
  margin-right: 4px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.inner-pages .main-search-input-item {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 50px;
  position: relative;
}

.inner-pages .form-group.looking input {
  float: left;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  padding-left: 20px;
}

.inner-pages .form-group .nice-select {
  margin: 0px;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 15px;
}

.inner-pages .form-group .nice-select i {
  color: #777;
  font-size: 1.1rem;
  margin-top: 5px;
  margin-right: 10px;
}

.inner-pages .form-group.categories {
  width: 100%;
}

.inner-pages .nice-select.wide {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
}

.inner-pages .form-group.location {
  width: 100%;
}

.inner-pages .form-group.beds,
.inner-pages .form-group.bath {
  width: 100%;
}

.inner-pages .main-search-field-2 {
  margin-top: 20px;
}

/* Range Slider
------------------------------------- */
.inner-pages .range-slider label {
  color: #333;
  font-size: 16px;
  margin-bottom: 25px;
}

.inner-pages .range-slider .ui-slider {
  position: relative;
  text-align: left;
}

.inner-pages .range-slider .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #FF385C;
  cursor: w-resize;
  background: #fff;
  outline: none;
  top: -8px;
  margin-left: -8px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.inner-pages .range-slider .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
  background-position: 0 0;
}

.inner-pages .range-slider .ui-widget-content {
  background-color: #e8e8e8 !important;
}

.inner-pages .range-slider .ui-widget-header {
  background-color: #FF385C;
}

.inner-pages .range-slider .ui-slider.ui-state-disabled .ui-slider-handle,
.inner-pages .range-slider .ui-slider.ui-state-disabled .ui-slider-range {
  -webkit-filter: inherit;
          filter: inherit;
}

.inner-pages .range-slider .ui-slider-horizontal {
  height: 3px;
  border-radius: 10px;
  width: calc(100% - 16px);
  margin-left: 8px;
}

.inner-pages .range-slider .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.inner-pages .range-slider .ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.inner-pages .range-slider .ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.inner-pages input.first-slider-value:focus,
.inner-pages input.second-slider-value:focus,
.inner-pages input.first-slider-value,
.inner-pages input.second-slider-value {
  width: 40%;
  display: inline-block;
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 12px 0 0 0;
  cursor: default;
}

.inner-pages input.first-slider-value {
  float: left;
  margin-left: -8px;
  width: 40%;
}

.inner-pages input.second-slider-value {
  float: right;
  width: 40%;
  text-align: right;
  margin-right: -8px;
}

.inner-pages .range-slider {
  line-height: 37px;
}

/*----------------------------------- */
/* Custom Checkboxes
------------------------------------- */
.inner-pages .checkboxes {
  list-style: none;
  padding: 0;
}

.inner-pages .checkboxes.in-row label {
  margin-top: 9px;
}

.inner-pages .checkboxes.one-in-row label {
  margin-top: 9px;
  width: 100%;
}

.inner-pages .checkboxes li {
  padding: 4px 0;
}

.inner-pages .checkboxes li:last-child {
  margin-bottom: -8px;
}

.inner-pages .checkboxes label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  margin-right: 20px;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
  color: #666;
  font-size: 14.5px;
}

.inner-pages .checkboxes label span {
  color: #909090;
  font-size: 15px;
}

.inner-pages .checkboxes input[type=checkbox] {
  display: none;
}

.inner-pages .checkboxes label:before {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 3px;
  background-color: #fff;
  border: 2px solid #d0d0d0;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.inner-pages .checkboxes input[type=checkbox]:checked + label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 15px;
  background-color: #FF385C;
  border: 2px solid transparent;
  -webkit-transition: border-color 0s;
  transition: border-color 0s;
}

/*---------------------------------------*/
/*- 48. INNER PAGE PROPERTIES MAP --*/
/*---------------------------------------*/
.google-maps-right {
  position: fixed;
  top: 1;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0 !important;
  margin-bottom: 0px;
}

.google-maps-right #map-canvas {
  height: 867px;
}

section.google-map-right {
}

.half-map-view .google-maps-right #map-canvas {
  height: 100vh;
  top: 0;
  bottom: 0;
  padding: 0px;
}

.half-map-view .blog .block-heading {
  padding: 140px 0 30px 0 !important;
}

.half-map-view .headings-2 {
  padding: 130px 0 20px 0;
}

.half-map-view .fx-google-map-right.pl-55 {
  padding-left: 15px;
}

.half-map-view .fx-google-map-right.pl-555 {
  padding-left: 35px;
  padding-right: 35px !important;
}

.half-map-view .mt-5.pagi {
  padding-left: 22px;
  margin-top: 0px !important;
}

.half-map-view .portfolio .homes .button,
.half-map-view .portfolio .homes-price {
  z-index: 1;
}

.header.google-map-right {
  z-index: 99999;
}

.header.google-map-right .header-bottom {
  position: fixed;
  width: 100%;
  z-index: 99999;
}

.half-map-view .rld-single-input input,
.half-map-view .rld-single-select .single-select,
.half-map-view .dropdown-filter span {
  width: 163px !important;
  background: #fff;
}

.half-map-view .rld-main-search {
  background: #f5f6ff;
  border: 0px;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 0px;
}

.half-map-view .tab-content > .active {
  background: #f5f6ff;
  margin-top: 0px;
}

.half-map-view .dropdown-filter span::after {
  color: #FF385C;
}

.half-map-view .btn.btn-yellow {
  background: #FF385C;
}

.half-map-view a.btn.btn-yellow:hover,
.half-map-view .form-group .nice-select:hover {
  color: #FF385C;
}

.half-map-view .explore__form-checkbox-list.full-filter.filter-block {
  margin-top: 1px;
}

.half-map-view .main-search-field-2,
.half-map-view .nice-select.wide {
  margin-top: 0px;
}

@media screen and (max-width: 991px) {
  .google-maps-right {
    position: relative;
    margin-top: 30px;
  }

  .mt-5.pagi {
    display: none;
  }

  .mt-55.pagi {
    display: none;
  }

  .half-map-view .fx-google-map-right.pl-55 {
    padding-left: 15px;
    padding-right: 30px !important;
  }

  .half-map-view .fx-google-map-right.pl-555 {
    padding-left: 15px !important;
    padding-right: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages.half-map-view.list .properties-right.list .my-44 {
    margin: 0px;
  }

  .inner-pages.half-map-view.list .portfolio .homes-content,
  .inner-pages.half-map-view.list .map-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 2.5rem;
  }

  .half-map-view .project-single.ps-1 {
    margin-top: 50px;
  }

  .half-map-view .btn.btn-yellow {
    width: 138px;
  }
}
@media screen and (min-width: 1024px) {
  .inner-pages.half-map-view.list .map-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .half-map-view .rld-single-input input,
  .half-map-view .rld-single-select .single-select,
  .half-map-view .dropdown-filter span {
    width: 163px !important;
  }

  .half-map-view .rld-single-select.ml-22,
  .half-map-view .dropdown-filter {
    margin-left: 15px;
  }

  .half-map-view .rld-single-input input {
    margin-right: 15px;
  }

  .half-map-view .rld-main-search .rld-single-input {
    width: 163px;
  }
}
@media screen and (max-width: 768px) {
  .inner-pages.half-map-view.list .portfolio .homes-content,
  .inner-pages.half-map-view.list .map-list {
    margin-bottom: 0rem;
  }

  .half-map-view .btn.btn-yellow {
    width: 163px;
  }

  .half-map-view .project-single.ps-1 {
    margin-top: 0px;
  }
}
@media screen and (max-width: 767px) {
  .google-maps-right {
    margin-top: 40px;
  }

  .half-map-view .rld-single-select.ml-22,
  .half-map-view .dropdown-filter {
    margin-left: 0px;
  }

  .half-map-view .rld-single-input input,
  .half-map-view .rld-main-search .rld-single-input,
  .half-map-view .rld-main-search .rld-single-select .single-select {
    width: 100% !important;
  }

  .half-map-view .rld-main-search .rld-single-input {
    width: 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .half-map-view .fx-google-map-right.pl-55 {
    padding-right: 15px !important;
  }
}
/*---------------------------------------*/
/*- 49. INNER PAGE PROPERTIES TOP MAP --*/
/*---------------------------------------*/
.header-map.google-maps.properties #map-canvas,
.header-map.google-maps.properties {
  height: 450px;
}

/*---------------------------------------*/
/*--- 50. INNER PAGE PROPERTY DETAILS ---*/
/*---------------------------------------*/
section.single-proper {
  padding: 0 0 6rem 0;
  background: none;
}

.pro-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

section.headings-2 {
  padding: 60px 0 55px 0;
  background: #f5f7fb;
}

.bg-white-inner {
  background: #f5f7fb !important;
}

.single.detail-wrapper {
  margin-left: 15rem;
}

.inner-pages .headings-2 .listing-title-bar h3 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  color: #222;
  text-transform: capitalize !important;
}

.inner-pages .headings-2 .listing-title-bar h4 {
  font-size: 24px;
  font-weight: 600;
  color: #FF385C;
  text-transform: capitalize !important;
}

.inner-pages .headings-2 .listing-title-bar a,
.inner-pages .headings-2 .listing-title-bar a p {
  font-size: 17px;
  text-decoration: none;
  color: #333;
}

.inner-pages .headings-2 span.category-tag {
  font-size: 14px;
  background: #FF385C;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 15px;
  border-radius: 50px;
  top: -3px;
  margin-left: .5rem;
  position: relative;
}

.schedule.widget-boxed.mt-33 {
  margin-top: 60px;
}

.inner-pages .detail-list-rating {
  float: left;
  margin-right: 15px;
}

.inner-pages .detail-list-rating i {
  color: #ffc107;
}

h4.widget_author__name {
  color: #222;
}

.inner-pages .listing-title-bar a {
  text-decoration: none;
  color: #333;
}

.inner-pages .listing-title-bar a:last-child {
  text-decoration: none;
  color: #666;
}

.inner-pages.sgv .header-user-name,
.inner-pages.sgv .header-user-name:before {
  color: #fff;
}

.inner-pages.sgv #navigation.style-2.cloned .header-user-name,
.inner-pages.sgv #header.cloned.sticky .header-user-name,
.inner-pages.sgv #navigation.style-2.cloned .header-user-name:before,
.inner-pages.sgv #header.cloned.sticky .header-user-name:before {
  color: #666;
}

/*-------------Sliders---------------------------------------*/
.listing-details-sliders .list-inline {
  white-space: nowrap;
  width: 100% !important;
}

.listing-details-sliders .carousel-indicators {
  position: static;
  left: initial;
  width: initial;
  margin-left: initial;
  margin-top: 15px;
}

.listing-details-sliders .carousel-indicators a {
  cursor: pointer;
}

.listing-details-sliders .carousel-indicators > li {
  height: initial;
  text-indent: initial;
  max-width: 127px;
  margin-right: 10px;
  margin-left: 0;
}

.listing-details-sliders .carousel-indicators > li:last-child {
  margin-right: 0px;
}

.dd.listing-details-sliders .carousel-indicators > li {
  width: initial;
  height: initial;
  text-indent: initial;
}

.smail-listing .list-inline-item {
  width: 20%;
}

.smail-listing .list-inline-item a img {
  width: 600px;
  border: none;
  border-radius: 0;
}

.elements-page .tab-box p {
  margin-bottom: 0;
}

.listing-details-sliders .carousel-indicators > li.active img {
  opacity: 0.9;
}

.listing-details-sliders .right {
  position: absolute;
  right: 15px;
  top: 45%;
}

.listing-details-sliders .left {
  position: absolute;
  top: 50%;
  left: 35px;
  background: #FF385C;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  z-index: 120;
  cursor: pointer;
  margin-top: -18px;
  -webkit-box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.4);
          box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.4);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.listing-details-sliders .left:hover,
.listing-details-sliders .right:hover {
  background: #121B22;
}

.listing-details-sliders .left i {
  font-size: 23px;
  margin-left: 12px;
  margin-top: 5px;
  color: #fff;
}

.listing-details-sliders .right {
  position: absolute;
  top: 50%;
  right: 35px;
  background: #FF385C;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  z-index: 120;
  cursor: pointer;
  margin-top: -18px;
  -webkit-box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.4);
          box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.4);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.listing-details-sliders .img-fluid {
  max-width: 100%;
  height: auto;
}

.single-proper .mb-30,
.property-location.map {
  margin-bottom: 30px !important;
}

.listing-details-sliders .right i {
  font-size: 23px;
  margin-left: 14px;
  margin-top: 5px;
  color: #fff;
}

.list-inline-item:not(:last-child) {
  margin-right: 1px;
}

div.datedropper.my-style {
  border-radius: 8px;
  width: 180px;
}

div.datedropper.my-style .picker {
  border-radius: 8px;
  -webkit-box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
}

div.datedropper.my-style .pick-l {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

div.datedropper.my-style .pick-lg-b .pick-sl:before,
div.datedropper.my-style .pick-lg-h,
div.datedropper.my-style .pick-m,
div.datedropper.my-style .pick-submit,
div.datedropper.my-style:before {
  background-color: #FF385C;
}

div.datedropper.my-style .pick-l,
div.datedropper.my-style .picker,
div.datedropper.my-style.picker-tiny .pick-m,
div.datedropper.my-style.picker-tiny:before {
  background-color: #FFF;
}

div.datedropper.my-style .pick li span,
div.datedropper.my-style .pick-btn,
div.datedropper.my-style .pick-lg-b .pick-wke,
div.datedropper.my-style .pick-y.pick-jump {
  color: #FF385C;
}

div.datedropper.my-style .pick-arw,
div.datedropper.my-style .pick-l,
div.datedropper.my-style .picker {
  color: #3a465e;
}

div.datedropper.my-style .pick-lg-b .pick-sl,
div.datedropper.my-style .pick-lg-h,
div.datedropper.my-style .pick-m,
div.datedropper.my-style .pick-m .pick-arw,
div.datedropper.my-style .pick-submit {
  color: #FFF;
}

div.datedropper.my-style.picker-tiny .pick-m,
div.datedropper.my-style.picker-tiny .pick-m .pick-arw {
  color: #3a465e;
}

.form-control[readonly] {
  background: #fff;
}

div.datedropper.my-style.picker-lkd .pick-submit {
  background-color: #FFF;
  color: #3a465e;
}

.schedule a.btn {
  padding: 10px 30px;
  text-transform: capitalize;
  -webkit-transition: all ease .4s;
  transition: all ease .4s;
}

.schedule .theme-btn,
.theme-btn:focus,
.theme-btn:hover,
.theme-btn:active {
  color: #fff;
  background: #FF385C;
}

.schedule .full-width {
  width: 100%;
  margin-top: 15px;
}

.schedule .input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:first-child) > .btn {
  background: #fff;
  -webkit-transition: all ease .4s;
  transition: all ease .4s;
}

.schedule .input-group-btn:not(:last-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn:hover {
  background: #FF385C;
  color: #fff;
}

.smail-listing {
  width: 100%;
}

.sidebar-widget .author-box {
  margin-bottom: 15px;
}

.sidebar-widget .author-box img {
  float: left;
  width: 85px;
  height: 85px;
  border: 4px solid #fff;
  margin-right: 15px;
}

.sidebar-widget .author__title {
  font-size: 18px;
  color: #222;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 2px;
}

.sidebar-widget .author-box .author__title {
  margin-top: 18px;
  font-size: 18px;
}

.sidebar .sidebar-widget .author__meta {
  font-size: 15px;
  color: #666;
  font-weight: 400;
}

.sidebar-widget .author__contact li {
  color: #666;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 15px;
}

.sidebar-widget .author__contact li span i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  color: #FF385C;
  border-radius: 50%;
  font-size: 18px;
  margin-right: 10px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.sidebar .sidebar-widget .author__contact li a {
  color: #666;
  text-decoration: none;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.sidebar .sidebar-widget .author__contact li a:hover {
  color: #FF385C;
}

.sidebar-widget ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sidebar-widget .author__img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #fff;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
          box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}

.sidebar-widget .author__link {
  text-align: center;
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #e4e4e4;
}

.sidebar-widget .author__link li {
  display: inline-block;
}

.sidebar-widget .author__link li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background-color: rgba(128, 137, 150, 0.2);
  color: #FF385C;
  border-radius: 50%;
  font-size: 16px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.widget-boxed {
  -webkit-box-shadow: 0px 0px 2px #e7e7e7 !important;
          box-shadow: 0px 0px 2px #e7e7e7 !important;
  border: 1px solid #e7e7e7;
}

.widget-boxed {
  border-radius: 6px;
  padding: 1.5rem;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  z-index: 90;
  margin-bottom: 0px;
  position: relative;
  border: 1px solid #eaeff5;
}

.widget-boxed .input-group .fa {
  font-size: 18px !important;
  color: #FF385C;
}

.widget-boxed .input-group .fa:hover {
  color: #fff;
}

.widget-boxed-header {
  padding-bottom: 1.5rem;
  padding-top: 0px;
  border-bottom: 1px solid #eaeff5;
}

.widget-boxed-header h4 {
  color: #222 !important;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.widget-boxed-header h4 .fa {
  color: #FF385C;
  position: relative;
  top: 0px;
}

.row.mrg-top-15 label.mb-3 {
  color: #333;
}

.widget-boxed-body {
  padding: 1.5rem 0 0;
}

.widget-boxed {
  background: #fff;
}

.agent-contact-form-sidebar {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #eeeeee;
}

.agent-contact-form-sidebar h4 {
  font-size: 16px;
  margin-bottom: 20px;
}

.agent-contact-form-sidebar input {
  width: 100%;
  height: 45px;
  border: 1px solid #dae2e9;
  background-color: #fff;
  padding: 15px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #7e8c99;
  margin-bottom: 1rem;
}

.agent-contact-form-sidebar textarea {
  border: 1px solid #dae2e9 !important;
  background-color: #fff;
  padding-left: 15px;
  width: 100%;
  height: 100px;
  color: #7e8c99;
}

.agent-contact-form-sidebar .multiple-send-message {
  background-color: #FF385C;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  padding-top: 12px;
  margin-top: 10px;
  margin-bottom: 0px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.agent-contact-form-sidebar .multiple-send-message:hover {
  background-color: #121B22;
}

/* Arrows */
.widget-boxed .slick-prev,
.widget-boxed .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 0%;
  display: block;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  color: #111;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  display: block;
  top: calc(0% - 35px);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.widget-boxed .slick-prev:hover,
.widget-boxed .slick-next:hover {
  color: transparent;
  outline: none;
  background: transparent;
}

.widget-boxed .slick-prev:hover:before,
.widget-boxed .slick-next:hover:before {
  outline: none;
}

.widget-boxed .slick-prev.slick-disabled,
.widget-boxed .slick-next.slick-disabled {
  pointer-events: none;
  color: #111;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.widget-boxed .slick-prev.slick-disabled:before,
.widget-boxed .slick-next.slick-disabled:before {
  color: #111;
}

.widget-boxed .slick-prev:before,
.widget-boxed .slick-next:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  font-size: 26px;
  line-height: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  color: #111;
}

.widget-boxed .slick-prev {
  left: 0px;
  margin-left: 197px;
}

.widget-boxed .slick-prev:before {
  content: '\f053';
  left: 0px;
  color: #666;
}

.widget-boxed .slick-next {
  right: 0px;
  margin-right: 0px;
}

.widget-boxed .slick-next:before {
  content: '\f054';
  right: 0px;
}

.homes-content .font-weight-bold.mr-1 {
  font-weight: 600;
  color: #555 !important;
}

.homes-content span.det {
  color: #666 !important;
}

.widget-boxed.mt-5,
.widget-boxed.my-5,
.main-search-field.mt-5 {
  margin-top: 30px !important;
}

.inner-pages .blog .block-heading.details h4 {
  color: #000;
}

.inner-pages .blog .blog-info.details,
.listing-details-sliders.mb-30,
.homes-content.details.mb-30,
.property-location,
.reviews.comments,
.reviews.leve-comments,
.wprt-image-video.w50.pro,
.ag-de .similar-property,
.ag-de .portfolio.py-0.age,
.det .similar-property {
  padding: 0;
}

.wprt-image-video.w50.pro {
  margin-bottom: 30px !important;
}

.inner-pages .blog .blog-info.details h4,
.inner-pages .blog .blog-info.details h5,
.inner-pages .carousel-inner h5,
.wprt-image-video.w50.pro h5 {
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.inner-pages .blog .blog-info.details {
  padding: 0;
  border-bottom: 0px;
}

.inner-pages .blog .homes-content .homes-list {
  padding: 0px;
  margin: 0px;
}

.inner-pages .blog .homes-content .homes-list li {
  width: 33%;
  float: left;
  font-size: 14px;
  line-height: 36px;
  list-style: none;
  color: #0098ef;
}

.inner-pages .blog .homes-content.details-2 {
  background: #303441;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1rem 0;
}

.inner-pages .blog .homes-content.details-2.mb-4 {
  margin-bottom: 30px !important;
}

.inner-pages .portfolio .homes-content .homes-list {
  padding-left: 0px;
  padding-bottom: .9rem;
  margin: 0;
}

.inner-pages.sin-1 .similar-property.portfolio .item {
  padding-bottom: 0px;
}

@media screen and (max-width: 1024px) {
  .inner-pages .headings-2 .listing-title-bar h3 {
    font-size: 18px;
  }

  .inner-pages .headings-2 .listing-title-bar h4 {
    font-size: 18px;
  }

  .widget-boxed .slick-prev {
    margin-left: 150px;
  }
}
@media screen and (max-width: 767px) {
  .inner-pages .blog .homes-content.details-2 {
    padding-left: 4rem;
  }

  .inner-pages .carousel.slide .carousel-inner img {
    width: 100%;
    height: 280px;
  }

  .inner-pages .headings-2 .listing-title-bar h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 500px) {
  .inner-pages .carousel.slide .carousel-inner img {
    width: 100%;
    height: 280px;
  }
}
@media screen and (max-width: 991px) {
  .inner-pages.sin-1 .similar-property .item {
    padding-bottom: 20px !important;
  }

  .inner-pages.sin-1 .similar-property .no-pb.pbp-3 {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 450px) {
  .inner-pages .blog .homes-content.details-2 {
    padding-left: 2.5rem;
  }

  .inner-pages .blog .block-heading.map {
    margin-top: 30px;
  }

  .pro-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .single.detail-wrapper {
    margin-top: 30px;
    margin-left: 0px;
  }

  .listing-details-sliders .list-inline {
    width: 88% !important;
    margin-right: 0px;
    margin-top: 0px;
    margin-left: 10px !important;
  }

  .inner-pages.sin-1 .blog .item {
    padding-bottom: 10px;
  }

  .inner-pages.sin-1 .similar-property .item {
    padding-bottom: 20px;
  }

  .inner-pages.sin-1 .similar-property .no-pb.pbp-3 {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }

  .inner-pages.sin-1 .listing-details-sliders .list-inline {
    margin-bottom: 0px;
  }

  .inner-pages.sin-1 .blog .homes-content.details span {
    font-size: 15px !important;
  }

  .inner-pages .single.homes-content .homes-list li {
    width: 100%;
    line-height: 27px;
    margin-bottom: 12px;
  }

  .inner-pages .homes-content.details.mb-30 .homes-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .inner-pages.st-1 .blog .homes-content .homes-list li:last-child {
    margin-left: 12px;
  }

  .property .popup-youtube,
  .property.wprt-image-video .iq-waves {
    top: 54.7% !important;
  }

  .property.vid-si2 .popup-youtube,
  .property.wprt-image-video.vid-si2 .iq-waves {
    top: 51% !important;
  }

  .property.vid-si3 .popup-youtube,
  .property.wprt-image-video.vid-si3 .iq-waves {
    top: 54.2% !important;
  }

  .widget-boxed .slick-prev {
    margin-left: 235px !important;
  }

  .inner-pages .headings-2 .listing-title-bar h3,
  .inner-pages .headings-2 .listing-title-bar h4 {
    font-size: 20px !important;
  }

  .inner-pages .headings-2 .listing-title-bar a {
    font-size: 15px;
  }

  .inner-pages .homes-content.details-2 ul {
    display: block !important;
  }

  .swiper-button-prev.swiper-button-white,
  .swiper-button-next.swiper-button-white {
    display: none;
  }
}
@media screen and (max-width: 360px) {
  .inner-pages .blog .homes-content.details-2 {
    padding-left: 1.3rem;
  }

  .widget-boxed .slick-prev {
    margin-left: 180px !important;
  }
}
.inner-pages .blog .homes-content.details-2 span {
  color: #fff;
  font-size: 1rem;
}

.inner-pages .blog .homes-content.details-2 .fa {
  color: #fff;
}

.inner-pages .blog .blog-info p.mb-3 {
  color: #666;
  font-weight: 400;
}

.inner-pages .blog .sorting-options span {
  color: #121B22;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.inner-pages .blog .sorting-options h5 {
  color: #FF385C;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-top: 0px;
}

.inner-pages .blog .sorting-options h6 {
  color: #18ba60;
  font-weight: bold;
  margin-right: 3.2rem;
}

.inner-pages .blog .wprt-image-video.w50 {
  margin-bottom: 5rem;
}

.inner-pages .blog .homes-content h5,
.inner-pages .blog .property-location h5,
.inner-pages .blog .team.assigned h5,
.similar-property h5,
.ag-de .blog-section h5,
.inner-pages .carousel h5 {
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.inner-pages .blog .homes-content h5::after,
.inner-pages .blog .blog-info.details h5::after,
.inner-pages .blog .property-location h5::after,
.inner-pages .blog .team.assigned h5::after,
.inner-pages .carousel h5:after,
.wprt-image-video.w50.pro h5:after,
.similar-property h5:after,
.ag-de .blog-section h5:after {
  color: #FF385C;
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.property .popup-youtube {
  background: #fff none repeat scroll 0 0;
  border-radius: 100%;
  color: #FF385C;
  font-size: 30px;
  height: 70px;
  left: 50%;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: 57%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 70px;
  z-index: 9;
}

.property .popup-youtube i {
  margin-left: 5px;
}

.property.wprt-image-video .iq-waves {
  height: 352px;
  height: 22rem;
  left: 50%;
  position: absolute;
  top: 57%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 352px;
  width: 22rem;
  z-index: 2;
}

.property.vid-si2 .popup-youtube,
.property.wprt-image-video.vid-si2 .iq-waves {
  top: 50.8%;
}

.property.wprt-image-video .iq-waves .waves {
  -webkit-animation: 3s ease-in-out 0s normal none infinite running waves;
          animation: 3s ease-in-out 0s normal none infinite running waves;
  background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0 padding-box;
  border-radius: 320px;
  height: 352px;
  height: 22rem;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  width: 352px;
  width: 22rem;
}

.property.wprt-image-video .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.property.wprt-image-video .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.property.wprt-image-video .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.carousel-inner.carus img {
  width: 1000%;
}

@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
            transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.6, 0.6);
            transform: scale(0.6, 0.6);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
            transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.6, 0.6);
            transform: scale(0.6, 0.6);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@media screen and (max-width: 991px) {
  .property.wprt-image-video {
    margin-top: 2.5rem;
  }

  .inner-pages .single.widget {
    padding-top: 0px !important;
  }

  .widget-boxed.popular {
    margin-bottom: 30px;
  }

  .widget-boxed .slick-prev {
    margin-left: 540px;
  }

  .widget-boxed .book {
    margin-bottom: 1.5rem;
  }

  .listing-details-sliders .list-inline {
    width: 94% !important;
    margin-right: 0px;
    margin-left: 20px;
  }

  .single.reviews.leve-comments {
    margin-bottom: 30px !important;
  }

  .inner-pages .headings-2 .listing-title-bar h3 {
    font-size: 24px;
  }

  .schedule.widget-boxed.mt-33 {
    margin-top: 0px;
  }

  .ag-de .single.reviews.leve-comments,
  .ag-de .widget-boxed.popular {
    margin-bottom: 0px !important;
  }
}
.inner-pages .carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.single.reviews.leve-comments {
  margin-top: 0px;
  margin-bottom: 60px;
}

.inner-pages .leave-rating {
  height: 24px;
  float: left;
}

.inner-pages .leave-rating:hover input[type=radio]:checked ~ label {
  color: #dadada;
}

.inner-pages .leave-rating input[type=radio] {
  display: none;
}

.inner-pages .leave-rating input[type=radio]:checked ~ label {
  color: #ffc600;
}

.inner-pages .leave-rating label {
  font-size: 24px;
  float: right;
  letter-spacing: 4px;
  color: #dadada;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
}

.inner-pages .leave-rating label:hover,
.inner-pages .leave-rating label:hover ~ label {
  color: #ffc600 !important;
}

.inner-pages .leave-rating-title {
  display: block;
  margin-top: 2.5rem;
  margin-bottom: 7px;
}

.inner-pages .add-review-photos {
  font-weight: 600;
  padding: 6px 7px;
  color: #fff;
  border: 0px;
  background-color: #FF385C;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  font-size: 14px;
  width: auto;
  -webkit-transition: .3s;
  transition: .3s;
  cursor: pointer;
  line-height: 20px;
  float: right;
  top: -25px;
}

.inner-pages .photoUpload {
  position: relative;
  overflow: hidden;
  padding: 3px 12px;
  cursor: pointer;
}

.inner-pages .photoUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.inner-pages .add-review-photos:hover {
  color: #fff;
  background-color: #121B22;
}

.inner-pages .add-review-photos i {
  color: #66676b;
  margin-right: 3px;
  -webkit-transition: .3s;
  transition: .3s;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.inner-pages .add-review-photos:hover i {
  color: #fff;
}

.inner-pages a.rate-review {
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  font-size: 13px;
  color: #666;
  font-weight: 500;
  padding: 5px 20px;
  margin-top: 20px;
  display: inline-block;
  -webkit-transition: .3s;
  transition: .3s;
}

.inner-pages a.rate-review span {
  border-left: 1px solid #e0e0e0;
  line-height: 20px;
  padding-left: 10px;
  margin-left: 5px;
  -webkit-transition: .3s;
  transition: .3s;
  color: #666;
}

.inner-pages a.rate-review i {
  padding-right: 3px;
}

.inner-pages a.rate-review:hover span {
  border-color: #d0d0d0;
}

.inner-pages a.rate-review:hover {
  border-color: #d0d0d0;
  background-color: #fafafa;
}

.inner-pages .blog .homes-content.details .fa {
  color: #FF385C;
  font-size: 1.5rem;
  font-weight: bold;
}

.inner-pages .blog .homes-content.details span {
  font-size: 1rem;
  color: #000;
}

#map-contact {
  width: 100%;
  height: 300px;
}

.contact-map {
  width: 100%;
  height: 300px;
}

.inner-pages .info-box-img {
  width: 180px !important;
  margin-right: 20px;
  float: left;
}

.inner-pages section.team.assigned {
  padding: 0px;
}

.swiper-button-prev.swiper-button-white,
.swiper-button-next.swiper-button-white {
  background-color: #FF385C;
  width: 55px;
  height: 55px;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #FF385C;
  padding: 8px;
}

.swiper-pagination-bullet {
  padding: 8px;
  background: #fff;
  font-weight: 700;
}

.inner-pages .commented .rest img {
  width: 165px;
  height: 110px;
  margin-top: 12px;
  border-radius: 5px;
  margin-right: 15px;
}

.inner-pages .resti {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.list-inline {
  line-height: 15px;
}

@media screen and (max-width: 575px) {
  .inner-pages .resti {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.single-property-4 {
  position: relative;
}

.p0 {
  padding: 0 !important;
}

.m0 {
  margin: 0 auto;
}

.pr1 {
  padding-right: 1px;
}

.pr1 {
  padding-right: 1px;
}

.w100 {
  width: 100% !important;
}

.single-property-5 .video-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  overflow: hidden;
}

.single-property-5.fullwidth-home-map.dark-overlay {
  height: 70vh !important;
  margin-bottom: 0;
}

.single-property-5.fullwidth-home-map.dark-overlay video {
  top: 50%;
  -webkit-transform: translateY(-52%);
          transform: translateY(-52%);
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -10;
  background-size: cover;
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
  position: absolute;
}

.single-property-5.dark-overlay:before {
  background: none !important;
}

.single-property-5.dark-overlay:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  background: none;
}

section.breadcrumb-outer {
  padding: 12rem 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(14, 18, 68, 0.3)), to(rgba(14, 18, 68, 0.3))), url(../images/bg/home-171.png) no-repeat scroll center center;
  background: linear-gradient(rgba(14, 18, 68, 0.3), rgba(14, 18, 68, 0.3)), url(../images/bg/home-171.png) no-repeat scroll center center;
  background-size: cover;
}

.detail-title .detail-title-inner .listing-rating i {
  color: #ffc107;
}

.detail-title .detail-title-inner .list-single-contacts li {
  font-size: 15px;
  padding-right: 17px;
}

.detail-title .detail-title-inner .list-single-details .list-single-rating {
  position: relative;
  z-index: 1;
  display: inline-block;
}

.detail-title .detail-title-inner .list-single-details .list-single-rating .rating-score span {
  background: #fff;
  color: #274abb;
  float: left;
  font-weight: 700;
  border-radius: 0;
  padding: 16px 18px;
  font-size: 24px;
}

.white {
  color: #fff !important;
}

h2.white span {
  color: #fff !important;
  margin-bottom: 20px;
}

h2.white {
  margin-top: 20px;
  margin-bottom: 20px;
}

.list-single-contacts ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-single-contacts ul li {
  margin-bottom: 0;
  line-height: 1.5;
  color: #666;
  position: relative;
  display: inline-block;
  padding: 0;
}

.list-single-contacts ul li a,
.breadcrumb-outer a {
  text-decoration: none;
}

.breadcrumb-outer a:hover {
  color: #fff;
}

.nir-btn,
.nir-btn1,
.nir-btn-black {
  position: relative;
  z-index: 1;
  font-size: 14px;
  text-align: center;
  padding: 10px 20px;
  text-transform: capitalize;
  -webkit-transition: all ease-in-out .5s;
  transition: all ease-in-out .5s;
  background: #274abb;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  border-radius: 0;
  -webkit-box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1);
          box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1);
}

.breadcrumb-outer .mt-1,
.my-1 {
  margin-top: 1rem !important;
}

.nir-btn1:before {
  border-color: #fff !important;
}

.nir-btn:before,
.nir-btn1:before,
.nir-btn-black:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transition: all ease-in-out .5s;
  transition: all ease-in-out .5s;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  z-index: -1;
  border: 2px solid #ff5a5f;
  color: #ff5a5f;
  background: 0 0;
  border-radius: 0;
}

.property.vid-si6 .popup-youtube,
.property.wprt-image-video.vid-si6 .iq-waves {
  top: 55.9%;
}

/*---------------------------------------*/
/*-- 51. INNER PAGE AGENTS LISTING GRID -*/
/*---------------------------------------*/
.inner-pages section.team {
  padding: 6rem 0;
  background: #f5f7fb;
}

.inner-pages .team .team-all {
  padding: 0;
  margin: 0;
}

.inner-pages .team-wrap {
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.inner-pages .team-img {
  position: relative;
  z-index: 9;
}

.inner-pages .team-img img {
  width: 100%;
}

.inner-pages .team-content {
  height: 80px;
  width: 100%;
  bottom: 0;
  position: absolute;
  text-align: center;
  overflow: hidden;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 9;
  background: #FF385C;
}

.inner-pages .team-wrap:hover .team-content {
  height: 150px;
}

.inner-pages .team-content .team-info {
  background: #fff !important;
}

.inner-pages .team-info {
  background: #FF385C;
  padding: 20px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.inner-pages .team-wrap:hover .team-content .team-info {
  background: #FF385C;
}

.inner-pages .team-content h3 {
  text-transform: capitalize;
  margin-bottom: 0px;
  color: #444;
  font-size: 16px;
}

.inner-pages .team-content p {
  color: #000;
  font-style: italic;
  margin-bottom: 10px;
  font-size: .97rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.inner-pages .team-content ul {
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.6s;
  transition: opacity 0.4s, -webkit-transform 0.6s;
  transition: transform 0.6s, opacity 0.4s;
  transition: transform 0.6s, opacity 0.4s, -webkit-transform 0.6s;
}

.inner-pages .team-wrap:hover .team-content ul {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: opacity 0.4s, -webkit-transform 0.6s;
  transition: opacity 0.4s, -webkit-transform 0.6s;
  transition: transform 0.6s, opacity 0.4s;
  transition: transform 0.6s, opacity 0.4s, -webkit-transform 0.6s;
  margin-bottom: 10px;
}

.inner-pages .team-content ul li {
  opacity: 0;
  padding: .3rem 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: opacity 0.1s, -webkit-transform 0.6s;
  transition: opacity 0.1s, -webkit-transform 0.6s;
  transition: transform 0.6s, opacity 0.1s;
  transition: transform 0.6s, opacity 0.1s, -webkit-transform 0.6s;
  display: inline-block;
}

.inner-pages .team-wrap:hover .team-content ul li {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.inner-pages .team-wrap:hover .team-content ul li:nth-child(1) {
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms;
}

.inner-pages .team-wrap:hover .team-content ul li:nth-child(2) {
  -webkit-transition-delay: 200ms;
          transition-delay: 200ms;
}

.inner-pages .team-wrap:hover .team-content ul li:nth-child(3) {
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms;
}

.inner-pages .team-wrap:hover .team-content ul li:nth-child(4) {
  -webkit-transition-delay: 400ms;
          transition-delay: 400ms;
}

.inner-pages .team-wrap:hover .team-content ul li:nth-child(5) {
  -webkit-transition-delay: 500ms;
          transition-delay: 500ms;
}

.inner-pages .team-socials ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inner-pages .team-content span a {
  color: #000;
  -webkit-transition: all 1s;
  transition: all 1s;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
}

.inner-pages .team-wrap:hover .team-content span a {
  bottom: 2px;
}

.inner-pages .team-content span a:hover {
  color: #FF385C;
}

.inner-pages .team .team-pro ul {
  padding: 0;
  margin: 0;
}

.inner-pages .team .team-pro ul li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.inner-pages .team .team-pro .fa {
  font-size: 1.2rem;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  margin: 0 .5rem;
  width: 2.1rem;
  height: 2.1rem;
  line-height: 2.1rem;
}

.inner-pages .team .team-all.padding {
  padding-bottom: 2.5rem;
}

.property-nearby b.title {
  font-size: 15.5px;
}

.property-nearby h6 {
  font-size: 13.2px;
  font-weight: 600;
}

.property-nearby span {
  line-height: 16.5px;
  font-size: 13.2px;
  color: #666;
}

@media screen and (max-width: 991px) {
  .inner-pages .team .team-pro {
    padding-bottom: 2.5rem;
  }

  .inner-pages .team .team-pro:last-child {
    padding-bottom: 0px !important;
  }

  .inner-pages .team .pb-none {
    padding-bottom: 0px;
  }

  .inner-pages .team .team-all.padding {
    padding-bottom: 0px;
  }

  .detail-title-inner,
  .list-single-details {
    text-align: center !important;
  }

  .list-single-details .rating-score {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    float: none !important;
    margin-bottom: .5rem;
  }

  .list-single-details {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .inner-pages .team .team-pro.pb-on {
    padding-bottom: 2.5rem;
  }

  .inner-pages .team-pro.pb-on.np,
  .inner-pages .team-pro.pb-none.np {
    padding-top: 0px;
  }

  .inner-pages .team-pro.pb-on.np-2 {
    padding-bottom: 2.5rem !important;
  }
}
@media screen and (max-width: 575px) {
  .list-single-contacts ul li {
    margin-bottom: .7rem;
    text-align: left;
  }

  h2.white span {
    line-height: 37px;
  }

  section.breadcrumb-outer {
    padding: 7rem 0;
  }
}
@media screen and (max-width: 360px) {
  .white.the-last {
    margin-top: 0px;
  }

  .list-single-contacts ul li {
    margin-bottom: .7rem;
    text-align: left;
  }
}
@media screen and (max-width: 450px) {
  .property.vid-si6 .popup-youtube,
  .property.wprt-image-video.vid-si6 .iq-waves {
    top: 53% !important;
  }
}
.inner-pages .team .team-pro .fa.fa-facebook:hover {
  background: #3b5998;
}

.inner-pages .team .team-pro .fa.fa-twitter:hover {
  background: #1da1f2;
}

.inner-pages .team .team-pro .fa.fa-instagram:hover {
  background: #e1306c;
}

/*-----------------------------------------*/
/*--- 52. INNER PAGE AGENTS LISTING ROW ---*/
/*-----------------------------------------*/
.inner-pages .agent-row .agent-img {
  width: 30%;
  display: inline-block;
  overflow: hidden;
  min-height: 285px;
  max-height: 285px;
  margin-bottom: 40px;
  background: #d4e0e7;
  float: left;
  z-index: 1;
}

.agents .portfolio .project-head img {
  background-color: #1d293e;
  opacity: 10;
}

ul.the-agents-details {
  list-style: none;
  padding-left: 0px;
  margin-top: 15px;
}

ul.the-agents-details li {
  margin-bottom: 6px;
}

ul.the-agents-details li a {
  text-decoration: none;
  color: #666;
  font-size: 14px;
}

.view-my-listing a {
  color: #FF385C !important;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.view-my-listing a:hover {
  color: #121B22 !important;
}

.agents .listing-hidden-content {
  background: #FF385C;
}

.agents .schedule.widget-boxed.mt-33 {
  margin-top: 80px;
}

.agents #header,
.sin-1 #header {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.agents .blog-section .news-link {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.agents .blog-section .news-item-sm:last-child {
  border-radius: 0 0 8px 8px;
}

.agents section.blog-section,
.agents .blog .block-heading,
.agents section.team {
  background: #f5f7fb;
}

@media screen and (min-width: 991px) {
  .agents .no-pb2.icho {
    padding-bottom: 45px;
  }

  .agents .pt-55 {
    padding-top: 45px;
  }

  .agents.pt-55 {
    padding-top: 45px;
  }

  .agents .pt-33 {
    padding-top: 15px;
  }
}
.agents .blog-section .news-item-sm .news-item-text {
  padding: 20px !important;
}

@media screen and (max-width: 991px) {
  .inner-pages .agent-row .agent-img,
  .inner-pages .agent-row .agent-content {
    width: 100% !important;
  }

  .inner-pages .agent-row .agent-content {
    min-height: 300px !important;
  }

  .agents .pagination.disabled {
    display: none;
  }

  .agents .schedule.widget-boxed.mt-33 {
    margin-top: 60px !important;
  }

  .inner-pages .agent-row .agent-img {
    float: none;
    display: block;
    width: 100%;
    min-height: auto;
    max-height: 100%;
    margin-bottom: 0px !important;
  }

  .inner-pages .agent {
    margin-bottom: 0px !important;
  }

  .inner-pages .team .agent-mb {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .inner-pages .agent-row .agent-footer {
    bottom: 22px !important;
  }
}
@media screen and (max-width: 381px) {
  .inner-pages .agent-row .agent-details .fa-envelope {
    padding: 1rem 0;
  }
}
.inner-pages .agent-row .agent-content {
  background: #fff;
  padding: 25px;
  width: 70%;
  float: left;
  min-height: 285px;
  position: relative;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.inner-pages .agent-row .agent-details h4 {
  margin-bottom: 1rem;
}

.inner-pages .agent-row .agent-text p {
  margin-top: 18px;
  line-height: 1.6;
  color: #666;
  overflow: hidden;
}

.inner-pages .agent-row .agent-details {
  padding-left: 0px;
  padding-right: 0px;
}

.inner-pages .agent-row .agent-details p {
  display: inline-block;
  margin-right: 15px;
}

.inner-pages .agent-row .agent-footer {
  text-align: left;
  left: 25px;
  right: 25px;
  position: absolute;
  bottom: 25px;
}

.inner-pages .agent-row .agent-footer .social-icons {
  margin-top: 5px;
}

.inner-pages .agent-row-sidebar .agent-img {
  min-height: 254px;
  max-height: 254px;
}

.inner-pages .agent-row-sidebar .agent-text {
  font-size: 13px;
}

.inner-pages .agent-row-sidebar .agent-content {
  min-height: 254px;
}

.inner-pages .agent-row-sidebar .agent-footer {
  bottom: 20px;
}

.inner-pages .agent {
  background: white;
  position: relative;
  margin-bottom: 30px;
}

.inner-pages .agent-tag {
  position: absolute;
  padding: 8px 8px;
  font-size: 15px;
  z-index: 20;
  top: 20px;
  left: 10px;
}

.inner-pages .agent-img {
  position: relative;
  display: block;
}

.inner-pages .agent-img img {
  width: 100%;
  height: auto;
}

.inner-pages .agent-content {
  padding: 5%;
}

.inner-pages .agent-content p {
  color: #666;
  margin: 0;
}

.inner-pages .button.alt {
  background-color: #FF385C;
  color: #fff;
}

.inner-pages .agent-details {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0px 10px 15px 10px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.9;
}

.inner-pages .agent-details:last-child {
  border-bottom: 0px;
}

.inner-pages .agent-details a {
  color: #777;
  font-size: 20px;
  margin-bottom: 1rem;
  text-decoration: none;
}

.inner-pages .homes-content .homes-address a {
  font-size: 1.2rem !important;
  margin: 0 0 15px;
  text-decoration: none;
  color: #FF385C;
}

.inner-pages .homes-address span {
  color: #666;
}

.inner-pages .homes-content .homes-list li i {
  font-size: 18px;
  margin-right: 5px;
  color: #696969;
}

.inner-pages .agent-details a:hover {
  color: #48a0dc;
}

.inner-pages .agent-details .fa {
  color: #FF385C;
  font-size: 15px;
  margin-right: 8px;
}

.inner-pages .agent-content .social-icons.circle li {
  padding-left: 4px;
  padding-right: 4px;
}

.inner-pages .agent-content .social-icons.circle li a {
  color: #9bb0bb;
  border: 1px solid #9bb0bb;
}

.inner-pages .agent-content .social-icons.circle li a:hover {
  color: white;
  border-color: #48a0dc;
}

.inner-pages .agent-content .netsocials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}

.inner-pages .agent-content .netsocials .fa {
  font-size: 1rem;
  text-align: center;
  background: transparent;
  border: 1px solid #fff;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 1.5rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .agent-content .netsocials a .fa-facebook {
  background: #3b5998;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-facebook:hover {
  background: #3b5998;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-twitter {
  background: #1da1f2;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-twitter:hover {
  background: #1da1f2;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-google-plus {
  background: #dd4b39;
  color: #fff;
}

.agent-content .netsocials a .fa-google-plus:hover {
  background: #dd4b39;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-youtube {
  background: #cd201f;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-youtube:hover {
  background: #cd201f;
  color: #fff;
}

.inner-pages .button.button-icon.right {
  float: right;
}

@media screen and (max-width: 531px) {
  .inner-pages .agent-row .agent-text p {
    margin-bottom: 3rem;
  }
}
/*---------------------------------------*/
/*---- 53. INNER PAGE AGENTS DETAILS ----*/
/*---------------------------------------*/
.inner-pages section.portfolio.agent-details {
  padding: 1rem 0;
  background: #f5f7fb;
}

.inner-pages .agent-details .section-title {
  text-align: left !important;
}

.inner-pages .agent-details .section-title .title-style h2,
.inner-pages .agent-details.no-mb a {
  margin-bottom: 0px;
}

.inner-pages .featured .compare {
  margin-top: 1.1rem;
}

.portfolio .project-head .fa,
.portfolio .project-head .fa-video {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-right: 0px;
  line-height: 0px;
}

.agents .blog .blog-info.details {
  margin-top: 30px;
}

.agents .single.reviews.leve-comments {
  margin-top: 30px;
  margin-bottom: 0px;
}

section.single-proper.details {
  padding: 6rem 0 !important;
}

.agents.det .portfolio {
  margin-bottom: 30px;
}

.agents.det .blog-section .news-item {
  -webkit-box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
          box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
  border: 1px solid #eaeff5;
}

.agents.det section.headings-2 {
  background: #f5f7fb;
}

.agents.det section.headings-2.hee {
  background: #f5f7fb;
}

.agents.det .text-heading.text-left a {
  font-size: 16px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.agents .pro-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.agents .fa.fa-th-large,
.agents .active-view-btn .fa.fa-th-list {
  color: #fff;
}

.agents .lde .fa.fa-th-large {
  color: #121b22;
}

.agents.det .text-heading.text-left a:hover {
  color: 000;
}

.agents.det .text-heading.text-left span {
  font-size: 16px;
  color: #FF385C;
}

.inner-pages .agent-details .button-effect {
  position: absolute;
  text-align: left;
  bottom: 0;
  right: 0;
  z-index: 33;
  background: transparent;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  padding: 8px 15px;
  border-radius: 20px 0 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: .7rem;
}

.inner-pages .agent-details .button-effect .btn {
  display: inline-block;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-right: 15px;
  border-radius: 100%;
  width: 31px;
  height: 31px;
  line-height: 31px;
  background: #fff;
  z-index: 99;
}

.inner-pages .button.alt,
.inner-pages .homes-tag.sale.rent {
  line-height: 1.5;
}

.inner-pages .agent-details .footer a {
  font-size: 13px;
  color: #666;
}

.inner-pages .agent-details .footer i {
  color: #666;
}

.inner-pages .agent-details .compare .fa-heart-o {
  color: #666;
}

.agents a.button.border,
a.button {
  background-color: #FF385C;
  color: #fff !important;
}

.inner-pages.agents .header-user-name,
.inner-pages.agents .header-user-name:before {
  color: #666;
}

@media screen and (max-width: 991px) {
  .inner-pages section.portfolio.agent-details {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .agents .blog-section .no-pb2.icho {
    padding-bottom: 0px !important;
  }
}
/*------------------------------------*/
/*------ 54. INNER PAGE DASHBOARD ----*/
/*------------------------------------*/
.user-page.section-padding {
  padding: 1rem 0;
  margin-top: 90px;
  background: #f5f7fb;
}

.user-page.section-padding.pt-55 {
  padding-top: 10rem !important;
}

.user-profile-box {
  background: #1d293e;
  -webkit-box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
          box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
  margin: 0 auto 50px;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 295px;
  height: 100vh;
  z-index: 99999;
}

.user-profile-box .header {
  padding: 20px 20px 120px;
  text-align: center;
  position: relative;
  background-repeat: no-repeat;
  border: none;
  margin: 0;
  background: #1d293e;
  background-size: cover;
  color: #ffffff;
  z-index: 1;
}

.user-profile-box .header h2 {
  margin: 0 0 8px;
  color: #aeb7c2;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
}

.user-profile-box .header h4 {
  font-size: 14px;
  color: #aeb7c2;
  margin-top: 15px;
  font-weight: 400;
}

.user-profile-box .profile-img {
  border-radius: 50%;
  background-clip: padding-box;
  border: 5px solid #18ba60;
  bottom: 5px;
  float: left;
  height: 100px;
  width: 100px;
  left: 59.5%;
  margin-left: -75px;
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.user-profile-box .detail {
  padding-top: 30px;
}

.user-profile-box .detail ul li a {
  color: #aeb7c2;
  padding: 12px 50px;
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

.my-address.pro label {
  color: #222;
  font-weight: 500;
}

.my-address.pro .form-group {
  margin-bottom: 1.5rem;
}

.user-profile-box .detail ul {
  list-style: none;
  padding-left: 0px;
}

.user-profile-box .detail ul li a i {
  margin-right: 10px;
}

.user-profile-box .detail ul li a {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.user-profile-box .detail ul li .active {
  background: #172133;
  color: #fff;
  font-weight: 500;
}

.user-profile-box .detail ul li a:hover {
  background: #172133;
  color: #fff;
}

.dashborad-box {
  background: #fff;
  padding: 30px;
  margin-bottom: 2.5rem;
  -webkit-box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
          box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
}

.dashborad-box.stat {
  background: #f7f7f7;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.db-top-header {
  position: fixed;
  z-index: 99;
  top: 0;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

.db-top-header #header.cloned.sticky {
  visibility: hidden;
}

.dashboard-bd.maxw1600 {
  max-width: 100%;
}

.dashboard-bd .left-side {
  margin-left: 80px;
}

.dashboard-bd.hd-white .left-side {
  float: left;
  width: 820px;
  display: inline-block;
  margin-left: 0px;
}

.dashboard-bd #logo {
  margin-right: 0px;
}

.dashboard-bd .sidebar-header {
  background-color: #24324a;
  padding: 28px 0 28px 0;
}

.dashboard-bd .left-side {
  width: 55%;
}

.dashboard-bd .right-side {
  width: auto;
  margin-right: 55px;
}

.dashboard-bd.hd-white .right-side {
  float: right;
  width: 120px;
  margin-left: 30px;
  display: inline-block;
  margin-right: 0px;
}

.dashboard-bd .my-address {
  background: #fff;
  padding: 30px;
}

.dashboard-bd a.button.border, a.button {
  background: #1ec38b;
  padding: 9px 20px;
  border-radius: 50px;
  border: 1px solid transparent;
  color: #fff !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.dashboard-bd.hd-white a.button.border,
.dashboard-bd.hd-white a.button {
  background-color: #FF385C;
  width: 150px;
  top: 0;
  padding: 10px 20px;
  color: #fff;
  position: relative;
  text-decoration: none;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
          box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
}

.dashboard-bd .active-user h2 {
  text-transform: capitalize;
  font-weight: 400;
  padding-top: 15px;
  font-size: 16px;
  margin-bottom: 30px;
  color: #aeb7c2;
  text-align: center;
}

body.dashboard-bd {
  background: #f5f7fb;
}

.dashboard-bd .sidebar-header img {
  width: 340px;
  height: 47px;
}

@media only screen and (max-width: 1024px) {
  .dashboard-bd.hd-white #logo {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    margin-top: 3px;
    width: 35%;
    text-align: center;
    left: 0;
    right: 0;
  }

  .dashboard-bd.hd-white .left-side {
    width: 50% !important;
  }
}
@media only screen and (max-width: 575px) {
  .dashboard-bd.hd-white .left-side {
    width: 50% !important;
  }

  .dashboard-bd.hd-white #logo img {
    margin-right: 0px;
  }
}
@media screen and (max-width: 991px) {
  .dashboard-bd.hd-white a.button.border,
  .dashboard-bd.hd-white a.button {
    width: 240px;
  }
}
@media (min-width: 1773px) {
  .dashboard-bd .col-lg-3.user-dash {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 17%;
            flex: 0 0 17%;
    max-width: 17%;
  }

  .dashboard-bd #navigation {
    margin-left: 175px;
  }
}
@media (min-width: 1773px) {
  .dashboard-bd .col-lg-9.user-dash2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 82.5%;
            flex: 0 0 82.5%;
    max-width: 82.5%;
    padding-top: 30px;
  }

  .dashboard-bd .left-side {
    width: 60%;
  }
}
@media (min-width: 1366px) {
  .dashboard-bd .left-side {
    width: 70%;
  }

  .dashboard-bd.hd-white .left-side {
    width: 820px;
  }

  .dashboard-bd a.button.border, a.button {
    display: none;
  }

  .dashboard-bd.hd-white a.button.border, a.button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .dashboard-bd #navigation {
    margin-left: 150px;
  }

  .dashboard-bd.hd-white #navigation {
    margin-left: 40px;
  }
}
@media (max-width: 1773px) {
  .dashborad-box .col-lg-3.dar.pro,
  .dashborad-box .col-lg-3.dar.rev,
  .dashborad-box .col-lg-3.dar.com,
  .dashborad-box .col-lg-3.dar.booked {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 48.9% !important;
  }

  .dashborad-box .col-lg-3.dar.pro,
  .dashborad-box .col-lg-3.dar.rev {
    margin-bottom: 30px;
  }

  .dashborad-box .col-lg-3.dar.rev {
    margin-right: 0px !important;
  }
}
@media (max-width: 575px) {
  .dashboard-bd .left-side {
    margin-left: 0px;
  }

  .dashborad-box .col-lg-3.dar.pro,
  .dashborad-box .col-lg-3.dar.rev,
  .dashborad-box .col-lg-3.dar.com,
  .dashborad-box .col-lg-3.dar.booked {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 1000%;
            flex: 0 0 1000%;
    max-width: 100% !important;
    margin-right: 0px !important;
  }

  .dashborad-box .col-lg-3.dar.com {
    margin-bottom: 30px;
  }
}
.header-user-menu {
  float: right;
  position: relative;
  top: 10px;
  margin-left: 0px;
  margin-right: 50px;
}

.header-user-name {
  position: relative;
  float: left;
  cursor: pointer;
  color: #666;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 10px;
  font-weight: 400;
}

.header-user-name:before {
  font-family: "FontAwesome";
  content: "\f0d7";
  position: absolute;
  color: #666;
  bottom: 1px;
  font-size: 13px;
  right: -16px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.header-user-name span {
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: visible;
  top: 0;
  left: -55px;
}

.header-user-name span img {
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
          box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 100%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.header-user-name.hu-menu-visdec:before {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.header-user-menu ul {
  margin: 10px 0 0 0;
  opacity: 0;
  list-style: none;
  visibility: hidden;
  position: absolute;
  min-width: 150px;
  top: 40px;
  left: -80px;
  z-index: 1;
  padding: 10px 0;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
}

.header-user-menu ul li {
  float: left;
  width: 100%;
  padding: 4px 0;
}

.header-user-menu ul li a {
  color: #50596E;
  float: left;
  width: 100%;
  font-size: 15px;
  text-decoration: none;
  font-weight: 400;
  text-align: left;
  padding: 6px 15px;
}

.dashborad-box .title {
  position: relative;
  padding-bottom: 15px;
  color: #222;
  margin-bottom: 15px;
}

.dashborad-box .item,
.table.table-striped {
  margin-bottom: 0px;
}

.dashborad-box .item .icon {
  float: left;
  font-size: 34px;
  width: 50px;
  position: relative;
}

.dashborad-box .item .icon .fa-list {
  color: #fff;
}

.dashborad-box .item .icon .fa-star {
  color: #fff;
}

.dashborad-box .item .icon .fa-comments {
  color: #fff;
}

.dashborad-box .item .icon .fa-heart {
  color: #fff;
}

.dashborad-box .col-lg-3.dar.pro,
.dashborad-box .col-lg-3.dar.rev,
.dashborad-box .col-lg-3.dar.com,
.dashborad-box .col-lg-3.dar.booked {
  max-width: 24%;
}

.dar.pro {
  border-radius: 8px;
  padding: 30px;
  background: #1ec38b;
}

.dar.rev {
  border-radius: 8px;
  padding: 30px;
  background: #f91;
}

.dar.com {
  border-radius: 8px;
  padding: 30px;
  background: #6ae;
}

.dar.booked {
  border-radius: 8px;
  padding: 30px;
  background: #f91942;
}

.dashborad-box .item .icon:before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background: #fff;
}

.dashborad-box a {
  color: #FF385C;
  text-decoration: none;
}

.dashborad-box .item .icon span {
  font-size: 32px;
  color: #FF385C;
}

.dashborad-box .item .info {
  padding-left: 20px;
  float: left;
}

.dashborad-box .item .info .number {
  font-size: 34px;
  font-weight: 600;
  margin: 0;
  color: #fff;
}

.dashborad-box .item .info .type {
  font-size: 15px;
  color: #fff;
  margin-bottom: 0px;
}

.dashborad-box .item:after {
  display: table;
  content: "";
  clear: both;
}

.dashboard-bd .second-footer {
  background: #fff;
  border: 1px solid #e6e6e6;
  color: #333;
  margin-top: 55px;
  bottom: 0;
  padding-top: 30px;
}

.dashboard-bd .second-footer.ad {
  margin-top: 80px;
}

.dashboard-bd .second-footer.ad2 {
  margin-top: 330px;
}

.dashboard-bd .messages .message .thumb img,
.dashboard-bd .reviews .review .thumb img {
  border-radius: 50px;
}

.dashboard-bd .second-footer p .fa-heart:before {
  color: #FF385C;
}

.listing-table .table thead tr .rating span {
  background: #f3f3f3;
  padding: 0 3px;
  border-radius: 4px;
}

.listing-table .table thead tr .status .active {
  background: #f3f3f3;
  color: #ffffff;
  padding: 0 3px;
  border-radius: 4px;
}

.listing-table .table thead tr .status .non-active {
  background: #d7d7d7;
  padding: 0 3px;
  border-radius: 4px;
}

.listing-table .table thead tr .edit a span {
  color: #FF385C !important;
}

.listing-table .table thead tr th {
  margin-bottom: 0;
  border: none;
  color: #333;
  font-weight: 600;
}

.messages .message {
  margin-top: 50px;
}

.messages .message:after {
  display: table;
  content: '';
  clear: both;
}

.messages .message .thumb {
  height: 80px;
  width: 80px;
  float: left;
  border-radius: 50px;
  overflow: hidden;
}

.messages .message .body {
  position: relative;
  float: left;
  width: calc(100% - 120px);
  padding-left: 20px;
}

.messages .message .body h6,
.reviews .review .body h6,
.reviews .review .body h4 {
  font-size: 18px;
  margin-bottom: .2rem;
  color: #111;
}

.reviews .review .body h4 {
  margin-bottom: 8px;
}

.messages .message .body h6 .badge {
  background: #FF385C;
  color: #ffffff;
}

.messages .message .body .post-time {
  margin-bottom: 0;
  color: #666;
}

.messages .message .body .controller {
  position: absolute;
  top: 0;
  right: 0;
}

.messages .message .body .controller ul li {
  display: inline-block;
  margin-left: 6px;
}

.messages .message .body .controller ul li a {
  color: #888;
}

.messages .message .body .controller ul li a:hover {
  color: #FF385C;
}

.review {
  margin-top: 30px;
  display: inline-block;
}

.review .thumb {
  height: 80px;
  width: 80px;
  float: left;
  border-radius: 50px;
  overflow: hidden;
}

.review .thumb:after {
  display: table;
  content: '';
  clear: both;
}

.review .body {
  position: relative;
  float: left;
  width: calc(100% - 120px);
  padding-left: 20px;
}

.review .body h6 .stars {
  margin-left: 10px;
  font-size: 1rem;
}

.review .body h6 .stars i {
  color: #fcc200;
}

.inner .starts li {
  margin-bottom: 0px;
}

.review .body .post-time {
  margin-bottom: 0;
  color: #666;
}

.review .body .controller {
  position: absolute;
  top: 0;
  right: 0;
}

.review .body .controller ul li {
  display: inline-block;
  margin-left: 6px;
}

.review .body .controller ul li a {
  color: #888;
}

.review .body .controller ul li a:hover {
  color: #FF385C;
}

.password-section {
  margin-top: 20px;
}

.password-section h6 {
  margin-bottom: 10px;
  color: #18ba60;
}

.my-properties {
  background: #FFFFFF;
  -webkit-box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
          box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
  padding: 30px;
}

.my-properties table {
  width: 100%;
  display: table;
}

.my-properties table thead tr th {
  font-size: 15px;
  color: #222;
  background-color: #f5f6ff;
  border: none;
  font-weight: 500;
  padding: 10px 10px 10px 0;
}

.my-properties table thead tr th:first-child {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  padding-left: 30px;
}

.my-properties table tbody tr td {
  border-top: none;
  border-bottom: 1px solid #f3f3f3;
  padding: 30px 20px 30px 0;
  vertical-align: middle;
  font-size: 14px;
}

.my-properties table h2 {
  border: none;
  font-size: 16px;
  color: #444;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0 0 9px 0;
  padding: 0;
}

.my-properties a {
  text-decoration: none;
}

.my-address label {
  color: #222;
}

.my-address .form-group {
  margin-bottom: 1.5rem;
}

.my-properties table tbody tr .image {
  width: 150px;
}

.my-properties table tbody tr .image a img {
  width: 100%;
  border-radius: 5px;
}

.my-properties table tbody tr td .actions .edit {
  float: left;
}

.my-properties table tbody tr td .inner figure {
  margin-bottom: 16px;
  color: #333;
}

.my-properties table tbody tr td .inner figure i {
  font-size: 13px;
}

.my-properties table tbody tr td .price {
  font-size: 12px;
  font-weight: 500;
}

.my-properties table tbody tr td.actions {
  text-align: right;
}

.my-properties table tbody tr td.actions .edit {
  float: left;
  color: #008000;
}

.my-properties table tbody tr td.actions .edit i {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-right: 5px;
}

.my-properties table tbody tr td.actions a {
  padding: 0;
  color: #f50057;
}

.my-properties table tbody tr td:last-child {
  padding-right: 5px;
}

.user-page .heading {
  margin-bottom: 1.5rem;
  text-transform: capitalize;
  background: #fff;
  color: #222;
  font-weight: 500;
}

.section-inforamation label {
  color: #222;
  font-weight: 500;
}

.section-inforamation .form-group {
  margin-bottom: 1.5rem;
}

.dashborad-box .starts {
  margin-top: .8rem;
}

.notification-box {
  padding: 30px 35px;
  color: #727272;
  border-radius: 4px;
  background-color: #f3f3f3;
}

.notification-box h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.notification-box p {
  margin-bottom: 0;
}

.submit-property .drop-file {
  position: relative;
  border: 2px dashed #e5e5e5;
  border-radius: 4px;
  background: #fcfcfc;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
  width: 100%;
}

.user-page.section-padding select {
  border: 0;
  font-size: 14px;
  width: 100%;
  line-height: 30px;
  padding: 0 15px;
  color: #727272;
  background: #ffffff;
  z-index: 1;
  width: 100%;
  position: relative;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  min-height: 45px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #f3f3f3;
  background: #fff;
  border-radius: 4px;
  width: 100% !important;
  cursor: pointer;
}

select:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.form-check-input {
  margin-top: 7px;
}

.drop-file {
  position: relative;
  border: 2px dashed #d0d0d0;
  border-radius: 4px;
  background: #fcfcfc;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
  width: 100%;
}

.drop-file:before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.95)), to(rgba(255, 255, 255, 0.9)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9));
  background-color: #66676b;
  opacity: 0;
  z-index: 10;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 4px;
}

.drop-file .drop-message {
  text-align: center;
  margin: 50px 0;
  font-size: 17px;
  color: #4a4747;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  z-index: 11;
}

.drop-file .drop-message:hover {
  color: #66676b;
}

.drop-file .drop-message span {
  display: inline;
  line-height: 117px;
}

.inner-pages .section-inforamation .form-control,
.inner-pages .my-address .form-control {
  border: 1px solid #666;
}

.inner-pages .section-inforamation .btn-primary,
.inner-pages .my-address .btn-primary {
  color: #fff;
  background-color: #FF385C;
  border: none;
  cursor: pointer;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .section-inforamation .btn-primary:hover,
.inner-pages .my-address .btn-primary:hover {
  background: #121B22;
  color: #fff;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.pagination-container .pagination {
  padding-top: 30px;
}

.pagination-container a {
  text-decoration: none;
}

.pagination-container a:hover {
  background: #121B22;
  color: #fff;
}

.btn-common {
  background-color: #FF385C;
  position: relative;
  z-index: 1;
  color: #fff;
}

.btn.btn-common {
  padding: 15px 20px;
}

.pagination-container .pagination .page-item .page-link {
  margin: 0 10px;
  padding: 15px 20px;
  color: #FF385C;
  border-color: #f3f3f3;
  border-radius: 4px;
  background: #fff;
}

.pagination-container .pagination .page-item .page-link:hover {
  background: #FF385C;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .my-address.pro {
    margin-top: 2.5rem;
  }

  .user-profile-box,
  .user-profile-box .detail.pass {
    margin-bottom: 2.5rem !important;
  }

  .dashborad-box .item {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .dashboard-bd .left-side {
    width: 80% !important;
  }
}
@media screen and (max-width: 1024px) {
  .user-profile-box {
    display: none;
  }

  .col-lg-3.user-dash {
    display: none;
  }

  .col-lg-9.user-dash2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 99.9%;
            flex: 0 0 99.9%;
    max-width: 99.9%;
  }

  .dashboard-bd #navigation {
    margin-left: 15px;
  }

  .dashboard-bd .left-side {
    width: 85%;
  }

  .dashboard-bd .left-side {
    margin-left: 0px;
  }

  .dashboard-bd #logo {
    margin-left: 0px;
  }

  .dashboard-bd .header-user-menu {
    margin-right: 0px;
  }

  .dashboard-bd .header-user-menu ul {
    left: -100px;
  }

  .dashboard-bd #logo {
    margin-left: 250px;
  }
}
@media screen and (max-width: 320px) {
  #logo img {
    width: 55px;
    margin-right: 35px;
  }
}
@media screen and (max-width: 767px) {
  .dashboard-bd #logo {
    margin-left: 250px;
  }
}
@media screen and (max-width: 500px) {
  .dashboard-bd #logo {
    margin-left: 130px !important;
  }
}
@media screen and (max-width: 415px) {
  .dashboard-bd #logo {
    margin-left: 100px !important;
  }
}
@media screen and (max-width: 575px) {
  .my-properties table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
  }

  .dashboard-bd #logo {
    margin-left: 160px;
  }

  .dashboard-bd .left-side {
    width: 63% !important;
  }

  .dashborad-box .item .info .number {
    font-size: 20px;
  }

  .user-profile-box .detail ul li a,
  .reviews .review .body h4 {
    font-size: 15px;
  }

  .my-properties table tbody tr .image {
    width: 100%;
  }

  .my-properties table thead tr th:first-child,
  .my-properties table thead tr th {
    padding-left: 1rem;
    font-size: 15px;
  }

  .my-properties table tbody tr td {
    padding-top: 0px;
  }

  .my-properties table tbody tr td:first-child,
  .inner-pages .pagination {
    margin-top: 30px;
  }

  .inner-pages .pagination.grid-3 {
    margin-top: 0px;
  }

  .my-properties table tbody tr td {
    padding-bottom: 15px;
  }

  .btn.btn-common,
  .pagination-container .pagination .page-item .page-link {
    padding: 8px;
  }
}
@media screen and (max-width: 450px) {
  .messages .message,
  .reviews .review {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .review .body,
  .messages .message .body {
    position: relative;
    float: left;
    width: calc(100% - 20px);
    padding-left: 0px;
  }

  .messages .message .thumb,
  .review .thumb {
    margin-bottom: 1rem;
    margin-right: 8rem;
  }

  .messages .message .body {
    padding-left: 0px;
  }

  .listing-table .table thead tr th,
  .listing-table .table tbody {
    font-size: 14px;
  }
}
@media screen and (max-width: 411px) {
  .messages .message .thumb {
    margin-right: 5rem;
  }

  .review .thumb {
    margin-right: 3rem;
  }
}
@media screen and (max-width: 360px) {
  .messages .message .thumb {
    margin-right: 10rem !important;
  }

  .review .thumb {
    margin-right: 10rem !important;
  }
}
@media screen and (max-width: 360px) {
  .messages .message .body,
  .review .body {
    width: 100%;
  }

  .review .body {
    padding-left: 0px;
  }

  .messages .message .thumb,
  .review .thumb {
    margin-right: 8rem;
  }
}
.user-page .royal-add-property-area {
  background: #f6f6f6 none repeat scroll 0 0;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/*---------------------------------------*/
/*---- 55. INNER PAGE PAYMENT-METHOD ----*/
/*---------------------------------------*/
.inner-pages section.payment-method {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background: #f5f6ff;
}

.tr-single-box {
  background: #ffffff;
  display: block;
  border-radius: 2px;
  border: 1px solid #f0f3f7;
  margin-bottom: 30px;
}

.tr-single-header {
  width: 100%;
  border-bottom: 1px solid #f3f4f7;
  margin-bottom: 2rem;
}

.tr-single-header i {
  color: #FF385C;
}

.tr-single-header h4 {
  margin: 0;
  font-size: 20px;
  color: #222;
  font-weight: 600;
}

.tr-single-header h4 > i {
  margin-right: 7px;
}

.tr-single-body {
  width: 100%;
  padding: 25px;
}

.payment-card {
  border-radius: 4px;
  padding: 18px 15px 15px;
  border: 1px solid #eaeff5;
  margin-bottom: 20px;
}

header.payment-card-header {
  display: inline-block;
  width: 100%;
}

.payment-card-title.flexbox {
  float: left;
}

header.payment-card-header .pull-right img {
  max-width: 100px;
}

.payment-card .collapse {
  padding: 20px 15px 10px;
}

.payment-card-title.flexbox h4 {
  margin: 0;
  font-size: 18px;
}

.include-features {
  margin-top: 15px;
}

.features-tag {
  background-color: #eaedf3;
  border-radius: 0 2px 2px 0;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.2727272727;
  margin: 2px 15px 5px 0;
  padding: 3px 7px;
  position: relative;
}

.features-tag:before {
  border-top: 10px solid transparent;
  border-left: 9px solid #eaedf3;
  border-bottom: 10px solid transparent;
  height: 0;
  position: absolute;
  top: 0;
  right: -8px;
  width: 0;
}

.features-tag:after {
  background-color: #fff;
  border-radius: 50%;
  height: 4px;
  position: absolute;
  top: 8px;
  right: -2px;
  width: 4px;
}

.features-tag:after,
.features-tag:before {
  content: "";
}

.payment-card .custom-checkbox input[type="checkbox"]:checked + label:after {
  top: 5px;
}

.mrg-bot-20 {
  margin-bottom: 20px;
}

.inner-pages .payment-method .form-control {
  border: 1px solid #666;
  margin-bottom: 1.3rem;
}

.booking-price-detail h5 {
  font-size: 16px;
  font-weight: 600;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  margin: 5px 0 0 3px;
  z-index: 9;
}

.custom-checkbox label:before {
  width: 18px;
  height: 18px;
}

.custom-checkbox label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  background: #ffffff;
  border: 1px solid #666;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 2;
}

.custom-checkbox input[type="checkbox"]:checked + label:after {
  content: '';
  position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  -webkit-transform: inherit;
          transform: inherit;
  z-index: 3;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.custom-checkbox input[type="checkbox"]:checked + label:before {
  border-color: #FF385C;
  background: #FF385C;
}

.custom-checkbox input[type="checkbox"]:checked + label:after {
  border-color: #fff;
}

.custom-checkbox input[type="checkbox"]:disabled + label:before {
  color: #b8b8b8;
  cursor: auto;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #ddd;
}

.side-list ul {
  margin: 0;
  padding: 0;
}

.side-list ul li {
  list-style: none;
  padding: 10px 5px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px dashed #eaeff5;
}

.side-list ul li:last-child,
.side-list.no-border ul li {
  border-bottom: none;
}

@media screen and (max-width: 575px) {
  .inner-pages .payment-method .form-control.address {
    margin-bottom: 1.3rem !important;
  }

  .payment-card-title.flexbox h4 {
    font-size: 16px;
  }
}
/*-----------------------------------*/
/*------ 56. INNER PAGE ABOUT US ----*/
/*-----------------------------------*/
.inner-pages section.about-us {
  background: #fff;
  padding: 6rem 0;
}

.inner-pages .headings {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 27, 34, 0.6)), to(rgba(18, 27, 34, 0.6))), url(../images/bg/bg-details.jpg) no-repeat center center;
  background: linear-gradient(rgba(18, 27, 34, 0.6), rgba(18, 27, 34, 0.6)), url(../images/bg/bg-details.jpg) no-repeat center center;
  width: 100%;
  height: 30vh;
}

.inner-pages.about .headings {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 27, 34, 0.6)), to(rgba(18, 27, 34, 0.6))), url(../images/bg/bg-testimonials.jpg) no-repeat center center;
  background: linear-gradient(rgba(18, 27, 34, 0.6), rgba(18, 27, 34, 0.6)), url(../images/bg/bg-testimonials.jpg) no-repeat center center;
}

.inner-pages .headings h1 {
  margin-top: 7.5rem;
  font-size: 26px;
  color: #fff;
}

.inner-pages .headings h2 a,
.inner-pages .headings h2 {
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .headings h2 a:hover {
  color: #FF385C;
}

.inner-pages .about-us h2::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #121B22;
  content: " ";
  width: 97px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.inner-pages .about-us.fh .popup-youtube {
  background: #0f1341 none repeat scroll 0 0;
  border-radius: 100%;
  color: #03a9f5;
  font-size: 30px;
  height: 70px;
  left: 50%;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 70px;
  z-index: 9;
}

.inner-pages .about-us.fh .wprt-image-video .iq-waves {
  height: 352px;
  height: 22rem;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 352px;
  width: 22rem;
  z-index: 2;
}

.inner-pages .about-us.fh .wprt-image-video .iq-waves .waves {
  -webkit-animation: 3s ease-in-out 0s normal none infinite running waves;
          animation: 3s ease-in-out 0s normal none infinite running waves;
  background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0 padding-box;
  border-radius: 320px;
  height: 352px;
  height: 22rem;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  width: 352px;
  width: 22rem;
}

.inner-pages .about-us.fh .box .button {
  padding: 20px;
  width: 100%;
}

.inner-pages .about-us.fh .wprt-image-video .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.inner-pages .about-us.fh .wprt-image-video .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.inner-pages .about-us.fh .wprt-image-video .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.inner-pages.about .sec-title {
  text-align: center;
  padding-bottom: 2rem;
}

.inner-pages.about .sec-title h2 {
  color: #444;
  font-size: 1.7rem;
  text-align: center;
  padding-bottom: 0rem;
}

.inner-pages.about .sec-title h2 span {
  font-weight: 600;
  color: #FF385C;
}

.inner-pages.about .sec-title h2 span::before {
  display: none;
}

.inner-pages.about .testimonials .test-1 p::before {
  left: 236px;
  top: -24px;
  position: relative;
}

.inner-pages.about .owl-dots {
  margin-top: 0px;
}

.inner-pages.about .starts .fa {
  color: #FF385C !important;
}

@media screen and (max-width: 500px) {
  .inner-pages .headings h1 {
    margin-top: 5rem;
    font-size: 1.5rem;
  }
}
.inner-pages .about-us .who-1 {
  background: #fff;
  background-size: cover;
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  min-height: 80px;
  left: 0;
  top: 0;
}

.inner-pages .about-us .who-1 h2 span {
  color: #FF385C;
}

.inner-pages .about-us .who-1 p {
  color: #666;
  background: #fff;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.inner-pages .services .top-headings-section.se h2 {
  color: #fff;
}

.inner-pages .about-us .box.bg-2 a {
  text-decoration: none;
}

.inner-pages .about-us .box.bg-2 {
  color: #000 !important;
  background: #fff;
}

.inner-pages .about-us .box.bg-2 .button:hover {
  color: #fff;
  border-color: #fff;
}

.inner-pages .services-2 .section-title .title-style h2,
.inner-pages .team .section-title .title-style h2 {
  color: #FF385C;
}

.inner-pages .services-2 .title-icon:before,
.inner-pages .team .title-icon:before {
  background: #121B22;
}

.inner-pages .services-2 .title-icon:after,
.inner-pages .team .title-icon:after {
  background: #121B22;
}

.inner-pages .services-2 .img-1 img {
  margin-bottom: 1rem;
}

.inner-pages .services-2 .art-1 {
  color: #121B22;
}

main.services-2 {
  background: #f8f8f8;
  padding: 6rem 0;
}

.services-2 .art-1 {
  background: #fff;
  color: #FF385C;
  text-align: center;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #f9f9f9;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.services-2 .art-1:hover {
  cursor: all-scroll;
}

.services-2 article .fa {
  font-size: 4rem;
  text-align: center;
}

.services-2 article h3 {
  color: #000;
  margin: 1.3rem 0 1rem;
}

.services-2 article p {
  color: #555;
}

.inner-pages .team-content .team-info {
  background: #121B22;
}

@media screen and (max-width: 991px) {
  .box.bg-2 img {
    width: 30%;
  }

  .inner-pages .about-us .wprt-image-video {
    margin-top: 2.5rem;
  }
}
.about-us .who img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .about-us .who img {
    width: 100%;
  }

  .inner-pages .team .jann {
    padding-top: 2.5rem;
  }

  .inner-pages .team .kat {
    padding-top: 2.5rem;
  }

  .services-2 .serv {
    margin-bottom: 2rem;
  }

  .services-2 .serv:last-child {
    margin-bottom: 0px;
  }
}
/*-----------------------------------*/
/*---- 57. INNER PAGE FAQ ---*/
/*-----------------------------------*/
.inner-pages section.faq {
  padding: 6rem 0;
}

.inner-pages .panel .panel-heading {
  font-size: 16px;
  font-weight: 400;
}

.inner-pages .panel .panel-heading.no-mb {
  margin-bottom: 0px;
}

.inner-pages .panel .panel-heading a {
  background: #FF385C;
  color: #fff;
  display: block;
  padding: 1rem;
  border-radius: 5px;
  text-decoration: none;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .panel .panel-heading a:hover {
  background: #121B22;
  color: #fff;
}

.inner-pages .opening-hours.faq {
  margin-top: 0px;
}

.inner-pages .opening-list.faq ul {
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .inner-pages .widget-service-details.faq {
    padding-top: 4rem;
  }

  .inner-pages .opening-list ul li:last-child {
    margin-bottom: 0px;
  }
}
/*-----------------------------------*/
/*----- 58. INNER PAGE PRICING ------*/
/*-----------------------------------*/
.inner-pages section.pricing-table {
  padding: 6rem 0;
  background: #f6f6f6;
}

section.pricing-table {
  padding: 6rem 0;
  background: #fff;
}

.plan {
  background: #fff;
  border: 1px solid #dedede;
}

.plan-name {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  display: block;
  font-size: 24px;
  line-height: 47px;
  font-weight: 800;
  padding: 20px 0;
  color: #000;
}

.plan-price {
  font-size: 48px;
  padding: 30px 0;
  margin-bottom: 30px;
  position: relative;
  background: #FF385C;
  color: #fff;
}

.plan-price:after {
  bottom: -30px;
  border: solid transparent;
  content: " ";
  position: absolute;
  border-width: 15px;
  border-top-color: #FF385C;
  left: 50%;
  margin-left: -16px;
}

.plan.featured .plan-price {
  background: #121B22;
}

.plan.featured .plan-price:after {
  border-top-color: #121B22;
}

.plan.featured .plan-name {
  color: #323232;
}

.plan.featured .plan-price {
  color: #fff;
}

.plan-price .currency {
  top: -0.9em;
  font-size: 50%;
  left: -0.01em;
  font-weight: 700;
}

.plan-name small {
  font-family: 'Poppins', sans-serif;
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}

.plan-price sub {
  font-size: 18px;
  font-weight: 700;
}

.plan ul {
  background: #fff;
  padding: 0;
  margin-bottom: 0;
}

.plan ul li {
  border-top: 1px dashed #dedede;
  padding: 12px 29px;
  font-weight: 500;
}

.plan ul li:first-child {
  border-top: 0 !important;
}

.plan a.btn {
  color: #fff;
  background: #FF385C;
  border: 0px;
  margin: 25px 0;
  padding: 8px 30px;
}

.plan a.btn:hover {
  background: #121B22;
  color: #fff;
}

@media screen and (max-width: 991px) {
  .plan {
    margin-bottom: 40px;
  }

  .plan.no-mgb {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 767px) {
  .plan.yes-mgb {
    margin-bottom: 40px;
  }
}
/*-----------------------------------*/
/*------ 59. INNER PAGE 404 ------*/
/*-----------------------------------*/
.inner-pages .notfound {
  padding: 6rem 0;
}

.inner-pages .top-headings h2 {
  font-size: 10rem;
  color: #FF385C;
  margin-bottom: 2rem;
  line-height: 0.73;
}

.inner-pages .top-headings h3 {
  font-size: 3rem;
  color: #000;
  margin-bottom: 2rem;
}

.inner-pages .top-headings p:last-child {
  font-size: 1.2rem;
}

.inner-pages .port-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 2rem;
}

.inner-pages .port-info .btn {
  background: #FF385C;
  color: #fff;
  border: 1px solid #FF385C;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .port-info .btn:hover {
  background: #121B22;
  color: #fff;
  border: 1px solid #121B22;
}

@media screen and (max-width: 500px) {
  .inner-pages .top-headings h2 {
    font-size: 7rem;
  }

  .inner-pages .top-headings h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .inner-pages .top-headings p:last-child {
    font-size: 1rem;
  }
}
/*---------------------------------------*/
/*- 60. INNER PAGE LOGIN --*/
/*---------------------------------------*/
#login .login,
#register .login {
  width: 430px;
  padding: 45px 60px 60px;
  overflow-y: auto;
  background-color: #fff;
}

#login {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

a.social_bt {
  border-radius: 3px;
  color: #fff;
  min-width: 200px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

a.social_bt.facebook {
  background-color: #3B5998;
}

a.social_bt.google {
  background-color: #DC4E41;
}

a.social_bt.linkedin {
  background-color: #0077B5;
}

.add_bottom_15,
a.social_bt {
  margin-bottom: 15px;
}

a.social_bt {
  text-align: center;
  text-decoration: none;
}

a.social_bt.facebook:before {
  content: "\f09a";
}

a.social_bt.google:before {
  content: "\f0d5";
  top: 12px;
}

a.social_bt.linkedin:before {
  content: "\f0e1";
}

a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
  font-family: FontAwesome;
  position: absolute;
  left: 12px;
  top: 10px;
  font-size: 1.3rem;
  color: #fff;
}

#login .divider {
  height: 1px;
  margin: 30px 0 20px;
  text-align: center;
  background-color: #e1e8ed;
}

#login .divider span {
  background-color: #fff;
  display: inline-block;
  position: relative;
  top: -20px;
  padding: 10px;
  font-style: italic;
}

.add_bottom_30 {
  margin-bottom: 30px;
}

#login .float-left {
  float: left !important;
}

#login .container_check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 10px;
}

#login .container_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

#login .container_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #d2d8dd;
  border-radius: 3px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

#login .float-right {
  float: right !important;
  margin-top: 0px !important;
}

.checkboxes label,
a#forgot {
  color: #666;
  text-decoration: none;
}

#login .btn_1.rounded,
a.btn_1.rounded {
  border-radius: 25px !important;
}

#login .btn_1.full-width,
a.btn_1.full-width {
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  margin-bottom: 5px;
}

#login .btn_1,
a.btn_1 {
  border: none;
  background: #FF385C;
  cursor: pointer;
  display: inline-block;
  padding: 15px 30px;
  color: #fff;
  text-align: center;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  border-radius: 3px;
}

#login a.btn_1:hover {
  background: #121B22;
}

#login .add_top_10 {
  margin-top: 16px;
}

#login .text-center {
  text-align: center !important;
}

#login strong a {
  text-decoration: none;
}

.inner-pages .filter-tags input {
  position: relative;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 0;
  color: #234dd4;
  width: 20px;
  height: 20px;
  color: #fff;
  outline: none;
  margin-right: .5rem !important;
  margin-bottom: 0px;
  background: #fff !important;
  -webkit-appearance: none;
}

.filter-tags input:checked:after,
.custom-form .filter-tags input:checked:after {
  font-family: FontAwesome;
  color: #234dd4;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\f00c";
  font-size: 14px;
  position: absolute;
  left: 2px;
  z-index: 20;
}

@media screen and (max-width: 767px) {
  #login .login,
  #register .login {
    width: 100%;
  }

  #login .checkboxes label,
  a#forgot {
    font-size: 13px;
  }
}
/*-----------------------------------*/
/*--- 61. INNER PAGE COMING SOON ----*/
/*-----------------------------------*/
.headings-7 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(58, 44, 95, 0.7)), to(rgba(32, 51, 100, 0.7))), url(../images/bg/coming-soon.jpg) no-repeat center top;
  background: linear-gradient(rgba(58, 44, 95, 0.7), rgba(32, 51, 100, 0.7)), url(../images/bg/coming-soon.jpg) no-repeat center top;
  color: #fff;
  background-size: cover;
  background-attachment: fixed !important;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.headings-7 .text-heading p.sorry {
  text-align: center;
  font-size: 1.3rem;
  margin-top: .8rem;
}

.headings-7 .bloq-email {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 2.5rem;
}

.headings-7 .bloq-email .email input[type="email"] {
  border: 0;
  padding: .8rem;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.headings-7 .bloq-email .email input[type="submit"] {
  border: 0;
  padding: .8rem;
  background: #FF385C;
  border: 1px solid #fff;
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.headings-7 .email input[type="submit"]:hover {
  background: #121B22;
  border: 1px solid #fff;
}

.font-16 {
  font-size: 16px;
  margin-right: 2rem;
}

.font-36 {
  font-size: 36px;
}

/*------------------------------------------*/
/*--- 62. INNER PAGE UNDER CONSTRUCTION ----*/
/*------------------------------------------*/
.headings-8 {
  background: url(../images/bg/bg-under.jpg) no-repeat center top;
  color: #fff;
  background-size: cover;
  background-attachment: fixed !important;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.headings-8 .text-heading p.sorry {
  text-align: center;
  font-size: 1.1rem;
  margin-top: .8rem;
}

.headings-8 .bloq-email {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 2.5rem;
}

.headings-8 .bloq-email .email input[type="email"] {
  border: 0;
  padding: .8rem;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.headings-8 .bloq-email .email input[type="submit"] {
  border: 0;
  padding: .8rem;
  background: #FF385C;
  border: 1px solid #fff;
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.headings-8 .email input[type="submit"]:hover {
  background: #121B22;
  border: 1px solid #fff;
}

.font-16 {
  font-size: 16px;
}

.font-36 {
  font-size: 36px;
}

/*------------------------------------*/
/*----- 63. INNER PAGE BLOG GRID -----*/
/*------------------------------------*/
.inner-pages section.blog-section {
  padding: 6rem 0;
  background: #f5f7fb;
}

.inner-pages .blog-section .news-item {
  background: #fff;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.inner-pages .blog-section .news-item.-news-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.inner-pages .blog-section .news-item-descr.big-news {
  height: 120px !important;
}

.inner-pages .blog-section .space,
.inner-pages .blog-section .space2.port {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.inner-pages .blog-section .news-item h3 {
  color: #000;
  -webkit-transition: all .25s;
  transition: all .25s;
}

.inner-pages .blog-section .news-item.-news-slider-item {
  height: 100%;
}

.inner-pages .blog-section .news-item.-news-slider {
  height: auto;
}

.inner-pages .blog-section .news-item a:hover {
  text-decoration: none;
}

.inner-pages .blog-section .news-item a:hover h3 {
  color: #FF385C;
  -webkit-transition: all .25s;
  transition: all .25s;
}

.inner-pages .blog-section .news-item .news-img-link.-news-page {
  display: block;
  position: relative;
  padding-bottom: 69%;
  width: 100%;
}

.inner-pages .blog-section .news-item .news-img-link.-news-page .resp-img {
  background-size: cover;
}

.inner-pages .blog-section .resp-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inner-pages .blog-section .news-item-img .resp-img {
  background-size: cover;
}

.inner-pages .blog-section .news-item-img {
  position: relative;
  width: 100%;
}

.inner-pages .blog-section .news-item-img .resp-img {
  background-size: cover;
}

.inner-pages .blog-section .news-item-text {
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.inner-pages .blog-section .news-item-text-header {
  display: block;
}

.inner-pages .blog-section .news-item .date {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
  color: #FF385C;
}

.inner-pages .blog-section .news-item-descr {
  margin-bottom: 20px;
  height: 110px !important;
}

.inner-pages .blog-section .news-item-descr p {
  color: #666;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.inner-pages .blog-section .news-item-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.inner-pages .blog-section .action-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: .8rem;
  color: #444;
}

.inner-pages .blog-section .action-list .fa-comment {
  color: #FF385C;
}

.inner-pages .blog-section .action-list .fa-share-alt {
  color: #FF385C;
}

.inner-pages .blog-section .action-list .fa-heart {
  color: #FF385C;
}

.inner-pages .blog-section ul.action-list {
  margin-bottom: 0px;
}

.inner-pages .blog-section .action-item {
  display: inline-block;
  padding-left: 15px;
}

.inner-pages .blog-section .news-slider-wrap .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inner-pages .blog-section .news-item-sm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2.5rem;
}

.inner-pages .blog-section .news-item-sm:last-child {
  margin-bottom: 0px;
}

.inner-pages .blog-section .news-item-sm .news-img-link {
  -ms-flex-preferred-size: 43.5%;
      flex-basis: 43.5%;
  position: relative;
}

.agents .blog-section .news-item-sm .news-img-link {
  -ms-flex-preferred-size: 48.5%;
      flex-basis: 48.5%;
  position: relative;
}

.inner-pages .blog-section .news-item-sm .news-img-link .news-item-img {
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.inner-pages .blog-section .news-item-sm .news-item-text {
  -ms-flex-preferred-size: 66.6%;
      flex-basis: 66.6%;
  padding: 25px 30px;
}

.inner-pages .blog-section .news-item-sm .news-item-descr {
  height: 52px;
  overflow: hidden;
}

.inner-pages .blog-section .news-link {
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  color: #FF385C;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.inner-pages .blog-section .news-link:hover {
  color: #121B22;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

@media screen and (max-width: 991px) {
  .inner-pages .blog-section .news-item {
    margin-bottom: 2.5rem;
  }

  .inner-pages .blog-section .news-item.nomb {
    margin-bottom: 0px;
  }

  .inner-pages .blog-section .news-item.no-mb:last-child {
    margin-bottom: 0px;
  }

  .inner-pages .blog-section .space.port,
  .inner-pages .blog-section .space2.port {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .inner-pages .blog-section .no-mb .news-item {
    margin-bottom: 0px;
  }

  .inner-pages .blog-section .space.no-pb2 {
    padding-bottom: 0px;
  }

  .inner-pages .pagination.wpt {
    padding-top: 3rem;
  }

  .inner-pages .blog-section .recent-post.sher {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .inner-pages .blog-section .no-mb.wpt-2 .news-item {
    margin-bottom: 2.5rem;
  }

  .inner-pages .blog-section .space.no-pb2 {
    padding-bottom: 2.5rem;
  }

  .inner-pages .blog-section .news-item.nomb {
    margin-bottom: 2.5rem;
  }

  .inner-pages .blog-section .recent-post.sher {
    margin-top: 0px;
  }
}
@media screen and (max-width: 577px) {
  .inner-pages .blog-section .news-item-sm {
    display: block !important;
  }

  .inner-pages .blog-section .news-item-sm .news-img-link .news-item-img {
    position: relative;
    padding-bottom: 69%;
  }
}
@media screen and (max-width: 414px) {
  .inner-pages .blog-section .news-item-descr p,
  .inner-pages .blog-section .news-item-descr {
    overflow: visible;
  }

  .inner-pages .blog-section .news-item-descr.visib {
    overflow: hidden;
    margin-bottom: 2rem;
  }

  .inner-pages .blog-section .news-item.no-mb2 {
    margin-bottom: 0px;
  }

  .inner-pages .blog-section ul.action-list {
    padding-left: 0px;
  }

  .inner-pages .blog-section .news-link {
    font-size: .9rem;
  }
}
.inner-pages .pagination {
  margin-bottom: 0px;
}

.page-item.active .page-link {
  background: #FF385C;
  border-color: #FF385C;
}

.page-link {
  color: #121B22;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.page-link:hover {
  background: #FF385C;
  color: #fff;
}

/*--------------------------------------*/
/*-- 64. INNER PAGE BLOG GRID SIDEBAR --*/
/*--------------------------------------*/
.inner-pages section.blog-section {
  padding: 6rem 0;
  background: #f5f7fb;
}

.inner-pages .blog-section .news-item {
  background: #fff;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.inner-pages .blog-section .news-item.-news-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.inner-pages .blog-section .news-item-descr.big-news {
  height: 120px !important;
}

.inner-pages .blog-section .space,
.inner-pages .blog-section .space2.port {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.inner-pages .blog-section .news-item h3 {
  color: #000;
  -webkit-transition: all .25s;
  transition: all .25s;
}

.inner-pages .blog-section .news-item.-news-slider-item {
  height: 100%;
}

.inner-pages .blog-section .news-item.-news-slider {
  height: auto;
}

.inner-pages .blog-section .news-item a:hover {
  text-decoration: none;
}

.inner-pages .blog-section .news-item a:hover h3 {
  color: #FF385C;
  -webkit-transition: all .25s;
  transition: all .25s;
}

.inner-pages .blog-section .news-item .news-img-link.-news-page {
  display: block;
  position: relative;
  padding-bottom: 69%;
  width: 100%;
}

.inner-pages .blog-section .news-item .news-img-link.-news-page .resp-img {
  background-size: cover;
}

.inner-pages .blog-section .resp-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inner-pages .blog-section .news-item-img .resp-img {
  background-size: cover;
}

.inner-pages .blog-section .news-item-img {
  position: relative;
  width: 100%;
}

.inner-pages .blog-section .news-item-img .resp-img {
  background-size: cover;
}

.inner-pages .blog-section .news-item-text {
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.inner-pages .blog-section .news-item-text-header {
  display: block;
}

.inner-pages .blog-section .news-item .date {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}

.inner-pages .blog-section .news-item-descr {
  margin-bottom: 20px;
  height: 110px !important;
}

.inner-pages .blog-section .news-item-descr p {
  color: #666;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.inner-pages .blog-section .news-item-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.inner-pages .blog-section .action-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: .8rem;
  color: #444;
}

.inner-pages .blog-section .action-list .fa-comment {
  color: #FF385C;
}

.inner-pages .blog-section .action-list .fa-share-alt {
  color: #FF385C;
}

.inner-pages .blog-section .action-list .fa-heart {
  color: #FF385C;
}

.inner-pages .blog-section ul.action-list {
  margin-bottom: 0px;
}

.inner-pages .blog-section .action-item {
  display: inline-block;
  padding-left: 15px;
}

.inner-pages .blog-section .news-slider-wrap .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 767px) {
  .inner-pages .space3 {
    padding-bottom: 2.5rem;
  }

  .inner-pages .widget {
    padding-top: 0 !important;
  }
}
.inner-pages .form-control {
  padding: .7rem;
  border: 1px solid #FF385C;
}

@media screen and (max-width: 767px) {
  .inner-pages .input-group {
    width: 90%;
  }

  .inner-pages .blog-section .no-pb2 {
    padding-bottom: 2.5rem !important;
  }
}
@media screen and (max-width: 991px) {
  .inner-pages .widget {
    padding-top: 2.5rem;
  }

  .inner-pages .blog-section .no-pb2 {
    padding-bottom: 0px;
  }
}
.inner-pages .input-group-btn .fa {
  font-size: 1rem;
  cursor: pointer;
}

.inner-pages .input-group-btn .btn-primary {
  background: #FF385C;
  border: 1px solid #FF385C;
  cursor: pointer;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .input-group-btn .btn-primary:hover {
  background: #121B22;
  color: #fff;
}

.inner-pages .recent-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
          flex: 0 1 100%;
}

@media screen and (max-width: 992px) {
  .inner-pages .recent-main {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.inner-pages .recent-img img {
  width: 90px;
  height: 70px;
  margin-right: 1rem;
}

.inner-pages .info-img h6 {
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
}

.inner-pages .info-img a {
  text-decoration: none;
  color: #000;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .info-img a:hover {
  color: #FF385C;
}

.inner-pages .recent-post .tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inner-pages .recent-post .tags a {
  background: #FF385C;
  color: #fff;
  border: 1px solid #FF385C;
  margin-bottom: .7rem;
  margin-left: .2rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .recent-post .tags a:hover {
  background: #fff;
  color: #FF385C;
}

.inner-pages .recent-post .tags.no-mb a {
  margin-bottom: 0px;
}

.inner-pages .recent-post ul {
  margin: 0;
  padding: 0;
}

.inner-pages .recent-post ul li {
  list-style: none;
  padding: .5rem 0;
}

.inner-pages .recent-post ul li.npt {
  padding-top: 0px;
}

.inner-pages .recent-post ul li:last-child {
  padding-top: .5rem;
  padding-bottom: 0px;
}

.inner-pages .recent-post ul li a {
  text-decoration: none;
  color: #666;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .recent-post ul li a:hover {
  color: #FF385C;
}

.inner-pages .recent-post ul li .fa {
  margin-right: 1rem;
}

.inner-pages .recent-post button {
  margin-right: .5rem;
  margin-bottom: .5rem;
}

.inner-pages .widget h5::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.inner-pages .recent-post .netsocials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.inner-pages .recent-post .netsocials a .fa {
  font-size: 1.3rem;
  text-align: center;
  background: transparent;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .recent-post .netsocials a .fa-facebook {
  background: #3b5998;
  color: #fff;
}

.inner-pages .recent-post .netsocials a .fa-twitter {
  background: #1da1f2;
  color: #fff;
}

.inner-pages .recent-post .netsocials a .fa-google-plus {
  background: #dd4b39;
  color: #fff;
}

.inner-pages .recent-post .netsocials a .fa-youtube {
  background: #cd201f;
  color: #fff;
}

.inner-pages .recent-post ul li.npt:last-child {
  padding-top: 0px;
}

/*--------------------------------------*/
/*-- 65. INNER PAGE BLOG LIST SIDEBAR --*/
/*--------------------------------------*/
.inner-pages .space2 {
  padding-bottom: 2.5rem;
}

@media screen and (max-width: 767px) {
  .inner-pages .space3 {
    padding-bottom: 2.5rem;
  }

  .inner-pages .widget {
    padding-top: 3.5rem !important;
  }
}
.inner-pages .form-control {
  padding: .7rem;
  border: 1px solid #dddddd;
}

@media screen and (max-width: 767px) {
  .inner-pages .input-group {
    width: 90%;
  }
}
@media screen and (max-width: 991px) {
  .inner-pages .widget {
    padding-top: 3.5rem;
  }

  .inner-pages .blog .recent-main.no-mb p {
    margin-bottom: 0px;
  }
}
.inner-pages .input-group-btn .fa {
  font-size: 1rem;
  cursor: pointer;
}

.inner-pages .input-group-btn .btn-primary {
  background: #FF385C;
  border: 1px solid #FF385C;
  cursor: pointer;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .input-group-btn .btn-primary:hover {
  background: #fff;
  color: #FF385C;
}

.inner-pages .recent-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
          flex: 0 1 100%;
}

@media screen and (max-width: 992px) {
  .inner-pages .recent-main {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .inner-pages .info-img {
    margin-top: 1rem;
  }
}
.inner-pages .recent-img img {
  width: 90px;
  height: 70px;
  margin-right: 1rem;
}

.inner-pages .info-img h6 {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  color: #222;
  font-weight: 400;
}

.inner-pages .info-img a {
  text-decoration: none;
  color: #000;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .info-img a:hover {
  color: #FF385C;
}

.inner-pages .recent-post .tags a {
  background: #fff;
  color: #000;
  border: 1px solid #ccc;
  margin-bottom: .7rem;
  margin-left: .2rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .recent-post .tags.no-mb a {
  margin-bottom: 0px;
}

.inner-pages .recent-post .tags a:hover {
  background: #fff;
  color: #FF385C;
  border: 1px solid #FF385C;
}

.inner-pages .recent-post ul {
  margin: 0;
  padding: 0;
}

.inner-pages .recent-post ul li {
  list-style: none;
  padding: .5rem 0;
  border-bottom: 1px solid #ccc;
}

.inner-pages .recent-post ul li:last-child {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.inner-pages .recent-post ul li a {
  text-decoration: none;
  color: #666;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .recent-post ul li a:hover {
  color: #FF385C;
}

.inner-pages .recent-post ul li .fa {
  margin-right: 1rem;
}

.inner-pages .recent-post button {
  margin-right: .5rem;
  margin-bottom: .5rem;
}

.inner-pages .widget h5::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

/*-----------------------------------*/
/*--- 66. INNER PAGE BLOG DETAILS ---*/
/*-----------------------------------*/
.inner-pages section.blog-section {
  padding: 6rem 0;
  background: #f5f7fb;
}

.inner-pages .blog-section .news-item-descr.big-news.details {
  height: 240px !important;
  -webkit-box-shadow: 0px !important;
          box-shadow: 0px !important;
}

.inner-pages .blog-section .news-item.details {
  background: transparent;
  -webkit-box-shadow: 0 0 0 0 #edeff1;
          box-shadow: 0 0 0 0 #edeff1;
}

.inner-pages .blog-section .news-item-text.details {
  padding: 20px 0;
}

.inner-pages .comments-info .conra {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.inner-pages .comments-info h5 {
  color: #FF385C;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0px;
}

.inner-pages .comments-info p:last-child {
  font-weight: 400;
}

.inner-pages .social-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  background: transparent;
  margin: 0;
  padding: 0;
}

.inner-pages .social-share .fa-facebook {
  color: #3b5998;
}

.inner-pages .social-share .fa-twitter {
  color: #1da1f2;
}

.inner-pages .social-share .fa-google-plus {
  color: #dd4b39;
}

.inner-pages .social-share .fa-youtube {
  color: #cd201f;
}

.inner-pages .social-share a {
  color: #FF385C;
  font-size: 1.5rem;
  margin: 0 .5rem;
}

.col-md-10.comments-info {
  padding-left: 0px;
}

.col-md-10.comments-info p.mb-2 {
  color: #333;
}

.col-md-10.comments-info p:last-child {
  font-weight: 400;
}

.inner-pages .comments-info.no-mb p:last-child {
  margin-bottom: 0px;
}

.inner-pages .comments h3::after,
.inner-pages .leve-comments h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.inner-pages .commented .comm-inf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}

.inner-pages .commented img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 2rem;
}

.inner-pages .data form .col-md-12 {
  padding: 0;
  padding-right: 1rem;
}

section.leve-comments {
  margin-top: 4rem;
}

.inner-pages .leve-comments .btn {
  background: #FF385C;
  cursor: pointer;
  font-size: 16px;
  border: none;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .leve-comments .btn:hover {
  background: #121B22;
  color: #fff;
}

.inner-pages .recent-post ul li.npt {
  padding-bottom: 0px;
}

.inner-pages .blog-section .news-item-descr.big-news.details {
  height: 240px !important;
  margin-bottom: 2.5rem !important;
  -webkit-box-shadow: 0px !important;
          box-shadow: 0px !important;
}

@media screen and (max-width: 767px) {
  .inner-pages .leve-comments.wpb {
    padding-bottom: 2.5rem;
  }

  .col-md-10.comments-info {
    padding-left: 15px;
  }
}
@media screen and (max-width: 575px) {
  section.leve-comments {
    margin-top: 2rem;
  }
}
/*-----------------------------------*/
/*---- 67. INNER PAGE CONTACT US ----*/
/*-----------------------------------*/
.inner-pages .contact-us {
  padding: 6rem 0;
}

.inner-pages .contact-us ul li {
  list-style: none;
  font-weight: 300;
}

.inner-pages .contact-us ul {
  margin: 0;
  padding: 0;
}

.inner-pages .contact-us .btn {
  background: #FF385C;
  border: 1px solid #FF385C;
  cursor: pointer;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .contact-us .btn:hover {
  background: #fff;
  color: #121B22;
  border: 1px solid #FF385C;
}

.inner-pages .address-info p:last-child {
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .inner-pages .offices h3:first-child {
    margin-top: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .inner-pages .contact-us h3 {
    font-size: 1.1rem !important;
  }
}
label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #000;
}

form label.error {
  display: block;
  color: #ff0000;
  font-size: 14px !important;
  opacity: 1 !important;
}

.successform,
.errorform {
  display: none;
  height: 70px;
}

.successform span,
.errorform span {
  display: block;
}

.successform span p,
.errorform span p {
  margin-top: 15px;
}

.successform span p {
  color: #01c15a;
  font-size: 2rem;
}

.errorform span p {
  color: #c0392b;
}

.inner-pages .contact-us h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
}

.inner-pages .offices h3 {
  text-transform: capitalize;
}

.inner-pages .offices p {
  font-weight: 300;
}

/*---- CONTACT INFO ----*/
.inner-pages .bgc {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(32, 51, 100, 0.8)), to(rgba(32, 51, 100, 0.8))), url(../images/bg/bg-testimonials.jpg) no-repeat center center;
  background: linear-gradient(rgba(32, 51, 100, 0.8), rgba(32, 51, 100, 0.8)), url(../images/bg/bg-testimonials.jpg) no-repeat center center;
  padding: 2rem;
  background-size: cover;
}

.inner-pages .request h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #FF385C;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.inner-pages .call-info h3 {
  color: #fff;
  margin-bottom: 2rem;
}

.inner-pages .call-info p {
  color: #fff;
}

.inner-pages .call-info h3::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #fff;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 991px) {
  .inner-pages .bgc {
    margin-top: 3rem;
  }
}
.inner-pages .call-info .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
}

.inner-pages .call-info .ti {
  margin-bottom: 0px;
}

.inner-pages .cll:last-child {
  margin-bottom: 0px;
}

.inner-pages .call-info .info p {
  color: #fff;
  margin: 0px;
  margin-left: 1.5rem;
  font-weight: 300;
}

.inner-pages .call-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.inner-pages .call-info ul li .fa {
  font-size: 1.6rem;
  color: #fff;
}

/*------------------------------------*/
/*-- 68. INNER PAGE SUBMIT PROPERTY --*/
/*------------------------------------*/
.inner-pages .royal-add-property-area {
  padding: 6rem 0;
  background: #f5f7fb none repeat scroll 0 0;
}

.inner-pages .royal-complete-order-head {
  margin-bottom: 50px;
  text-align: center;
}

.inner-pages .property-form-group {
  padding: 0 50px;
}

.inner-pages .royal-complete-order-head h2 {
  color: #333;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.inner-pages .single-add-property > h3 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  padding-bottom: 25px;
  color: #FF385C;
  text-transform: capitalize;
}

.inner-pages .property-form-group label {
  color: #333;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}

.inner-pages .property-form-group p input {
  border: 1px solid #ddd;
  height: 50px;
  padding: 10px;
  width: 100%;
}

.inner-pages .property-form-group p.no-mb,
.inner-pages .property-form-group p.no-mb input {
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .inner-pages .property-form-group p.no-mb,
  .inner-pages .property-form-group p.no-mb input {
    margin-bottom: 20px;
  }

  .inner-pages .property-form-group p.no-mb.first input {
    margin-bottom: 20px !important;
  }

  .inner-pages .property-form-group p.no-mb.last:last-child,
  .inner-pages .property-form-group p.no-mb input:last-child {
    margin-bottom: 0px;
  }
}
.inner-pages .property-form-group p textarea {
  border: 1px solid #ddd;
  height: 180px;
  padding: 10px;
  width: 100%;
}

.inner-pages .single-add-property {
  background: #fff none repeat scroll 0 0;
  margin-bottom: 30px;
  padding: 30px;
}

.inner-pages .property-form-group p,
.property-form-group .dropdown.faq-drop {
  margin-bottom: 20px;
}

.property-form-group .dropdown.faq-drop.no-mb {
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .property-form-group .dropdown.faq-drop.no-mb {
    margin-bottom: 40px;
  }

  .property-form-group .dropdown.faq-drop.no-mb.last {
    margin-bottom: 0px;
  }
}
.inner-pages .property-form-group .dropdown-toggle {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ddd;
  border-radius: 0;
  color: #999;
  padding: 10px;
  text-align: left;
  width: 100%;
  text-transform: capitalize;
}

.inner-pages .property-form-group .dropdown-toggle::after {
  display: none;
}

.inner-pages .property-form-group .dropdown-menu.faq-drop-open {
  width: 100%;
  padding: 0;
}

.inner-pages .property-form-group .dropdown-menu.faq-drop-open > li {
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 20px;
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.inner-pages .property-form-group .dropdown-menu.faq-drop-open > li:last-child {
  border-bottom: 0px solid #ddd;
}

.inner-pages .property-form-group .dropdown-menu.faq-drop-open > li:hover {
  background: #f9f9f9;
}

.inner-pages .property-form-group .dropdown-toggle i {
  float: right;
  font-size: 20px;
  line-height: 25px;
}

.inner-pages .property-form-group .dz-message {
  background: #fff none repeat scroll 0 0;
  border: 2px dashed #1ABC9C;
  padding: 50px 20px;
  text-align: center;
}

.inner-pages .dz-default i {
  color: #1ABC9C;
  display: block;
  font-size: 60px;
  margin-bottom: 30px;
}

.inner-pages .dz-default.dz-message > span {
  color: #1ABC9C;
  font-size: 20px;
  text-transform: capitalize;
}

.inner-pages .dropdown.faq-drop span {
  color: #999;
  margin-left: 5px;
}

.inner-pages .pro-feature-add label {
  color: #666;
  font-weight: 400;
}

.inner-pages .pro-feature-add input,
.custom-form .filter-tags input {
  position: relative;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 0;
  color: #fa2964;
  width: 20px;
  height: 20px;
  color: #fff;
  outline: none;
  margin-right: .5rem;
  margin-bottom: 0px;
  background: #fff !important;
  -webkit-appearance: none;
}

.inner-pages .pro-feature-add > li {
  display: inline-block;
  margin: 5px 10px;
}

.inner-pages .pro-feature-add li.no-mb input,
.inner-pages .pro-feature-add .no-mb label,
.inner-pages ul.pro-feature-add,
.inner-pages .pro-feature-add > li.no-mb {
  margin-bottom: 0px;
}

@media screen and (max-width: 404px) {
  .inner-pages .pro-feature-add .no-mb label {
    margin-bottom: 20px;
  }

  .inner-pages .pro-feature-add .no-mb.last label:last-child {
    margin-bottom: 0px;
  }
}
.inner-pages .prperty-submit-button > button {
  background: #FF385C none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  padding: 10px 20px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-transform: capitalize;
}

.inner-pages .prperty-submit-button > button:hover {
  background: #121B22 none repeat scroll 0 0;
}

.inner-pages .royal-complete-order-head > p {
  font-size: 17px;
}

@media screen and (max-width: 575px) {
  .inner-pages .property-form-group {
    padding-left: 0px;
    padding-right: 0px;
  }
}
/*-----------------------------------*/
/*------ 69. PRELOADER ------*/
/*-----------------------------------*/
#preloader {
  background: #FFF;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

#status,
.status-mes {
  background-image: url(../images/preloader.gif);
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  left: 50%;
  margin: -100px 0 0 -100px;
  position: absolute;
  top: 50%;
  width: 200px;
}

.status-mes {
  background: none;
  left: 0;
  margin: 0;
  text-align: center;
  top: 65%;
}

/*-----------------------------------*/
/*----- 70. HEADER GOOGLE MAPS ------*/
/*-----------------------------------*/
.header-map.google-maps {
  background: #e1e5f1;
  padding: 0;
  height: 755px;
  position: static;
}

.header-map.google-maps .container {
  position: static;
}

#map-canvas {
  width: 100%;
  height: 755px;
  position: absolute;
  z-index: 0;
}

.info-box-img {
  width: 180px;
  margin-right: 20px;
  float: left;
}

.info-box p {
  color: black;
}

.header-map.google-maps.horizontal,
.header-map.google-maps.horizontal #map-canvas {
  height: 600px;
  position: static;
}

.map-leaflet-wrapper,
.map-google-wrapper {
  position: absolute;
}

#map-canvas,
#map-google {
  width: 100%;
  height: 755px;
  position: absolute;
  z-index: 0;
}

.map-pull-top #map-canvas,
.map-pull-top #map-google {
  margin-top: -180px;
}

#map-canvas.full,
#map-google.full {
  display: table;
  height: 100%;
  width: 100%;
}

.map-marker {
  height: 50px;
  width: 50px;
}

.leaflet-div-icon,
.map-marker {
  background-color: #FF385C;
  border: 5px solid #FF385C;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  -webkit-transition: all .15s linear;
  transition: all .15s linear;
  z-index: 10;
}

.leaflet-div-icon:before,
.map-marker:before {
  border: 4px solid transparent;
  border-width: 10px 8px;
  border-top-color: #FF385C;
  bottom: -24px;
  height: 0;
  content: '';
  display: block;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 11;
}

.leaflet-div-icon:after,
.map-marker:after {
  background-color: #fff;
  border-radius: 50%;
  bottom: 0;
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 12;
}

.leaflet-div-icon .fa,
.map-marker .fa {
  color: #000;
  font-size: 23px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  z-index: 13;
}

.leaflet-div-icon:hover,
.map-marker:hover {
  top: -2px;
}

.map-popup-content-wrapper {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  display: none;
  left: -100px;
  top: -210px !important;
  position: absolute;
  z-index: 9999;
}

.map-popup-content-wrapper:after {
  border: 7px solid transparent;
  border-top-color: #fff;
  bottom: -14px;
  content: '';
  display: block;
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
}

.map-popup-content-wrapper,
.leaflet-popup-content-wrapper {
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: 240px;
}

.map-popup-content,
.leaflet-popup-content {
  background-color: #fff;
  height: 243px;
  margin: 0 0 8px 0;
  padding: 0;
}

.leaflet-popup-tip {
  width: 17px;
  height: 1px;
  padding: 1px;
  margin: -1px auto 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.map-popup-content a:hover,
.leaflet-popup-content a:hover {
  text-decoration: none;
}

.map-popup-content-wrapper .close {
  color: #fff;
  display: block;
  font-size: 11px;
  opacity: 1;
  position: absolute;
  right: 15px;
  top: 15px;
  text-shadow: none;
  z-index: 99999;
}

.listing-window-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 180px;
  position: relative;
}

.listing-window-image:after {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
  visibility: hidden;
  z-index: 100;
}

.listing-window-image:before {
  -webkit-font-smoothing: antialiased;
  background-color: #FF385C;
  border-radius: 50%;
  color: #fff;
  content: '\f002';
  display: block;
  font-family: 'FontAwesome';
  height: 32px;
  left: 50%;
  line-height: 30px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
  visibility: hidden;
  width: 32px;
  z-index: 101;
}

.map-popup-content-wrapper:hover .listing-window-image:before,
.map-popup-content-wrapper:hover .listing-window-image:after,
.leaflet-popup-content-wrapper:hover .listing-window-image:before,
.leaflet-popup-content-wrapper:hover .listing-window-image:after {
  opacity: 1;
  visibility: visible;
}

.listing-window-content h2,
.listing-window-content h3 {
  display: block;
  color: #323232;
}

.listing-window-content h2 {
  display: block;
  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.5;
  margin: 8px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listing-window-content p {
  display: block;
  font-size: 1.1em;
  font-weight: 500;
  color: #000;
  line-height: 1.5;
  margin: 4px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listing-window-content h3 {
  background-color: #FF385C;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  left: 15px;
  margin: 0;
  padding: 4px 8px;
  position: absolute;
  top: 15px;
}

.marker-cluster-small {
  background-color: rgba(39, 74, 187, 0.6);
  color: #fff;
  font-weight: 700;
}

.marker-cluster-small div {
  background-color: rgba(39, 74, 187, 0.6);
  color: #fff;
  font-weight: 700;
}

.marker-cluster-medium {
  background-color: rgba(39, 74, 187, 0.6);
  color: #fff;
  font-weight: 700;
}

.marker-cluster-medium div {
  background-color: rgba(39, 74, 187, 0.6);
  color: #fff;
  font-weight: 700;
}

.marker-cluster-large {
  background-color: rgba(39, 74, 187, 0.6);
  color: #fff;
  font-weight: 700;
}

.marker-cluster-large div {
  background-color: rgba(39, 74, 187, 0.6);
}

/*-----------------------------------*/
/*----- 71. HEADER FILTER ------*/
/*-----------------------------------*/
.filter {
  min-height: 300px;
  background: #cbd8e3;
  padding-bottom: 40px;
}

.filter-header {
  background: #48a0dc;
}

.filter .ui-tabs .ui-tabs-panel {
  padding: 50px 0px 0px 0px;
  background: transparent;
}

.filter .ui-tabs .ui-tabs-nav {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.filter .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  text-align: center;
  font-weight: 700;
  color: white;
  padding: 16px;
  min-width: 100px;
}

.filter .ui-tabs .ui-tabs-nav li.ui-state-active a {
  background: #408dc1;
}

.filter .ui-tabs .ui-tabs-nav li.ui-state-active a:after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -9px;
  border-width: 9px 9px 0;
  border-style: solid;
  border-color: #408dc1 transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.filter .ui-tabs .ui-tabs-nav li {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0px;
  background: none;
}

.filter label {
  display: block;
}

.filter label.label-submit {
  visibility: hidden;
  display: inline-block;
}

.filter-item {
  float: left;
  margin-right: 2.6%;
  width: 23%;
}

.filter-item-7 {
  width: 31.6%;
}

.filter-item-7:nth-child(3) {
  margin-right: 0;
}

.filter-item-7:nth-last-child(4) {
  width: 23%;
}

.filter-item-7:nth-last-child(3) {
  width: 23%;
}

.filter-item-7:nth-last-child(2) {
  width: 23%;
}

.filter-item-7:last-child {
  width: 23%;
  margin-right: 0;
}

.noUi-horizontal {
  height: 8px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: rgba(0, 0, 0, 0.09);
  margin-bottom: 53px;
  margin-top: 7px;
}

.filter .noUi-horizontal {
  background: white;
}

.noUi-connect {
  background: #48a0dc;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.noUi-horizontal .noUi-handle {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: white;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.noUi-horizontal .noUi-handle:before,
.noUi-horizontal .noUi-handle:after {
  content: '';
  display: none;
}

.noUi-horizontal .noUi-tooltip {
  bottom: auto;
  top: 23px;
  font-size: 11px;
  background: rgba(0, 0, 0, 0.05);
  border: none;
  padding: 3px 4px;
}

.filter-item .area-filter {
  width: 48.5%;
  float: left;
}

.filter-item .area-filter.filter-1 {
  margin-right: 3% !important;
}

.area-filter:nth-last-child(2) {
  margin-right: 0;
}

.filter-item .button {
  width: 100% !important;
  cursor: pointer;
  color: #fff;
}

.filter-with-slider {
  margin-top: -51px;
  color: white;
  min-height: auto;
  position: relative;
  z-index: 100;
  background: transparent;
}

.filter-with-slider .tabs.ui-widget {
  background: #408dc1;
}

.filter-with-slider .ui-tabs .ui-tabs-panel {
  padding: 20px 20px 0px 20px;
}

.filter-with-slider .filter-item:nth-last-child(2) {
  margin-right: 0;
}

.filter-with-slider label {
  color: white;
}

.filter-with-slider .noUi-horizontal {
  background: rgba(0, 0, 0, 0.13);
}

.filter-with-slider .noUi-connect {
  background: #323746;
}

.filter-with-slider .noUi-horizontal .noUi-tooltip {
  color: white;
}

.filter-with-slider .button {
  background: #323746;
}

/** FILTER GOOGLE MAPS **/
.google-maps .filter {
  padding: 20px;
  margin-top: 40px;
  width: 30%;
  position: relative;
  z-index: 100;
  border-radius: 5px;
  background: #FF385C;
  float: right;
  color: #fff;
}

.google-maps .fa-search {
  margin-right: .5rem;
}

@media screen and (max-width: 768px) {
  .google-maps .filter .filter-toggle {
    display: block;
  }

  .google-maps .filter h6 {
    margin-bottom: 0px;
  }

  .google-maps .filter {
    min-height: auto;
    width: 50%;
    text-align: center;
    padding: 0;
    margin-top: 16px;
    position: relative;
    z-index: 20;
  }

  .google-maps .filter form {
    text-align: left;
    display: none;
    padding: 15px;
    position: absolute;
    width: 100%;
    background: #FF385C;
  }

  .google-maps .filter .filter-item {
    width: 100% !important;
  }

  .google-maps .slider_amount {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 575px) {
  .google-maps .filter {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .google-maps .slider_amount {
    font-size: 12px;
  }

  .info-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 200px;
  }

  .info-box-img {
    width: 100%;
    margin-bottom: 1rem;
  }

  .info-box h4 {
    font-size: 1.2rem;
    text-align: center;
  }

  .info-box p {
    font-size: 13px;
    text-align: center;
  }

  .small {
    font-size: 15px !important;
    text-align: center;
  }
}
.google-maps .filter .filter-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  padding: 20px;
  color: white;
  font-weight: 700;
}

.google-maps .filter-item {
  width: 100%;
  float: none;
}

.google-maps .filter-item:nth-last-child(2) input {
  margin-bottom: 0px;
}

.google-maps .filter-item label {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  color: white;
}

.google-maps .filter-half {
  width: 48.5%;
  margin-right: 3%;
  float: left;
}

.google-maps .filter-half-last {
  margin-right: 0;
}

.google-maps .noUi-connect {
  background: #323746;
}

.google-maps .noUi-horizontal .noUi-tooltip {
  color: white;
}

.google-maps .filter .button {
  background: #121B22;
  margin-bottom: 5px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.google-maps .filter .button:hover {
  background: #fff;
  color: #000;
}

/** FORM ELEMENTS **/
.google-maps input,
.google-maps textarea,
.google-maps select {
  width: 100%;
  height: auto;
  padding: 9px;
  margin-bottom: 20px;
  border-radius: 3px;
  border: none;
  font-weight: 400;
  color: #000;
  font-family: 'Poppins', sans-serif;
  background: white;
}

input[type="file"] {
  background: white;
  border: none;
  padding: 5px 0px 5px 0px;
  width: auto;
  display: inline-block;
}

.chosen-container {
  margin-bottom: 20px;
  width: 100% !important;
}

.chosen-container.chosen-with-drop .chosen-single {
  border: none;
  background: white;
}

.chosen-container .chosen-single {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 3px;
  background: white;
  height: 39px;
  line-height: 39px;
  font-size: 15px;
}

.chosen-container .chosen-single div {
  top: 8px;
  right: 6px;
}

.chosen-container .chosen-drop {
  border: none;
}

.chosen-container .chosen-search {
  padding: 10px;
}

.chosen-container .chosen-results {
  padding: 0px 10px 10px 10px;
}

.chosen-container .chosen-results li.highlighted {
  background: #48a0dc;
  border-radius: 2px;
}

input[type="checkbox"],
input[type="radio"] {
  width: auto;
  margin-right: 5px;
}

textarea {
  height: 130px;
}

input.border,
select.border,
textarea.border,
.form-block.border .chosen-container .chosen-single,
.form-block.border .chosen-container .chosen-drop {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.form-block.border .chosen-container .chosen-results {
  padding-top: 10px;
}

.form-block label {
  display: block;
}

.form-block label .error {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}

.search-form {
  position: relative;
}

.search-form button[type="submit"] {
  border: none;
  background: none;
  position: absolute;
  right: 13px;
  top: 10px;
  color: #97aab7;
}

.button.small {
  padding: 6px 23px 6px 23px;
  font-size: 12px;
  color: #fff;
  background: #FF385C;
  display: inline-block;
  width: auto;
  border: none;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.button.small:hover {
  background: #121B22;
}

/*-----------------------------------*/
/*------ 72. UI ELEMENT ------*/
/*-----------------------------------*/
/** Accordions **/
section.ui-element {
  padding: 6rem 0;
}

.ui-elements h5.uppercase {
  letter-spacing: 2px;
  margin-right: -2px;
}

.ui-elements .mb40 {
  margin-bottom: 40px;
}

.ui-elements .mb70 {
  margin-bottom: 70px;
}

.ui-elements .uppercase {
  font-weight: 400;
  text-transform: uppercase;
}

.ui-elements .accordion .title {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ui-elements .accordion .content {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ui-elements .accordion li.active .content {
  max-height: 400px;
}

.ui-elements .accordion p:last-child {
  margin-bottom: 0;
}

.ui-elements .accordion-1 li {
  margin-bottom: 10px;
}

.ui-elements .accordion-1 li:last-child {
  margin-bottom: 0;
}

.ui-elements .accordion-1 .title {
  background: #f5f5f5;
  padding: 13px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ui-elements .bg-secondary .accordion-1 .title {
  background: #ebebeb;
}

.ui-elements .bg-secondary .accordion-1 .title:hover {
  background: #e4e4e4;
}

.ui-elements .accordion-1 .title span {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}

.ui-elements .accordion-1 .content {
  border: 1px solid #eee;
  border-top: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
}

.ui-elements .accordion-1 .content p {
  padding: 13px;
}

.ui-elements .accordion-1 .active .content {
  opacity: 1;
  -webkit-transform: translate3d(0, 0px, 0);
          transform: translate3d(0, 0px, 0);
}

.ui-elements ul {
  margin-bottom: 24px;
  margin-top: 0;
  padding: 0;
  list-style: none;
}

/** Accordions **/
/** Tabs **/
.ui-elements .tabs {
  overflow: hidden;
}

.ui-elements .tabs li {
  display: inline-block;
  cursor: pointer;
}

.ui-elements .button-tabs .tabs {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  background: #FF385C;
  border-radius: 9px;
}

.ui-elements .button-tabs .tabs > li {
  margin-bottom: 0px;
  float: left;
}

.ui-elements .button-tabs .tabs > li:last-child {
  margin-right: 0;
}

.ui-elements .button-tabs .tab-title {
  padding: 13px;
  display: inline-block;
  text-align: center;
  border-radius: 9px;
  width: auto;
  background: #FF385C;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ui-elements .bg-secondary .button-tabs .tab-title {
  background: #eee;
}

.ui-elements .button-tabs .tab-title span {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.ui-elements .button-tabs .active .tab-title {
  background: #ff797d;
  color: #fff;
  border-radius: 9px;
}

.ui-elements .button-tabs .active .tab-title:hover {
  background: #ff797d;
}

.ui-elements .button-tabs.vertical {
  overflow: hidden;
}

.ui-elements .button-tabs.vertical > .tabs {
  max-width: 30%;
  float: left;
}

.ui-elements .button-tabs.vertical > .content {
  padding-left: 3%;
  max-width: 70%;
  float: right;
}

@media all and (max-width: 767px) {
  .ui-elements .button-tabs .tabs li {
    width: 49%;
    margin-bottom: 15px;
  }
}
.ui-elements .tabbed-content .content > li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: none;
}

.ui-elements .tabbed-content .content .active {
  opacity: 1;
  visibility: visible;
  display: block;
}

.ui-elements .text-tabs .tabs {
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
  overflow: visible;
}

.ui-elements .text-tabs.no-border .tabs {
  border-bottom: none;
}

.ui-elements .text-tabs .tab-title {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0 24px;
  opacity: 0.5;
  position: relative;
}

.ui-elements .text-tabs .tab-title span {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}

.ui-elements .text-tabs .tab-title:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #47b475;
  bottom: 10px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  bottom: -17px;
  left: 0;
}

.ui-elements .text-tabs .active .tab-title {
  opacity: 1;
  color: #47b475;
}

.ui-elements .bg-primary .text-tabs .tab-title:after {
  background: #b3e1c6;
}

.ui-elements .text-tabs .active .tab-title:after {
  opacity: 1;
}

.ui-elements .text-tabs .tab-title:hover {
  opacity: 1;
}

@media all and (max-width: 767px) {
  .ui-elements .text-tabs .tabs {
    padding-bottom: 0;
  }

  .ui-elements .text-tabs .tabs li {
    width: 49%;
    margin-bottom: 15px;
  }

  .ui-elements .text-tabs .tab-title:after {
    display: none;
  }

  .ui-elements .text-tabs:not(.text-center) .tabs li:first-child .tab-title {
    padding-left: 24px;
  }
}
/** Tabs **/
/** Table **/
.ui-elements table.basic-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  margin-bottom: 15px;
}

.ui-elements table.basic-table th {
  background-color: #66676b;
  text-align: left;
  color: #fff;
  vertical-align: top;
  font-weight: 500;
}

.ui-elements table.basic-table th:first-child {
  border-radius: 4px 0 0 4px;
}

.ui-elements table.basic-table th:last-child {
  border-radius: 0 4px 4px 0;
}

.ui-elements table.basic-table th,
.ui-elements table.basic-table td {
  padding: 15px 28px;
}

.ui-elements table.basic-table tr:nth-child(odd) {
  background-color: #f6f6f6;
}

.ui-elements table.basic-table {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .ui-elements table.basic-table {
    border: 0;
  }

  .ui-elements table.basic-table th {
    display: none;
  }

  .ui-elements table.basic-table tr {
    display: block;
    margin-bottom: 0;
  }

  .ui-elements table.basic-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 14px;
    text-align: right;
  }

  .ui-elements table.basic-table td:before {
    content: attr(data-label);
    float: left;
    font-weight: 600;
  }

  .ui-elements table.basic-table td:last-child {
    border-bottom: 0;
  }
}
/** Table **/
/** Notifications **/
.ui-elements #result .success,
.ui-elements .notification {
  line-height: 24px;
  margin-bottom: 15px;
  position: relative;
  padding: 20px 26px;
  padding-right: 50px;
  border-radius: 3px;
}

.ui-elements .notification p {
  color: #fff;
  margin: 0;
  font-size: 15px;
}

.ui-elements #result .success,
.ui-elements .notification.success {
  background-color: #31c77f;
}

.ui-elements #result .success,
.ui-elements .notification.success,
.ui-elements .notification.success a,
.ui-elements .notification.success strong {
  color: #31c77f;
}

.ui-elements .notification.error {
  background-color: #e72660;
}

.ui-elements .notification.error,
.ui-elements .notification.error a,
.ui-elements .notification.error strong {
  color: #e72660;
}

.ui-elements .notification.warning {
  background-color: #f19711;
}

.ui-elements .notification.warning,
.ui-elements .notification.warning a,
.ui-elements .notification.warning strong {
  color: #f19711;
}

.ui-elements .notification.notice h4 {
  font-size: 19px;
  margin: 3px 0 15px;
}

.ui-elements .notification.notice h4,
.ui-elements .notification.notice,
.ui-elements .notification.notice a,
.ui-elements .notification.notice strong {
  color: #17a2b8;
}

.ui-elements .notification.notice {
  background-color: #17a2b8;
}

.ui-elements .notification.success .close,
.ui-elements .notification.error .close,
.ui-elements .notification.warning .close,
.ui-elements .notification.notice .close {
  padding: 0 9px;
  position: absolute;
  color: #fff;
  right: 0;
  top: 22px;
  display: block;
  height: 8px;
  width: 8px;
  cursor: pointer;
}

.ui-elements .notification.notice p span i {
  font-weight: 500;
}

.ui-elements .notification a.button {
  float: right;
  color: #fff;
  margin-top: 3px;
}

.ui-elements .notification.notice a.button {
  background-color: #388fc5;
}

.ui-elements .notification.warning a.button {
  background-color: #dfbe51;
}

.ui-elements .notification.error a.button {
  background-color: #e72660;
}

.ui-elements .notification.success a.button {
  background-color: #79ba38;
}

.ui-elements .notification.closeable a.close:before {
  content: "\f00d";
  font-family: fontawesome;
  position: absolute;
  color: #fff;
  right: 25px;
  top: 0;
  cursor: pointer;
}

.ui-elements .close {
  opacity: .7;
}

/** Notifications **/
/** Buttons **/
.ui-elements .group-20 > * {
  margin-top: 0;
  margin-bottom: 1.25rem;
  margin-left: 1.25rem;
}

.ui-elements .ui-buttons .btn {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 2px solid transparent;
  font-weight: 700;
  -webkit-box-shadow: 10px 15px 18px rgba(23, 23, 36, 0.15);
          box-shadow: 10px 15px 18px rgba(23, 23, 36, 0.15);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0.6875rem 1.75rem;
  font-size: 1.0625rem;
  line-height: 1.47;
  border-radius: 0.375rem;
}

.ui-elements .ui-buttons .btn-primary {
  color: #fff;
  border-color: #31c77f;
  background-color: #31c77f;
}

.ui-elements .ui-buttons .btn-primary:hover {
  color: #fff;
  border-color: #2ca46d;
  background-color: #2ca46d;
}

.ui-elements .ui-buttons .btn-secondary {
  color: #fff;
  border-color: #6b39bd;
  background-color: #6b39bd;
}

.ui-elements .ui-buttons .btn-secondary:hover {
  color: #fff;
  border-color: #5a329e;
  background-color: #5a329e;
}

.ui-elements .ui-buttons .btn-warning {
  color: #fff;
  border-color: #f19711;
  background-color: #f19711;
}

.ui-elements .ui-buttons .btn-warning:hover {
  color: #fff;
  border-color: #c57d15;
  background-color: #c57d15;
}

.ui-elements .ui-buttons .btn-danger {
  color: #fff;
  border-color: #e72660;
  background-color: #e72660;
}

.ui-elements .ui-buttons .btn-danger:hover {
  color: #fff;
  border-color: #bd2354;
  background-color: #bd2354;
}

.ui-elements .ui-buttons .btn-dark {
  color: #fff;
  border-color: #171724;
  background-color: #171724;
}

.ui-elements .ui-buttons .btn-dark:hover {
  color: #fff;
  border-color: #44444e;
  background-color: #44444e;
}

.ui-elements .ui-buttons .btn-primary.btn-outline {
  color: #31c77f;
  border-color: #31c77f;
  background-color: transparent;
}

.ui-elements .ui-buttons .btn-primary.btn-outline:hover {
  color: #fff;
  background-color: #31c77f;
}

.ui-elements .ui-buttons .link.link-secondary {
  color: #6b39bd;
}

.ui-elements .ui-buttons .link {
  position: relative;
  display: inline-block;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
  background: none;
  -webkit-transition: .25s;
  transition: .25s;
  color: inherit;
}

.ui-elements .ui-buttons .link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0;
  border-bottom: 2px solid;
  -webkit-transition: width .25s;
  transition: width .25s;
}

.ui-elements .ui-buttons .link:hover {
  color: #5a329e;
}

.ui-elements .ui-buttons .link:hover::before {
  width: 100%;
  opacity: 1;
}

.ui-elements .ui-buttons h4.effect {
  font-family: Lato, sans-serif;
  font-size: 18px;
  color: #444;
  font-weight: 400;
}

.ui-elements .ui-buttons .btn-anis {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  overflow: hidden;
  -webkit-transition: .25s;
  transition: .25s;
}

.ui-elements .ui-buttons .btn-anis::before, .btn-anis::after {
  content: '';
  position: absolute;
  top: 50%;
  z-index: -1;
  padding: 10px;
  border-radius: 50%;
  opacity: 1;
  -webkit-transition: 0s;
  transition: 0s;
  will-change: transform;
}

.ui-elements .ui-buttons .btn-anis::before {
  left: -30%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.ui-elements .ui-buttons .btn-anis::after {
  right: -30%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.ui-elements .ui-buttons .btn-anis:hover {
  -webkit-transition: all .25s, border-color .3s .5s, background-color .3s .5s;
  transition: all .25s, border-color .3s .5s, background-color .3s .5s;
}

.ui-elements .ui-buttons .btn-anis:hover::before, .btn-anis:hover::after {
  padding: 50%;
  opacity: 0;
  -webkit-transition: left .4s, right .4s, padding .3s .5s, opacity .05s .75s;
  transition: left .4s, right .4s, padding .3s .5s, opacity .05s .75s;
}

.ui-elements .ui-buttons .btn-anis:hover::before {
  left: 50%;
}

.ui-elements .ui-buttons .btn-anis:hover::after {
  right: 50%;
}

.ui-elements .ui-buttons .btn-anis.btn-outline:hover {
  -webkit-transition: all .25s, background-color .1s .7s, color .3s .5s;
  transition: all .25s, background-color .1s .7s, color .3s .5s;
}

.ui-elements .ui-buttons .btn-primary.btn-anis::before,
.ui-elements .ui-buttons .btn-primary.btn-anis::after {
  background-color: #2ca46d;
}

.ui-elements .ui-buttons .btn-primary.btn-anis.btn-outline::before,
.ui-elements .ui-buttons .btn-primary.btn-anis.btn-outline::after {
  background-color: #31c77f;
}

.ui-elements .ui-buttons .btn-secondary.btn-anis::before,
.ui-elements .ui-buttons .btn-secondary.btn-anis::after {
  background-color: #5a329e;
}

.ui-elements .ui-buttons .btn-secondary.btn-anis.btn-outline::before,
.ui-elements .ui-buttons .btn-secondary.btn-anis.btn-outline::after {
  background-color: #6b39bd;
}

.ui-elements .ui-buttons .btn-success.btn-anis::before,
.ui-elements .ui-buttons .btn-success.btn-anis::after {
  background-color: #649922;
}

.ui-elements .ui-buttons .btn-success.btn-anis.btn-outline::before,
.ui-elements .ui-buttons .btn-success.btn-anis.btn-outline::after {
  background-color: #77ba21;
}

.ui-elements .ui-buttons .btn-info.btn-anis::before,
.ui-elements .ui-buttons .btn-info.btn-anis::after {
  background-color: #1475be;
}

.ui-elements .ui-buttons .btn-info.btn-anis.btn-outline::before,
.ui-elements .ui-buttons .btn-info.btn-anis.btn-outline::after {
  background-color: #138ce4;
}

.ui-elements .ui-buttons .btn-warning.btn-anis::before,
.ui-elements .ui-buttons .btn-warning.btn-anis::after {
  background-color: #c57d15;
}

.ui-elements .ui-buttons .btn-warning.btn-anis.btn-outline::before,
.ui-elements .ui-buttons .btn-warning.btn-anis.btn-outline::after {
  background-color: #f19711;
}

.ui-elements .ui-buttons .btn-danger.btn-anis::before,
.ui-elements .ui-buttons .btn-danger.btn-anis::after {
  background-color: #bd2354;
}

.ui-elements .ui-buttons .btn-danger.btn-anis.btn-outline::before,
.ui-elements .ui-buttons .btn-danger.btn-anis.btn-outline::after {
  background-color: #e72660;
}

.ui-elements .ui-buttons .btn-light.btn-anis::before,
.ui-elements .ui-buttons .btn-light.btn-anis::after {
  background-color: #cacacd;
}

.ui-elements .ui-buttons .btn-light.btn-anis.btn-outline::before,
.ui-elements .ui-buttons .btn-light.btn-anis.btn-outline::after {
  background-color: #f7f7f7;
}

.ui-elements .ui-buttons .btn-dark.btn-anis::before,
.ui-elements .ui-buttons .btn-dark.btn-anis::after {
  background-color: #44444e;
}

.ui-elements .ui-buttons .btn-dark.btn-anis.btn-outline::before,
.ui-elements .ui-buttons .btn-dark.btn-anis.btn-outline::after {
  background-color: #171724;
}

.ui-elements .ui-buttons .btn-secondary.btn-outline {
  color: #6b39bd;
  border-color: #6b39bd;
  background-color: transparent;
}

.ui-elements .ui-buttons .btn-secondary.btn-outline:hover {
  color: #fff;
  background-color: #6b39bd;
}

.ui-elements .ui-buttons .btn-warning.btn-outline {
  color: #f19711;
  border-color: #f19711;
  background-color: transparent;
}

.ui-elements .ui-buttons .btn-warning.btn-outline:hover {
  color: #fff;
  background-color: #f19711;
}

.ui-elements .ui-buttons .btn-danger.btn-outline {
  color: #e72660;
  border-color: #e72660;
  background-color: transparent;
}

.ui-elements .ui-buttons .btn-danger.btn-outline:hover {
  color: #fff;
  background-color: #e72660;
}

.ui-elements .ui-buttons .btn-dark.btn-outline {
  color: #171724;
  border-color: #171724;
  background-color: transparent;
}

.ui-elements .ui-buttons .btn-dark.btn-outline:hover {
  color: #fff;
  background-color: #171724;
}

.ui-elements .ui-buttons .btn .fas {
  font-size: 1em;
  margin: 0 .47058em;
}

.ui-elements .ui-buttons .btn-lg,
.ui-elements .ui-buttons .btn-group-lg > .btn {
  padding: 1rem 2.5rem;
  font-size: 1.0625rem;
  line-height: 1.47;
  border-radius: 0.375rem;
}

.ui-elements .ui-buttons .btn-sm,
.ui-elements .ui-buttons .btn-group-sm > .btn {
  padding: 0.375rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.47;
  border-radius: 0.375rem;
}

.ui-elements .ui-buttons .link .fas {
  color: #31c77f;
}

.ui-elements section.headings-2 {
  padding: 6rem 0 6.5rem 0;
}

/** Buttons **/
/** Form Elements **/
.ui-elements input[type="text"],
.ui-elements input[type="password"] {
  background: #f5f5f5;
  border: none;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  border-radius: 0;
}

.ui-elements textarea {
  width: 100%;
  border: none;
  background: #f5f5f5;
  margin-bottom: 24px;
  border-radius: 0;
  padding: 16px 20px;
}

.ui-elements select {
  height: 50px;
  background: #f5f5f5;
  width: 100%;
  border-radius: 0;
  border: none;
  outline: none;
  padding-left: 20px;
  position: relative;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.ui-elements .select-option {
  position: relative;
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  margin-bottom: 24px;
}

.ui-elements .mb24 {
  margin-bottom: 24px;
}

.ui-elements .checkbox-option,
.ui-elements .radio-option {
  display: inline-block;
  width: 50px;
  height: 25px;
  border-radius: 25px;
  border: 1px solid #47b475;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 24px;
}

.ui-elements .pull-right {
  float: right !important;
}

.ui-elements .checkbox-option .inner,
.ui-elements .radio-option .inner {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #47b475;
  position: relative;
  top: 2px;
  left: 2px;
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.ui-elements .checkbox-option input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.ui-elements h4 {
  letter-spacing: 2px;
  margin-right: -2px;
  font-family: Lato, sans-serif;
  font-size: 18px;
  color: #444;
  font-weight: 400;
  margin-bottom: 24px;
}

.ui-elements .radio-option {
  width: 25px;
  height: 25px;
  text-align: left;
}

.ui-elements .radio-option.checked .inner {
  background: #47b475;
}

.ui-elements .radio-option input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.ui-elements .the-check-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ui-elements .radio-option + span {
  font-size: 13px;
  top: 0;
}

.ui-elements .radio-option .inner {
  border: none;
  width: 19px;
  height: 19px;
  left: 2px;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.ui-elements .checkbox-option.checked .inner {
  -webkit-transform: translate3d(25px, 0, 0);
          transform: translate3d(25px, 0, 0);
  background: #47b475;
}

.ui-elements .radio-option.checked .inner {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.ui-elements .form-elemts input[type="submit"],
.ui-elements .form-elemts button[type="submit"] {
  height: 50px;
  line-height: 48px;
  border: 2px solid #47b475;
  background: #47b475;
  color: #fff;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 0 !important;
}

/** Form Elements **/
/** Progress Bars **/
.ui-elements .skills {
  padding-top: 17px;
}

.ui-elements .skills h4 {
  font-weight: 600;
}

.ui-elements .skills h4::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #e72660;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.ui-elements .barfiller {
  width: 95%;
  height: 12px;
  background: #0f1341;
  border: none;
  position: relative;
  margin-bottom: 2rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui-elements .barfiller:last-child {
  margin-bottom: 0px;
}

.ui-elements .barfiller .fill {
  display: block;
  position: relative;
  width: 0px;
  height: 100%;
  background: #e72660 !important;
  z-index: 1;
}

.ui-elements .barfiller .tipWrap {
  display: none;
}

.ui-elements .barfiller .tip {
  margin-top: -30px;
  padding: 2px 4px;
  font-size: 11px;
  color: #fff;
  left: 0px;
  position: absolute;
  z-index: 2;
  background: #0f1341;
}

.ui-elements .barfiller .tip:after {
  border: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent;
  border-width: 6px 6px 0 6px;
  content: "";
  display: block;
  position: absolute;
  left: 9px;
  top: 100%;
  z-index: 9;
}

@media screen and (max-width: 991px) {
  .ui-elements .sks {
    padding-top: 5rem;
  }

  .ui-elements .skills .bar2 {
    margin-top: 1.5rem;
  }
}
.ui-elements .skills .skil1 ul li {
  list-style: none;
  text-align: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.ui-elements .skills .skil1 .fa {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #666;
  text-align: center;
}

.ui-elements .skills .skil1 li:last-child {
  text-align: center;
  font-size: 1.1rem;
}

.ui-elements .skills h3 {
  font-size: 1rem;
}

@media screen and (max-width: 400px) {
  .ui-elements .skills h3 {
    font-size: .9rem;
  }
}
.ui-elements .skills .sk1 {
  padding-bottom: 6.5rem;
}

.ui-elements .skil1 ul,
.ui-elements .skil1 h3 {
  margin-bottom: 0px;
}

/** Progress Bars **/
/** CountDowns **/
.ui-elements .countdown-boxed .countdown-block {
  padding: 0 10px;
}

.ui-elements .countdown-boxed .countdown-wrap {
  border-radius: 0.375rem;
  -webkit-box-shadow: 5px 8px 28px rgba(23, 23, 36, 0.1);
          box-shadow: 5px 8px 28px rgba(23, 23, 36, 0.1);
}

.ui-elements .countdown-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.ui-elements .countdown-boxed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  margin: 0 -15px;
  line-height: 1.2;
  text-align: center;
}

@media (min-width: 768px) {
  .ui-elements .countdown-boxed .countdown-counter {
    min-width: 100px;
    font-size: 32.4px;
  }
}
.ui-elements .countdown-boxed .countdown-counter {
  min-width: 80px;
  padding: 15px 10px;
  font-size: 32.4px;
  line-height: 1;
  color: #fff;
  background-color: #6b39bd;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}

.ui-elements .countdown-boxed .countdown-title {
  padding: 5px;
  font-size: 1.0625rem;
  font-weight: 400;
  color: #171724;
  text-transform: uppercase;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.ui-elements .slider_amount {
  color: #000;
  font-weight: 400;
}

.ui-elements .ui-widget.ui-widget-content {
  background: #e72660;
  width: 440px;
}

.ui-elements .hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-top: 2rem;
  font-size: 14px;
  cursor: pointer;
  background: #e72660;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  padding: 12px 22px;
  border-radius: 0;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.ui-elements .hvr-bounce-to-right:hover {
  background: #0f1341;
}

.ui-elements .main-search-field-2 input[type="text"] {
  background: #fff;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: left;
}

.ui-elements .first-footer .bloq-email .email input[type="submit"] {
  line-height: inherit;
}

@media screen and (max-width: 991px) {
  .ui-elements .ui-widget.ui-widget-content {
    width: 340px;
  }
}
@media screen and (max-width: 767px) {
  .ui-elements .theskills {
    margin: 70px 0;
  }

  .ui-elements .ui-widget.ui-widget-content {
    width: 440px;
  }
}
@media screen and (max-width: 575px) {
  .ui-elements .theskills {
    margin: 70px 0;
  }

  .ui-elements .ui-widget.ui-widget-content {
    width: 340px;
  }
}
/** CountDowns **/
/*-----------------------------------*/
/*------ 73. PAGE SHOP ------*/
/*-----------------------------------*/
.shop section.blog-section {
  background: #f5f7fb;
}

.shop .blog .blog-info {
  padding: 20px 25px;
  background: #fff;
  height: 217px;
}

.shop .blog .blog-pots h3 {
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0;
}

.shop .product-old-price {
  color: #858585;
  text-decoration: line-through;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 22px;
}

.shop .starts {
  margin: 0px;
}

.shop .starts li {
  margin-bottom: 0px;
}

.shop p.recent-price {
  color: #fe4f4f;
  font-weight: bold;
  margin-bottom: 30px;
}

.shop .btn1 {
  background: #fe4f4f;
  border: 1px solid #fe4f4f;
  border-radius: 8px;
  text-shadow: none;
  text-decoration: none;
  padding: 15px;
  border-right: 8px;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  border: 1px solid #e6e6e6;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.shop .btn1:hover {
  background: #fff;
  color: #fe4f4f;
  border: 1px solid #fe4f4f;
}

.shop .fa-shopping-cart {
  margin-right: .5rem;
}

.shop .blog-section .news-item {
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.single-p-info.shop h2 {
  font-weight: 600;
  text-transform: capitalize;
}

.checkout section.payment-method {
  padding: 6rem 0 !important;
}

[data-animate] {
  opacity: 0;
}

[data-animate].animated {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .sin-1 .thumax-2 {
    margin-bottom: 30px;
  }

  .single-p-info h2 {
    margin-top: 20px;
  }
}
/*-----------------------------------*/
/*------ 74. MEDIAS QUERIES ------*/
/*-----------------------------------*/
@media screen and (min-width: 992px) {
  .search-button {
    margin-left: 4rem;
    margin-right: 0;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  #bloq-search {
    position: absolute;
    width: 20rem;
    right: 17.5rem;
    top: 2.5rem;
    z-index: 99999;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  .main-search-field h3 {
    font-size: 1.5rem;
  }

  .portfolio .port-item img {
    width: 100%;
  }

  .team .team-pro img {
    width: 100%;
  }

  .team .team-pro {
    padding-right: 1.1rem;
    padding-left: 1.1rem;
  }

  .blog .blog-pots img {
    width: 100%;
  }

  .agents .admin.company img {
    width: 30%;
    height: 30%;
  }

  .agents .admin.company {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .second-footer .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .second-footer .container h3 {
    margin-bottom: .8rem;
  }

  .box.bg-3.hed-3 {
    padding-top: 0px;
  }

  .inner-pages .commented img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
  }

  .inner-pages .offices h2 {
    margin-top: 4rem;
  }

  .inner-pages .box.bg-3.mt-5 {
    padding-top: 0px;
  }

  .parallax-search .welcome-text h1 {
    font-size: 30px;
  }

  .agents-grid {
    margin-right: 0px;
  }

  .inner-pages .blog-section .news-item-descr.big-news.details {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .header-top .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .top-info {
    margin-bottom: 1rem;
  }

  #navigation {
    margin-left: 0px;
  }

  .rld-main-search .rld-single-input,
  .rld-main-search .rld-single-select .single-select {
    margin-bottom: 15px !important;
  }

  .testimonials .test-1 p::before {
    left: 170px;
  }
}
@media screen and (max-width: 992px) {
  h3 {
    font-size: 1.2rem;
  }

  .headings .text-heading p {
    font-size: 1rem;
  }

  .header-top .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .top-info {
    margin-bottom: 1rem;
  }

  .who-we-are .who img {
    height: 415px;
  }

  .blog .blog-pots h3 {
    font-size: 1.2rem;
  }

  .portfolio img {
    width: 100%;
  }

  .team .team-info .artl {
    padding-bottom: 1.3rem;
  }

  .testimonials .test-1 p::before {
    left: 120px;
  }
}
@media screen and (max-width: 576px) {
  body {
    font-size: 15px;
  }

  h1 {
    font-size: 1.6rem;
  }

  .section-title h3 {
    font-size: 16px;
  }

  .section-title h2 {
    font-size: 26px;
  }

  .who-we-are .who img {
    height: 300px;
  }

  .counterup .countr p {
    font-size: 26px;
  }

  .testimonials .test-1 p {
    padding: 0px 1rem;
  }

  .team .team-pro {
    padding-right: 0;
    padding-left: 0;
  }

  .banner-inner h1 {
    font-size: 28px;
    line-height: 37px;
  }

  .banner-inner .sub-title {
    font-size: 17px;
  }

  .inner-pages .services {
    margin: 0px;
  }

  .inner-pages article.faq h2 {
    font-size: 1.4rem;
  }

  .inner-pages .panel .panel-heading a {
    font-size: 1rem;
  }

  .first-footer h2 {
    margin-top: 2rem;
  }

  .inner-pages .blog .sorting-options h5 {
    font-size: 1.2rem;
    margin-top: 0px;
  }

  .inner-pages .blog .block-heading .sorting-options {
    margin-top: 0px;
  }

  .inner-pages .blog .sorting-options h6 {
    font-size: 0.95rem;
  }

  .inner-pages .blog .homes-content.details-2 span {
    font-size: .9rem;
  }

  .inner-pages .headings h2 a,
  .inner-pages .headings h2 {
    font-size: 1rem;
  }

  .inner-pages .contact-us .offices {
    margin-top: 2rem;
  }

  .headings-7 .text-heading p.sorry {
    font-size: 1.1rem;
  }

  .font-36 {
    font-size: 26px;
  }
}

/*# sourceMappingURL=styles.css.map */


.listing-details-sliders .carousel-indicators.dots{
    position: relative;
    left: initial;
    width: initial;
    margin-left: initial;
    z-index: 9999;
    margin: -35px auto 50px auto;
}

.listing-details-sliders .carousel-indicators.dots > li {
    height: initial;
    text-indent: initial;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    background: #FFF;
    margin-left: 0;
    text-align: center;
    border-radius: 100%!important;
    border: none;
}

.listing-details-sliders .carousel-indicators.dots a {
    cursor: pointer;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
}