body{
}

h1, h2, h3, #navigation ul a{
    color: #272B2B;
}

.header-container{
    border-bottom: #D6E1E0;
}

.bg-home1{
    position: absolute;
    top: 0;
    left: 0;
}

.bg-home2{
    position: absolute;
    bottom: 0;
    right: 0;
}

.event-container{
    background: rgba(39, 43, 43, 1);
    font-size: 14px;
    line-height: 42px;
    color: rgba(242, 247, 247, 1);
}

.event-link{
    color: rgba(225, 227, 62, 1);
    font-weight: 600;
    text-align: left;
}

.event-link:hover{
    color: rgba(204, 206, 50, 1);
    text-decoration: none;
}

#header-container.fixed, #header-container.search-open.fixed{
    position: fixed;
    background: rgba(255, 255, 255, 0.90);
    backdrop-filter: blur(40px);
    z-index: 9999;
    width: 100%;
    top: 0;
    transition: all 0.3s ease-in-out;
}

.overlay-search{
    position: fixed;
    display:block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.25);
    top: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.overlay-search.visible{
    opacity: 1;
    cursor: unset;
    pointer-events: auto;
}

.comeca-btn{
    padding: 16px 32px;
    border-radius: 8px;
    color: #272B2B;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    display: inline-block;
    line-height: 1em;
    font-size: 16px;
}

.btn-green{
    background: #9AD3B5;
    border: 1px solid #9AD3B5;
}

.btn-green:hover{
    background: rgba(175, 226, 200, 1);
    color: rgba(67, 71, 71, 1);
    border: 1px solid #9AD3B5;
    text-decoration: none;
}

.btn-dark{
    color: #FFFFFF;
    background: #272B2B;
    font-size: 15px;
}

.btn-dark:hover{
    background: rgba(67, 71, 71, 1);
    color: rgba(242, 247, 247, 1);
    text-decoration: none;
}


.btn-clear{
    border: 1px solid #272B2B;
    background: transparent;
}

.btn-clear:hover{
    color: rgba(67, 71, 71, 1);
    border-color: rgba(67, 71, 71, 1);
    text-decoration: none;
}


.home-part-1{
    margin-top: 59px;
}

.mt-32{
    margin-top: 32px;
}

.mt-0{
    margin-top: 32px;
}


.mb-32{
    margin-bottom: 32px;
}

.mb-0{
    margin-bottom: 32px;
}

.bg-green{
    background: #9AD3B5;
}

.bg-light-green{
    background: rgb(154,211,181);
    background: linear-gradient(90deg, rgba(154,211,181,1) 0%, rgba(199,248,222,1) 100%);
}

.home-part-2{
    padding-top: 72px;
    padding-bottom: 69px;
}

.home-part-2 .data{
    font-size: 48px;
    font-weight: 600;
    padding-left: 56px;
    line-height: 1em;
    margin-left: 20%;
    color: #272B2B;
}

.data.recommandation{
    background: url(../images/record_voice_over.webp) top left no-repeat;
}
.data.immodvisor{
    background: url(../images/hotel_class.webp) top left no-repeat;
    line-height: .9em;
}
.data.maisons{
    background: url(../images/villa.webp) top left no-repeat;
}

.arbres-container{
    position: relative;
    margin-bottom: 36px;
}

.arbres-counter{
    background: #9AD3B5;
    padding: 32px;
    border-radius: 16px;
    display: block;
    bottom: -36px;
    right: 36px;
    position: absolute;
}

.data.arbres{
    background: url(../images/volunteer_activism.webp) top left no-repeat;
    font-size: 48px;
    font-weight: 600;
    padding-left: 56px;
    line-height: 1em;
    color: #272B2B;
    border: none;
}

.data small{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: block;
}

.v-separator{
    position: absolute;
    right: 0;
    height: 48px;
    border-right: 1px solid #434747;
    top: 10px;
}
.listing-details-sliders .carousel-indicators.dots{
    margin-bottom: 60px;
}

.mt-180{
    margin-top: 180px;
}
.mb-180{
    margin-bottom: 180px;
}

.home-part-3{
    margin-top: 180px;
    margin-bottom: 180px;
}

.tuile-maison{
    margin-left: -5px;
    overflow: hidden;
    margin-right: -5px;
    margin-top: 80px;
}

.tuile-maison a.img{
    display: block;
    overflow: hidden;
    width: 355px;
    height: 376px;
    border-radius: 16px;
}

.tuile-maison a.img img{
    border-radius: 16px;
    transition: all .3s ease-in-out;
}

.tuile-maison a.img:hover img {
   transform: scale(110%);
}


.tuile-maison h3{
    font-weight: 600;
    font-size: 18px;
    margin-top: 23px;
    margin-bottom: 14px;
    line-height: 1em;
}

.tuile-maison p{
    color: #626464;
}

.engagement h3{
    font-size: 20px;
    font-weight: 600px;
    margin-top: 16px;
    margin-bottom: 16px;
}


.parrainage{
    border-radius: 16px;
    padding: 32px;
    position: relative;
}

.parrainage h2{
    font-size: 21px;
}


.parrainage .text{
}

#vitahomeForm a{
    text-decoration: underline;
    color: #000;
}

.nav-footer.agencies ul li a{
    text-transform: capitalize;
    background: url(../images/location_on.svg) left center no-repeat;
    padding-left: 25px;
    margin-left: -5px;
    margin-right: -5px;
}


.nav-footer ul li a:hover{
    text-decoration: underline;
}

.nav-footer ul li a:hover span{
   color: #434747;
}

.title.alt{
    color: #949E9D;
    margin-top: 13px;
    font-weight: 500;
}

.netsocials{
    float: right;
    text-align: right;
}

.social{
    display: block;
    margin-top: 5px;
    height: 20px;
    width: 20px;
    margin-left: 24px;
}

.social.fb{
    background: url(../images/fb.webp) center center no-repeat;
}
.social.yt{
    background: url(../images/yt.webp) center center no-repeat;
}
.social.insta{
    background: url(../images/insta.webp) center center no-repeat;
}
.social.linkedin{
    background: url(../images/linkedin.webp) center center no-repeat;
}
.social.pinterest{
    background: url(../images/pinterest.webp) center center no-repeat;
}

.homepage-2 .right-side{
    width: 290px;
}

#btn-menu-search{
    cursor: pointer;
}

#btn-menu-search{
    float: left;
    display: block;
    margin-right: 10px;
    width: 236px;
    height: 42px;
    padding: 4px 6px 4px 24px;
    border: 1px solid #D6E1E0;
    border-radius: 22px;
    position: relative;
    font-weight: 500;
    transition: all 0.5s ease-in-out;
}


#btn-menu-search:hover{
box-shadow: 0px 4px 8px 0px rgba(159, 166, 168, 0.5);
}
#btn-menu-search.search-open{
    opacity:0;
}

#btn-menu-search .text{
    float: left;
    color: #272B2B;
    line-height: 34px;
    transition: all 0.5s ease-in-out;
}

#btn-menu-search .btn-search{
    display: block;
    float: right;
    width: 32px;
    height: 32px;
    background: url(../images/search.webp) 50% 50% no-repeat #9AD3B5;
    position: relative;
    border-radius: 16px;
    transition: all 0.5s ease-in-out;
}

.search-form .btn-search:hover{
    background-color: rgba(154, 211, 181, 0.8);
}

#header-container.search-open #btn-menu-search .btn-search{
    background: url(../images/close.webp) 50% 50% no-repeat #272B2B;
    transition: all 0.5s ease-in-out;
}


#header-container{
    border: 1px solid #D6E1E0;
    transition: all 0.3s ease-in-out;
    height: 82px;
}

.search-form{
    border: 1px solid #D6E1E0;
    border-radius: 32px;
    padding: 6px;
    margin-bottom: 16px;
    transition: all .5s ease-in-out;
    top: -80px;
    opacity: 0;
}


.search-form .btn-search{
    display: block;
    float: right;
    width: 48px;
    height: 48px;
    background: url(../images/search-big.webp) 50% 50% no-repeat #9AD3B5;
    position: relative;
    border-radius: 32px;
    transition: all 0.5s ease-in-out;
}

.search-form .form-group{
    padding-left: 24px;
    margin: 0;
}

.search-form .form-group:after{
    display: block;
    content:"";
    height: 32px;
    border-right: 1px solid #DEDEDE;
    position: absolute;
    top: 8px;
    right: -13px;
}

.search-form .form-group.last:after{
    display: none;
}

.search-form .form-group label{
    font-weight: 600;
    font-size: 12px;
    line-height: 1em;
    margin-bottom: 0;
}

.search-form .form-group input{
    font-size: 14px;
    line-height: 1em;
    font-weight: 400;
    color: #727272;
    border: none;
    padding: 0;
    margin-top: 1px;
    width: 100%;
}

.search-form .form-group .nice-select{
    font-size: 14px;
    line-height: 1em;
    font-weight: 400;
    color: #727272;
    border: none !important;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    height: auto;
    border-right: 1px solid #DEDEDE;
}

#header-container.search-open{
    height: 161px;
    background: #FFF;
    z-index: 9999;
    position: relative;
}

#header-container.search-open.annonces{
    height: auto;
    padding-bottom: 16px;
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: #FFF;
}

#header-container.search-open .search-form{
    opacity: 1;
    top: 0;
}

#header-container.search-open.annonces .search-form{
    margin-bottom: 0;
}

#header-container.search-open.annonces .reset{
    display: initial;
}

.half-map-view .first-footer{
    display:none;
}

#header-container .nice-select:after{
    display: none;
}

.nice-select.open .list{
    padding: 20px 26px;
    background: #FFF;
    box-shadow: 0px 3px 9px 0px rgba(34, 34, 34, 0.25);
    border-radius: 32px;
    margin-top: 15px;
    margin-left: -28px;
    border: none;
}

.nice-select .option{
    line-height: 42px!important;
    font-size: 16px;
    color: #434747;
}

.nice-select .option.focus, .nice-select .option.selected.focus, .nice-select .option:hover{
    background-color: #FFF;
}

.nice-select .option:hover{
    text-decoration: underline;
}

.typeahead{
    padding: 26px 32px;
    background: #FFF;
    box-shadow: 0px 3px 9px 0px rgba(34, 34, 34, 0.25);
    border-radius: 32px;
    margin-top: 15px;
    margin-left: -28px;
    border: none;
}

.typeahead li a{
    padding-left: 50px!important;
    background: url(../images/marker-list.svg) center left no-repeat;
    line-height: 42px!important;
    font-size: 16px;
    color: #434747;
}

.reset{
  color: rgba(78,174,123,1);
  display: none;
}

section.portfolio{
    padding-top: 186px;
}
section.headings-2{
    background: none;
    padding-top: 39px;
}

.half-map-view .headings-2{
    padding-top: 39px;
}

.half-map-view .google-maps-right #map-canvas{
    height: calc(100vh - 186px);
}

.pro-wrapper {
    align-items: end;
}

.annonces-title h1{
    font-size: 21px;
    line-height: 40px;
}

.annonces-title h1 span{
    font-size: 16px;
    font-weight: 500;
    color: rgba(148, 158, 157, 1);
}

.sortby-container label{
    font-weight: 400;
    color: rgba(148, 158, 157, 1);
    line-height: 1em;
    margin-bottom: 1.2rem;
}

.sortby-select{
    border: none;
    line-height: 1em;
    height: auto;
    margin-bottom: 1.2rem;
}

.sortby-select{
    font-weight: 500;
    font-size: 16px;
    color: rgba(39, 43, 43, 1);
}

.sortby-select::after{
    border-bottom: 2px solid #2f3030;
    border-right: 2px solid #2f3030;
    height: 9px;
    margin-top: -7px;
    right: 12px;
    width: 9px;
}

.breadcrumb{
    background: none!important;
    color: rgba(148, 158, 157, 1)!important;
    padding: 0!important;
    margin: 0 0 16px 0!important;
    font-size: 14px;
}

.breadcrumb a, .breadcrumb span{
    color: rgba(148, 158, 157, 1)!important;
    font-size: 14px!important;
    font-weight: 400!important;
}

.nice-select:hover, .sortby-select:hover{
    color: rgba(78,174,123,1) !important;
}

.half-map-view .headings-2 {
    padding-top: 16px;
    padding-bottom: 0;
}

.homepage-2 .portfolio .project-head:before{
    display: none;
}

.homepage-2 .portfolio .project-single {
    border-radius: 16px;
    border: 1px solid rgba(200, 200, 200, 0);
    transition: all .3s ease-in-out;
}

.homepage-2 .portfolio .project-single:hover{
    border: 1px solid rgba(200, 200, 200, 1);
    box-shadow: 0px 3px 9px 0px rgba(34, 34, 34, 0.25);
}

.portfolio .homes-content{
    padding: 16px;
    font-weight: 500;
    font-size: 16px;
    color: rgba(39, 43, 43, 1);
}

span.text-price{
    font-size: 21px;
    font-weight: 600;
}

span.text-area{
    padding-left: 24px;
    line-height: 20px;
    background: url(../images/open_in_full.svg) top left no-repeat;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate{
    transition-delay: 0s;
}

.portfolio .homes-address{
    padding-left: 20px;
    line-height: 24px;
    background: url(../images/location_on.svg) top left no-repeat;
    margin: 0;
}

.portfolio .homes-content .homes-address a span{
    color: rgba(39, 43, 43, 1);
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
}

.homepage-2 .popular-places .listing-badges .featured, .homepage-2 .homes-tag.featured{
    border-radius: 16px!important;
    width: auto!important;
    background-color: rgba(39, 43, 43, 1)!important;
    color: rgba(255, 255, 255, 1)!important;
    border-radius: 8px!important;
    top: -7px;
    left: 8px;
    padding: 0 16px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
}

.homepage-2 .portfolio .project-head img{
    opacity: 1;
}

.title-detail-annonce h1{
    font-size: 36px;
}

input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}

input[type=checkbox] {
    border-radius: 3px;
    height: 15px;
    width: 15px;
    background: #fff;
    border: 1px solid #ccc;
    text-align: center;
    margin-left: -24px;

}

input[type="checkbox"]:checked {
  background: #9AD3B5;
  position: relative;
}

input[type="checkbox"]:checked:before {
    font-family: FontAwesome;
    content: '\f00c';
    display: block;
    color: #FFF;
    font-size: 10px;
    line-height: 16px;
    position: absolute;
    margin-left: 2px;
    margin-top: -2px;
  }

.listing-details-sliders img{
    border-radius: 16px;
}

.listing-details-sliders .left, .listing-details-sliders .right {
    background: #FFF;
    color: #222;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.listing-details-sliders .left i, .listing-details-sliders .right i {
    font-size: 23px;
    color: #222;
}

.listing-details-sliders .left:hover, .listing-details-sliders .right:hover{
    background: #FFF;
}

.img-rounded{
    border-radius: 16px;
}

#map-single{
    border-radius: 16px;
    height: 300px;
}

.listing-title-bar{
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
}

.listing-title-bar .price{
    color: #272B2B;
}

.single.detail-wrapper{
    margin: 0;
}

.homes-list{
    padding-left: 0;
    margin-left: 0;
}

.homes-list li{
    list-style: none!important;
    font-size: 21px;
    font-weight: 600;
    color: rgba(39, 43, 43, 1);
    margin-bottom: 24px;
    padding-left: 40px;
    line-height: 24px;
}

.homes-list li.li-area{
    background: url(../images/icons/open_in_full.svg) top left no-repeat;
}
.homes-list li.li-price{
    background: url(../images/icons/euro.jpg) top left no-repeat;
}
.homes-list li.li-lotissement{
    background: url(../images/icons/villa.svg) top left no-repeat;
}
.homes-list li.li-map{
    background: url(../images/icons/location_on.svg) top left no-repeat;
}
.homes-list li.li-padding{
    margin-left: -40px;
}
.homes-list li.li-padding i{
    width: 24px;
    height: 24px;
    text-align: center;
}

.homes-list li.li-padding span{
    padding-left: 16px;
}

.homes-list li small{
    font-size: 14px;
    font-weight: 400;
    color: rgba(98, 100, 100, 1);
    list-style: 20px;
}

.subtitle2{
    font-size: 21px;
    margin-bottom: 24px;
}


#vitahomeForm label, #parrainage-form label{
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 600;
}
#vitahomeForm label span, #parrainage-form label span{
    color: rgba(154, 211, 181, 1);
    font-weight: 400;
}

#vitahomeForm label.checkbox-label{
    font-size: 14px;
    padding-left: 24px;
    font-weight: 400;
    color: #272B2B;
}

#vitahomeForm input.form-control, #vitahomeForm textarea, #vitahomeForm, #parrainage-form input.form-control, #parrainage-form  textarea, #parrainage-form{
    border: none;
    padding: 0;
}

#vitahomeForm input.form-control, #parrainage-form input.form-control{
    margin-top: -2px;
}

#vitahomeForm .form-control, #parrainage-form .form-control{
    height: auto;
    color: #949E9D;
}

#vitahomeForm .form-group, #parrainage-form .form-group{
    border: 1px solid rgba(148, 158, 157, 1);
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
}

section.single-proper.details{
    padding-top: 32px!important;
}

.aside-cta{
    font-size: 14px;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(113, 113, 113, 0.15);
    border: 1px solid rgba(214, 225, 224, 1);
    max-width: 350px;
}

.aside-cta h3{
    font-size: 21px;
    font-weight: 600;
}

.aside-cta.fixed{
    position: fixed;
    top: 20px;
    min-width: 350px;
}

.mt-100{
    margin-top: 100px!important;
}

.pt-100{
    padding-top: 80px!important;
}
.pb-100{
    padding-bottom: 80px!important;
}

.maisons-1{
    background: url('../images/ciel.webp') top center no-repeat;
    background-size: cover;
}

.maisons-1 img{
    position: relative;
    margin-bottom: -160px;
}

.maisons-1 h1{
font-family: Poppins;
font-size: 80px;
font-weight: 600;
line-height: 80px;
letter-spacing: 0em;
text-align: center;

}
.maisons-1 h2{
font-family: Poppins;
font-size: 36px;
font-weight: 400;
line-height: 47px;
letter-spacing: 0em;
text-align: center;

}
.maisons-2{
    padding-top: 260px;
}

.maisons-2 .h1{
font-size: 48px;
font-weight: 600;
line-height: 62px;
letter-spacing: 0em;
text-align: left;
color: rgba(39, 43, 43, 1);
}

a.link-arrow{
    color: rgba(39, 43, 43, 1);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding-right: 20px;
    background: url(../images/arrow_back_ios_new.svg) center right no-repeat;
}

.gamme-slide.listing-details-sliders .img-fluid{
    width:100%;
    max-width: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.gamme-slide .left, .gamme-slide .right{
    top: 45%;
}

.gamme-slide .carousel-inner{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px rgba(214, 225, 224, 0.5);
}

.gamme-slide .item{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px rgba(214, 225, 224, 0.5);
}


.gamme-slide .item p{
    padding: 20px 16px 8px 16px;
    margin-bottom: 0;
font-size: 16px;
font-weight: 600;
color: rgba(39, 43, 43, 1);

}
.gamme-slide .item ul{
    padding: 0 16px 0 16px;
    margin: 0 0 16px 0;
}

.gamme-slide .item ul li{
    padding: 0;
    padding-top: 3px;
    list-style: none;
    float: left;
    color: rgba(98, 100, 100, 1);
    padding-left: 32px;
    padding-right: 16px;
    margin-bottom: 20px;
    line-height: 24px;
}

.gamme-slide .item ul li.li-area{
    background: url(../images/icons/activity_zone.svg) center left no-repeat;
}
.gamme-slide .item ul li.li-garage{
    background: url(../images/icons/garage_home.svg) center left no-repeat;
}
.gamme-slide .item ul li.li-bedroom{
    background: url(../images/icons/bed.svg) center left no-repeat;
}

.maisons-3 h2, .maisons-4 h2, .maisons-5 h2, .maisons-6 h2{
font-size: 48px;
font-weight: 600;
line-height: 62px;
letter-spacing: 0em;
text-align: left;

}

.maisons p{
    line-height: 26px;
}

.img-garantie {
    margin-top: 350px;
}

.img-garantie img{
    margin-top: -250px;
}

.maisons-5 h3{
font-size: 21px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0em;
text-align: left;

}

.ui-elements .accordion-1 .title:hover{
    background-color: transparent;
}
.ui-elements .accordion-1 .active .title:hover{
    background-color: transparent;
}

.ui-elements .accordion-1{
    position: relative;
}

.ui-elements .accordion-1 .title h3{
    margin-bottom: 0;
    background: none;
    font-size: 21px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;

}

.ui-elements .accordion-1 .active .title{
    background-color: transparent;
}
.ui-elements .accordion-1 .title{
    background-color: transparent;
    padding-left: 0;
    position: relative;
}

.ui-elements .accordion-1 .title::after {
  content: "";
  position: absolute;
  display: block;
  right: 24px;
  top: 13px;
  width: 24px;
  height: 24px;
  background: url(../images/icons/arrow_back_ios_new.svg) center right no-repeat;
  transform: rotate(-180deg);
  transition: all .3s ease-in-out;
}

.ui-elements .accordion-1 .active .title::after {
  transform: rotate(0deg);
}

.ui-elements .accordion-1 .content p {
    padding: 0;
    margin-bottom: 0.5rem;
}

.ui-elements .accordion li.active .content{
    padding: 0;
}

.ui-elements .accordion-1 .content{
    border: none;
}

.page-item.active .page-link {
    background: #9ad3b5;
    border-color: #9ad3b5;
}

.page-link:hover {
    background: #9ad3b5;
    color: #fff;
}

.page-item:first-child .page-link {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}
.pagination-wrapper {
    display: flex;
}

.pagination {
    margin: 0 auto;
}


.mt-70{
margin-top: 70px!important;
}

.mb-70{
margin-bottom: 70px!important;
}

a{
    color: #000;
}
a:hover{
    color: #000;
}
.how-it-works  p {
    margin-top: 0;
    margin-bottom: 0;
}
a.insta{

 line-height: 20px;
}
a.insta i{
 font-size: 20px;
}

p{
    color: #626464;
}

.content p{
    margin-bottom: 10px!important;
}

.a-retenir{
    background: url(/images/bookmark_added.webp) left center no-repeat;
    padding-left: 35px;
}

.aside-cta ul{
    margin-left: -12px;
    margin-right: 20px;
}

.aside-cta ul li{
    margin-top: 5px;
}

h2 span{
    color: #9ad3b5;
    font-weight: 500;
}
.container-map{
    z-index: 1;
    height: 545px;
}
.container-map #map-canvas{
    border-radius: 16px;
    height: 100%;
}

ol{
    padding-left: 16px;
}

ol li{
    margin-bottom: 10px;
}

p.remarque {
    border: 1px solid #D6E1E0;
    font-size: 14px;
    border-radius: 8px;
    padding: 16px 16px 16px 48px;
    background: url(/images/remarque.webp) 16px center no-repeat;
}

.imdw-page-container .imdw-page-reviews-section .imdw-page-review-section .imdw-page-full-review {
    display: block;
    border-color: #DDDDDD!important;
    padding: 30px;
    margin-bottom: 20px;
    color: #3e3e3e;
    font-size: 14px;
    border-radius: 16px;
}

.imdw-page-container .imdw-page-header{
    border-radius: 16px;
    background: none!important;
    padding: 25px 0!important;
}

.imdw-page-container .imdw-page-reviews-section .imdw-page-review-section .imdw-page-review-response{
    border-radius: 16px!important;
}


.eapps-instagram-feed-popup-item-text.es-post-text a{
    color: #000!important;
}

#eapps-instagram-feed-popup-1 .eapps-instagram-feed-popup-item .eapps-instagram-feed-posts-item-user-follow-link a, #eapps-instagram-feed-popup-1 .eapps-instagram-feed-popup-item .eapps-instagram-feed-posts-item-user-follow-link a:hover{
 color: #9AD3B5!important;
}

.eapps-instagram-feed-posts-grid-load-more-container.eapps-instagram-feed-posts-grid-load-more-enabled{
    display: none!important;
}

.img-agence{
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(113, 113, 113, 0.15);
}
.mini-container-map{height: 265px; min-width: 265px; position: relative; margin-bottom: 20px; margin-top: 20px;}
#mini-map-canvas{height: 100%; width: 100%; min-width:265px; border-radius: 16px;}

.form-control:focus, .search-form .form-group input:focus-visible{
    box-shadow: none;
    border: none;
    outline: none!important;
}

#vitahomeForm .form-group.active, #parrainage-form .form-group.active{
    border: 1px solid #3dc27c;
}

.dsp-none{
    display: none;
}

.mfp-bg{
        z-index: 99999;
}

.mfp-wrap {
    z-index: 100000;
}

a.popup-img.btn-absolute {
    position: absolute;
    margin: 0 auto;
    top: 5%;
    font-weight: 500;
    font-size: 14px;
    right: 10%;
    padding: 16px 24px;
}

.mb-120{
    margin-bottom: 120px;
}

.bg-light{
    background-color: #F2F7F7!important;
}

.home-part-4 img{
    width: 100%;
}

.home-part-5{
    background-color: rgb(159,205,214);
}

.btn-parrainage{
    width: 100%;
    height: 63px;
}

.visible-sm{
    display: none;
}

.btn-to-map{
    border-radius: 8px;
    background: url(../images/map-icon.svg) no-repeat #272B2B;
    padding: 6px 35px 6px 12px;
    background-position-x: 92%;
    background-position-y: center;
    color: #F2F7F7;
    font-size: 16px;
    float: right;
}

@media (max-width: 768px) {
    .infowindow h3{
            font-size: 16px;
    width: calc(100% - 14px);
    }
    .title-detail-annonce h1 {
    font-size: 26px;
    margin-top: 30px;
}
.single.detail-wrapper.col-md-4.text-right{
    text-align: left!important;
}
.listing-title-bar{
    font-size: 26px;
        margin-top: 10px;
}
    .annonces-title h1{
        margin-bottom: 10px;
    }
    .half-map-view .google-maps-right #map-canvas {
    height: calc(100vh - 82px);
}
    .input-group{
        width: 60%!important;
    }
    .btn-to-map{
        float: right;
        display: contents;
        margin-top: -12px;
    }
    .visible-sm{
        display:inherit;
    }
    .hidden-sm{
        display:none;
    }
    .event-container{
        line-height: 25px;
    }
    .home-part-1{
        text-align: center;
    }
    .home-part-1 h1{
        font-size: 2.5em;
    }
    .home-part-2 {
        padding-top: 50px;
        padding-bottom: 40px;
        text-align: center;
    }

    .home-part-2 .data{
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .home-part-3 {
        margin-top: 50px;
        margin-bottom: 50px;
        text-align: center;
    }

    .mt-180, .mt-100{
        margin-top: 50px!important;
    }

    .home-part-3 .mt-180 h2{
        margin: 35px 0;
    }

    .home-part-3 img{
        width: 100%;
    }
    .home-part-3 .engagement img{
        width: auto;
    }

    .home-part-3 .mt-180 .text-right{
        text-align: left;
    }
    .engagement .btn-dark{
        margin-bottom: 50px;
    }

    .mb-180, .mb-100{
        margin-bottom: 50px!important;
    }
    .home-part-5{
        text-align: center;
    }
    .home-part-5 p{
        display: none;
    }
    .tuile-maison a.img{
        width: 100%;
     .tuile-maison img{
    }
    width: 100%;
     }
    .tuile-maison{
        margin-left: 0;
        margin-right: 0;
    }
    .navigation .row, .navigation.row{
        margin-left: 0;
        margin-right: 0;
    }
    .navigation ul{
            margin-left: 15px;
    }
    footer .title {
        margin: 35px 0 10px 20px;
        display:block;
        font-size: 1.8em;
    }
    footer .col-md-6:first-child .title {
        margin: 20px 0 0 20px;
    }
    .first-footer .top-footer{
        padding-top: 35px;
        padding-bottom: 50px;
    }
    .title.alt{
        margin-top: 10px;
        margin-left: 0;
        font-size: 1.3em;
        margin-bottom: 0;
    }
    .second-footer {
        padding: 10px 20px;
    }
    .aside-cta{
        margin-top: 35px;
    }
    section.how-it-works{
        padding-top: 50px;
    }
    div.parrainage{
        margin-top: 35px;
    }
    #logo {
        margin-top: -24px;
    }

    .header-widget{
        border: none;
        position: absolute;
        right: 10px;
    }

    #btn-menu-search{
        width: 46px;
        float: right;
        margin-right: 0;
        margin-left: 10px;
    }

    #btn-menu-search .text{
        display: none;
    }

    .btn-profile{
        display: none;
    }
    #header-container.search-open .search-form{
        background: #FFF;
    }
    #header-container .container-search {
        pointer-events: none;
    }
    #header-container.search-open .container-search{
        pointer-events: auto;
    }
    .search-form .form-group{
        margin-top: 15px;
    }
    .search-form .btn-search{
        right: 10px;
        bottom: 8px;
    }
    #header-container.search-open{
        height: 82px;
    }
    .pagination{
        display: block;
    }
    li.page-item{
        float: left;
    }
    .google-maps-right{
        margin-right: 15px;
        margin-left: -15px;
    }
    section.portfolio{
        margin-top: -8px;
        padding-top: 40px;
    }
    .fx-google-map-right{
        display: block;
        width: 100%;
    }
    .pro-wrapper{
        margin-top: -30px;
    }

    .maisons-1 h1 {
        font-size: 2.5em;
        line-height: 45px;
    }

    .maisons-1 h2 {
        font-size: 2em;
        line-height: 36px;
    }
    .maisons-1 h1.mt-5{
        margin-top: 0!important;
    }
    .maisons-1 img{
        margin-top: -155px;
    }
    .maisons-2 {
        padding-top: 72px;
    }
    .maisons-2 .h1, .maisons-3 h2, .maisons-4 h2, .maisons-5 h2, .maisons-6 h2 {
        font-size: 2em;
        line-height: 40px;
    }
    .maisons-4 a.link-arrow{
        margin-bottom: 30px!important;
        display: inline-block;
    }
    .maisons-5{
        margin-top: 150px;
        padding-top: 98px;
    }
    .img-garantie {
        margin-top: 20px;
        margin-bottom: -55px;
        position: absolute;
    }
    .ui-elements .accordion-1 .title h3{
        padding-right: 20px;
    }
    .typeahead{
        z-index: 99999;
        max-width: 95%;
    }
    .container-map{
    }
    .container-map #map-canvas{
        position: relative;
        margin-top: 20px;
    }
    h1, h2, h3, h4, h5, p, .data, .tuile-maison{
        text-align: left;
    }
    h2.h1{
        font-size: 2em;
    }

    .bg-home1, .bg-home2{
        display: none;
    }
}