.typeahead li a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

#filterForm{
    margin: 35px 0;
}

.ui-slider {
    margin-top: 12px;
}

#cookieBar {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    z-index: 10000;
    background: #212121;
    color: #FFF;
    display: none;
}